import Picker from 'react-mobile-picker-scroll';
import { forwardRef } from 'react';
import { css, styled } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const DatePickerWrapperStyled = Styled(
  'DatePicker',
  'DatePickerLabelStyled'
)(styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  padding: 10px;

  ${({ theme: { application } }) => css`
    border: 1px solid ${application.palette.whitesmoke};
    background-color: ${application.palette.white};
  `}
`);

export const DatePickerLabelStyled = Styled(
  'DatePicker',
  'DatePickerLabelStyled'
)(styled('div')`
  cursor: pointer;
  width: 100%;
  height: inherit;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 30px 0 10px;
  font-size: 16px;
  font-weight: 400;
  margin-right: 5px;
  border: 1px solid transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &.focused {
    outline: 2px solid rgba(0, 0, 0, 0.3);
  }

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.white};
  `}
`);

export const DatePickerActiveOptionStyled = Styled(
  'DatePicker',
  'DatePickerActiveOptionStyled'
)(styled('div')`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme: { application }, $isActive }) => css`
    color: ${$isActive ? application.palette.black : '#B0B0B0'};
  `}
`);

export const DatePickerStyled = Styled(
  'DatePicker',
  'DatePickerStyled'
)(styled('div')`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;

  & svg {
    position: absolute;
    right: 5px;
    width: 25px;
    height: 100%;
  }

  ${({ theme: { application }, error }) =>
    error &&
    css`
      border: 1px solid ${application.palette.danger};

      & ${DatePickerWrapperStyled} {
        border-color: ${application.palette.danger};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;

      &::after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.5);
      }
    `}
`);

export const DatePickerControllerStyled = Styled(
  'DatePicker',
  'DatePickerControllerStyled'
)(styled(
  forwardRef((props, ref) => (
    <div ref={ref} className={props.className}>
      <Picker {...props} />
    </div>
  ))
)`
  & .picker-container,
  & .picker-inner {
    padding: 0;
    font-size: 1em;
  }

  & .picker-container .picker-column .picker-item {
    padding: 0 5px;
  }

  ${({ variant, innerWidth }) => {
    const calcSize = innerWidth * 0.075;

    switch (variant) {
      case 'date': {
        return css`
          & .picker-column {
            font-size: ${calcSize && calcSize < 18 ? calcSize : 18}px;
          }

          & .picker-column:first-child {
            flex-grow: 2;
          }
        `;
      }
      case 'time': {
        return css`
          & .picker-column {
            font-size: ${calcSize && calcSize < 18 ? calcSize : 18}px;
            flex-grow: 2;
          }

          & .picker-column:last-child {
            flex-grow: 1;
          }
        `;
      }
    }
  }}
`);
