import { css, styled } from '@mui/material';

import { ButtonStyled } from '@framework/styles/App';

import Styled from '@framework/decorators/Styled';

export const CartButtonStyled = Styled(
  'Cart',
  'CartButtonStyled'
)(styled(ButtonStyled)`
  width: 100%;
  background-color: transparent;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
  `}
`);
