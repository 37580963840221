import { inject, observer } from 'mobx-react';
import { Box, useTheme } from '@mui/material';
import { SettingsOutlined } from '@mui/icons-material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import View from '@framework/decorators/View';

import { PageContainerStyled, NotFoundGearStyled } from '@framework/styles/App';

const NotFoundView = View('NotFound')(props => {
  const theme = useTheme(),
    navigate = useNavigate(),
    {
      store: { localesStore, helperStore },
    } = props;

  const handleLinkClick = useCallback(() => {
    helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
    helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
    helperStore.cartAnimationDirection = helperStore.Direction.Rtl;
    navigate('/');
  }, []);

  return (
    <PageContainerStyled>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '128px',
            color: theme.application.palette.black,
          }}
        >
          4
          <NotFoundGearStyled>
            <SettingsOutlined />
          </NotFoundGearStyled>
          4
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            color: theme.application.palette.gray,
          }}
        >
          {localesStore.translate('notfound.label.description')}
          <Box
            onClick={handleLinkClick}
            sx={{
              cursor: 'pointer',
              marginTop: '20px',
              color: theme.application.palette.blue,
            }}
          >
            {localesStore.translate('notfound.controls.link')}
          </Box>
        </Box>
      </Box>
    </PageContainerStyled>
  );
});

export default inject('store')(observer(NotFoundView));
