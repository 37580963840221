import { action, makeObservable, observable } from 'mobx';

import Store from '@framework/decorators/Store';

export class LocalesStore {
  isReady = false;
  currentLocale = 'de';
  translates = {};

  constructor(store) {
    this.store = store;

    makeObservable(this, {
      isReady: observable,
      translates: observable,
      currentLocale: observable,
    });

    this.init();
  }

  fetch = async url => {
    const data = await fetch(url);
    return await data.json();
  };

  init = action(async () => {
    this.translates = (await this.fetch(`/locales/${this.currentLocale}.json`)) ?? {};
    this.isReady = true;
  });

  translate = key => this.translates[key] || '';
}

export default Store('LocalesStore')(LocalesStore);
