import { css, styled } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const AnswerStyled = Styled(
  'Requests',
  'AnswerStyled'
)(styled('span')`
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;

  ${({ theme: { application }, $isActive }) => css`
    color: ${$isActive ? application.palette.black : application.palette.darkgray};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      font-size: 16px;
    }
  `}
`);

export const DisabledOverlayStyled = Styled(
  'Requests',
  'DisabledOverlayStyled'
)(styled('div')`
  cursor: not-allowed;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
`);
