import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class CameraModel {
  isToggled = false;
  isPreviewToggled = false;
  
  image = null;

  resolve = () => {};
  reject = () => {};

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
      isPreviewToggled: observable,
      image: observable,
      resolve: observable,
      reject: observable,
    });
  }
  
  register = action((resolve, reject) => {
    this.resolve = (value) => {
      this.closePromise();
      resolve(value);
    };

    this.reject = value => {
      this.closePromise();
      reject(value);
    };
  });
  
  closePromise = action(() => {
    this.isToggled = false;
    this.isPreviewToggled = false;
    this.image = null;
    this.resolve = () => {};
    this.reject = () => {};
  })

  cameraPromise = action(() => {
    this.isToggled = true;
    return new Promise(this.register);
  });
  
  handleCaptureCall = action((value) => {
    this.isToggled = false;
    this.isPreviewToggled = true;
    
    this.image = value;
  })
  
  handleRetakeCall = action(() => {
    this.isPreviewToggled = false;
    this.isToggled = true;
    
    this.image = null;
  })
  
  handleCloseCall = action(() => {
    this.reject('exit');
  });
  
  reset = action(() => {
    this.closePromise();
  })
}

export default Model('CameraModel')(CameraModel);
