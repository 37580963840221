import { isReactComponent } from './helpers';

const Modal = (modalModel, target = 'default', debug = false) => {
  let importedModel = null;

  try {
    importedModel = require(`@tenant/modals/${modalModel}`)[target];
  } catch (error) {
    if (debug) console.error(error);
  }

  return rootModel => (isReactComponent(importedModel) ? importedModel : rootModel);
};

export default Modal;
