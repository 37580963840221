import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const Reservation = memo(() => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
    width="50px"
    height="50px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <image
      width="100"
      height="100"
      x="0"
      y="0"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAQAAADa613fAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN RQfnAhgRDxZGUM1TAAADEklEQVR42u3Zz3GbQBiH4TeZFEAJSgWhA5F7ZqJUYDqIUoGcCqIObFcQ dSBlxnfhe2aMKzCe8V05IGH+LbtCu7Bkvp8usgTLPsuyH8IgkUgkEolEIpFI7CUgGLsLdhh79tOn BOw5cJg65cSYOKXMmDClzpgopcxISKZKqTICgmlS6oz8s8lR2hgTpKgYE6N0MSZE0TEmQjFhTIBi yvCccg7DY8q5DE8pfRgeUvoyPKNcwvCIcinDE4oNhgcUW4yRKTYZI1JsM0aiuGCMQHHFGJjikjEg xTVjIEqZ8dfhif/S7znYB2PGlrD4a0lmtNeCT0QAZOz4Q2Kwz2vpfciWz4bHMmRUnxpGBnuseG48 aXzkSrtn5O7pZPPhpw6yaEGcXltNx06QV9uUMuPVCBKQKRkHDjyXJqkacm/3sq+uVPeGZyTgmowD KWsWRETEbCoUdcdOkJ3NFay+4O6MrxEIiGufzIr9D2wNINYW42bd0EN0/y+8LVqMFVuUIZYozfKn hyTcaFo9tfFoBKlTeiRuqeI6yLpzrPPMinZDI0iVomz7fccB8zwQGZakmO8A3HSuSil3x3cLwyHN iHio9eqM5OOwq8zLrjMSlmrHo2I2z5gzZ1VMlDlz5jV284zkvdldcodXH1k1pF4021eltgqTGUHa enNB1JBNo4PXiqGpbxfXtlBDLEYFWbZW8EVrG9VtbxvfjwrJExTd685bLUlavu0JeX/e5p3JeDq+ izu3Wx7XoBfjdWtgCMVEWWnAMS/AgtR3yExT3xNifrq9Crqjv0VZF/Nfd6uiigfXCMB16TrZ87X0 TcCK3zZrwSUxuY0PK0XvmS2/uGFbOlOzzmN4sPy+UdLOX4hrFxDbUwsgISxuDOt54htLB8d0AskX 2I/c8VL59IEfhGycHNH4udb5SYmBkICQjJTUZtUYEpIngWHqhZupNUIE4lsE4lsE4lsE4lv+G0i/ W5Qr5s56NBsCkhwBsTPGW1KXjev+qWbzFbodJ92vPzuv7Pxz/q7HWYkcj1bGxu3EkkgkEolEIpFI JF7kH/Y8Y8UXshutAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTAyLTI0VDE3OjE1OjIyKzAxOjAw 64CTmAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMi0yNFQxNzoxNToyMiswMTowMJrdKyQAAAAA SUVORK5CYII="
    />
  </svg>
));

export default Icon('Reservation')(Reservation);
