import { inject, observer } from 'mobx-react';
import { Box, useTheme, Fade } from '@mui/material';
import { Fragment, useEffect, useCallback, useState } from 'react';
import { Check, Close, KeyboardArrowDown, KeyboardArrowUp, OpenInFull, CloseFullscreen } from '@mui/icons-material';

import Modal from '@framework/decorators/Modal';

import Loader from '@framework/icons/Loader';

import SelectComponent from '@framework/components/Select';

import {
  DialogStyled,
  ModalAnimation,
  DialogTitleStyled,
  ButtonStyled,
  MoreDetailsStyled,
  RectangleWrapperStyled,
} from '@framework/styles/App';

import {
  ArticleButtonStyled,
  ArticleImageStyled,
  AvailabilityButtonStyled,
  AvailabilityBlockStyled,
  ArticleContentStyled,
} from '@framework/styles/Article';

const ArticleModal = Modal('ArticleModal')(props => {
  const {
    models: { ArticleModel, CartModel },
    store,
  } = props;

  const [touchStart, setTouchStart] = useState(0),
    [isResizable, setIsResizable] = useState(false);

  const theme = useTheme(),
    isMobile = store.helperStore.isMobile();

  const {
    isGreenChoice,
    isStore2Store,
    isToggled,
    isColorsBarToggled,
    isComplaintsWindow,
    isDetailsOnlyWindow,
    isAvailableInCurrentStore,
    isAvailabilityScreen,
    isAvailabilityLoading,
    isSimilarArticlesLoading,
    currentArticleGroup,
    currentArticle,
    currentGroup,
    loadedImages,
    galleryMounted,
    articleMounted,
    sizeFilter,
    availabilityMounted,
    currentGalleryIndex,
    articleColors,
    articleColorName,
    articleCurrency,
    articlePrice,
    articleSizes,
    articleCare,
    availableStores,
    articleTotalQuantity,
    articleRecommendations,
    setArticleGroupByIndex,
    handleCloseCall,
    handleColorsCall,
    handleAddCartCall,
    handleReservationCall,
    handleImageChange,
    handleToggleAvailability,
    handleSizeChange,
    handleLtrSwipe,
    handleRtlSwipe,
    handleRecommendationClick,
    handleComplaintsClick,
  } = ArticleModel;

  useEffect(() => articleMounted(), [currentArticleGroup]);
  useEffect(() => galleryMounted(), [currentArticleGroup]);
  useEffect(() => availabilityMounted(), [currentArticle, currentArticleGroup]);

  const articleInCart = CartModel.cart.find(article => article.productId === currentGroup);

  const handleReferenceAssignition = useCallback(
      node => {
        if (node) node.scrollTop = 0;
      },
      [currentArticleGroup]
    ),
    handleResizeToggle = useCallback(() => {
      setIsResizable(prev => !prev);
    }, []);

  return (
    <DialogStyled
      fullWidth
      fullScreen={isMobile}
      maxWidth="lg"
      open={isToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleCloseCall}
    >
      {isToggled ? (
        <Fragment>
          <DialogTitleStyled>
            <Box
              component="span"
              sx={
                isMobile
                  ? {
                      display: 'block',
                      fontSize: '21px',
                      textAlign: 'center',
                      width: '100%',
                    }
                  : {}
              }
            >
              {!isMobile && isGreenChoice && (
                <Box
                  component="img"
                  src="images/ig-green-choice.png"
                  sx={{
                    display: 'block',
                    margin: '10px 0',
                    alignSelf: 'flex-start',
                    height: '24px',
                  }}
                />
              )}
              {isMobile
                ? store.localesStore.translate('modal.article.label.title')
                : currentArticleGroup?.title || store.localesStore.translate('modal.article.label.title')}
            </Box>
            <Box sx={{ cursor: 'pointer' }} onClick={handleCloseCall}>
              <Close />
            </Box>
          </DialogTitleStyled>
          <ArticleContentStyled grid ref={handleReferenceAssignition}>
            <Box
              sx={{
                position: 'relative',
                top: '0px',
                display: isMobile ? 'flex' : 'grid',
                gridTemplateColumns: isMobile ? 'unset' : 'calc(100% - 400px) 400px',
                gridTemplateRows: isMobile ? 'unset' : '100%',
                flexDirection: isMobile ? 'column' : 'unset',
                height: '100%',
                flexGrow: '1',
                overflow: isMobile ? 'unset' : 'hidden',
              }}
            >
              {isMobile && (
                <Box sx={{ padding: '15px 0' }}>
                  {isGreenChoice && (
                    <Box
                      component="img"
                      src="images/ig-green-choice.png"
                      sx={{
                        margin: '10px 0',
                        alignSelf: 'flex-start',
                        height: '24px',
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      fontSize: '21px',
                      fontWeight: '600',
                      color: theme.application.palette.black,
                      marginBottom: '10px',
                    }}
                  >
                    {currentArticleGroup?.title}
                  </Box>

                  <Box
                    sx={{
                      marginBottom: '10px',
                      fontSize: '14px',
                      color: theme.application.palette.darkgray,
                    }}
                  >
                    {store.localesStore.translate('placeholder.label.articleNumber') +
                      ': ' +
                      currentArticleGroup?.articleNumberText}
                  </Box>
                </Box>
              )}

              {!isMobile &&
                (!isAvailabilityScreen ? (
                  <Box sx={{ position: 'relative' }}>
                    {isSimilarArticlesLoading || !loadedImages.length ? (
                      <Loader />
                    ) : (
                      <Fragment>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: '1',
                          }}
                        >
                          <Box
                            component="span"
                            sx={{
                              marginTop: '3px',
                              marginBottom: '17px',
                              color: theme.application.palette.darkgray,
                            }}
                          >
                            {store.localesStore.translate('placeholder.label.articleNumber') +
                              ': ' +
                              currentArticleGroup?.['articleNumberText']}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            height: isDetailsOnlyWindow || isComplaintsWindow ? '100%' : 'calc(100% - 175px)',
                            justifyContent: 'center',
                            position: 'relative',
                          }}
                        >
                          {currentGalleryIndex === 0 &&
                            Boolean(currentArticleGroup.additionalProperties?.promotion) && (
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: '0px',
                                  left: '125px',
                                  fontSize: '18px',
                                  fontWeight: '600',
                                  paddingBottom: '3px',
                                  zIndex: '3',
                                  borderBottom: `2px solid ${
                                    currentArticleGroup.additionalProperties.promotion === 'sale'
                                      ? theme.application.palette.danger
                                      : theme.application.palette.black
                                  }`,
                                  color:
                                    currentArticleGroup.additionalProperties.promotion === 'sale'
                                      ? theme.application.palette.danger
                                      : theme.application.palette.black,
                                }}
                              >
                                {store.localesStore.translate(
                                  'catalog.label.promotion.' + currentArticleGroup.additionalProperties.promotion
                                )}
                              </Box>
                            )}
                          <Box
                            onClick={handleResizeToggle}
                            sx={{
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '0px',
                              right: '15px',
                              background: theme.application.palette.white,
                              zIndex: '10',
                            }}
                          >
                            <RectangleWrapperStyled>
                              {!isResizable ? <OpenInFull /> : <CloseFullscreen />}
                            </RectangleWrapperStyled>
                          </Box>

                          <Box
                            sx={{
                              position: 'absolute',
                              left: '0px',
                              display: 'grid',
                              gridTemplateColumns: '100px',
                              gridAutoRows: '100px',
                              rowGap: '10px',
                              height: '100%',
                              paddingRight: '10px',
                              overflow: 'hidden auto',
                              zIndex: '1',
                            }}
                          >
                            {loadedImages?.map((image, index) => (
                              <Box
                                key={index}
                                sx={{
                                  cursor: 'pointer',
                                  width: '100%',
                                  height: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  overflow: 'hidden',
                                  border:
                                    index === currentGalleryIndex
                                      ? `2px solid ${theme.application.palette.black}`
                                      : `1px solid ${theme.application.palette.darkgray}`,
                                }}
                                onClick={() => handleImageChange(index)}
                              >
                                {image}
                              </Box>
                            ))}
                          </Box>
                          <Box
                            sx={{
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                              padding: '0px 60px 0px 120px',
                              zIndex: '0',
                            }}
                          >
                            <Fade key={currentGalleryIndex} in={!!(currentGalleryIndex + 1)}>
                              <ArticleImageStyled
                                key={currentGalleryIndex}
                                isResizable={isResizable}
                                src={loadedImages[currentGalleryIndex]?.props.src}
                                alternateSrc={loadedImages[currentGalleryIndex]?.props.alternateSrc}
                              />
                            </Fade>
                          </Box>
                        </Box>
                        {
                          !isDetailsOnlyWindow && !isComplaintsWindow && (
                            <Box
                              sx={{
                                marginTop: '10px',
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 100px)',
                                gridTemplateRows: 'max-content 100px',
                                rowGap: '5px',
                                columnGap: '20px',
                                height: '140px',
                              }}
                            >
                              {Boolean(articleRecommendations.length) && (
                                <Fragment>
                                  <Box
                                    sx={{
                                      color: theme.application.palette.darkgray,
                                      gridColumn: 'span 3',
                                    }}
                                  >
                                    {store.localesStore.translate('modal.article.label.similar')}
                                  </Box>
                                  {articleRecommendations.map(({ id, imageUrl }) => (
                                    <Box
                                      key={id}
                                      onClick={() => handleRecommendationClick(id)}
                                      component="img"
                                      src={imageUrl}
                                      sx={{
                                        cursor: 'pointer',
                                        height: '100%',
                                      }}
                                    />
                                  ))}
                                </Fragment>
                              )}
                            </Box>
                          )
                        }
                      </Fragment>
                    )}
                  </Box>
                ) : (
                  <Box sx={{ position: 'relative' }}>
                    {isAvailabilityLoading ? (
                      <Loader />
                    ) : (
                      <Fragment>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: '1',
                          }}
                        >
                          <Box
                            component="span"
                            sx={{
                              marginTop: '3px',
                              marginBottom: '17px',
                              color: theme.application.palette.darkgray,
                            }}
                          >
                            {store.localesStore.translate('placeholder.label.articleNumber') +
                              ': ' +
                              currentArticleGroup?.['articleNumberText']}
                          </Box>
                        </Box>
                        <Fade in={isAvailabilityScreen}>
                          <AvailabilityBlockStyled>
                            <Box
                              sx={{
                                width: '100%',
                                height: '100%',
                                paddingRight: '20px',
                                overflow: 'hidden auto',
                              }}
                            >
                              {availableStores.map(data => (
                                <Box
                                  key={data.storeName}
                                  sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '20px 20px 20px 0',
                                    borderBottom: `2px solid ${theme.application.palette.whitesmoke}`,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Box
                                      component="span"
                                      sx={{
                                        fontSize: '21px',
                                        fontWeight: '600',
                                      }}
                                    >
                                      {data.storeName}
                                    </Box>

                                    {data.storeLocation && (
                                      <Box component="span" sx={{ marginTop: '10px' }}>
                                        {data.storeLocation + ', ' + data.storePhone}
                                      </Box>
                                    )}
                                  </Box>
                                  <Box
                                    component="span"
                                    sx={{
                                      fontSize: '21px',
                                      fontWeight: '600',
                                    }}
                                  >
                                    {data.available}
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                            <Box
                              sx={{
                                display: 'inline-block',
                                marginTop: '20px',
                                height: '60px',
                              }}
                            >
                              <AvailabilityButtonStyled onClick={handleToggleAvailability}>
                                {store.localesStore.translate('modal.article.controls.availability.close')}
                              </AvailabilityButtonStyled>
                            </Box>
                          </AvailabilityBlockStyled>
                        </Fade>
                      </Fragment>
                    )}
                  </Box>
                ))}
              {isMobile &&
                (!isAvailabilityScreen ? (
                  <Box sx={{ position: 'relative' }}>
                    {!loadedImages.length ? (
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          minHeight: '375px',
                        }}
                      >
                        <Loader />
                      </Box>
                    ) : (
                      <Fragment>
                        <Box
                          sx={{
                            position: 'relative',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginBottom: '20px',
                          }}
                        >
                          <Box
                            sx={{
                              width: '75%',
                              marginBottom: '10px',
                              minWidth: '240px',
                              height: '400px',
                            }}
                            onTouchStart={e => setTouchStart(e.targetTouches[0].clientX)}
                            onTouchEnd={e => {
                              const touchEnd = e.changedTouches[0].clientX;
                              if (!isResizable) {
                                if (touchStart - touchEnd < -75) handleLtrSwipe();
                                if (touchStart - touchEnd > 75) handleRtlSwipe();
                              }
                            }}
                          >
                            {currentGalleryIndex === 0 &&
                              Boolean(currentArticleGroup.additionalProperties?.promotion) && (
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '0px',
                                    left: '0px',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    paddingBottom: '3px',
                                    zIndex: '3',
                                    borderBottom: `2px solid ${
                                      currentArticleGroup.additionalProperties.promotion === 'sale'
                                        ? theme.application.palette.danger
                                        : theme.application.palette.black
                                    }`,
                                    color:
                                      currentArticleGroup.additionalProperties.promotion === 'sale'
                                        ? theme.application.palette.danger
                                        : theme.application.palette.black,
                                  }}
                                >
                                  {store.localesStore.translate(
                                    'catalog.label.promotion.' + currentArticleGroup.additionalProperties.promotion
                                  )}
                                </Box>
                              )}
                            <Box
                              onClick={handleResizeToggle}
                              sx={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0px',
                                right: '0px',
                                background: theme.application.palette.white,
                                zIndex: '10',
                              }}
                            >
                              <RectangleWrapperStyled>
                                {!isResizable ? <OpenInFull /> : <CloseFullscreen />}
                              </RectangleWrapperStyled>
                            </Box>
                            <Fade key={currentGalleryIndex} in={!!(currentGalleryIndex + 1)}>
                              <ArticleImageStyled
                                key={currentGalleryIndex}
                                isResizable={isResizable}
                                src={loadedImages[currentGalleryIndex]?.props.src}
                                alternateSrc={loadedImages[currentGalleryIndex]?.props.alternateSrc}
                              />
                            </Fade>
                          </Box>

                          {loadedImages.length > 1 && (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {loadedImages?.map((image, index) => (
                                <Box
                                  key={index}
                                  onClick={() => handleImageChange(index)}
                                  sx={{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    marginLeft: '5px',
                                    background:
                                      currentGalleryIndex === index
                                        ? theme.application.palette.darkgray
                                        : theme.application.palette.gainsboro,
                                  }}
                                />
                              ))}
                            </Box>
                          )}
                        </Box>
                        
                        {
                          !isDetailsOnlyWindow && !isComplaintsWindow && (
                            <Box
                              sx={{
                                marginTop: '10px',
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 100px)',
                                gridTemplateRows: 'max-content 100px',
                                rowGap: '5px',
                                columnGap: '20px',
                                height: Boolean(articleRecommendations.length) ? '140px' : '0px',
                              }}
                            >
                              {Boolean(articleRecommendations.length) && (
                                <Fragment>
                                  <Box
                                    sx={{
                                      color: theme.application.palette.darkgray,
                                      gridColumn: 'span 3',
                                    }}
                                  >
                                    {store.localesStore.translate('modal.article.label.similar')}
                                  </Box>
                                  {articleRecommendations.map(({ id, imageUrl }) => (
                                    <Box
                                      key={id}
                                      onClick={() => handleRecommendationClick(id)}
                                      component="img"
                                      src={imageUrl}
                                      sx={{
                                        cursor: 'pointer',
                                        height: '100%',
                                      }}
                                    />
                                  ))}
                                </Fragment>
                              )}
                            </Box>
                          )
                        }
                      </Fragment>
                    )}
                  </Box>
                ) : (
                  <Box sx={{ position: 'relative' }}>
                    {!availableStores.length ? (
                      <Loader />
                    ) : (
                      <Fade in={isAvailabilityScreen}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: '1',
                          }}
                        >
                          <AvailabilityBlockStyled>
                            <Box sx={{ width: '100%', height: '100%' }}>
                              {availableStores.map(data => (
                                <Box
                                  key={data.storeName}
                                  sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '20px 20px 20px 0',
                                    borderBottom: `2px solid ${theme.application.palette.whitesmoke}`,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Box
                                      component="span"
                                      sx={{
                                        fontSize: '21px',
                                        fontWeight: '600',
                                      }}
                                    >
                                      {data.storeName}
                                    </Box>

                                    {data.storeLocation && (
                                      <Box component="span" sx={{ marginTop: '10px' }}>
                                        {data.storeLocation + ', ' + data.storePhone}
                                      </Box>
                                    )}
                                  </Box>

                                  <Box
                                    component="span"
                                    sx={{
                                      fontSize: '21px',
                                      fontWeight: '600',
                                    }}
                                  >
                                    {data.available}
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                            <Box
                              sx={{
                                display: 'inline-block',
                                marginTop: '20px',
                                height: '60px',
                              }}
                            >
                              <AvailabilityButtonStyled onClick={handleToggleAvailability}>
                                {store.localesStore.translate('modal.article.controls.availability.close')}
                              </AvailabilityButtonStyled>
                            </Box>
                          </AvailabilityBlockStyled>
                        </Box>
                      </Fade>
                    )}
                  </Box>
                ))}
              {!isMobile || (isMobile && !isAvailabilityScreen) ? (
                <Box
                  sx={{
                    position: 'relative',
                    width: isMobile ? '100%' : '400px',
                    overflow: isMobile ? 'unset' : 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      overflow: isMobile ? 'unset' : 'hidden auto',
                      height: isMobile ? 'auto' : (isDetailsOnlyWindow ? '100%' : 'calc(100% - 68px)'),
                      position: isMobile ? 'static' : 'absolute',
                      left: '0px',
                    }}
                  >
                    <Box width={isMobile ? '100%' : '370px'}>
                      <Box
                        sx={{
                          marginTop: '3px',
                          padding: '20px',
                          background: theme.application.palette.whitesmoke,
                          border: `2px solid ${theme.application.palette.black}`,
                        }}
                      >
                        <Box sx={{ width: '100%', height: '40px' }}>
                          <SelectComponent
                            name="size"
                            data={articleSizes}
                            quantity={4}
                            defaultValue={sizeFilter || articleSizes[0]?.value}
                            onChange={handleSizeChange}
                            useLowerCaseValue={false}
                            useDefaultValue
                          />
                        </Box>

                        <Box sx={{ marginTop: '20px' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginBottom: '20px',
                            }}
                          >
                            <Box component="span">
                              {store.localesStore.translate('catalog.label.filters.type.color_filter') +
                                ': ' +
                                articleColorName}
                            </Box>

                            {articleColors.length > 4 &&
                              (!isColorsBarToggled ? (
                                <KeyboardArrowDown sx={{ cursor: 'pointer' }} onClick={handleColorsCall} />
                              ) : (
                                <KeyboardArrowUp sx={{ cursor: 'pointer' }} onClick={handleColorsCall} />
                              ))}
                          </Box>
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: 'repeat(4, 1fr)',
                              columnGap: '40px',
                              rowGap: '10px',
                              maxHeight: '200px',
                              overflow: 'hidden auto',
                              paddingRight: '10px',
                            }}
                          >
                            {articleColors?.map((color, index) => {
                              const { itemIndex, data, imageUrl } = color;

                              if (index > 3 && !isColorsBarToggled) return;

                              return (
                                <Box
                                  key={itemIndex}
                                  sx={{
                                    cursor: 'pointer',
                                    padding: '5px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    background: theme.application.palette.white,
                                    border:
                                      currentArticleGroup?.colorNumber === data?.colorNumber
                                        ? `1px solid ${theme.application.palette.black}`
                                        : 'unset',
                                  }}
                                  onClick={() => setArticleGroupByIndex(itemIndex)}
                                >
                                  <Box sx={{ width: '24px' }}>
                                    <img width="100%" src={imageUrl || 'images/ig-placeholder.png'} />
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          margin: '20px 0',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            fontWeight: '600',
                            fontSize: isMobile ? '18px' : '24px',
                            lineHeight: '1',
                          }}
                        >
                          {Boolean(articlePrice.minPrice) ? (
                            <Fragment>
                              {`${
                                articlePrice.minPrice ? articlePrice.minPrice.toFixed(2).replace('.', ',') : 'NaN'
                              } ${articleCurrency}`}
                              {articlePrice.maxPrice > 0 && (
                                <Box
                                  sx={{
                                    marginLeft: '10px',
                                    textDecoration: 'line-through',
                                    color: theme.application.palette.darkgray,
                                  }}
                                >
                                  {`${articlePrice.maxPrice.toFixed(2).replace('.', ',')} ${articleCurrency}`}
                                </Box>
                              )}
                            </Fragment>
                          ) : (
                            <span>{store.localesStore.translate('cockpit.label.availability')}</span>
                          )}
                        </Box>
                        <Box sx={{ marginLeft: '10px' }}>
                          {store.localesStore.translate('modal.article.label.price')}
                        </Box>
                      </Box>
                      
                      {
                        !isDetailsOnlyWindow && !isComplaintsWindow && (
                          <Box
                            sx={{
                              width: '100%',
                              height: '50px',
                              margin: '10px 0 25px',
                            }}
                          >
                            <ArticleButtonStyled $disabled={store.isKioskMode} onClick={handleToggleAvailability}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Box component="span" sx={{ lineHeight: 1 }}>
                                  {store.localesStore.translate(
                                    `modal.article.controls.availability.${isStore2Store ? 'stores' : isAvailableInCurrentStore ? 'current' : 'open'}`
                                  )}
                                </Box>
    
                                <Box component="span" sx={{ lineHeight: 0 }}>
                                  {Boolean(availableStores?.length) ? (
                                    (!isStore2Store && availableStores.some(store => Boolean(store.available))) || isAvailableInCurrentStore ? (
                                      <Check
                                        sx={{
                                          fill: theme.application.palette.darkgreen,
                                        }}
                                      />
                                    ) : (
                                      <Close
                                        sx={{
                                          fill: theme.application.palette.danger,
                                        }}
                                      />
                                    )
                                  ) : (
                                    <Box
                                      sx={{
                                        position: 'relative',
                                        width: '22px',
                                        height: '22px',
                                      }}
                                    >
                                      <Loader $inherited />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </ArticleButtonStyled>
                          </Box>
                        )
                      }
                      

                      <MoreDetailsStyled
                        onChange={ref => {
                          if (!ref) return;

                          ref?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                          });
                        }}
                        ex={
                          currentArticleGroup?.['descr']
                            ? {
                                border: `2px solid ${theme.application.palette.black}`,
                                padding: '10px',
                                marginBottom: '25px',
                              }
                            : {
                                border: `2px solid ${theme.application.palette.darkgray}`,
                                color: theme.application.palette.darkgray,
                                padding: '10px',
                                marginBottom: '25px',
                              }
                        }
                        labelValue={store.localesStore.translate('modal.article.controls.description')}
                        value={currentArticleGroup?.['descr'] || ''}
                        disabled={!currentArticleGroup?.['descr']}
                      />

                      <MoreDetailsStyled
                        onChange={ref => {
                          if (!ref) return;

                          ref?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                          });
                        }}
                        ex={
                          Boolean(articleCare.wash || articleCare.material)
                            ? {
                                border: `2px solid ${theme.application.palette.black}`,
                                padding: '10px',
                              }
                            : {
                                border: `2px solid ${theme.application.palette.darkgray}`,
                                color: theme.application.palette.darkgray,
                                padding: '10px',
                              }
                        }
                        labelValue={store.localesStore.translate('modal.article.controls.care')}
                        disabled={!articleCare.wash && !articleCare.material}
                      >
                        {articleCare?.material && <Box sx={{ marginBottom: '10px' }}>{articleCare.material}</Box>}
                        {articleCare?.wash && <Box>{articleCare.wash}</Box>}
                      </MoreDetailsStyled>
                      {isGreenChoice && (
                        <MoreDetailsStyled
                          onChange={ref => {
                            if (!ref) return;

                            ref?.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                            });
                          }}
                          ex={{
                            marginTop: '25px',
                            padding: '10px',
                            border: '2px solid #547761',
                            background: '#547761',
                            color: 'white',
                          }}
                          value={currentArticleGroup.shortDescr}
                          labelValue={
                            <Box letterSpacing="2px">
                              <b>GREEN</b>CHOICE
                            </Box>
                          }
                          disabled={!articleCare.wash && !articleCare.material}
                        />
                      )}
                    </Box>
                  </Box>
                  
                  
                  {
                    !isDetailsOnlyWindow && (
                       <Box
                          sx={{
                            width: isMobile ? '100%' : 'calc(100% - 30px)',
                            height: '60px',
                            marginTop: isMobile ? '30px' : '10px',
                            marginBottom: isMobile ? '20px' : '0',
                            position: isMobile ? 'static' : 'absolute',
                            bottom: '0',
                            left: '0',
                          }}
                        >
                          <ButtonStyled
                            $fullWidth
                            onClick={isComplaintsWindow ? handleComplaintsClick : isStore2Store ? handleReservationCall : handleAddCartCall}
                            disabled={
                              !isComplaintsWindow && (
                                !availableStores.some(store => !!store.available) ||
                                (articleInCart && articleTotalQuantity === articleInCart.quantity)
                              )
                            }
                          >
                            {isComplaintsWindow ? ("Auswählen") :isStore2Store
                              ? store.localesStore.translate('modal.article.controls.reservation')
                              : store.localesStore.translate('modal.article.controls.cart')}
                          </ButtonStyled>
                        </Box>
                    )
                  }
                </Box>
              ) : null}
            </Box>
          </ArticleContentStyled>
        </Fragment>
      ) : null}
    </DialogStyled>
  );
});

export default inject('store', 'models')(observer(ArticleModal));
