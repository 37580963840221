import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class HeaderModel {
  isToggled = false;

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
    });
  }

  toggleMenuBar = action(() => {
    this.isToggled = !this.isToggled;
  });

  handleMenuBtnCall = () => {
    this.toggleMenuBar();
  };
}

export default Model('HeaderModel')(HeaderModel);
