import { styled, css, Input } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const AuthOverlayStyled = Styled(
  'Auth',
  'AuthOverlayStyled'
)(styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow-x: hidden;
  background: black;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${props =>
    props.$dev &&
    css`
    & ${AuthLogoStyled} {
      position: relative;
    }

    & ${AuthLogoStyled} > img {
      opacity: 0.3;
    }

    & ${AuthLogoStyled}::after {
      content: '[prelive]';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      letter-spacing: 10px;
      line-height: 3;
      font-size: 40px;
      font-weight: 400;
      color: ${props.theme.application.palette.danger};
    }
  `}

  ${props =>
    props.$url
      ? css`
          background-image: linear-gradient(to right, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url(${props.$url});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;
        `
      : css`
          background-color: transparent;
        `}
`);

export const AuthFormStyled = Styled(
  'Auth',
  'AuthFormStyled'
)(styled('form')`
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
  padding: 60px 45px 45px;

  ${({ theme: { application } }) => css`
    border: solid 3px ${application.palette.white};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      padding: 0 25px;
      border-color: transparent;
    }
  `}
`);

export const AuthLogoStyled = Styled(
  'Auth',
  'AuthLogoStyled'
)(styled('div')`
  margin: 0 0 20px 0;
  width: 100%;
  text-align: center;

  & > img {
    width: 80%;
  }
`);

export const AuthDescriptionStyled = Styled(
  'Auth',
  'AuthDescriptionStyled'
)(styled('div')`
  margin-bottom: 50px;
  padding: 30px 10px;
  font-size: 16px;
  line-height: 1.15;
  text-align: center;

  opacity: 0.75;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
    background-color: ${application.palette.white};
  `}
`);

export const AuthButtonStyled = Styled(
  'Auth',
  'AuthButtonStyled'
)(styled('button')`
  width: 100%;
  margin-top: 20px;
  padding: 15px 0;
  font-weight: 600;
  text-align: center;
  opacity: 0.5;
  transition: linear opacity 200ms;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
    background-color: ${application.palette.white};
  `}
  &:hover {
    opacity: 1;
  }
`);

export const AuthInputWrapperStyled = Styled(
  'Auth',
  'AuthInputWrapperStyled'
)(styled('div')`
  & + & {
    margin-top: 20px;
  }
`);

export const AuthInputStyled = Styled(
  'Auth',
  'AuthInputStyled'
)(styled(Input)`
  display: block;

  & > input {
    text-align: center;

    &::placeholder {
      opacity: 0.7;
    }
  }

  &::before {
    display: none;
  }

  ${({ theme: { application } }) => css`
    color: ${application.palette.white};

    &::after {
      transform: scale(1);
      border-color: ${application.palette.white};
    }
  `}
`);

export const AuthInputMessageStyled = Styled(
  'Auth',
  'AuthInputMessageStyled'
)(styled('p')`
  margin: 3px 0;
  font-size: 14px;
  line-height: 1.5;

  ${({ theme: { application } }) => css`
    color: ${application.palette.danger};
  `}
`);
