import { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Box, Dialog } from '@mui/material';

import Modal from '@framework/decorators/Modal';

import { DialogWrapperStyled } from '@framework/styles/Loader';
import { ButtonStyled, ButtonOutlinedStyled } from '@framework/styles/App';

const NotificationModal = Modal('NotificationModal')(props => {
  const {
    store: { helperStore, localesStore },
  } = props;
  const type = helperStore.notification?.type ?? 'standard';

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={Boolean(helperStore.notification)}
      onClose={() => helperStore.toggleNotification(null)}
      sx={{ zIndex: '100000050 !important' }}
    >
      {Boolean(helperStore.notification) ? (
        <DialogWrapperStyled
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          {helperStore.notification?.title && (
            <Box
              sx={
                type === 'standard'
                  ? { fontSize: '24px' }
                  : {
                      fontSize: '26px',
                      padding: '25px 0 20px',
                      textAlign: 'center',
                    }
              }
            >
              {helperStore.notification.title}
            </Box>
          )}
          {helperStore.notification?.body && (
            <Box
              sx={{
                margin: '10px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {helperStore.notification?.body}
            </Box>
          )}
          {helperStore.notification?.description && (
            <Box
              dangerouslySetInnerHTML={{
                __html: helperStore.notification.description
              }}
              sx={
                type === 'standard'
                  ? { padding: '50px 0' }
                  : {
                      fontSize: '18px',
                      whiteSpace: 'pre-line',
                      textAlign: 'center',
                      padding: '10px 20px 30px',
                    }
              }
            />
          )}
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: helperStore.notification?.onReject ? '1fr 1fr' : '1fr',
              gridTemplateRows: '60px',
              columnGap: '10px',
              width: '100%',
            }}
          >
            {type === 'inverse' ? (
              <Fragment>
                {helperStore.notification?.onReject && (
                  <ButtonOutlinedStyled onClick={helperStore.notification?.onReject}>
                    {helperStore.notification?.reject || localesStore.translate('placeholder.label.no')}
                  </ButtonOutlinedStyled>
                )}

                <ButtonStyled onClick={helperStore.notification?.onConfirm}>
                  {helperStore.notification?.confirm || localesStore.translate('placeholder.label.yes')}
                </ButtonStyled>
              </Fragment>
            ) : (
              <Fragment>
                <ButtonStyled $isActive={type === 'standard'} onClick={helperStore.notification?.onConfirm}>
                  {helperStore.notification?.confirm || localesStore.translate('placeholder.label.yes')}
                </ButtonStyled>

                {helperStore.notification?.onReject && (
                  <ButtonStyled onClick={helperStore.notification?.onReject}>
                    {helperStore.notification?.reject || localesStore.translate('placeholder.label.no')}
                  </ButtonStyled>
                )}
              </Fragment>
            )}
          </Box>
        </DialogWrapperStyled>
      ) : null}
    </Dialog>
  );
});

export default inject('store')(observer(NotificationModal));
