import { useEffect, useRef, useState } from 'react';
import { styled, css, Box } from '@mui/material';

import Styled from '@framework/decorators/Styled';

const ProductImageStyled = Styled(
  'Catalog',
  'ProductImageStyled'
)(styled(props => {
  const [loaded, setLoaded] = useState(false);

  const isMountedRef = useRef(false),
    { src, className } = props;

  useEffect(() => {
    isMountedRef.current = true;

    if (src) {
      const img = new Image();
      img.onload = () => {
        if (isMountedRef.current) {
          setLoaded(true);
        }
      };
      img.src = src;
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [src]);

  return (
    <Box display="flex" alignItems="center" height="260px">
      <img className={className} src={loaded && src ? src : 'images/ig-placeholder.png'} alt="" />
    </Box>
  );
})`
  flex: 1;
  object-fit: contain;
  width: ${({ isGridView }) => (isGridView ? '180px' : '250px')};
  max-height: 100%;

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      width: 100px;
    }
  `}
`);

export const ProductStyled = Styled(
  'Catalog',
  'ProductStyled'
)(styled(({ className, children, thumbnailUrl, isGridView, onClick }) => (
  <div className={className} onClick={onClick}>
    <ProductImageStyled isGridView={isGridView} src={thumbnailUrl} />
    {children}
  </div>
))`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  & p {
    display: inline;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.white};
  `}
`);

export const IconsBlockStyled = Styled(
  'Catalog',
  'IconsBlockStyled'
)(styled(Box)`
  width: 100%;
  display: grid;
  justify-content: flex-end;
  grid-template-rows: 40px;
  grid-gap: 20px;

  ${({ $isSearchbarToggled, $isMobile }) =>
    $isSearchbarToggled
      ? $isMobile
        ? css`
            grid-template-columns: 1fr;
          `
        : css`
            padding-left: 286px;
            grid-template-columns: 1fr repeat(2, 40px);
          `
      : css`
          grid-template-columns: repeat(3, 40px);
          justify-content: end;
        `};
`);

export const CounterStyled = Styled(
  'Catalog',
  'CounterStyled'
)(styled('div')`
  margin-left: 15px;
  display: flex;
  justify-content: center;
  border-radius: 11px;
  padding: 3px 7px;
  font-size: 14px;

  ${({ theme: { application } }) => css`
    border: 1px solid ${application.palette.danger};
    color: ${application.palette.danger};
    background-color: ${application.palette.white};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      width: 20px;
      height: 20px;
      padding: 0px;
      font-size: 12px;
      line-height: 1.5;
    }
  `}
`);
