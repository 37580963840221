import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import View from '@framework/decorators/View';

import FormComponent from '@framework/components/Form';

import { BackButtonStyled, LayoutContainerStyled, PageContainerStyled, ToolbarStyled } from '@framework/styles/App';

const CustomerCreationView = View('CustomerCreation')(props => {
  const {
    store: { helperStore, localesStore },
    models: { FormModel },
  } = props;

  const navigate = useNavigate(),
    isMobile = helperStore.isMobile();

  FormModel.setFormSettings(FormModel.Type.Creation);

  return (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.baseAnimationDirection)}>
      <LayoutContainerStyled>
        <ToolbarStyled $centered>
          <BackButtonStyled
            onClick={e => {
              e.preventDefault();
              helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
              navigate('/');
            }}
          >
            {localesStore.translate('placeholder.label.back')}
          </BackButtonStyled>
          <h2>
            {isMobile
              ? localesStore.translate('page.title.customer.creation')
              : localesStore.translate('page.title.prefix') + localesStore.translate('page.title.customer.creation')}
          </h2>
        </ToolbarStyled>
        <FormComponent />
      </LayoutContainerStyled>
    </PageContainerStyled>
  );
});

export default inject('store', 'models')(observer(CustomerCreationView));
