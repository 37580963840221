import i18nIsoCountries from 'i18n-iso-countries';
import { inject, observer } from 'mobx-react';
import { Box, Dialog, useTheme } from '@mui/material';
import { Close, KeyboardArrowDown, KeyboardArrowUp, PriorityHigh } from '@mui/icons-material';

import { CounterStyled } from '@framework/styles/App';

import Modal from '@framework/decorators/Modal';

import { DialogStyled, ModalAnimation, DialogContentStyled, DialogTitleStyled } from '@framework/styles/App';
import getSymbolFromCurrency from 'currency-symbol-map';
import { ButtonStyled } from '../../styles/App';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const CustomerReservationModal = Modal('CustomerReservationModal')(props => {
  const {
    store: { helperStore, localesStore },
    models: { CartModel, CartProcessingModel, OrdersModel },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile(),
    translations = i18nIsoCountries.getNames(localesStore.currentLocale, {
      select: 'official',
    });

  const {
    isReservationToggled,
    handleReservationClose,
    handleReservationCall,
    orderData,
    options: { product },
  } = CartProcessingModel;

  const isInfoToggled = true;

  return isMobile ? (
    <Dialog
      fullScreen
      open={isReservationToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleReservationClose}
    >
      <DialogTitleStyled>
        <Box component="span" fontSize={24}>
          {localesStore.translate('modal.cartProcessing.customer.label.reserve')}
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={handleReservationClose}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box p="20px" bgcolor={theme.application.palette.whitesmoke}>
          <Box display="grid" alignItems="start" rowGap="5px" fontSize={16}>
            <Box component="span" fontWeight="bold">
              {`${localesStore.translate(`placeholder.label.salutation.${orderData?.details?.salutation}`)} ${
                orderData?.details?.firstName
              } ${orderData?.details?.lastName}`}
            </Box>
            <Box component="span" mb="10px">
              {`${localesStore.translate(`placeholder.table.customer`)}: ${orderData?.details?.customerId}`}
            </Box>
            <Box component="span">{`${orderData?.details?.street} ${orderData?.details?.streetNumber}`}</Box>
            <Box component="span">{`${orderData?.details?.postalCode} ${orderData?.details?.city}`}</Box>
            <Box component="span">
              {translations && orderData?.details?.country && translations[orderData?.details?.country?.toUpperCase()]
                ? translations[orderData?.details?.country?.toUpperCase()]
                : orderData?.details?.country}
            </Box>
            <Box component="span" mt="10px">
              {orderData?.details?.email}
            </Box>
            <Box component="span" mt="10px">
              {`${localesStore.translate('placeholder.label.amount')}: ${
                product?.price ? product?.price.toFixed(2).replace('.', ',') : ''
              } ${product?.currency ? getSymbolFromCurrency(product?.currency) : ''}`}
            </Box>
          </Box>
        </Box>
        <Box
          display="grid"
          alignItems="center"
          margin="20px 0"
          columnGap="20px"
          p="10px"
          bgcolor={theme.application.palette.white}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box component="span" fontSize={18} fontWeight={600}>
              {product?.name}
            </Box>
          </Box>

          <Box display="grid" mt="10px" rowGap="10px">
            <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
              {localesStore.translate('placeholder.label.articleNumber') + ': ' + product?.articleNumber}
            </Box>
            <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
              {`${localesStore.translate('placeholder.label.ean')}: ${product?.gtin}`}
            </Box>
          </Box>

          <Box mt="10px" display="flex" justifyContent="space-between">
            <Box display="flex" justifyContent="center" height="90px" width="80px" mr="10px">
              <img height="100%" src={product?.imageUrl ?? 'images/ig-placeholder.png'} alt="" />
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Box
                display="grid"
                gridAutoColumns="max-content"
                columnGap="5px"
                justifyContent="space-between"
                alignItems="center"
                gridAutoFlow="column"
                mt="10px"
              >
                {Boolean(product?.color) && <Box fontSize={14}>{product.color}</Box>}

                <Box height="21px" width="21px" border={`solid 1px ${theme.application.palette.black}`}>
                  <img
                    width="100%"
                    height="100%"
                    src={product?.colorImageUrl ?? product?.imageUrl ?? 'images/ig-placeholder.png'}
                    alt=""
                  />
                </Box>

                {Boolean(product?.size) && <CounterStyled>{product.size}</CounterStyled>}
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center" mt="10px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box component="span" fontSize={16} fontWeight={600}>
                    {`${product?.price ? product?.price.toFixed(2).replace('.', ',') : ''} ${
                      product?.currency ? getSymbolFromCurrency(product?.currency) : ''
                    }`}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt="auto" display="grid" gridTemplateRows="50px">
          <ButtonStyled onClick={handleReservationCall}>
            {localesStore.translate('modal.cartProcessing.customer.controls.reserve')}
          </ButtonStyled>
        </Box>
      </DialogContentStyled>
    </Dialog>
  ) : (
    <DialogStyled
      fullWidth
      maxWidth="md"
      open={isReservationToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleReservationClose}
    >
      <DialogTitleStyled>
        <span>{localesStore.translate('modal.cartProcessing.customer.label.reserve')}</span>
        <Box sx={{ cursor: 'pointer' }} onClick={handleReservationClose}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box display="flex" justifyContent="space-between" bgcolor={theme.application.palette.whitesmoke} p="20px">
          <Box display="grid" alignItems="start" rowGap="5px" fontSize={16}>
            <Box component="span" fontWeight="bold">
              {`${localesStore.translate(`placeholder.label.salutation.${orderData?.details?.salutation}`)} ${
                orderData?.details?.firstName
              } ${orderData?.details?.lastName}`}
            </Box>
            <Box component="span">{`${orderData?.details?.street} ${orderData?.details?.streetNumber}`}</Box>
            <Box component="span">{`${orderData?.details?.postalCode} ${orderData?.details?.city}`}</Box>
            <Box component="span">
              {translations && orderData?.details?.country && translations[orderData?.details?.country?.toUpperCase()]
                ? translations[orderData?.details?.country?.toUpperCase()]
                : orderData?.details?.country}
            </Box>
            <Box component="span" mt="10px">
              {orderData?.details?.email}
            </Box>
          </Box>
          <Box display="grid" alignItems="start" rowGap="5px" fontSize={16}>
            <Box component="span">
              {`${localesStore.translate(`placeholder.table.customer`)}: ${orderData?.details?.customerId}`}
            </Box>
            <Box sx={{ visibility: 'hidden' }}>_</Box>
            <Box sx={{ visibility: 'hidden' }}>_</Box>
            <Box sx={{ visibility: 'hidden' }}>_</Box>
            <Box component="span" mt="10px">
              {`${localesStore.translate('placeholder.label.amount')}: ${
                product?.price ? product?.price.toFixed(2).replace('.', ',') : ''
              } ${product?.currency ? getSymbolFromCurrency(product?.currency) : ''}`}
            </Box>
          </Box>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="max-content 1fr max-content"
          alignItems="center"
          columnGap="20px"
          margin="20px 0"
          p="10px"
          sx={{ cursor: 'pointer' }}
        >
          <Box display="flex" justifyContent="center" height="100px" width="90px">
            <img height="100%" src={product?.imageUrl ?? 'images/ig-placeholder.png'} alt="" />
          </Box>

          <Box display="grid" gridAutoFlow="row" rowGap="5px">
            <Box component="span" fontSize={14} fontWeight={600}>
              {product?.name}
            </Box>
            <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
              {localesStore.translate('placeholder.label.articleNumber') + ': ' + product?.articleNumber}
            </Box>
            <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
              {`${localesStore.translate('placeholder.label.ean')}: ${product?.gtin}`}
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              pt="10px"
              mt="10px"
              sx={{
                '& > *': {
                  marginRight: isMobile ? '10px' : '15px',
                },
              }}
            >
              {Boolean(product?.color) && <Box>{product?.color}</Box>}

              <Box height="25px" width="25px" border={`solid 1px ${theme.application.palette.dark}`}>
                <img
                  width="100%"
                  height="100%"
                  src={Boolean(product?.imageUrl) ? product?.imageUrl : 'images/ig-placeholder.png'}
                  alt=""
                />
              </Box>

              {Boolean(product?.size) && <CounterStyled>{product.size}</CounterStyled>}
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center" p="0 20px">
            <Box component="span" fontSize={20} fontWeight={600}>
              {`${product?.price ? product?.price.toFixed(2).replace('.', ',') : ''} ${
                product?.currency ? getSymbolFromCurrency(product?.currency) : ''
              }`}
            </Box>
          </Box>
        </Box>

        <Box mt="auto" display="grid" gridTemplateColumns="1fr 280px" gridTemplateRows="50px">
          <div></div>
          <ButtonStyled onClick={handleReservationCall}>
            {localesStore.translate('modal.cartProcessing.customer.controls.reserve')}
          </ButtonStyled>
        </Box>
      </DialogContentStyled>
    </DialogStyled>
  );
});

export default inject('store', 'models')(observer(CustomerReservationModal));
