import { observer, inject } from 'mobx-react';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';

import View from '@framework/decorators/View';

import FormComponent from '@framework/components/Form';

import { PageContainerStyled, LayoutContainerStyled, ToolbarStyled } from '@framework/styles/App';

const CustomerEditorView = View('CustomerEditor')(props => {
  const {
    store: { localesStore, helperStore },
    models: { FormModel, CustomerDetailsModel, SearchModel },
  } = props;

  const navigate = useNavigate(),
    location = useLocation();

  useEffect(() => {
    if (!FormModel.settings?.prevData) {
      const customerId = location.search.slice(1);
      SearchModel.fetchResultDetails(encodeURIComponent(customerId)).then(userData => {
        CustomerDetailsModel.isDataLoading = false;

        FormModel.setFormSettings(FormModel.Type.Editor, {
          prevData: userData,
          data: {
            salutation: userData.salutation || '',
            birthday: userData.birthday ? format(+userData.birthday, 'yyyy-MM-dd') : '',
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            phone: userData.phone,
            street: userData.address?.street || '',
            streetNumber: userData.address?.streetNumber || '',
            city: userData.address?.city || '',
            addressLine1: userData.address?.addressLine1 || '',
            addressLine2: userData.address?.addressLine2 || '',
            email: userData.address?.email || '',
            postalCode: userData.address?.postalCode || '',
            country: userData.address?.country ?? localesStore.currentLocale,
            isEmailContactAllowed: Boolean(userData.isEmailContactAllowed),
            isPhoneContactAllowed: Boolean(userData.isPhoneContactAllowed),
            isSMSMMSContactAllowed: Boolean(userData.isSMSMMSContactAllowed),
            armLength: userData?.additionalProperties?.armLength || '',
            cut: userData?.additionalProperties?.cut || '',
            size: userData?.additionalProperties?.size || '',
            store: userData?.favouriteStoreId || '',
            language: userData?.preferredLanguage ?? 'DE',
            userAgreementAllowed: true,
          },
          exclude: ['signature'],
          onResolve: customerId => {
            CustomerDetailsModel.setUserData(null);
            CustomerDetailsModel.customerId = encodeURIComponent(`"${customerId}"`);
            navigate(`/customer/details?${customerId}`);
          },
        });

        helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
        helperStore.screensAnimationDirection = helperStore.Direction.Ltr;

        helperStore.toggleLoader(false);
      });
    }
  }, []);

  return (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.baseAnimationDirection)}>
      <LayoutContainerStyled $fullHeight>
        <ToolbarStyled $centered>
          <h2>{localesStore.translate('page.title.prefix') + localesStore.translate('page.title.customer.cockpit')}</h2>
        </ToolbarStyled>
        <Box pb="20px">
          {FormModel.settings?.prevData ? (
            <FormComponent
              handleBackClick={() => {
                CustomerDetailsModel.setUserData(null);
                navigate(`/customer/details?${FormModel.settings?.prevData.id}`);
              }}
            />
          ) : null}
        </Box>
      </LayoutContainerStyled>
    </PageContainerStyled>
  );
});

export default inject('store', 'models')(observer(CustomerEditorView));
