import { inject, observer } from 'mobx-react';
import { Box, Dialog, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useCallback } from 'react';

import ArticleCatalogIcon from '@framework/icons/ArticleCatalog';
import ArticleScanIcon from '@framework/icons/ArticleScan';
import ArticleSearchIcon from '@framework/icons/ArticleSearch';

import {
  DialogStyled,
  ModalAnimation,
  ButtonOutlinedStyled,
  DialogContentStyled,
  DialogTitleStyled,
} from '@framework/styles/App';

import Modal from '@framework/decorators/Modal';

const CartArticlesModal = Modal('CartArticlesModal')(props => {
  const {
    store: { helperStore, localesStore },
    models: { CartProcessingModel },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile();

  const { isArticlesToggled, toggleArticles, handleCatalogCall, handleSearchCall, handleScannerCall } =
    CartProcessingModel;

  const handleCloseWindow = useCallback(() => {
    toggleArticles(false);
  }, []);

  return isMobile ? (
    <Dialog
      fullScreen
      open={isArticlesToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleCloseWindow}
    >
      <DialogTitleStyled>
        <Box position="relative" display="flex" alignItems="center">
          <Box component="span" fontSize={24}>
            {localesStore.translate('modal.cartArticles.label.title')}
          </Box>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={handleCloseWindow}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box display="grid" rowGap="20px">
          <ButtonOutlinedStyled $icon onClick={handleCatalogCall}>
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <ArticleCatalogIcon height="70" width="70" />
            </Box>
            {localesStore.translate('modal.cartArticles.controls.catalog')}
          </ButtonOutlinedStyled>
          <ButtonOutlinedStyled $icon onClick={handleScannerCall}>
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <ArticleScanIcon height="70" width="70" />
            </Box>
            {localesStore.translate('modal.cartArticles.controls.scanner')}
          </ButtonOutlinedStyled>
          <ButtonOutlinedStyled $icon onClick={handleSearchCall}>
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <ArticleSearchIcon height="70" width="70" />
            </Box>
            {localesStore.translate('modal.cartArticles.controls.search')}
          </ButtonOutlinedStyled>
        </Box>
      </DialogContentStyled>
    </Dialog>
  ) : (
    <DialogStyled
      fullWidth
      maxWidth="lg"
      open={isArticlesToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleCloseWindow}
    >
      <DialogTitleStyled>
        <Box position="relative" display="flex" alignItems="center">
          <Box component="span">{localesStore.translate('modal.cartArticles.label.title')}</Box>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={handleCloseWindow}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          gridTemplateRows="100%"
          height="100%"
          columnGap="20px"
          flexGrow={1}
        >
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <ArticleCatalogIcon width="250" height="250" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled onClick={handleCatalogCall}>
                {localesStore.translate('modal.cartArticles.controls.catalog')}
              </ButtonOutlinedStyled>
            </Box>
          </Box>
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <ArticleScanIcon width="250" height="250" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled onClick={handleScannerCall}>
                {localesStore.translate('modal.cartArticles.controls.scanner')}
              </ButtonOutlinedStyled>
            </Box>
          </Box>
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <ArticleSearchIcon width="250" height="250" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled onClick={handleSearchCall}>
                {localesStore.translate('modal.cartArticles.controls.search')}
              </ButtonOutlinedStyled>
            </Box>
          </Box>
        </Box>
      </DialogContentStyled>
    </DialogStyled>
  );
});

export default inject('store', 'models')(observer(CartArticlesModal));
