import { styled, css, DialogContent, Dialog } from '@mui/material';

import { OutlinedInputStyled } from '@framework/styles/App';

import Styled from '@framework/decorators/Styled';

export const BarcodeScannerDialogStyled = Styled(
  'Barcode',
  'BarcodeScannerDialogStyled'
)(styled(Dialog)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11000;
`);

export const BarcodeScannerTitleStyled = Styled(
  'Barcode',
  'BarcodeScannerTitleStyled'
)(styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 32px;
  line-height: 1.19;
  font-weight: 600;
  height: 80px;
  padding: 20px;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
    border-bottom: 1px solid ${application.palette.grayline};
  `}
`);

export const BarcodeScannerStreamStyled = Styled(
  'Barcode',
  'BarcodeScannerStreamStyled'
)(styled('video')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`);

export const BarcodeScannerContentStyled = Styled(
  'Barcode',
  'BarcodeScannerContentStyled'
)(styled(DialogContent)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: hidden;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      overflow: hidden;
    }
  `}
`);

export const BarcodeScannerInputStyled = Styled(
  'Barcode',
  'BarcodeScannerInputStyled'
)(styled(OutlinedInputStyled)`
  padding: 0 10px;
  min-width: 240px;
  height: 40px;
  font-size: 16px;

  & > input {
    padding: 0;
    line-height: 1;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
    height: 100%;
  }

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.white};
  `}
`);
