import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const Guest = memo(({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width ?? '124'} height={height ?? '142'} viewBox="0 0 124 142">
    <path
      fillRule="evenodd"
      d="M122.481 142H1.52c-.839 0-1.52-.683-1.52-1.522V1.522C0 .669.667-.001 1.52-.001h87.642l.64.105-.007.031 33.641 33.583.026.003.434.456.104 106.301c0 .853-.668 1.522-1.519 1.522zM88.106 3.044H3.038v135.912h117.923l.053-102.943L88.106 3.044zM44.195 103.696l-11.908 3.365-1.969 2.039a1.014 1.014 0 0 1-1.468 0 1.092 1.092 0 0 1-.304-.761c0-.286.108-.555.304-.759l1.969-2.038 3.247-12.329 51.084-52.89a3.986 3.986 0 0 1 5.764-.002l4.362 4.518c1.59 1.647 1.59 4.324 0 5.97l-51.081 52.887z"
    />
  </svg>
));

export default Icon('Guest')(Guest);
