import { format } from 'date-fns';
import { useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { FilterAltOutlined, MoreHoriz, Refresh } from '@mui/icons-material';
import { Table, TableHead, TableBody, Box, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import View from '@framework/decorators/View';

import {
  BackButtonStyled,
  ToolbarStyled,
  LayoutContainerStyled,
  PageContainerStyled,
  CircleWrapperStyled,
  RectangleWrapperStyled,
} from '@framework/styles/App';

import {
  TableCardStyled,
  TableRowHeadStyled,
  TableCellHeadStyled,
  TableHeadGapStyled,
  TableBodyGapStyled,
  TableContainerStyled,
  TableRowBodyStyled,
  TableCellBodyStyled,
} from '@framework/styles/Table';

import { FilterTitleStyled } from '@framework/styles/Filter';

const ComplaintIcon = () => (
  <svg viewBox="0 0 24 24">
    <path
      d="M7.11 8.53 5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z"></path>
  </svg>
);

const ComplaintsView = View('Complaints')(props => {
  const {
    models: {ComplaintsModel, ComplaintsProcessingModel},
    store: {helperStore, localesStore},
  } = props;
  
  const theme = useTheme(),
    navigate = useNavigate(),
    location = useLocation(),
    isMobile = helperStore.isMobile();
  
  const {
    Filter,
    isFilterToggled,
    currentTab,
    currentFilter,
    filters,
    viewMounted,
    complaintsByFilter,
    parkedComplaintsByFilter,
    handleFilterCall,
    handleFilterToggle,
    handleRefreshCall,
    handleDetailsCall,
  } = ComplaintsModel;

  const {
    handleParkedCall
  } = ComplaintsProcessingModel;
  
  useEffect(() => {
    viewMounted();
  }, [currentTab]);

  const isArchive = !location.pathname.includes("parked")
  
  return (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.baseAnimationDirection)}>
      <LayoutContainerStyled $scrollable>
        <ToolbarStyled>
          <BackButtonStyled
            onClick={e => {
              e.preventDefault();
              helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
              helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
              navigate('/');
            }}
          >
            {localesStore.translate('placeholder.label.back')}
          </BackButtonStyled>
          <h2>{isArchive ? "Reklamationsarchiv" : "Reklamationen in Erstellung" }</h2>
          <Box display="flex" position="absolute" right="0">
            <RectangleWrapperStyled mr="10px" onClick={handleRefreshCall}>
              <Refresh/>
            </RectangleWrapperStyled>
            <RectangleWrapperStyled padding={isMobile ? '5px' : '5px 25px'} mr="10px" onClick={handleFilterToggle}>
              <FilterAltOutlined />
              {!isMobile && (
                <Box component="span" ml="5px">
                  {currentFilter === Filter.All
                    ? localesStore.translate('placeholder.filters.all')
                    : filters[currentFilter] + ' ' + localesStore.translate('placeholder.filters.days')}
                </Box>
              )}
            </RectangleWrapperStyled>
          </Box>
        </ToolbarStyled>
        {isFilterToggled && (
          <Box
            display="flex"
            justifyContent="center"
            border={`2px solid ${theme.application.palette.black}`}
            mb="10px"
            overflow="hidden"
          >
            <Box
              display="grid"
              gridTemplateColumns={isMobile ? 'repeat(5, max-content)' : 'repeat(6, max-content)'}
              columnGap={isMobile ? '20px' : '30px'}
              padding="40px 20px"
              overflow="auto"
            >
              {!isMobile && (
                <FilterTitleStyled $isActive>
                  {localesStore.translate('placeholder.filters.default')}:
                </FilterTitleStyled>
              )}
              <FilterTitleStyled
                $isActive={currentFilter === Filter.All}
                onClick={handleFilterCall.bind(this, Filter.All)}
              >
                {localesStore.translate('placeholder.filters.all')}
              </FilterTitleStyled>
              <FilterTitleStyled
                $isActive={currentFilter === Filter.LastTwoDays}
                onClick={handleFilterCall.bind(this, Filter.LastTwoDays)}
              >
                2 {localesStore.translate('placeholder.filters.days')}
              </FilterTitleStyled>
              <FilterTitleStyled
                $isActive={currentFilter === Filter.LastFiveDays}
                onClick={handleFilterCall.bind(this, Filter.LastFiveDays)}
              >
                5 {localesStore.translate('placeholder.filters.days')}
              </FilterTitleStyled>
              <FilterTitleStyled
                $isActive={currentFilter === Filter.LastTenDays}
                onClick={handleFilterCall.bind(this, Filter.LastTenDays)}
              >
                10 {localesStore.translate('placeholder.filters.days')}
              </FilterTitleStyled>
              <FilterTitleStyled
                $isActive={currentFilter === Filter.LastMonth}
                onClick={handleFilterCall.bind(this, Filter.LastMonth)}
              >
                30 {localesStore.translate('placeholder.filters.days')}
              </FilterTitleStyled>
            </Box>
          </Box>
        )}
        <TableHeadGapStyled />
        <TableContainerStyled>
          {isMobile ? (
            (isArchive ? complaintsByFilter : parkedComplaintsByFilter).map(item => (
              <TableCardStyled key={item.objectId} onClick={
                () => isArchive ? handleDetailsCall(item) : handleParkedCall(item)
              }>
                <Box sx={{ width: '36px', height: '36px', }}>
                  <ComplaintIcon />
                </Box>
                <div>
                  <Box
                    display="flex"
                    alignItems="center"
                    p="5px 0 15px"
                    borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                  >
                    {item.complaintId}
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr"
                    p="15px 0"
                    borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                  >
                    <Box fontSize={12}>
                      Kaufebene:
                    </Box>
                    <Box fontSize={12}>
                      {item.complaintType}
                    </Box>
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr"
                    p="15px 0"
                    borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                  >
                    <Box fontSize={12}>
                      Warenebene:
                    </Box>
                    <Box fontSize={12}>
                      {item.purchaseType || '-'}
                    </Box>
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr"
                    p="15px 0"
                    borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                  >
                    <Box fontSize={12}>
                      {localesStore.translate('placeholder.time.at') + ': ' + format(+item.created, 'dd MMM, yyyy')}
                    </Box>
                    <Box fontSize={12}>
                      {localesStore.translate('placeholder.time.at') +
                        ': ' +
                        format(+item.created, 'HH:mm') +
                        ' ' +
                        localesStore.translate('placeholder.label.time')}
                    </Box>
                  </Box>
                  <Box p="15px 0" borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}>
                    <Box fontSize={12} fontWeight={600} mb="5px">
                      {`${item.customerAddress?.firstName ?? ''} ${item.customerAddress?.lastName ?? ''}`}
                    </Box>
                    <Box fontSize={12} fontWeight={600} mb="5px">
                      {item.customerAddress?.email}
                    </Box>
                    <Box fontSize={12} fontWeight={600}>
                      {item.customerAddress?.phone}
                    </Box>
                  </Box>
                  <Box p="15px 0 0">
                    Status
                  </Box>
                </div>
                <Box display="flex" flexDirection="column" justifyContent="flex-end" pt="5px">
                  <CircleWrapperStyled>
                    <MoreHoriz />
                  </CircleWrapperStyled>
                </Box>
              </TableCardStyled>
            ))
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRowHeadStyled>
                  <TableCellHeadStyled>
                    <span>Kaufebene</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled align="center">
                    <span>{localesStore.translate('placeholder.table.processId')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled align="center">
                    <span>Reklamationsgrund</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled align="center">
                    <span>{localesStore.translate('placeholder.table.date')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled width="25%">
                    <span>{localesStore.translate('placeholder.table.customer')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled>
                    <span>Warenebene</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled />
                </TableRowHeadStyled>
              </TableHead>
              <TableBody>
                {(isArchive ? complaintsByFilter : parkedComplaintsByFilter).map(item => (
                  <Fragment key={item.objectId}>
                    <TableBodyGapStyled />
                    <TableRowBodyStyled onClick={() => isArchive ? handleDetailsCall(item) : handleParkedCall(item)}>
                      <TableCellBodyStyled>
                        <Box display="flex" alignItems="center">
                          <Box sx={{ width: '36px', height: '36px', '& svg': { width: '100%', height: '100%' } }}>
                            <ComplaintIcon />
                          </Box>
                          
                          <Box ml="15px">{item.complaintType}</Box>
                        </Box>
                      </TableCellBodyStyled>
                      <TableCellBodyStyled align="center">{item.complaintId}</TableCellBodyStyled>
                      <TableCellBodyStyled align="center">{item.complaintReason}</TableCellBodyStyled>
                      <TableCellBodyStyled sx={{ whiteSpace: 'nowrap' }}>
                        <Box>
                          {localesStore.translate('placeholder.time.at') + ': ' + format(+item.created, 'dd MMM, yyyy')}
                        </Box>
                        <Box>
                          {localesStore.translate('placeholder.time.around') +
                            ': ' +
                            format(+item.created, 'HH:mm') +
                            ' ' +
                            localesStore.translate('placeholder.label.time')}
                        </Box>
                      </TableCellBodyStyled>
                      <TableCellBodyStyled $isEllipsed sx={{ wordBreak: 'break-word' }}>
                        <div>{`${item.customerAddress.firstName ?? ' '} ${item.customerAddress.lastName ?? ' '}`}</div>
                        <div>{item.customerAddress.email ?? ' '}</div>
                        <div>{item.customerAddress.phone ?? ' '}</div>
                      </TableCellBodyStyled>
                      <TableCellBodyStyled>{item.purchaseType}</TableCellBodyStyled>
                      <TableCellBodyStyled>
                        <CircleWrapperStyled>
                          <MoreHoriz />
                        </CircleWrapperStyled>
                      </TableCellBodyStyled>
                    </TableRowBodyStyled>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainerStyled>
      </LayoutContainerStyled>
    </PageContainerStyled>
  );
});

export default inject('store', 'models')(observer(ComplaintsView));
