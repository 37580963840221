import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class AppointmentsFormModel {
  isToggled = false;
  isAppointmentsShowed = false;

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
      isAppointmentsShowed: observable,
    });
  }

  verifyForm = action(data => {
    const { store, AppointmentsModel } = this.rootModels;

    const validateEmail = email => {
      const emailValidationRegexp = /^([a-zA-Z0-9_!#$%&’*+/=?`\{|}~^.-]){1,64}@([a-zA-Z0-9.-]){1,177}$/i;
      return emailValidationRegexp.test(email?.toLowerCase());
    };

    const validatePhone = phone => {
      const phoneValidationRegexp =
        /[\+|0]+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/i;

      return phoneValidationRegexp.test(phone?.toLowerCase()?.replace(/\s/g, ''));
    };

    const requiredFields = ['salutation', 'firstName', 'lastName', 'email', 'phone', 'type', 'date', 'time'];

    const requiredFieldErrors = requiredFields.reduce((acc, next) => {
      if (next === 'phone' && data[next].trim() !== '' && !validatePhone(data[next])) {
        acc[next] = store.localesStore.translate('modal.appointment.form.field.error.' + next);
      } else if (next === 'email' && data[next].trim() !== '' && !validateEmail(data[next])) {
        acc[next] = store.localesStore.translate('modal.appointment.form.field.error.' + next);
      } else if (data['date'] !== null && next === 'time') {
        const [hours] = data[next].split(':'),
          currentDate = new Date(),
          requestedDate = new Date(`${data['date']}T${data[next]}`);

        if (
          currentDate > requestedDate ||
          hours > AppointmentsModel.Settings.MaxTime ||
          hours < AppointmentsModel.Settings.MinTime
        ) {
          acc['date'] = store.localesStore.translate('modal.appointment.form.field.error.date');
          acc['time'] = store.localesStore.translate('modal.appointment.form.field.error.time');
        } else if (data[next] === null || data[next] === '') {
          acc[next] = store.localesStore.translate('modal.appointment.form.field.error.' + next);
        }
      } else if ((typeof data[next] === 'string' && data[next].trim() === '') || data[next] === null) {
        acc[next] = store.localesStore.translate('modal.appointment.form.field.error.' + next);
      }

      return acc;
    }, {});

    return Object.assign({}, requiredFieldErrors);
  });

  handleFormSubmit = action(async data => {
    const {
        store: { debug, networkStore, storageStore, helperStore, localesStore },
        AppointmentsModel,
      } = this.rootModels,
      { salutation, firstName, lastName, email, phone, type, date, time, note } = data;

    try {
      const [hours] = time.split(':');
      const appointmentDate = new Date(`${date}T${time}`).getTime();

      helperStore.toggleLoader(true);

      if (
        typeof appointmentDate !== 'number' ||
        hours < AppointmentsModel.Settings.MinTime ||
        hours > AppointmentsModel.Settings.MaxTime
      )
        throw Error('Type of appointmentDate is invalid!');

      if (
        AppointmentsModel.appointmentsByConfirmed.some(
          appointment => parseInt(appointment.appointmentDate) === appointmentDate
        )
      )
        return swal('', localesStore.translate('modal.appointment.form.field.error.creation'), 'error');

      const { status, response } = await networkStore.post('StoreAppointment', {
        firstName,
        lastName,
        note,
        salutation,
        email,
        phone,
        appointmentDate,
        status: AppointmentsModel.Status.Confirmed,
        type: type === 'phone' ? 'Phone' : 'Store',
        storeId: storageStore.storeId,
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "StoreAppointment" Failed!');

      AppointmentsModel.fetchAppointments().catch(error => debug && console.error(error));

      this.isToggled = false;
    } catch (error) {
      if (debug) console.error(error);
    } finally {
      helperStore.toggleLoader(false);
    }
  });

  handleFormCall = action(() => {
    this.isToggled = true;
  });

  handleFormClose = action(() => {
    this.isToggled = false;
  });

  handleToggleAppointments = action(() => {
    this.isAppointmentsShowed = !this.isAppointmentsShowed;
  });
}

export default Model('AppointmentsFormModel')(AppointmentsFormModel);
