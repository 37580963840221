import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const ShoppingCart = memo(() => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="30 37 50 46">
    <path
      fill="#000"
      fillRule="evenodd"
      d="M73.606 49.721l-4.518-10.553A3.597 3.597 0 0 0 65.768 37H44.232a3.598 3.598 0 0 0-3.32 2.168l-4.517 10.553H30v9.334h1.616v19.576c0 2.41 1.992 4.369 4.441 4.369h37.886c2.448 0 4.44-1.959 4.44-4.369V59.055h1.616v-9.334h-6.393zM35.355 79.728a1.29 1.29 0 0 1-.622-1.097V59.055h40.534v19.576c0 .445-.232.854-.62 1.097a1.63 1.63 0 0 1-.509.182l-.032.007c-.065.009-.122.017-.163.017H36.057c-.04 0-.099-.008-.171-.019h-.003a1.54 1.54 0 0 1-.528-.187zm-2.239-26.942h43.768v3.204H33.116v-3.204zm10.669-12.428l.001-.002a.481.481 0 0 1 .446-.291h21.536c.196 0 .371.114.447.292l4.009 9.364H39.776l4.009-9.363z"
    />
  </svg>
));

export default Icon('ShoppingCart')(ShoppingCart);
