import { subDays } from 'date-fns';
import { action, computed, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class RequestsModel {
  Type = {
    Wholesale: 'Wholesale',
    ClickAndReserve: 'ClickAndReserve',
    ClickAndCollect: 'ClickAndCollect',
    Reservation: 'Reservation',
    InStore: 'InStore',
  };

  Status = {
    Open: 'Open',
    Closed: 'Closed',
    Declined: 'Declined',
    Refused: 'Refused',
    Confirmed: 'Confirmed',
  };

  Payment = {
    None: 'None',
    Alternative: 'Alternative',
  };

  Tabs = {
    Opened: 1,
    Processed: 2,
  };

  Filter = {
    All: 0,
    LastTwoDays: 1,
    LastFiveDays: 2,
    LastTenDays: 3,
    LastMonth: 4,
  };

  isLoading = false;
  isFilterToggled = false;
  isDataLoaded = false;
  isProcessedLoaded = false;
  currentTab = 1;
  currentFilter = 0;

  data = [];
  processed = [];
  filters = [0, 2, 5, 10, 30];

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isLoading: observable,
      isFilterToggled: observable,
      isDataLoaded: observable,
      isProcessedLoaded: observable,

      currentTab: observable,
      currentFilter: observable,

      data: observable,
      processed: observable,

      requestsByOpened: computed,
      requestsByProcessed: computed,
      requestsByStatus: computed,
    });
  }

  handleTabCall = action(tab => {
    this.currentTab = tab;
  });

  handleFilterCall = action(filter => {
    this.currentFilter = filter;
    this.isFilterToggled = false;
  });

  handleFilterToggle = action(() => {
    this.isFilterToggled = !this.isFilterToggled;
  });

  handleRefreshCall = action(() => {
    const {
      currentTab,
      Tabs: { Processed },
      rootModels: { store },
    } = this;

    if (currentTab === Processed) {
      this.fetchProcessedRequests().catch(error => store.debug && console.error(error));
    } else {
      this.fetchRequests().catch(error => store.debug && console.error(error));
    }
  });

  viewMounted = action(() => {
    const {
      currentTab,
      Tabs: { Processed },
      rootModels: { store },
    } = this;

    if (!this.isLoading && !this.isDataLoaded && currentTab !== Processed)
      this.fetchRequests().catch(error => store.debug && console.error(error));

    if (!this.isLoading && !this.isProcessedLoaded && currentTab === Processed)
      this.fetchProcessedRequests().catch(error => store.debug && console.error(error));
  });

  fetchRequests = action(async (disableModal = false) => {
    this.isLoading = true;

    const {
      store: { debug, storageStore, networkStore, helperStore },
    } = this.rootModels;

    const isViewModel = document.location.pathname.includes('requests');

    if (isViewModel || disableModal) helperStore.toggleLoader(true);

    try {
      const { storeId } = storageStore;
      const whereClause = `(sourceStoreId = '${storeId}') AND status = 'Open'`;

      const { response, status } = await networkStore.post('LoadObjectsOfClass', {
        whereClause: whereClause,
        offset: 0,
        orderByName: 'created',
        orderByType: 'DESC',
        pageSize: 100,
        relations: ['articleItems.logs', 'customer.addresses', 'deliveryAddress', 'invoiceAddress'],
        clazz: 'com.innomos.baas.common.model.OmniChannelRequest',
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "LoadObjectsOfClass" Failed!');

      const {
        data: { data },
      } = JSON.parse(response);

      this.data = helperStore.mapRequestsData(data);

      this.isDataLoaded = true;
      if (isViewModel) helperStore.toggleLoader(false);
    } catch (error) {
      if (debug) console.error(error);
      if (isViewModel || disableModal) helperStore.toggleLoader(false);
    } finally {
      this.isLoading = false;
    }
  });

  fetchProcessedRequests = action(async (disableModal = false) => {
    const isViewModel = document.location.pathname.includes('requests'),
      {
        store: { debug, storageStore, networkStore, helperStore },
      } = this.rootModels;

    if (isViewModel) helperStore.toggleLoader(true);

    try {
      this.isLoading = true;

      const { storeId } = storageStore,
        created = +subDays(new Date(), 120);

      const whereClause = `(sourceStoreId = '${storeId}') AND (status = 'Declined' OR status = 'Closed') AND createdDate > ${created}`;

      const { response, status } = await networkStore.post('LoadObjectsOfClass', {
        whereClause: whereClause,
        offset: 0,
        orderByName: 'created',
        orderByType: 'DESC',
        pageSize: 100,
        relations: ['articleItems.logs', 'customer.addresses', 'deliveryAddress', 'invoiceAddress'],
        clazz: 'com.innomos.baas.common.model.OmniChannelRequest',
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "LoadObjectsOfClass" Failed!');

      const {
        data: { data },
      } = JSON.parse(response);

      this.processed = helperStore.mapRequestsData(data);
      this.isProcessedLoaded = true;
      if (isViewModel || disableModal) helperStore.toggleLoader(false);
    } catch (error) {
      if (debug) console.error(error);
      if (isViewModel || disableModal) helperStore.toggleLoader(false);
    } finally {
      this.isLoading = false;
    }
  });

  get requestsByOpened() {
    const useStatusFilter = item => item.status === this.Status.Open;

    const useTimeFilter = item =>
      useStatusFilter(item) && +item.created >= subDays(new Date(), this.filters[this.currentFilter]).getTime();

    return !this.currentFilter ? this.data.filter(useStatusFilter) : this.data.filter(useTimeFilter);
  }

  get requestsByProcessed() {
    const { Open } = this.Status;

    const useStatusFilter = item => item.status !== Open;

    const useTimeFilter = item =>
      useStatusFilter(item) && +item.created >= subDays(new Date(), this.filters[this.currentFilter]).getTime();

    return !this.currentFilter ? this.processed.filter(useStatusFilter) : this.processed.filter(useTimeFilter);
  }

  get requestsByStatus() {
    const {
      currentTab,
      Tabs: { Opened, Processed },
    } = this;

    switch (currentTab) {
      case Opened:
        return this.requestsByOpened;
      case Processed:
        return this.requestsByProcessed;
      default:
        return [];
    }
  }

  reset = action(() => {
    this.isDataLoaded = false;
    this.isProcessedLoaded = false;
    this.data = [];
    this.processed = [];
  });
}

export default Model('RequestsModel')(RequestsModel);
