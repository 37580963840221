import { useCallback, useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { addDays, format } from 'date-fns';
import { UnfoldMore } from '@mui/icons-material';
import { Box } from '@mui/material';
import * as DateLocalization from 'date-fns/locale';

import { ButtonOutlinedStyled, ButtonStyled } from '@framework/styles/App';

import {
  DatePickerActiveOptionStyled,
  DatePickerControllerStyled,
  DatePickerLabelStyled,
  DatePickerStyled,
  DatePickerWrapperStyled,
} from '@framework/styles/DatePicker';

const formatNumericDate = value => {
  const numericValue = parseInt(value);
  return numericValue >= 10 ? numericValue.toString() : '0' + numericValue;
};

const generateTimeOptions = (variant, defaultValue, settings) => {
  const currentTime = new Date(),
    options = {},
    defaults = {};

  switch (variant) {
    case 'date': {
      let currentYear = currentTime.getFullYear(),
        currentMonth = currentTime.getMonth() + 1,
        currentDate = currentTime.getDate();

      options.month = Array(12)
        .fill(1)
        .map((_, index) =>
          format(new Date().setMonth(index, 1), 'LLLL', {
            locale: DateLocalization[settings.locale],
          })
        );
      options.date = Array(31)
        .fill(1)
        .map((value, index) => formatNumericDate(value + index));
      options.year = Array(100)
        .fill(1)
        .map((value, index) =>
          String(index < 50 ? currentYear - (50 - index) : currentYear > 50 ? currentYear + (index - 50) : currentYear)
        );

      if (settings.MinTime && settings.MaxTime) {
        const currentHours = currentTime.getHours();

        if (currentHours < settings.MinTime || currentHours > settings.MaxTime) {
          const nextTime = addDays(currentTime, 1);

          currentYear = nextTime.getFullYear();
          currentMonth = nextTime.getMonth() + 1;
          currentDate = nextTime.getDate();
        }
      }

      if (defaultValue) [currentYear, currentMonth, currentDate] = defaultValue.split('-');

      defaults.year = String(currentYear);
      defaults.month = options.month[currentMonth - 1];
      defaults.date = formatNumericDate(currentDate);

      return { options, defaults };
    }
    case 'time': {
      let hours = currentTime.getHours(),
        minutes = currentTime.getMinutes();

      const withMeridiem = settings.variant !== 'international';

      if (withMeridiem) options.meridiem = ['AM', 'PM'];
      options.hours = Array(withMeridiem ? 12 : 24)
        .fill(1)
        .map((_, index) => formatNumericDate(withMeridiem ? (index === 0 ? 12 : index) : index));
      options.minutes = Array(4)
        .fill(0)
        .map((value, index) => formatNumericDate(parseInt(settings.TimeStep) * (value + index)));

      let allowedMinutes = options.minutes.find(value => value > minutes) ?? false;
      hours = +hours + (!allowedMinutes ? 1 : 0);

      if (defaultValue) [hours, allowedMinutes] = defaultValue.split(':');

      if (settings.MinTime && settings.MaxTime) {
        const currentHours = currentTime.getHours();

        if (currentHours < settings.MinTime || currentHours > settings.MaxTime) {
          hours = 9;
          minutes = 0;
        }
      }

      const defaultDate = new Date(`${settings.date}T${hours}:${allowedMinutes}`);

      if (currentTime.getTime() > defaultDate.getTime()) {
        hours = currentTime.getHours();
        allowedMinutes = options.minutes.find(value => value > minutes) ?? false;
        hours = +hours + (!allowedMinutes ? 1 : 0);
      }

      defaults.hours = formatNumericDate(withMeridiem && hours > 12 ? hours - 12 : hours);
      defaults.minutes = formatNumericDate(allowedMinutes || 0);
      if (withMeridiem) defaults.meridiem = hours > 11 && hours !== 0 ? 'PM' : 'AM';

      return { options, defaults };
    }
  }
};

const DatePickerComponent = props => {
  const {
    store: { localesStore },
  } = props;

  const { value, variant = 'date', error, placeholder, onChange, required, disabled, settings = {} } = props;

  const [toggled, setToggled] = useState(false),
    { options: optionGroups, defaults } = useMemo(
      () =>
        generateTimeOptions(variant, value, {
          ...settings,
          locale: localesStore.currentLocale,
        }),
      [toggled, value]
    );

  const withMeridiem = settings.variant !== 'international';

  const [datePickerWidth, setDataPickerWidth] = useState(0),
    [selectedValue, setSelectedValue] = useState(value),
    [valueGroups, setValueGroups] = useState(defaults);

  const handleBlur = useCallback(() => {
      setToggled(false);
    }, []),
    handleChange = (name, value) => {
      let additionalSetup = {};
      const { MinTime, MaxTime, PastDisabled, time, date } = settings;

      let isWrong = false;

      switch (variant) {
        case 'date': {
          const chosenYear = name === 'year' ? value : valueGroups['year'],
            chosenMonth = formatNumericDate(
              optionGroups['month'].findIndex(month => month === (name === 'month' ? value : valueGroups['month'])) + 1
            ),
            chosenDate = name === 'date' ? value : formatNumericDate(valueGroups['date']);

          const currentISOString = `${chosenYear}-${chosenMonth}-${chosenDate}`;

          let requestedDate = new Date(currentISOString),
            currentDate = new Date();

          const currentHours = currentDate.getHours(),
            requestedISOString = requestedDate.toISOString().split('T')[0];

          if (time) {
            const [hours, minutes] = time.split(':');
            currentDate = currentDate.getTime();
            requestedDate.setHours(hours, minutes, 0, 0);
          } else {
            requestedDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);
          }

          if (
            requestedISOString !== currentISOString ||
            (PastDisabled && requestedDate < currentDate) ||
            (currentDate instanceof Date && (currentHours < MinTime || currentHours > MaxTime))
          )
            isWrong = true;

          break;
        }
        case 'time': {
          const hours = name === 'hours' ? value : valueGroups['hours'],
            minutes = name === 'minutes' ? value : valueGroups['minutes'],
            meridiem = withMeridiem ? (name === 'meridiem' ? value : valueGroups['meridiem']) : '';

          let isoHours = withMeridiem ? (meridiem !== 'AM' ? +hours + 12 : hours) : hours;

          if (withMeridiem) {
            if (+isoHours === 24 && meridiem === 'PM') isoHours = 12;
            if (+isoHours === 12 && meridiem === 'AM') isoHours = 0;
          }

          const currentDate = new Date().getTime(),
            requestedDate = date ? new Date(date).setHours(isoHours, minutes) : null;

          if (withMeridiem) {
            if (isoHours > MaxTime && value === 'PM') {
              isoHours = '12';
              additionalSetup['hours'] = '12';
            } else if (isoHours < MinTime && value === 'AM') {
              additionalSetup['hours'] = isoHours = '09';
            } else if (isoHours === 0) {
              additionalSetup['hours'] = isoHours = '12';
              additionalSetup['meridiem'] = 'AM';
            }
          }

          if (
            (PastDisabled && requestedDate && requestedDate < currentDate) ||
            isoHours < MinTime ||
            isoHours > MaxTime ||
            (isoHours >= MaxTime && minutes > 0)
          )
            isWrong = true;

          break;
        }
      }

      if (isWrong) additionalSetup = {};

      setValueGroups(prev => ({
        ...prev,
        [name]: isWrong ? valueGroups[name] : value,
        ...additionalSetup,
      }));
    },
    handleConfirm = useCallback(() => {
      setToggled(false);

      switch (variant) {
        case 'date': {
          const chosenYear = valueGroups['year'],
            chosenMonth = formatNumericDate(
              optionGroups['month'].findIndex(month => month === valueGroups['month']) + 1
            ),
            chosenDate = formatNumericDate(valueGroups['date']);

          return setSelectedValue(`${chosenYear}-${chosenMonth}-${chosenDate}`);
        }

        case 'time': {
          const isoHours = withMeridiem && valueGroups.meridiem !== 'AM' ? +valueGroups.hours + 12 : valueGroups.hours;

          const hours = withMeridiem && valueGroups.meridiem !== 'AM' && isoHours < 24 ? isoHours : valueGroups.hours,
            minutes = valueGroups.minutes;

          return setSelectedValue(`${hours}:${minutes}`);
        }
      }
    }, [valueGroups]);

  useEffect(() => {
    if (value && selectedValue !== value) setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    if (typeof onChange === 'function') onChange(selectedValue);
  }, [selectedValue]);

  return (
    <DatePickerStyled error={error} disabled={disabled} onMouseLeave={handleBlur}>
      <DatePickerLabelStyled data-element="date" data-toggled={toggled} onClick={() => setToggled(prev => !prev)}>
        <DatePickerActiveOptionStyled $isActive={Boolean(selectedValue)}>
          {selectedValue
            ? variant === 'date'
              ? format(new Date('' + selectedValue), 'dd MMM, yyyy')
              : format(new Date('2000-01-01T' + selectedValue), 'HH:mm') +
                ' ' +
                localesStore.translate('placeholder.label.time')
            : required
            ? placeholder + ' *'
            : placeholder}
        </DatePickerActiveOptionStyled>

        {!disabled && <UnfoldMore />}
      </DatePickerLabelStyled>
      {toggled && (
        <DatePickerWrapperStyled>
          <Box>
            <DatePickerControllerStyled
              ref={node => node && node?.offsetWidth && !datePickerWidth && setDataPickerWidth(node?.offsetWidth)}
              innerWidth={datePickerWidth}
              variant={variant}
              optionGroups={optionGroups}
              valueGroups={valueGroups}
              onChange={handleChange}
              height={140}
            />
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateRows: `${parseInt(datePickerWidth) < 250 ? 20 : 35}px`,
              gridTemplateColumns: '45% 45%',
              columnGap: '10%',
              '& > *': {
                padding: '0 5px !important',
                fontSize: `${parseInt(datePickerWidth) < 250 ? 10 : 14}px !important`,
              },
            }}
          >
            <ButtonOutlinedStyled onClick={handleBlur}>
              {localesStore.translate('placeholder.controls.cancel')}
            </ButtonOutlinedStyled>
            <ButtonStyled onClick={handleConfirm}>
              {localesStore.translate('placeholder.controls.confirm')}
            </ButtonStyled>
          </Box>
        </DatePickerWrapperStyled>
      )}
    </DatePickerStyled>
  );
};

export default inject('store')(observer(DatePickerComponent));
