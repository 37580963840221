import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const Transaction = memo(({ width, height, fill = '#000000', count }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width ?? '80'} height={height ?? '80'} viewBox="0 0 126 130">
    <path
      fill={fill ?? '#000000'}
      fillRule="evenodd"
      d="M124.663 24.361H87.621c0-13.433-11.891-24.363-26.506-24.363S34.61 10.928 34.61 24.361H1.337C.6 24.361 0 24.96 0 25.695v102.969c0 .736.6 1.334 1.337 1.334h123.326c.75 0 1.337-.586 1.337-1.334V25.695c0-.748-.587-1.334-1.337-1.334zM61.115 2.72c13.111 0 23.778 9.708 23.778 21.641H37.337c0-11.933 10.666-21.641 23.778-21.641zm62.211 124.61H2.673V27.028h120.653V127.33z"
    />
    <text y="92" x="50%" textAnchor="middle" fontSize="48">
      {count}
    </text>
  </svg>
));

export default Icon('Transaction')(Transaction);
