import { v4 } from 'uuid';
import {Account, Client, Databases, Functions, Storage} from 'appwrite';

import Store from '@framework/decorators/Store';

export class NetworkStore {
  isNativeThread = false;
  baseFunctionName = 'SalesMateAPI';
  baseUrl = process.env.SERVICE_API;

  client = new Client().setEndpoint(process.env.APPWRITE_API).setProject(process.env.APPWRITE_ID);

  account = new Account(this.client);
  storage = new Storage(this.client);
  functions = new Functions(this.client);
  databases = new Databases(this.client);

  webviewListeners = {};

  constructor(store) {
    this.store = store;
    this.initNativeBridge();
  }

  initNativeBridge = () => {
    const isNativeThread = Boolean(window.webkit);

    if (!isNativeThread) return;

    this.isNativeThread = isNativeThread;
    window.actions = window.actions || {};
    window.actions.callFromApp = this.application.bind(this);
  };
  
  trackAnalytics = async (category, action, label, value = '') => {
    const { storageStore } = this.store;
    
    const defaultPayload = {
      action: action,
      category: category,
      storeId: storageStore.storeId,
      deviceId: storageStore.deviceId,
      storeName: storageStore.storeName,
      merchantId: storageStore.merchant?.id || '',
      label: label,
      value: value
    };
    
    return await this.post('AnalyticsKeypoint', defaultPayload);
  }

  webview = (type, data = {}) =>
    new Promise(resolve => {
      const id = v4(),
        { debug } = this.store;
      const serializedObject = JSON.parse(JSON.stringify(data));

      try {
        this.webviewListeners[id] = resolve;

        window.webkit.messageHandlers.appMessageHandler.postMessage(
          type === 'response'
            ? { ...serializedObject }
            : {
                id,
                type,
                data: serializedObject,
              }
        );
      } catch (error) {
        if (debug) console.error(error);
        resolve({ id, type: 'crashed', data: error });
      }
    });

  application = ({ id, type, data } = {}) => {
    const { storageStore } = this.store;

    const isRequest = Boolean(type) && type !== 'read_user_prefs';

    if (isRequest) {
      switch (type) {
        case 'registration': {
          let result = 'success';

          try {
            storageStore.deviceRegistration(data);
          } catch {
            result = 'failed';
          }

          return this.webview('response', { id, data: { result } });
        }
        default:
          console.error('Undefined WebApp method!');
      }
    } else {
      if (typeof this.webviewListeners[id] !== 'function') return data;
      this.webviewListeners[id](data);
    }
  };

  get = async (service, body = {}) => {
    const { storageStore } = this.store;
    const userToken = storageStore.merchant?.userToken ?? null;

    switch (service) {
      case 'Authorization': {
        return this.account.getSession(body.id);
      }
      case 'GetSignature': {
        return this.storage.getFile('merchantSignatures', body.id);
      }
      case 'GetComplaintImage': {
        return this.storage.getFileView('complaintImages', body.id);
      }
      default: {
        try {
          const baseResponse = await this.functions.createExecution(
            this.baseFunctionName,
            JSON.stringify(Object.assign({
              apiUrl: this.baseUrl + '/' + service,
              args: body,
              requestMethod: 'GET',
            }, userToken ? { userToken } : {}))
          );
          
          const { data: responseId } = JSON.parse(baseResponse.response);
  
          const { responseBody } = await this.databases.getDocument('administration', 'responseBodies', responseId);
  
          return Promise.resolve({
            status: 'completed',
            response: JSON.stringify({ data: JSON.parse(responseBody) }),
          });
        } catch (error) {
          console.log(error);
          if (service === 'MaintenancePageState') {
            return Promise.resolve({
              status: 'completed',
              response: JSON.stringify({ data: { activated: false } }),
            });
          } else {
            throw error;
          }
        }
      }
    }
  };

  post = async (service, body = {}) => {
    const { storageStore } = this.store;
    const userToken = storageStore.merchant?.userToken ?? null;

    switch (service) {
      case 'Authorization': {
        return this.account.createEmailSession(body.email, body.password);
      }
      case 'CreateComplaintImage': {
        return this.storage.createFile('complaintImages', body.id, body.file);
      }
      case 'CreateSignature': {
        return this.storage.createFile('merchantSignatures', body.id, body.file);
      }
      default: {
        const documentId = v4(),
          data =  JSON.stringify(Object.assign({
            apiUrl: this.baseUrl + '/' + service,
            args: body,
            requestMethod: 'POST',
          }, userToken ? { userToken } : {}))

        try {
          const { $id } = await this.databases.createDocument('administration', 'postBodies', documentId, {
            postBody: data,
          });

          if (!$id) throw Error('Network Store: Request processing failed!');

          const baseResponse = await this.functions.createExecution(this.baseFunctionName, documentId);

          const { data: responseId } = JSON.parse(baseResponse.response);

          const { responseBody } = await this.databases.getDocument('administration', 'responseBodies', responseId);

          return Promise.resolve({
            status: 'completed',
            response: JSON.stringify({ data: JSON.parse(responseBody) }),
          });
        } catch (error) {
          return {
            status: 'failed',
            message: error,
          };
        }
      }
    }
  };

  put = async (service, body = {}) => {
    const { storageStore } = this.store;
    const userToken = storageStore.merchant?.userToken ?? null;

    switch (service) {
      case 'Authorization': {
        try {
          storageStore.session = '';
          return this.account.deleteSession(body.sessionId || 'current');
        } finally {
          console.warn('Appwrite: session was not found in DB!')
        }
      }
      case 'UpdateSignature': {
        return this.storage.updateFile('customer_signatures', body.id);
      }
      default: {
        const documentId = v4(),
          data =  JSON.stringify(Object.assign({
            apiUrl: this.baseUrl + '/' + service,
            args: body,
            requestMethod: 'PUT',
          }, userToken ? { userToken } : {}))

        try {
          const { $id } = await this.databases.createDocument('administration', 'postBodies', documentId, {
            postBody: data,
          });

          if (!$id) throw Error('Network Store: Request processing failed!');

          return this.functions.createExecution(this.baseFunctionName, documentId);
        } catch (error) {
          return {
            status: 'failed',
            message: error,
          };
        }
      }
    }
  };

  catchException = async (exception, at, _a, _b, errorObject) => {
    try {
      return await this.databases.createDocument('administration', 'exceptions', v4(), JSON.stringify({ exception, at: errorObject.stack }));
    } catch (error) {
      console.error(error)
    }
  }
  
  stringify = (object) => {
    const props = {};
  
    Object.getOwnPropertyNames(object).forEach((key) => {
      props[key] = object[key];
    });
  
    return JSON.stringify(props, null, 2);
  }

  log = async (type, data) => {
    try {
      const exception = JSON.stringify({ exceptionType: type, exceptionData: this.stringify(data) })
      return await this.databases.createDocument('administration', 'web-service-info', v4(), JSON.stringify({ log: exception }));
    } catch (error) {
      console.error('Logging failed!');
    }
  }
}

export default Store('NetworkStore')(NetworkStore);
