import {addDays, format} from 'date-fns';
import { useMediaQuery } from '@mui/material';
import {action, computed, makeObservable, observable} from 'mobx';

import Store from '@framework/decorators/Store';

export class HelperStore {
  Direction = { Ltr: 'ltr', Rtl: 'rtl' };

  isLoader = false;
  isInactivity = false;
  isInactivityVideo = false;
  notification = null;
  tracker = null;
  externalWindow = null;

  baseAnimationDirection = this.Direction.Rtl;
  screensAnimationDirection = this.Direction.Rtl;
  cartAnimationDirection = this.Direction.Rtl;
  cockpitAnimationDirection = this.Direction.Ltr;

  previousLocation = '/';
  previousLocationSearch = '';
  previousSearchValue = '';

  constructor(store) {
    this.store = store;

    makeObservable(this, {
      isLoader: observable,
      isInactivity: observable,
      isInactivityVideo: observable,
      notification: observable,
      tracker: observable,
      isIpadPro: computed
    });

    window.showUpdateNotification = function (worker) {
      swal({
        title: this.store.localesStore.translate('placeholder.label.notice'),
        text: this.store.localesStore.translate(
          'notification.update.label.description'
        ),
        buttons: {
          apply: this.store.localesStore.translate('placeholder.label.ok'),
        },
      }).then(() => {
        worker?.postMessage({ type: 'UPDATE' });
        window.location.reload();
      });
    }.bind(this);
  }

  toggleLoader = action(state => {
    this.isLoader = state;
  });

  toggleNotification = action(notification => {
    this.notification = notification;
  });

  toggleInactivity = action(state => {
    this.isInactivity = state;
  });
  
  toggleInactivityVideo = action(state => {
    this.isInactivityVideo = state;
  });

  toggleTracker = action(tracker => {
    const { networkStore } = this.store;

    if (networkStore.isNativeThread) {
      return networkStore.webview('modal', tracker);
    } else if (this.externalWindow && !this.externalWindow.closed) {
      this.externalWindow.focus();
    } else {
      this.externalWindow = window.open(tracker.url, '_blank');
    }
  });
  
  downloadUrl = action(async url => {
      const { helperStore, storageStore, networkStore, secretKey, applicationId } = this.store;
      
      const userToken = storageStore.merchant?.userToken ?? null;
    
      helperStore.toggleLoader(true);
      
      try {
        const response = await fetch(url, {
          headers: {
            'application-id': applicationId,
            'secret-key': secretKey,
            'user-token': userToken,
          },
        });
        
        if (!response.ok) throw new Error('account_permissions_invalid');
      
        const blob = await response.blob();
        
        const [fileString] = url.split('/').reverse();
        
        const [fileName, fileExt] = fileString.split('.');
        
        if (networkStore.isNativeThread) {
          const fileData = await new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = ({ target }) => {
              if (!target || typeof target.result !== 'string')
                return reject('complaint_attachment_format');

              const [_, result] = (target.result || '').split('base64,');

              resolve(result);
            };

            reader.onerror = () => {
              reject('complaint_attachment_invalid');
            };

            reader.readAsDataURL(fileContent);
          });
          
          return await networkStore.webview('download', {
            fileData: fileData,
            fileMimeType: blob.type,
            fileName: `${fileName}.${fileExt}`,
          });
        } else {
          const objectUrl = URL.createObjectURL(blob);

          const anchor = document.createElement('a');

          anchor.setAttribute('href', objectUrl);
          anchor.setAttribute('target', '_blank');
          anchor.setAttribute('download', `${fileName}.${fileExt}`);

          anchor.click();

          anchor.remove();

          URL.revokeObjectURL(objectUrl);
        }
      } finally {
        helperStore.toggleLoader(false);
      }
  })
  get isIpadPro() {
    const { storageStore } = this.store;
    const { userAgent } = navigator;
    
    const isIosDevice = /iPad|iPhone|iPod/.test(userAgent) && window.MSStream;
    const isIosNative = ((storageStore.device?.os || '').toLowerCase()).includes('ios');
    
    if (isIosDevice || isIosNative) {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;

        const iPadProResolutions = [
          { width: 1024, height: 1366 }, // iPad Pro 12.9-inch
          { width: 1366, height: 1024 }, // iPad Pro 12.9-inch (landscape)
          { width: 834, height: 1194 },  // iPad Pro 11-inch
          { width: 1194, height: 834 },  // iPad Pro 11-inch (landscape)
        ];

        for (let i = 0; i < iPadProResolutions.length; i++) {
            const modelResolution = iPadProResolutions[i];
            
            if (
              (screenWidth === modelResolution.width && screenHeight === modelResolution.height) ||
              (screenWidth === modelResolution.height && screenHeight === modelResolution.width)
            ) {
                return true;
            }
        }
    }

    return false;
  }
  
  toggleUrl = action(url => {
    const { networkStore } = this.store;

    if (networkStore.isNativeThread) {
      return networkStore.webview('modal', { url: url });
    } else if (this.externalWindow && !this.externalWindow.closed) {
      this.externalWindow.focus();
    } else {
      this.externalWindow = window.open(url, '_blank');
    }
  });

  setPreviousSearchValue = value => {
    this.previousSearchValue = value;
  };

  setPreviousLocation = value => {
    this.previousLocation = value;
  };

  setPreviousLocationSearch = value => {
    this.previousLocationSearch = value;
  };

  isMobile = () => {
    const isExtraSmall = useMediaQuery('(max-width: 600px)'),
      isSmall = useMediaQuery('(min-width: 600px) and (max-width: 900px)');

    return isExtraSmall || isSmall;
  };

  isLTR = direction => {
    return direction === this.Direction.Ltr;
  };

  mapBackendData = backendData => {
    return Object.entries(backendData).reduce((formattedData, [key, value]) => {
      if (key.includes('_')) return { ...formattedData };

      return {
        ...formattedData,
        [key]: value,
      };
    }, {});
  };

  mapVouchers = vouchers => {
    return vouchers.forEach(voucher => {
      for (const key in voucher) {
        if (key.includes('_') || key === 'addresses') delete voucher[key];
        voucher.formattedFields = {
          validTill: format(+voucher.validTill, 'dd.MM.yyyy'),
        };
      }
    });
  };

  mapOrdersData = ordersData => {
    const {
      objectId,
      invoiceAddress,
      deliveryAddress,
      type,
      articleItems,
      created,
      status,
      processId,
      trackingUrl,
      updated,
      sourceStoreSellerId,
      shippingType,
    } = ordersData;

    const updatedAt = Boolean(updated) ? +updated : +created;

    const customer = {
      salutation: invoiceAddress?.salutation,
      firstName: invoiceAddress?.firstName,
      lastName: invoiceAddress?.lastName,
      email: invoiceAddress?.email,
      phone: invoiceAddress?.phone,
      street: invoiceAddress?.street,
      streetNumber: invoiceAddress?.streetNumber,
      postalCode: invoiceAddress?.postalCode,
      city: invoiceAddress?.city,
      country: invoiceAddress?.country,
      customerId: invoiceAddress?.customerId || '-',
    };

    const {
      salutation,
      firstName,
      lastName,
      email,
      phone,
      street,
      streetNumber,
      postalCode,
      city,
      country,
      customerId,
    } = customer;

    const { price: totalPrice, currency: currency } = articleItems.reduce(
      (acc, item) => ({
        ...acc,
        price: acc.price + (item.price ?? 0) * item.quantity,
        currency: item.currency || '',
      }),
      { price: 0, currency: '' }
    );

    articleItems.sort((prev, next) => prev.created - next.created);
    
    const archiveStatuses = [
      'Delivered', 'Revoked', 'Expired', 'Canceled', 'Rejected', 'Declined',
    ];
    
    const isOverdue =
      !archiveStatuses.includes(status) &&
      +addDays(+updatedAt, 8) < Date.now() &&
      (type === 'ClickAndReserve' || type === 'ClickAndCollect' || type === 'Reservation');
    
    const itemObject = {
      objectId,
      salutation,
      firstName,
      lastName,
      email,
      phone,
      type,
      articleItems,
      created: +created,
      timestamp: +created,
      updated: updated ? +updated : +created,
      status: isOverdue ? 'OVERDUE' : status,
      processId,
      street,
      streetNumber,
      postalCode,
      mobile: invoiceAddress?.addressLine2,
      city,
      country,
      customerId,
      totalPrice,
      currency,
      trackingUrl: shippingType ?? trackingUrl,
      sourceStoreSellerId,
    };

    if (status === 'Parked' || status === 'Creation') {
      itemObject.processData = {
        processId,
        type,
        invoiceAddress,
        deliveryAddress,
        articleItems,
      };
    }

    return itemObject;
  };

  mapShoppingHistory = data => {
    return data.reduce((acc, transaction) => {
      const { storeName } = transaction;

      const transactionPositions = transaction.transactionPositions.map(position => {
        const transactionDate = transaction['___dates___'][0];

        for (const key in position) {
          if (key.includes('_')) delete position[key];
        }

        return {
          storeName: storeName,
          transactionId: transaction.transactionId,
          transactionDate: transactionDate,
          ...position,
        };
      });

      return [...acc, ...transactionPositions];
    }, []);
  };

  mapRequestsData = data => {
    return data.reduce((acc, item) => {
      const mappedItem = this.mapBackendData(item);
      const { invoiceAddress, deliveryAddress } = mappedItem;

      mappedItem.invoiceAddress = this.mapBackendData(invoiceAddress);
      mappedItem.deliveryAddress = this.mapBackendData(deliveryAddress);
      mappedItem.articleItems = mappedItem.articleItems.map(this.mapBackendData);

      return [...acc, { ...mappedItem }];
    }, []);
  };

  mapSearchData = data => {
    return data.reduce((acc, item) => {
      const { addresses } = item;
      const [address] = addresses;

      return [
        ...acc,
        {
          customerId: item.objectId,
          name: `${item.salutation} ${item.firstName} ${item.lastName}`,
          email: item.email,
          address: {
            street: address.street,
            streetNumber: address.streetNumber,
            postalCode: address.postalCode,
            city: address.city,
            country: address.country,
            phone: item.phone,
          },
        },
      ];
    }, []);
  };

  mapDetailsData = async data => {
    const {
      addresses: [address],
    } = data;

    for (const key in data) {
      if (key.includes('_') || key === 'addresses') delete data[key];
    }

    for (const key in address) {
      if (key.includes('_')) delete address[key];
    }

    let favoriteStore = '-';

    try {
      const { response } = await this.store.networkStore.post(
        'LoadObjectsOfClass',
        {
          whereClause: `storeId='${data.favouriteStoreId}'`,
          offset: 0,
          orderByType: 'ASC',
          pageSize: 100,
          relations: [],
          clazz: 'com.innomos.baas.common.model.Store',
        },
        'POST'
      );

      const {
        data: {
          data: [store],
        },
      } = JSON.parse(response);

      if (store != null) {
        favoriteStore = store.name;
      }
    } catch (error) {
      if (this.store.debug) console.error(error);
    }

    return (
      data && {
        id: data.customerId,
        formattedFields: {
          fullName: `${data.firstName} ${data.lastName}`,
          birthday: data.birthday ? format(+data.birthday, 'dd.MM.yyyy') : null,
          favoriteStore: favoriteStore,
        },
        address: {
          ...address,
        },
        ...data,
      }
    );
  };

  mapParkedArticleItems = async article => {
    if (!article) throw Error('Incorrect article object!');

    const { networkStore } = this.store;

    try {
      const { status, response } = await networkStore.post('LoadObjectsOfClass', {
        whereClause: `articles.gtin='${article.gtin}'`,
        pageSize: 1,
        relations: [],
        clazz: 'com.innomos.baas.common.model.Product',
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "LoadObjectsOfClass" Failed!');

      const {
        data: { data },
      } = JSON.parse(response);

      return {
        productId: data.length ? data[0].objectId : null,
        articleMpn: article.articleMpn,
        articleNumber: article.articleNumber,
        color: article.color,
        colorCode: article.colorCode,
        colorImageUrl: article.colorImageUrl,
        created: article.created,
        price: article.price,
        currency: article.currency,
        descriptionLong: article.descriptionLong,
        descriptionShort: article.descriptionShort,
        gtin: article.gtin,
        imageUrl: article.imageUrl,
        name: article.name,
        size: article.size,
        objectId: article.objectId,
        quantity: article.quantity,
        returnQuantity: article.returnQuantity,
        returnReason: article.returnReason,
        status: article.status,
        trackingUrl: article.trackingUrl,
        updated: article.updated,
        additionalProperties: article.additionalProperties,
        productURL: article.productURL,
      };
    } catch (error) {
      if (this.store.debug) console.error(error);
      this.store.networkStore.log('[HelperStore]:[mapParkedArticleItems]', error)
    }
  };
  
  convertBase64toBlob = (base64String) => {
    const contentType = base64String.split(';')[0].split(':')[1];
    const byteCharacters = atob(base64String.split(',')[1]);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: contentType });
  }
}

export default Store('HelperStore')(HelperStore);
