import { css, styled } from '@mui/material';

import { DialogStyled, DialogTitleStyled, DialogContentStyled } from '@framework/styles/App';

import Styled from '@framework/decorators/Styled';

export const VouchersDialogStyled = Styled('CustomerVouchers', 'VouchersDialogStyled')(styled(DialogStyled)``);

export const VouchersTitleStyled = Styled(
  'CustomerVouchers',
  'VouchersTitleStyled'
)(styled(DialogTitleStyled)`
  margin-bottom: 30px;

  & > h2 {
    font-size: 24px;
    text-align: center;
    font-weight: 400;
  }

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      margin-bottom: 0px;
      border-bottom: 1px solid ${application.palette.grayline};
      padding: 0 10px;
      height: 80px;

      & > h2 {
        font-size: 21px;
      }
    }
  `}
`);

export const VouchersContentStyled = Styled(
  'CustomerVouchers',
  'VouchersContentStyled'
)(styled(DialogContentStyled)`
  overflow: auto;

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      height: calc(100% - 80px);
      padding: 10px;
    }
  `}
`);
