import { Container, css, Grid, styled } from '@mui/material';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import SelectComponent from '@framework/components/Select';
import DatePickerComponent from '@framework/components/DatePicker';

import Styled from '@framework/decorators/Styled';

export const getMediaByType = type => {
  switch (type) {
    case 'full': {
      return { xs: 12, sm: 12, md: 12, lg: 12 };
    }
    case 'tablet-main': {
      return { xs: 12, sm: 12, md: 4, lg: 4 };
    }
    case 'desktop-main': {
      return { xs: 12, sm: 12, md: 4, lg: 4 };
    }
    case 'short': {
      return { xs: 5, sm: 5, md: 5, lg: 5 };
    }
    case 'half': {
      return { xs: 6, sm: 6, md: 6, lg: 6 };
    }
    case 'long': {
      return { xs: 7, sm: 7, md: 7, lg: 7 };
    }
  }
};

export const FormContainerStyled = Styled(
  'Form',
  'FormContainerStyled'
)(styled(Container)`
  padding: 10px 0;
`);

export const FormGridStyled = Styled(
  'Form',
  'FormGridStyled'
)(styled(Grid)`
  position: relative;

  ${({ merged }) =>
    merged &&
    css`
      grid-row: span ${merged};
    `}
`);

export const FormRowStyled = Styled(
  'Form',
  'FormRowStyled'
)(
  styled(props => (
    <FormGridStyled container {...props}>
      {props.children}
    </FormGridStyled>
  ))``
);

export const FormSectionStyled = Styled(
  'Form',
  'FormSectionStyled'
)(styled(FormRowStyled)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${({ gap }) => css`25px ${gap ?? '20'}px`};
  margin-top: 10px;
  width: auto;

  & h3 {
    width: auto;
  }

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.whitesmoke};
  `}
`);

export const FormGroupedButtonsStyled = Styled(
  'Form',
  'FormGroupedButtonsStyled'
)(styled(FormRowStyled)`
  position: relative;
  margin-top: 25px;
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: flex-end;

  & button {
    & + button {
      margin-left: 20px;
    }
  }

  ${({ theme: { application }, isAdditionalAddressEnabled }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      & button {
        width: 100%;
        height: 100%;

        & + button {
          margin-left: 0px;
        }
      }

      ${isAdditionalAddressEnabled &&
      css`
        height: unset;
        grid-template-rows: 50px 50px;

        & button:last-child {
          grid-column: span 2;
        }
      `}
    }
  `}
`);

export const FormColumnStyled = Styled(
  'Form',
  'FormColumnStyled'
)(styled(props => {
  const media = props?.media ?? { xs: 12, sm: 12, md: 4, lg: 4 };

  return (
    <FormGridStyled item {...props} {...media}>
      {props.children}
    </FormGridStyled>
  );
})`
  ${({ $isMain, $isMerged, theme: { application }, gap }) =>
    $isMain &&
    css`
      padding: ${gap ?? '20'}px;
      background-color: ${application.palette.whitesmoke};

      @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
        margin-top: 10px;
      }

      ${$isMerged &&
      css`
        display: grid;
        grid-template-rows: repeat(4, 1fr);

        @media ${application.breakpoints.xs}, ${application.breakpoints.sm}, ${application.breakpoints.md} {
          grid-auto-rows: 1fr;
        }
      `}
    `}
`);

export const FormHeaderStyled = Styled(
  'Form',
  'FormHeaderStyled'
)(styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`);

export const FormHeadingStyled = Styled(
  'Form',
  'FormHeadingStyled'
)(styled('h2')`
  font-size: 24px;
  text-align: center;
  font-weight: 400;
`);

export const FormSubheadingStyled = Styled(
  'Form',
  'FormSubheadingStyled'
)(styled('h3')`
  width: 100%;
  font-size: 15px;
  text-align: center;
  margin: 0 0 20px 0;

  ${({ theme: { application } }) => css`
    color: ${application.palette.disabled};
  `}
`);

export const FormHintStyled = Styled(
  'Form',
  'FormHintStyled'
)(styled('p')`
  margin-top: 10px;
  margin-bottom: 0px;
  display: block;
  width: 100%;
  font-size: 14px;
  text-align: right;
`);

export const FormErrorStyled = Styled(
  'Form',
  'FormErrorStyled'
)(styled('div')`
  margin: 3px 0;
  width: 100%;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 15px;
  visibility: hidden;
  opacity: 0;

  ${({ theme: { application } }) => css`
    color: ${application.palette.danger};
  `}
`);

export const FormControlStyled = Styled(
  'Form',
  'FormControlStyled'
)(styled('div')`
  position: relative;
  width: 100%;
  height: 50px;
  margin-bottom: 25px;

  ${({ $checkbox, error, disabled, theme: { application } }) => css`
    ${$checkbox &&
    css`
      height: auto;
    `};
    
    ${disabled && css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    `}

    ${error &&
    css`
      & ${FormErrorStyled} {
        visibility: visible;
        opacity: 1;
      }

      & ${FormInputStyled} {
        border-color: ${application.palette.danger};
      }

      & ${FormGenderRadioStyled} label {
        border: 1px solid ${application.palette.danger};
      }

      & ${FormCheckboxStyled} label {
        border: 1px solid ${application.palette.danger};
      }
    `}
  `}
`);

export const FormInputStyled = Styled(
  'Form',
  'FormInputStyled'
)(styled(
  forwardRef(({ className, disabled, maxLength, type, error, placeholder, required, name, keyboardIndex, onChange, onBlur, value }, ref) => (
    <FormControlStyled disabled={disabled} error={Boolean(error)}>
      <input
        ref={ref}
        className={className}
        data-keyboard={keyboardIndex}
        data-element="input"
        type={type}
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        placeholder={required ? placeholder + ' *' : placeholder}
        required={required}
      />
      {Boolean(error) && <FormErrorStyled>{error}</FormErrorStyled>}
    </FormControlStyled>
  ))
)`
  cursor: pointer;
  appearance: none;
  width: inherit;
  height: inherit;
  padding: 0 10px;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 400;

  &:focus {
    outline: none;
    border: 1px solid #ebebeb;
  }

  &::placeholder {
    font-size: 16px;
    color: #b0b0b0;
    font-weight: 400;
  }

  &.focused {
    outline: 2px solid rgba(0, 0, 0, 0.3);
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      outline: 2px solid rgba(0, 0, 0, 0.3);
    `}
`);

export const FormDigitalSignatureStyled = Styled(
  'Form',
  'FormDigitalSignatureStyled'
)(styled(
  forwardRef(({ className, placeholder, error, value, onClick, keyboardIndex }, ref) => (
    <div className={className}>
      <div onClick={onClick} data-element="signature" data-keyboard={keyboardIndex} ref={ref}>
        {value ? <img height="100%" src={value} /> : <span>{placeholder}</span>}
      </div>
      {Boolean(error) && <span>{error}</span>}
    </div>
  ))
)`
  & > div {
    cursor: pointer;
    width: 100%;
    height: 126px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > span {
      font-size: 16px;
      color: #b0b0b0;
      font-weight: 400;
      align-self: flex-start;
    }
  }

  & > span {
    display: block;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    margin: 3px 0;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 15px;
  }

  & > .focused {
    outline: 2px solid rgba(0, 0, 0, 0.3);
  }

  ${({ error, $isActive, theme: { application } }) => css`
    & > div {
      background-color: ${application.palette.white};
      ${error &&
      css`
        border: 1px solid ${application.palette.danger};
      `}
      ${$isActive &&
      css`
        outline: 2px solid rgba(0, 0, 0, 0.3);
      `}
    }

    & > span {
      color: ${application.palette.danger};
      ${error &&
      css`
        visibility: visible;
        opacity: 1;
      `}
    }
  `}
`);

export const FormGenderRadioStyled = Styled(
  'Form',
  'FormGenderRadioStyled'
)(styled(({ className, name, value, placeholder, required, rootValue, onChange }) => (
  <div className={className}>
    <input
      type="radio"
      id={name + '-' + value}
      name={name}
      value={value}
      onChange={onChange}
      checked={value === rootValue}
      required={required}
    />
    <label htmlFor={name + '-' + value}>{required ? (placeholder ?? value) + ' *' : placeholder ?? value}</label>
  </div>
))`
  flex-grow: 1;
  height: 100%;

  & label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #b0b0b0;
    background-color: #fff;
    font-weight: 400;
  }

  & input {
    display: none;

    &:checked {
      & + label {
        background-color: #808080;
        color: #fff;
      }
    }
  }

  & + & {
    margin-left: 5px;
  }
`);

export const FormGenderStyled = Styled(
  'Form',
  'FormGenderStyled'
)(styled(({ className, error, children }) => (
  <FormControlStyled error={Boolean(error)}>
    <div className={className}>{children}</div>
    {error && <FormErrorStyled>{error}</FormErrorStyled>}
  </FormControlStyled>
))`
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  ${props =>
    props.spacing &&
    css`
      & ${FormGenderRadioStyled} + ${FormGenderRadioStyled} {
        margin-left: ${props.spacing}px;
      }
    `}
`);

export const FormCheckboxLabelStyled = Styled(
  'Form',
  'FormCheckboxLabelStyled'
)(styled('p')`
  font-size: 14px;
  margin: 0 0 0 10px;
  word-break: keep-all;
  white-space: nowrap;

  ${({ theme: { application } }) => css`
    & a {
      font-weight: bold;
      color: ${application.palette.black};
    }
  `}
`);

export const FormCheckboxStyled = Styled(
  'Form',
  'FormCheckboxStyled'
)(
  styled(({ className, disabled, name, required, onChange, value, placeholder, error }) => (
    <FormControlStyled $checkbox error={error} disabled={disabled}>
      <div className={className}>
        <div>
          <input
            type="checkbox"
            disabled={disabled}
            id={name}
            name={name}
            onChange={onChange}
            required={required}
            checked={Boolean(value)}
          />
          <label htmlFor={name} />
        </div>
        <FormCheckboxLabelStyled dangerouslySetInnerHTML={{ __html: placeholder }} />
      </div>
    </FormControlStyled>
  ))`
    display: flex;
    align-items: center;

    & > div {
      position: relative;

      & label {
        position: relative;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: #ffffff;

        &::after {
          display: none;
          position: absolute;
          content: ' ';
          width: 10px;
          height: 5px;
          top: 26px;
          left: 13px;
          transform: rotate(45deg);
          background-color: #000;
          z-index: 1;
        }

        &::before {
          display: none;
          position: absolute;
          content: ' ';
          width: 25px;
          top: 22px;
          left: 16px;
          height: 5px;
          transform: rotate(-45deg);
          background-color: #000;
          z-index: 1;
        }
      }

      & input {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        width: 0;

        &:checked {
          & + label {
            &::after {
              display: block;
            }

            &::before {
              display: block;
            }
          }
        }
      }
    }
  `
);

export const FormSelectStyled = Styled(
  'Form',
  'FormSelectStyled'
)(
  styled(
    forwardRef(
      (
        {
          isParentControl,
          error,
          className,
          name,
          data,
          quantity,
          value,
          onChange,
          useDefaultValue = true,
          useLowerCaseValue = true,
          keyboardIndex,
          withBorders,
        },
        ref
      ) =>
        !isParentControl ? (
          <FormControlStyled error={Boolean(error)}>
            <SelectComponent
              ref={ref}
              keyboardIndex={keyboardIndex}
              className={className}
              name={name}
              data={data}
              error={Boolean(error)}
              quantity={quantity}
              onChange={value =>
                onChange({
                  target: {
                    name: name,
                    value: value,
                  },
                })
              }
              defaultValue={value}
              noneIndex
              useDefaultValue={useDefaultValue}
              useLowerCaseValue={useLowerCaseValue}
              $isThickness={withBorders}
            />
            {error && <FormErrorStyled>{error}</FormErrorStyled>}
          </FormControlStyled>
        ) : (
          <SelectComponent
            ref={ref}
            keyboardIndex={keyboardIndex}
            className={className}
            name={name}
            data={data}
            quantity={quantity}
            error={error}
            onChange={value =>
              onChange({
                target: {
                  name: name,
                  value: value,
                },
              })
            }
            defaultValue={value}
            noneIndex
            useDefaultValue={useDefaultValue}
              $isThickness={withBorders}
            useLowerCaseValue={useLowerCaseValue}
          />
        )
    )
  )``
);

export const FormDatePickerStyled = Styled(
  'Form',
  'FormDatePickerStyled'
)(
  styled(
    forwardRef(
      (
        {
          isParentControl,
          error,
          variant,
          className,
          name,
          value,
          onChange,
          keyboardIndex,
          settings,
          placeholder,
          required,
          disabled,
        },
        ref
      ) =>
        !isParentControl ? (
          <FormControlStyled error={Boolean(error)}>
            <DatePickerComponent
              ref={ref}
              variant={variant}
              keyboardIndex={keyboardIndex}
              className={className}
              name={name}
              value={value}
              error={Boolean(error)}
              onChange={value =>
                onChange({
                  target: {
                    name: name,
                    value: value,
                  },
                })
              }
              disabled={disabled}
              placeholder={placeholder}
              defaultValue={value}
              settings={settings}
              required={required}
            />
            {error && <FormErrorStyled>{error}</FormErrorStyled>}
          </FormControlStyled>
        ) : (
          <DatePickerComponent
            ref={ref}
            variant={variant}
            keyboardIndex={keyboardIndex}
            className={className}
            value={value}
            name={name}
            error={Boolean(error)}
            onChange={value =>
              onChange({
                target: {
                  name: name,
                  value: value,
                },
              })
            }
            disabled={disabled}
            defaultValue={value}
            placeholder={placeholder}
            settings={settings}
            required={required}
          />
        )
    )
  )``
);

export const FormBirthdayStyled = Styled(
  'Form',
  'FormBirthdayStyled'
)(styled(
  forwardRef((props, ref) => {
    const { withBorders, className, placeholder, value, error, label, onChange, limitYears } = props;

    const [year = '', month = '', date = ''] = value?.split('-'),
      [yearPlaceholder = '-', monthPlaceholder = '-', datePlaceholder = '-'] = placeholder?.split('-');

    const defaultDateData = {
        value: '',
        label: datePlaceholder,
        inactive: true,
      },
      defaultMonthData = { value: '', label: monthPlaceholder, inactive: true },
      defaultYearData = { value: '', label: yearPlaceholder, inactive: true };

    const [dateData, setDateData] = useState([defaultDateData]),
      [monthData, setMonthData] = useState([defaultMonthData]),
      [yearData, setYearData] = useState([defaultYearData]);

    const getAllDaysInMonth = useCallback(
      (month, year) =>
        Array.from({ length: new Date(year, month, 0).getDate() }, (_, i) => new Date(year, month - 1, i + 1)),
      []
    );

    useEffect(() => {
      const DateObject = new Date(),
        customDateData = [defaultDateData],
        customMonthData = [defaultMonthData],
        customYearData = [defaultYearData];

      const allowedDate = month ? getAllDaysInMonth(month, year) : { length: 31 };

      if (date > allowedDate.length) {
        onChange({
          target: {
            name: 'birthday',
            value: [year, month, ''].join('-'),
          },
        });
      }

      for (let i = 1; i <= allowedDate.length; i++) {
        const value = (i < 10 ? '0' + i : i).toString();
        const isSelected = value === date;

        customDateData.push({
          value: value,
          label: value,
          selected: isSelected,
        });
      }

      for (let i = 1; i <= 12; i++) {
        const value = (i < 10 ? '0' + i : i).toString();
        const isSelected = value === month;

        customMonthData.push({
          value: value,
          label: value,
          selected: isSelected,
        });
      }

      for (let i = DateObject.getFullYear(); i > DateObject.getFullYear() - (limitYears ?? 150); i--) {
        const isSelected = i.toString() === year;

        customYearData.push({
          value: i.toString(),
          label: i.toString(),
          selected: isSelected,
        });
      }

      setDateData(customDateData);
      setMonthData(customMonthData);
      setYearData(customYearData);
    }, [value]);

    return (
      <FormControlStyled error={Boolean(error)}>
        <div className={className}>
          <p>{label}</p>
          <FormRowStyled spacing={1}>
            <FormColumnStyled media={{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <FormSelectStyled
                keyboardIndex="3"
                ref={ref}
                value={date}
                data={dateData}
                error={Boolean(error)}
                isParentControl
                withBorders={withBorders}
                onChange={({ target }) => {
                  const { value } = target;
                  onChange({
                    target: {
                      name: 'birthday',
                      value: [year, month, value].join('-'),
                    },
                  });
                }}
                quantity={5}
                useDefaultValue
              />
            </FormColumnStyled>
            <FormColumnStyled media={{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <FormSelectStyled
                keyboardIndex="4"
                ref={ref}
                value={month}
                data={monthData}
                error={Boolean(error)}
                isParentControl
                withBorders={withBorders}
                onChange={({ target }) => {
                  const { value } = target;
                  onChange({
                    target: {
                      name: 'birthday',
                      value: [year, value, date].join('-'),
                    },
                  });
                }}
                quantity={5}
                useDefaultValue
              />
            </FormColumnStyled>
            <FormColumnStyled media={{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <FormSelectStyled
                keyboardIndex="5"
                ref={ref}
                value={year}
                data={yearData}
                error={Boolean(error)}
                isParentControl
                withBorders={withBorders}
                onChange={({ target }) => {
                  const { value } = target;
                  onChange({
                    target: {
                      name: 'birthday',
                      value: [value, month, date].join('-'),
                    },
                  });
                }}
                quantity={5}
                useDefaultValue
              />
            </FormColumnStyled>
          </FormRowStyled>
        </div>
        {error && <FormErrorStyled>{error}</FormErrorStyled>}
      </FormControlStyled>
    );
  })
)`
  & > p {
    font-size: 13px;
    margin: 0 0 3px 0;
  }

  & ${FormSelectStyled} {
    height: 32px;
  }
`);

export const FormPredictionsListStyled = Styled(
  'Form',
  'FormPredictionsListStyled'
)(styled('ul')`
  position: absolute;
  bottom: 10px;
  width: calc(100% - 15px);
  left: 15px;
  list-style-type: none;
  z-index: 1000;
  padding: 0;
  transform: translateY(100%);
  box-shadow: 0 6px 10px 1px rgba(0, 0, 0, 0.2);

  &::after {
    content: ' ';
    background-image: url('images/ig-google.png');
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: contain;
    display: block;
    height: 16px;
    margin: 10px 0;
  }

  & li {
    cursor: pointer;
    width: 100%;
    padding: 5px 10px;

    &:hover {
      background-color: #e5e5e5;
    }
  }

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.white};
  `}
`);

export const FormTextareaStyled = Styled(
  'Form',
  'FormTextareaStyled'
)(styled(
  forwardRef(({ className, type, error, placeholder, required, name, keyboardIndex, onChange, onBlur, value }, ref) => (
    <FormControlStyled className={className} error={Boolean(error)}>
      <textarea
        ref={ref}
        data-keyboard={keyboardIndex}
        data-element="textarea"
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        placeholder={required ? placeholder + ' *' : placeholder}
        required={required}
      />
      {Boolean(error) && <FormErrorStyled>{error}</FormErrorStyled>}
    </FormControlStyled>
  ))
)`
  display: flex;
  flex-grow: 1;

  & textarea {
    cursor: pointer;
    appearance: none;
    width: inherit;
    padding: 10px;
    resize: none;
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: 400;
    flex-grow: 1;

    &:focus {
      outline: none;
      border: 1px solid #ebebeb;
    }

    &::placeholder {
      font-size: 16px;
      color: #b0b0b0;
      font-weight: 400;
    }

    &.focused {
      outline: 2px solid rgba(0, 0, 0, 0.3);
    }

    ${({ $isActive }) =>
      $isActive &&
      css`
        outline: 2px solid rgba(0, 0, 0, 0.3);
      `}
  }

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      height: 150px;
    }
  `}
`);
