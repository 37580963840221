import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';
import { subMonths } from 'date-fns';

export class CustomerDetailsModel {
  Tabs = {
    Recently: 1,
    History: 2,
  };

  isLoading = false;
  isDataLoading = false;

  customerId = '';
  tab = this.Tabs.Recently;

  list = [];
  transactions = [];
  vouchers = [];

  transactionDates = [
    String(subMonths(Date.now(), 3).getTime()),
    String(subMonths(Date.now(), 6).getTime()),
    String(subMonths(Date.now(), 12).getTime()),
  ];
  transactionDateIndex = 2;

  userData = null;

  navigate = () => {};
  location = {};

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isLoading: observable,
      isDataLoading: observable,
      tab: observable,
      list: observable,
      customerId: observable,
      userData: observable,
      vouchers: observable,
      navigate: observable,
      location: observable,
      transactions: observable,
      transactionDates: observable,
      transactionDateIndex: observable,
    });
  }

  register = action((navigate, location) => {
    this.navigate = navigate;
    this.location = location;
  });

  userDetailsMounted = action(() => {
    if (this.userData || this.isDataLoading) return;

    const { store } = this.rootModels;

    const customerId = this.location?.search.slice(1);

    if (!customerId || customerId.includes('CT') || this.userData === false) this.navigate('/');

    store.helperStore.toggleLoader(true);

    this.customerId = customerId;

    this.rootModels.SearchModel.fetchResultDetails(customerId)
      .then(data => {
        if (!data) throw data;
        this.userData = data;
        this.fetchShoppingHistory(customerId);
      })
      .catch(() => {
        this.userData = false;
        this.isDataLoading = false;
      });
  });

  setCurrentTab = action(tab => {
    this.tab = tab;
  });

  setUserData = action(data => {
    this.userData = data;
  });

  setArticleDetails = action(({ gtin, color, size }) => {
    const { store, ArticleModel } = this.rootModels;

    store.helperStore.toggleLoader(true);

    ArticleModel.fetchArticleGroupsByGtin(gtin, {
      error: false,
      colorCode: color,
      size: size,
      skipOnError: false,
      onSuccess: () => {
        store.helperStore.toggleLoader(false);
        ArticleModel.toggleWindow();
      },
      onError: type => {
        store.helperStore.toggleNotification({
          type: 'inverse',
          title: store.localesStore.translate('placeholder.label.notice'),
          description: store.localesStore.translate(
            type === 'unavailable' ? 'modal.article.unavailable.label.description' : 'modal.products.label.error'
          ),
          confirm: store.localesStore.translate('placeholder.label.ok'),
          onConfirm: () => {
            store.helperStore.toggleNotification(null);
          },
        });

        store.helperStore.toggleLoader(false);
      },
    }).catch(error => store.debug && console.error(error));
  });

  fetchShoppingHistory = action(async customerId => {
    const { store } = this.rootModels;
    const { networkStore, helperStore } = store;

    try {
      this.list = [];
      this.isLoading = true;

      const sinceTimestamp = this.transactionDates[this.transactionDateIndex];

      const { status, response } = await networkStore.get(
        `TransactionByCustomerId?customerId=%22${
          customerId ?? this.customerId
        }%22&sinceTimeStamp=%22${sinceTimestamp}%22`
      );

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "TransactionByCustomerId" Failed!');

      const {
        data: { data },
      } = JSON.parse(response);

      this.transactions = data.map(helperStore.mapBackendData).sort((a, b) => b.created - a.created);

      this.list = helperStore.mapShoppingHistory(data).sort((a, b) => b.transactionDate - a.transactionDate);
    } catch (error) {
      if (store.debug) console.error(error);
    } finally {
      this.isLoading = false;
      helperStore.toggleLoader(false);
    }
  });

  handleTransactionDateChange = action(nextValue => {
    this.transactionDateIndex = this.transactionDates.findIndex(value => value === nextValue);
  });

  reset = action(() => {
    this.tab = this.Tabs.Recently;
    this.transactionDateIndex = 2;
    this.list = [];
    this.transactions = [];
  });
}

export default Model('CustomerDetailsModel')(CustomerDetailsModel);
