import swal from 'sweetalert';
import { v4 } from 'uuid';
import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class FormModel {
  Type = {
    Creation: '@creation',
    Editor: '@editor',
    Guest: '@guest',
  };

  isAdditionalAddressToggled = false;

  variant = this.Type.Creation;
  signature = null;
  settings = null;

  predictions = [];
  timer = null;
  attempts = 3;

  AutoCompleteField = null;
  AutoCompleteService = null;
  PlaceService = null;

  requiredFields = ['salutation', 'firstName', 'lastName', 'email', 'country', 'userAgreementAllowed'];

  additionalFields = ['phone', 'addressLine2', 'birthday', 'postalCode'];

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isAdditionalAddressToggled: observable,
      variant: observable,
      settings: observable,
      signature: observable,
      predictions: observable,
      attempts: observable,
      AutoCompleteService: observable,
      AutoCompleteField: observable,
      PlaceService: observable,

      requiredFields: observable,
      additionalFields: observable,
    });
  }

  connectGoogleApi = action(() => {
    let timerId = null;

    if (!this.rootModels.store.googleKey) return () => clearTimeout(timerId);

    if (this.attempts > 0) {
      timerId = setTimeout(() => {
        if (window.isGoogelMapsConnected && window.google && !this.AutoCompleteService && !this.PlaceService) {
          try {
            this.AutoCompleteService = new window.google.maps.places.AutocompleteService();
            this.PlaceService = new window.google.maps.places.PlacesService(document.createElement('div'));
            this.attempts = 0;
          } catch (error) {
            if (this.rootModels.store.debug) console.error(error);
            this.attempts -= 1;
          }
        } else {
          this.attempts -= 1;
        }
      }, 1000);
    }

    return () => clearTimeout(timerId);
  });

  setFormSettings = action((value, settings = {}) => {
    this.variant = value;
    this.settings = settings;

    if (Array.isArray(settings.include)) {
      settings.include.forEach(item => {
        if (!this.requiredFields.includes(item)) {
          this.requiredFields = [...this.requiredFields, item];
        }
      });
      
      this.requiredFields = this.requiredFields.filter(item => !this.settings.exclude?.includes(item))
    }
  });

  getFormDataByVariant = action((isAdditionalAddressEnabled = false) => {
    const {
      variant,
      Type: { Creation, Editor, Guest },
      rootModels: { store },
    } = this;

    const { storeData } = store.storageStore;
    const currentStoreAddress = storeData?.addresses[0];

    const additionalAddressFields = {
      additionalSalutation: '',
      additionalFirstName: '',
      additionalLastName: '',
      additionalPostalCode: '',
      additionalCity: '',
      additionalStreet: '',
      additionalStreetNumber: '',
      additionalAddressLine1: '',
      additionalCountry: currentStoreAddress?.country ?? store.localesStore.currentLocale,
    };

    switch (variant) {
      case Creation: {
        return {
          salutation: '',
          birthday: '',
          firstName: '',
          lastName: '',
          phone: '',
          street: '',
          streetNumber: '',
          city: '',
          addressLine1: '',
          addressLine2: '',
          email: '',
          store: '',
          language: 'DE',
          postalCode: '',
          country: currentStoreAddress?.country ?? store.localesStore.currentLocale,
          isEmailContactAllowed: false,
          isPhoneContactAllowed: false,
          isSMSMMSContactAllowed: false,
          armLength: '',
          cut: '',
          size: '',
          userAgreementAllowed: false,
          ...(isAdditionalAddressEnabled ? additionalAddressFields : {}),
          ...(this.settings?.data ?? {}),
        };
      }

      case Editor: {
        return this.settings.data
          ? {
              ...this.settings.data,
              store: this.settings.data.store || '',
              ...(isAdditionalAddressEnabled ? additionalAddressFields : {}),
            }
          : {
              salutation: '',
              birthday: '',
              firstName: '',
              lastName: '',
              phone: '',
              street: '',
              streetNumber: '',
              city: '',
              store: '',
              language: 'DE',
              addressLine1: '',
              addressLine2: '',
              email: '',
              postalCode: '',
              country: currentStoreAddress?.country ?? store.localesStore.currentLocale,
              isEmailContactAllowed: false,
              isPhoneContactAllowed: false,
              isSMSMMSContactAllowed: false,
              armLength: '',
              cut: '',
              size: '',
              userAgreementAllowed: true,
              ...(isAdditionalAddressEnabled ? additionalAddressFields : {}),
            };
      }

      case Guest: {
        return {
          salutation: '',
          birthday: '',
          firstName: '',
          lastName: '',
          phone: '',
          street: '',
          streetNumber: '',
          city: '',
          addressLine1: '',
          addressLine2: '',
          email: '',
          postalCode: '',
          country: currentStoreAddress?.country ?? store.localesStore.currentLocale,
          userAgreementAllowed: false,
        };
      }
    }
  });

  handleToggleAdditionalAddress = action(handleChange => {
    if (!this.initialFields) this.initialFields = JSON.parse(JSON.stringify(this.requiredFields));

    const fields = [
      'additionalSalutation',
      'additionalPostalCode',
      'additionalFirstName',
      'additionalLastName',
      'additionalCity',
      'additionalStreet',
      'additionalStreetNumber',
      'additionalCountry',
    ];

    if (!this.isAdditionalAddressToggled) {
      this.isAdditionalAddressToggled = true;
      this.requiredFields = [...this.requiredFields, ...fields];
    } else {
      this.isAdditionalAddressToggled = false;
      this.requiredFields = [...this.initialFields];

      fields.forEach(field => {
        handleChange({
          target: { name: field, value: '' },
        });
      });

      this.initialFields = null;
    }
  });

  verifyForm = action(data => {
    const { store } = this.rootModels;
    
    const postalCodeCountries = {
      'de': /^\d{5}$/,
      'fr': /^\d{5}$/,
      'at': /^\d{4}$/,
      'be': /^\d{4}$/,
      'lu': /^\d{4}$/,
      'ch': /^\d{4}$/,
      'nl': /^\d{4}(?: [A-Za-z]{2})?$/,
    }

    const validateEmail = email => {
        const emailValidationRegexp = /^([a-zA-Z0-9_!#$%&’*+/=?`\{|}~^.-]){1,64}@([a-zA-Z0-9.-]){1,177}$/i;
        return emailValidationRegexp.test(email?.toLowerCase());
      },
      validatePhone = phone => {
        const phoneValidationRegexp =
          /[\+|0]+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/i;

        return phoneValidationRegexp.test(phone?.toLowerCase()?.replace(/\s/g, ''));
      };

    const requiredFields = this.requiredFields.filter(item => !this.settings.exclude?.includes(item));

    const additionalFields = this.additionalFields.filter(item => !this.settings.exclude?.includes(item));
    
    const requiredFieldErrors = requiredFields.reduce((acc, next) => {
        if (next === 'userAgreementAllowed' && !data[next]) {
          acc[next] = !data[next];
        } else if ((next === 'postalCode') && ((Object.keys(postalCodeCountries).includes(data['country']) && !postalCodeCountries[data['country']].test(data[next])) || data[next].trim() === '')) {
          acc[next] = store.localesStore.translate('customer.form.label.error.' + next);
        } else if ((next === 'additionalPostalCode') && ((Object.keys(postalCodeCountries).includes(data['additionalCountry']) && !postalCodeCountries[data['additionalCountry']].test(data[next])) || data[next].trim() === '')) {
          acc[next] = store.localesStore.translate('customer.form.label.error.' + next);
        } else if (next === 'email' && data[next].trim() !== '' && !validateEmail(data[next])) {
          acc[next] = store.localesStore.translate('customer.form.label.error.' + next);
        } else if (typeof data[next] === 'string' && data[next].trim() === '') {
          acc[next] = store.localesStore.translate('customer.form.label.error.' + next);
        }

        return acc;
      }, {}),
      additionalFieldErrors = additionalFields.reduce((acc, next) => {
        if (
          next === 'birthday' &&
          data[next] !== '' &&
          data[next] !== '--' &&
          !data[next].match(/^\d{4}-\d{2}-\d{2}$/)
        ) {
          acc[next] = store.localesStore.translate('customer.form.label.error.' + next);
        } else if (
          (next === 'phone' || next === 'addressLine2') &&
          data[next].trim() !== '' &&
          !validatePhone(data[next])
        ) {
          acc[next] = store.localesStore.translate('customer.form.label.error.' + next);
        } else if (data[next].length > 0 && (next === 'postalCode') && ((Object.keys(postalCodeCountries).includes(data['country']) && !postalCodeCountries[data['country']].test(data[next])) || data[next].trim() === '')) {
          acc[next] = store.localesStore.translate('customer.form.label.error.' + next);
        } else if ((next === 'additionalPostalCode') && ((Object.keys(postalCodeCountries).includes(data['additionalCountry']) && !postalCodeCountries[data['additionalCountry']].test(data[next])) || data[next].trim() === '')) {
          acc[next] = store.localesStore.translate('customer.form.label.error.' + next);
        }  else if (next === 'email' && data[next].trim() !== '' && !validateEmail(data[next])) {
          acc[next] = store.localesStore.translate('customer.form.label.error.' + next);
        }

        return acc;
      }, {});

    const validationObject = Object.assign({}, requiredFieldErrors, additionalFieldErrors);
    const validationKeys = Object.keys(validationObject).reduce((acc, key) => {
      if (key.includes('additional') && !acc.includes('additional')) {
        acc.push('additional');
      } else if (!key.includes('additional')) {
        acc.push(key);
      }

      return acc;
    }, []);

    if (validationKeys.length > 0) {
      swal({
        title: store.localesStore.translate('placeholder.label.notice'),
        text: validationKeys
          .map(key => '- ' + store.localesStore.translate(`modal.customer.error.label.${key}`))
          .join('\n\n'),
        buttons: {
          apply: store.localesStore.translate('placeholder.label.ok'),
        },
      });
    }

    return validationObject;
  });

  uploadSignature = action(async (blob, fileName) => {
    const {
      store: { networkStore },
    } = this.rootModels;

    const id = v4(),
      file = new File([blob], fileName + '.png', { type: 'image/png' });

    const { $id, bucketId } = await networkStore.post('CreateSignature', {
      id,
      file,
    });

    if (!$id) throw Error('Network Store: Request "CreateSignature" Failed!');

    return { $id, bucketId };
  });

  createCustomer = action(async (data, callback) => {
    const {
      store: { debug, networkStore, storageStore, helperStore, localesStore },
    } = this.rootModels;

    try {
      helperStore.toggleLoader(true);

      const birthday = data.birthday && data.birthday !== '--' ? new Date(data.birthday).getTime() : null;

      const additionalProperties = {};

      if (data.cut) additionalProperties['cut'] = data.cut;
      if (data.size) additionalProperties['size'] = data.size;
      if (data.armLength) additionalProperties['armLength'] = data.armLength;

      const { status, response } = await networkStore.post('Customer', {
        birthday: birthday,
        lastName: data.lastName,
        addresses: [
          {
            birthday: birthday,
            country: data.country,
            lastName: data.lastName,
            streetNumber: data.streetNumber,
            city: data.city,
            addressType: 'MainAddress',
            postalCode: data.postalCode,
            title: null,
            firstName: data.firstName,
            phone: data.phone.replace(/\s/g, ''),
            street: data.street,
            customerId: null,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2.replace(/\s/g, ''),
            salutation: data.salutation,
            email: data.email,
          },
        ],
        preferredLanguage: data.language,
        favouriteStoreId: data.store,
        title: null,
        initialStoreId: storageStore.storeId,
        recruitedBy: storageStore.merchant.id,
        customerId: null,
        isPhoneContactAllowed: Boolean(data.isPhoneContactAllowed),
        isEmailContactAllowed: Boolean(data.isEmailContactAllowed),
        isSMSMMSContactAllowed: Boolean(data.isSMSMMSContactAllowed),
        isPostalMailContactAllowed: Boolean(data.isPostalMailContactAllowed),
        email: data.email,
        firstName: data.firstName,
        mobilePhone: data.addressLine2.replace(/\s/g, ''),
        phone: data.phone.replace(/\s/g, ''),
        salutation: data.salutation,
        additionalProperties: additionalProperties,
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      ) {
        const message = JSON.parse(response || '{}')?.data?.message;
        throw message
          ? { message: localesStore.translate(message) || message }
          : new Error('Network Store: Request "Customer" failed!');
      }

      const { data: responseData } = JSON.parse(response);

      helperStore.toggleLoader(false);

      helperStore.toggleNotification({
        type: 'creation',
        title: localesStore.translate('modal.customer.creation.title'),
        description: localesStore
          .translate('modal.customer.creation.description')
          .replace('{{customerNumber}}', responseData.customerId),
        confirm: localesStore.translate('placeholder.label.ok'),
        onConfirm: () => {
          if (typeof callback === 'function') callback();
          
          if (typeof this.settings.onResolve === 'function') this.settings.onResolve(responseData.customerId, data, responseData);
          helperStore.toggleNotification(null);
        },
      });
    } catch (error) {
      if (debug) console.error(error);

      if (typeof this.settings.onReject === 'function') return this.settings.onReject(data);

      helperStore.toggleLoader(false);

      if (typeof error === 'object') {
        swal({
          title: localesStore.translate('placeholder.label.notice'),
          text: typeof error === 'object' ? error.message : localesStore.translate('customer.form.label.error'),
          buttons: {
            apply: localesStore.translate('placeholder.label.ok'),
          },
        });
      } else {
        swal('', localesStore.translate('customer.form.label.error'), 'error');
      }
    }
  });

  updateCustomer = action(async (data, callback) => {
    const {
      store: { debug, networkStore, helperStore, localesStore },
    } = this.rootModels;

    if (!Object.entries(this.settings.data).some(([key, value]) => data[key] !== value)) {
      this.settings.onResolve(this.settings.prevData.id, this.settings.data);
      return this.settings.data;
    } else {
      if(this.settings.complaints) {
        this.settings.onResolve(this.settings.prevData.id, data);
        return data;
      }
      
      try {
        helperStore.toggleLoader(true);

        const additionalProperties = {};

        if (data.cut) additionalProperties['cut'] = data.cut;
        if (data.size) additionalProperties['size'] = data.size;
        if (data.armLength) additionalProperties['armLength'] = data.armLength;

        const birthday = data.birthday && data.birthday !== '--' ? new Date(data.birthday).getTime() : null;

        const sendData = {
          customerId: this.settings.prevData.id,
          customer: {
            birthday: birthday,
            lastName: data.lastName,
            addresses: [
              {
                birthday: birthday,
                country: data.country,
                lastName: data.lastName,
                streetNumber: data.streetNumber,
                city: data.city,
                addressType: 'MainAddress',
                postalCode: data.postalCode,
                title: null,
                firstName: data.firstName,
                phone: data.phone.replace(/\s/g, ''),
                street: data.street,
                customerId: this.settings.prevData.id,
                addressLine1: data.addressLine1,
                addressLine2: data.addressLine2.replace(/\s/g, ''),
                salutation: data.salutation,
                email: data.email,
              },
            ],
            preferredLanguage: data.language ?? this.settings.prevData.preferredLanguage,
            favouriteStoreId: data.store ?? this.settings.prevData.favouriteStoreId,
            initialStoreId: this.settings.prevData.initialStoreId,
            // 'recruitedBy': null,
            title: null,
            customerId: this.settings.prevData.id,
            isPhoneContactAllowed: Boolean(data.isPhoneContactAllowed),
            isEmailContactAllowed: Boolean(data.isEmailContactAllowed),
            isSMSMMSContactAllowed: Boolean(data.isSMSMMSContactAllowed),
            isPostalMailContactAllowed: Boolean(data.isPostalMailContactAllowed),
            email: data.email,
            firstName: data.firstName,
            mobilePhone: data.addressLine2.replace(/\s/g, ''),
            phone: data.phone.replace(/\s/g, ''),
            salutation: data.salutation,
            additionalProperties: additionalProperties,
          },
        };

        const { status, response } = await networkStore.put('Customer', sendData);

        if (
          status !== 'completed' ||
          (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
        ) {
          const message = JSON.parse(response || '{}')?.data?.message;
          throw message
            ? { message: localesStore.translate(message) || message }
            : new Error('Network Store: Request "Customer" failed!');
        }
        const { data: responseData } = JSON.parse(response);

        if (typeof callback === 'function') callback();

        this.settings.onResolve(this.settings.prevData.id, data, responseData);

        return responseData;
      } catch (error) {
        if (debug) console.error(error);

        if (typeof error === 'object') {
          swal({
            title: localesStore.translate('placeholder.label.notice'),
            text: typeof error === 'object' ? error.message : localesStore.translate('customer.form.label.error'),
            buttons: {
              apply: localesStore.translate('placeholder.label.ok'),
            },
          });
        } else {
          swal('', localesStore.translate('customer.form.label.save'), 'error');
        }

        return null;
      } finally {
        helperStore.toggleLoader(false);
      }
    }
  });

  handleSignatureCall = action((onChange, data) => {
    const {
      store: { debug },
      CustomerSignatureModel,
    } = this.rootModels;

    CustomerSignatureModel.signatureModalCall(data)
      .then(data => {
        this.signature = data.blob;

        onChange({
          target: {
            name: 'signature',
            value: data.url,
          },
        });
      })
      .catch(error => debug && console.error(error));
  });

  handleGoogleInput = action((e, values, handleChange) => {
    handleChange(e);

    if (this.timer) clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (!this.AutoCompleteService) return;

      const { name: fieldName, value: fieldValue } = e.target;

      this.AutoCompleteField = fieldName;

      let settings = null;

      switch (fieldName) {
        case 'street': {
          settings = {
            input: [values['postalCode'], values['city'], fieldValue].join(' '),
            types: ['address'],
            componentRestrictions: { country: [values['country']] },
          };

          break;
        }

        case 'additionalStreet': {
          settings = {
            input: [values['additionalPostalCode'], values['additionalCity'], fieldValue].join(' '),
            types: ['address'],
            componentRestrictions: { country: [values['additionalCountry']] },
          };

          break;
        }

        case 'postalCode': {
          settings = {
            input: [values['street'], fieldValue].join(' '),
            types: ['(regions)'],
            componentRestrictions: { country: [values['country']] },
          };

          break;
        }

        case 'additionalPostalCode': {
          settings = {
            input: [values['additionalCity'], fieldValue].join(' '),
            types: ['(regions)'],
            componentRestrictions: { country: [values['additionalCountry']] },
          };

          break;
        }

        case 'city': {
          settings = {
            input: fieldValue,
            types: ['(cities)'],
            componentRestrictions: { country: [values['country']] },
          };
          break;
        }

        case 'additionalCity': {
          settings = {
            input: fieldValue,
            types: ['(cities)'],
            componentRestrictions: { country: [values['additionalCountry']] },
          };
        }
      }

      if (!settings) return;

      this.AutoCompleteService.getPlacePredictions(settings, predictions => {
        if (!document.activeElement?.name && this.AutoCompleteField !== document.activeElement?.name) return;
        this.predictions = predictions || [];
      });
    }, 300);
  });

  handleGoogleChoose = action((id, handleChange, isAdditionalFields = false) => {
    if (!this.PlaceService) return;

    this.PlaceService.getDetails(
      {
        placeId: id,
      },
      response => {
        const { address_components } = response;

        address_components.forEach(component => {
          if (component.types.includes('locality')) {
            handleChange({
              target: {
                name: isAdditionalFields ? 'additionalCity' : 'city',
                value: component.long_name,
              },
            });
          } else if (component.types.includes('postal_code')) {
            handleChange({
              target: {
                name: isAdditionalFields ? 'additionalPostalCode' : 'postalCode',
                value: component.long_name,
              },
            });
          } else if (component.types.includes('route')) {
            handleChange({
              target: {
                name: isAdditionalFields ? 'additionalStreet' : 'street',
                value: component.long_name,
              },
            });
          }
        });
      }
    );

    this.handleGoogleBlur();
  });

  handleGoogleBlur = action(() => {
    this.predictions = [];
    this.AutoCompleteField = null;
  });

  handleSubmitCall = action((data, callback) => {
    const {
      variant,
      Type: { Creation, Editor, Guest },
    } = this;

    switch (variant) {
      case Creation: {
        return this.createCustomer(data, callback);
      }
      case Editor: {
        return this.updateCustomer(data, callback);
      }
      case Guest: {
        if (typeof callback === 'function') callback();
        return this.settings.onResolve(null, data);
      }
    }
  });
  
  mountForm = action(async() => {
    try {
      const { networkStore } = this.rootModels.store;
      
      const { response: stores } = await networkStore.post('LoadObjectsOfClass', {
          whereClause: '',
          offset: 0,
          orderByName: 'created',
          orderByType: 'DESC',
          pageSize: 200,
          clazz: 'com.innomos.baas.common.model.Store',
       })
      
      const storeList = JSON.parse(stores).data?.data ?? [];
      
      const sortedStores = storeList.filter(({ name }) => !((name || '').toLowerCase().includes('outlet'))).sort((a, b) => a.name.localeCompare(b.name));
      const outletStores = storeList.filter(({ name }) => ((name || '').toLowerCase().includes('outlet'))).sort((a, b) => a.name.localeCompare(b.name));
      
      this.rootModels.store.stores = [...sortedStores, ...outletStores];
    } catch (error) {
      console.error(error);
    }
  })

  keyboardInterfaceMounted = action(fields => {
    let timer = null,
      combination = '',
      chosenField = null,
      chosenOption = null;

    const isInput = target => target instanceof HTMLInputElement;

    const handleTabKey = () => {
      if (chosenField) chosenField.classList.remove('focused');

      const fieldWithFocusIndex = document.activeElement.dataset.keyboard ?? chosenField?.dataset.keyboard;
      const fieldToFocusIndex = fieldWithFocusIndex ? (parseInt(fieldWithFocusIndex) + 1).toString() : '1';

      const fieldWithFocus = fields[fieldWithFocusIndex],
        fieldToFocus = fields[fieldToFocusIndex];

      if (fieldToFocus) {
        const fieldWithFocusType = fieldWithFocus?.dataset.element,
          fieldToFocusType = fieldToFocus.dataset.element;

        if (chosenOption) chosenOption.click();

        switch (fieldWithFocusType) {
          case 'input': {
            fieldWithFocus.blur();
            break;
          }
          case 'select': {
            fieldWithFocus.dispatchEvent(new Event('unfocused'));
            break;
          }
        }

        fieldToFocusType === 'input' ? fieldToFocus.focus() : fieldToFocus.click();

        chosenField = fieldToFocus;
      } else {
        chosenField = fields[combination];
      }
    };

    const handleEnterKey = () => {
      if (!chosenField) return;

      chosenField.classList.remove('focused');

      switch (chosenField.dataset.element) {
        case 'input': {
          chosenField.focus();
          break;
        }
        case 'select': {
          if (chosenField.dataset.toggled === 'false') {
            chosenField.click();
          } else {
            if (chosenOption) {
              chosenOption.click();
            }
          }
          break;
        }
      }

      chosenOption = null;
      combination = '';
    };

    const handleEscapeKey = e => {
      if (!chosenField || e.type === 'mousedown') return;

      chosenField.classList.remove('focused');

      switch (chosenField.dataset.element) {
        case 'input': {
          chosenField.blur();
          break;
        }
        case 'select': {
          if (!chosenOption) chosenField.dispatchEvent(new Event('unfocused'));
          break;
        }
      }

      chosenField = null;
      chosenOption = null;
      combination = '';
    };

    const handleKeyboardEvent = e => {
      clearTimeout(timer);

      const keyboardKey = e.key?.toLowerCase();

      if (['tab'].includes(keyboardKey)) {
        e.preventDefault();
      } else {
        combination += e.key;
      }

      timer = setTimeout(() => {
        switch (keyboardKey) {
          case 'enter':
            return handleEnterKey();
          case 'tab':
            return handleTabKey();
          case 'escape':
            return handleEscapeKey();
          default: {
            if (isInput(e.target)) return (combination = '');

            if (chosenField) {
              chosenField.classList.remove('focused');

              if (chosenField.dataset.element === 'select' && chosenField.dataset.toggled === 'true') {
                const list = Array.from(chosenField.nextElementSibling.querySelectorAll('li'));
                const index = parseInt(combination);
                chosenOption =
                  chosenField.nextElementSibling.querySelector(
                    `li[value="${isNaN(index) ? index : index >= 10 ? index : '0' + index}"]`
                  ) || list[index + 1];

                if (chosenOption) {
                  list.forEach(option => option.classList.remove('focused'));
                  chosenOption.classList.add('focused');
                  chosenOption.scrollIntoView();
                }

                return (combination = '');
              }
            }

            chosenField = fields[combination];

            if (chosenField) {
              e.preventDefault();
              chosenField.classList.add('focused');
            }

            combination = '';
          }
        }
      }, 300);
    };

    const handleMouseEvent = e => {
      const target = e.target?.closest('[data-keyboard]') || e.target?.querySelector('[data-keyboard]');
      const option = e.target?.hasAttribute('value') && e.target;

      if (option) chosenOption = e.target;
      
      handleEscapeKey(e);

      if (target) chosenField = target;
    };

    document.addEventListener('keydown', handleKeyboardEvent);
    document.addEventListener('mousedown', handleMouseEvent);
    document.addEventListener('cleanup', handleEscapeKey);

    return () => {
      combination = '';
      chosenField = null;
      chosenOption = null;
      if (timer) clearTimeout(timer);
      document.removeEventListener('keydown', handleKeyboardEvent);
      document.removeEventListener('mousedown', handleMouseEvent);
      document.removeEventListener('cleanup', handleEscapeKey);
    };
  });

  reset = action(() => {
    this.isAdditionalAddressToggled = false;
    this.variant = this.Type.Creation;
    this.requiredFields = ['salutation', 'firstName', 'lastName', 'email', 'country', 'userAgreementAllowed'];
    this.settings = null;
  });
}

export default Model('FormModel')(FormModel);
