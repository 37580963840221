import { memo } from 'react';
import { Box } from '@mui/material';

import Icon from '@framework/decorators/Icon';

const Loader = memo(({ $relative, $inherited, $disableMargin }) => (
  <Box
    position={$relative ? 'relative' : 'absolute'}
    mt={$relative && !$disableMargin ? '20px' : 'unset'}
    width="100%"
    height="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <img width={$inherited ? '100%' : '35px'} height={$inherited ? '100%' : '35px'} src="images/ig-loader.gif" alt="" />
  </Box>
));

export default Icon('Loader')(Loader);
