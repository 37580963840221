import { observer, inject } from 'mobx-react';
import { Box, Dialog } from '@mui/material';
import { useEffect, useState } from 'react';

import Modal from '@framework/decorators/Modal';

import { ButtonStyled, ModalAnimation } from '@framework/styles/App';
import { DialogWrapperStyled, InactivityStyled } from '@framework/styles/Loader';

const InactivityModal = Modal('InactivityModal')(props => {
  const {
    store: { isAuthorized, localesStore, helperStore, isKioskMode },
    models: { AuthModel },
  } = props;

  const [time, setTime] = useState(10);

  useEffect(() => {
    if (!isAuthorized || (!helperStore.isInactivity && !helperStore.isInactivityVideo) || isKioskMode) return () => clearTimeout(timer);
  
    const timer = setTimeout(() => {
      if (time > 0) {
        setTime(prevTime => prevTime - 1);
      } else {
        setTime(10);
        
        AuthModel.logout();
        
        if (!isKioskMode) AuthModel.handleEnterKioskMode();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [time, helperStore.isInactivity, isAuthorized]);

  const handleContinueClick = () => {
    helperStore.toggleInactivity(false);
    AuthModel.updateActivity();
    setTime(10);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={helperStore.isInactivity}
      onClose={handleContinueClick}
      TransitionComponent={ModalAnimation}
      sx={{ zIndex: '100000051 !important' }}
    >
      <DialogWrapperStyled flexDirection="column" alignItems="center" p="20px">
        <Box
          sx={{
            fontSize: '26px',
            padding: '10px 0 20px',
            textAlign: 'center',
          }}
        >
          {localesStore.translate('modal.inactivity.label.title')}
        </Box>

        <Box position="relative" m="30px 0">
          <InactivityStyled />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '36px',
            }}
          >
            {time}
          </Box>
        </Box>

        <Box
          sx={{
            fontSize: '18px',
            whiteSpace: 'pre-line',
            textAlign: 'center',
            padding: '10px 20px 30px',
          }}
        >
          {localesStore.translate('modal.inactivity.label.description')}
        </Box>
        <Box sx={{ width: '100%', height: '50px' }}>
          <ButtonStyled $fullWidth onClick={handleContinueClick}>
            {localesStore.translate('modal.inactivity.controls.continue')}
          </ButtonStyled>
        </Box>
      </DialogWrapperStyled>
    </Dialog>
  );
});

export default inject('store', 'models')(observer(InactivityModal));
