import { memo } from 'react';
import { Box } from '@mui/material';

import Modal from '@framework/decorators/Modal';

import { DialogBodyStyled, DialogStyled, DialogWrapperStyled, LoadingStyled } from '@framework/styles/Loader';

const LoaderModal = memo(({ $isShowed = true, children }) => (
  <DialogStyled open={$isShowed} $isWithContent={Boolean(children)} sx={{ zIndex: '1000000000 !important' }}>
    <DialogBodyStyled>
      <DialogWrapperStyled>
        <LoadingStyled />
        {children && <Box ml="50px">{children}</Box>}
      </DialogWrapperStyled>
    </DialogBodyStyled>
  </DialogStyled>
));

export default Modal('LoaderModal')(LoaderModal);
