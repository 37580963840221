import { isReactComponent } from './helpers';

const Icon = (iconModel, target = 'default', debug = false) => {
  let importedModel = null;

  try {
    importedModel = require(`@tenant/icons/${iconModel}`)[target];
  } catch (error) {
    if (debug) console.error(error);
  }

  return rootModel => (isReactComponent(importedModel) ? importedModel : rootModel);
};

export default Icon;
