import { styled, css } from '@mui/material';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';

import { fadeIn, fadeOut, slideIn, slideOut } from '@framework/styles/Animation';

import Styled from '@framework/decorators/Styled';

export const MenuLinkStyled = Styled(
  'Navbar',
  'MenuLinkStyled'
)(styled(({ className, to, children, onClick }) => (
  <li>
    {children.length === 2 ? (
      <Fragment>
        <Link className={className} to={to} onClick={onClick}>
          {children[0]}
        </Link>
        {children[1]}
      </Fragment>
    ) : (
      <Link className={className} to={to} onClick={onClick}>
        {children}
      </Link>
    )}
  </li>
))`
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  font-size: 22px;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
  `}
`);

export const MenuListStyled = Styled(
  'Navbar',
  'MenuListStyled'
)(styled('ul')`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  padding-left: 25px;
  list-style-type: none;

  & li + li {
    margin-top: 15px;
  }

  & & ${MenuLinkStyled} {
    font-size: 16px;
  }
`);

export const MenuBackdropStyled = Styled(
  'Navbar',
  'MenuBackdropStyled'
)(styled('div')`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
`);

export const MenuContainerStyled = Styled(
  'Navbar',
  'MenuContainerStyled'
)(styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: 100%;
  padding-top: 20px;
  opacity: 0;
  visibility: hidden;

  & > ${MenuListStyled} {
    height: calc(100% - 120px);
    overflow-y: auto;
  }

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.whitesmoke};
  `}
`);

export const MenuStyled = Styled(
  'Navbar',
  'MenuStyled'
)(styled('div')`
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 80px;
  width: 100%;
  height: 100%;
  transition: transform linear 50ms;
  z-index: 9;

  ${props =>
    props.$isToggled
      ? css`
          transform: translateX(0%);

          & ${MenuContainerStyled} {
            animation: ${slideIn} linear 200ms forwards;
          }

          & ${MenuBackdropStyled} {
            animation: ${fadeIn} linear 200ms forwards;
          }
        `
      : css`
          transform: translateX(-100%);
          transition-delay: 200ms;

          & ${MenuContainerStyled} {
            animation: ${slideOut} linear 200ms forwards;
          }

          & ${MenuBackdropStyled} {
            animation: ${fadeOut} linear 200ms forwards;
          }
        `}
`);

export const MenuToolbarStyled = Styled(
  'Navbar',
  'MenuToolbarStyled'
)(styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 0;
  height: 90px;

  ${({ theme: { application } }) => css`
    border-top: 1px solid ${application.palette.grayline};

    & > svg {
      cursor: pointer;
      color: ${application.palette.black};
    }
  `}
`);

export const MenuToolbarContentStyled = Styled(
  'Navbar',
  'MenuToolbarContentStyled'
)(styled('div')`
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);

  & h2 {
    width: 100%;
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${({ theme: { application } }) => css`
    & span {
      font-size: 18px;
      color: ${application.palette.black};
    }

    & h2 {
      color: ${application.palette.black};
    }
  `}
`);
