import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class CustomerProductsModel {
  isToggled = false;

  active = null;
  activeIndex = 0;

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
      active: observable,
      activeIndex: observable,
    });
  }

  setProduct = action(value => {
    const { CustomerDetailsModel } = this.rootModels;

    this.activeIndex = CustomerDetailsModel.list.findIndex(item => item.objectId === value.objectId);
    this.active = value;
    this.isToggled = true;
  });

  handleProductDetails = action(() => {
    const { CustomerDetailsModel } = this.rootModels;

    if (this.active) CustomerDetailsModel.setArticleDetails(this.active);
  });

  handleCloseCall = action(() => {
    this.reset();
  });

  handlePrevCall = action(e => {
    e.preventDefault();

    const { CustomerDetailsModel } = this.rootModels;

    const nextProduct = CustomerDetailsModel.list[this.activeIndex - 1];
    this.setProduct(nextProduct);
  });

  handleNextCall = action(e => {
    e.preventDefault();

    const { CustomerDetailsModel } = this.rootModels;

    const nextProduct = CustomerDetailsModel.list[this.activeIndex + 1];
    this.setProduct(nextProduct);
  });

  handleRtlSwipe = action(() => {
    const { CustomerDetailsModel } = this.rootModels;

    if (this.activeIndex === 0) {
      const lastProductIndex = CustomerDetailsModel.list.length - 1;
      return this.setProduct(CustomerDetailsModel.list[lastProductIndex]);
    }

    const previousProduct = CustomerDetailsModel.list[this.activeIndex - 1];
    return this.setProduct(previousProduct);
  });

  handleLtrSwipe = action(() => {
    const { CustomerDetailsModel } = this.rootModels;

    if (this.activeIndex === CustomerDetailsModel.list.length - 1) {
      const firstProductIndex = 0;
      return this.setProduct(CustomerDetailsModel.list[firstProductIndex]);
    }

    const nextProduct = CustomerDetailsModel.list[this.activeIndex + 1];
    return this.setProduct(nextProduct);
  });

  reset = action(() => {
    this.active = null;
    this.activeIndex = 0;
    this.isToggled = false;
  });
}

export default Model('CustomerProductsModel')(CustomerProductsModel);
