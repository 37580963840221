import { subDays } from 'date-fns';
import { action, makeObservable, observable, computed } from 'mobx';

import Model from '@framework/decorators/Model';

export class OrdersModel {
  Type = {
    Wholesale: 'Wholesale',
    ClickAndReserve: 'ClickAndReserve',
    ClickAndCollect: 'ClickAndCollect',
    Reservation: 'Reservation',
    InStore: 'InStore',
  };

  Status = {
    Created: 'CREATED',
    Creation: 'Creation',
    Parked: 'Parked',
    Requested: 'Requested',
    Sent: 'Sent',
    Declined: 'Declined',
    Received: 'Received',
    Overdue: 'OVERDUE',
    Delivered: 'Delivered',
    Revoked: 'Revoked',
    Expired: 'Expired',
    Canceled: 'Canceled',
    Deleted: 'Deleted',
    Rejected: 'Rejected'
  };

  Payment = {
    None: 'None',
    Alternative: 'Alternative',
  };

  Tabs = {
    InCreation: 1,
    WaitingForDelivery: 2,
    WaitingForCustomer: 3,
    Finished: 4,
  };

  Filter = {
    All: 0,
    LastTwoDays: 1,
    LastFiveDays: 2,
    LastTenDays: 3,
    LastMonth: 4,
  };

  isLoading = false;
  isFilterToggled = false;
  isDataLoaded = false;
  isFinishedLoaded = false;

  currentTab = 1;
  currentFilter = 0;

  data = [];
  finished = [];
  filters = [0, 2, 5, 10, 30];

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isLoading: observable,
      isFilterToggled: observable,
      isDataLoaded: observable,
      isFinishedLoaded: observable,

      currentTab: observable,
      currentFilter: observable,

      data: observable,
      finished: observable,

      ordersByInCreation: computed,
      ordersByWaitingForDelivery: computed,
      ordersByWaitingForCustomers: computed,
      ordersByFinished: computed,
      ordersByStatus: computed,
    });
  }

  viewMounted = action(() => {
    const {
      isLoading,
      isDataLoaded,
      isFinishedLoaded,
      currentTab,
      Tabs: { Finished },
      fetchOrders,
      fetchFinishedOrders,
      rootModels: { store },
    } = this;

    if (!isLoading && !isDataLoaded && currentTab !== Finished)
      fetchOrders().catch(error => store.debug && console.error(error));

    if (!isLoading && !isFinishedLoaded && currentTab === Finished)
      fetchFinishedOrders().catch(error => store.debug && console.error(error));
  });

  handleTabCall = action(tab => {
    this.currentTab = tab;
  });

  handleFilterCall = action(filter => {
    this.currentFilter = filter;
    this.isFilterToggled = false;
  });

  handleFilterToggle = action(() => {
    this.isFilterToggled = !this.isFilterToggled;
  });

  handleRefreshCall = action(() => {
    const {
      currentTab,
      fetchOrders,
      fetchFinishedOrders,
      Tabs: { Finished },
    } = this;

    switch (currentTab) {
      case Finished:
        return fetchFinishedOrders();
      default:
        return fetchOrders();
    }
  });

  updateOrders = action(order => {
    this.data = this.data.map(item => (order.objectId === item.objectId ? order : item));
  });

  fetchOrders = action(async () => {
    this.isLoading = true;

    const { store } = this.rootModels;
    const { storageStore, networkStore, helperStore } = store;

    const isViewModel = document.location.pathname.includes('orders');

    if (isViewModel) helperStore.toggleLoader(true);

    try {
      const { storeId } = storageStore;
      const whereClause = `(targetStore.storeId = '${storeId}' OR sourceStore.storeId = '${storeId}' OR targetStoreId = '${storeId}' OR sourceStoreId = '${storeId}') AND (status = 'Parked' OR status = 'Creation' OR status = 'CREATED' OR status = 'Received' OR status = 'OVERDUE' OR (type <> 'InStore' AND (status = 'Requested' OR status = 'Sent')))`;

      const { response, status } = await networkStore.post('LoadObjectsOfClass', {
        whereClause,
        offset: 0,
        orderByName: 'created',
        orderByType: 'DESC',
        pageSize: 200,
        relations: [
          'articleItems.logs',
          'customer.addresses',
          'deliveryAddress',
          'invoiceAddress',
          'customerDigitalInfo',
          'orderDocuments',
        ],
        clazz: 'com.innomos.baas.common.model.OmniChannelProcess',
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "LoadObjectsOfClass" Failed!');

      const {
        data: { data },
      } = JSON.parse(response);

      this.data = data.map(helperStore.mapOrdersData);
      this.isDataLoaded = true;
      if (isViewModel) helperStore.toggleLoader(false);
    } catch (error) {
      if (store.debug) console.error(error);
      if (isViewModel) helperStore.toggleLoader(false);
    } finally {
      this.isLoading = false;
    }
  });

  fetchOrderByParcelId = action(async parcelId => {
    this.isLoading = true;

    const { store, OrdersProcessingModel } = this.rootModels;
    const { storageStore, networkStore, helperStore } = store;

    const isViewModel = document.location.pathname.includes('orders');

    if (isViewModel) helperStore.toggleLoader(true);

    try {
      const { storeId } = storageStore;
      const whereClause = `(targetStore.storeId = '${storeId}' OR sourceStore.storeId = '${storeId}' OR targetStoreId = '${storeId}' OR sourceStoreId = '${storeId}') AND (shippingType = '${parcelId}' OR trackingUrl = '${parcelId}')`;

      const { response, status } = await networkStore.post('LoadObjectsOfClass', {
        whereClause,
        offset: 0,
        orderByName: 'created',
        orderByType: 'DESC',
        pageSize: 200,
        relations: [
          'articleItems.logs',
          'customer.addresses',
          'deliveryAddress',
          'invoiceAddress',
          'customerDigitalInfo',
          'orderDocuments',
        ],
        clazz: 'com.innomos.baas.common.model.OmniChannelProcess',
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "LoadObjectsOfClass" Failed!');

      const {
        data: { data },
      } = JSON.parse(response);

      const order = data.map(helperStore.mapOrdersData)[0];

      if (!order) throw Error('Order not found!');

      this.isDataLoaded = true;
      if (isViewModel) helperStore.toggleLoader(false);

      OrdersProcessingModel.setOrder(order);
    } catch (error) {
      if (store.debug) console.error(error);
      if (isViewModel) helperStore.toggleLoader(false);
      
      return true;
    } finally {
      this.isLoading = false;
    }
  });

  fetchFinishedOrders = action(async () => {
    this.isLoading = true;

    const { store } = this.rootModels;
    const { storageStore, networkStore, helperStore } = store;

    try {
      helperStore.toggleLoader(true);

      const { storeId } = storageStore;

      const whereClause = `((targetStoreId = '${storeId}' OR sourceStoreId = '${storeId}' OR targetStoreId = '${storeId}' OR sourceStoreId = '${storeId}') AND (type = 'Wholesale' AND (status = 'Canceled' OR status = 'Delivered' OR status = 'Revoked' OR status = 'Declined') OR (type = 'InStore' AND (status = 'Requested' OR status = 'Sent' OR status = 'Declined' OR status = 'Canceled' OR status = 'Revoked')) OR (type = 'ClickAndReserve' AND (status = 'Delivered' OR status = 'Canceled' OR status = 'Expired')) OR (type = 'ClickAndCollect' AND (status = 'Delivered' OR status = 'Canceled' OR status = 'Expired' OR status = 'Declined')) OR (type = 'Reservation' AND (status = 'Delivered' OR status = 'Rejected' OR status = 'Canceled' OR status = 'Expired' OR status = 'Declined'))))`;

      const { response, status } = await networkStore.post('LoadObjectsOfClass', {
        whereClause,
        offset: 0,
        orderByName: 'created',
        orderByType: 'DESC',
        pageSize: 200,
        relations: [
          'articleItems.logs',
          'customer.addresses',
          'deliveryAddress',
          'invoiceAddress',
          'customerDigitalInfo',
          'orderDocuments',
        ],
        clazz: 'com.innomos.baas.common.model.OmniChannelProcess',
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "LoadObjectsOfClass" Failed!');

      const {
        data: { data },
      } = JSON.parse(response);

      this.finished = data.map(helperStore.mapOrdersData);
      this.isFinishedLoaded = true;
    } catch (error) {
      if (store.debug) console.error(error);
    } finally {
      this.isLoading = false;
      helperStore.toggleLoader(false);
    }
  });

  get ordersByInCreation() {
    const { Creation, Created, Parked } = this.Status;

    const useStatusFilter = item => item.status === Creation || item.status === Created || item.status === Parked;

    const useTimeFilter = item =>
      useStatusFilter(item) && item.timestamp >= subDays(new Date(), this.filters[this.currentFilter]);

    return !this.currentFilter ? this.data.filter(useStatusFilter) : this.data.filter(useTimeFilter);
  }

  get ordersByWaitingForDelivery() {
    const { InStore } = this.Type,
      { Requested, Sent } = this.Status;

    const useStatusFilter = item => item.type !== InStore && (item.status === Sent || item.status === Requested);

    const useTimeFilter = item =>
      useStatusFilter(item) && item.timestamp >= subDays(new Date(), this.filters[this.currentFilter]);

    return !this.currentFilter ? this.data.filter(useStatusFilter) : this.data.filter(useTimeFilter);
  }

  get ordersByWaitingForCustomers() {
    const { Received, Overdue } = this.Status;

    const useStatusFilter = item => item.status === Received || item.status === Overdue;

    const useTimeFilter = item =>
      useStatusFilter(item) && item.timestamp >= subDays(new Date(), this.filters[this.currentFilter]);

    return !this.currentFilter ? this.data.filter(useStatusFilter) : this.data.filter(useTimeFilter);
  }

  get ordersByFinished() {
    const useTimeFilter = item => item.timestamp >= subDays(new Date(), this.filters[this.currentFilter]);

    return !this.currentFilter ? this.finished : this.finished.filter(useTimeFilter);
  }

  get ordersByStatus() {
    const {
      Tabs: { InCreation, WaitingForDelivery, WaitingForCustomer, Finished },
      currentTab,
    } = this;

    switch (currentTab) {
      case InCreation:
        return this.ordersByInCreation;
      case WaitingForDelivery:
        return this.ordersByWaitingForDelivery;
      case WaitingForCustomer:
        return this.ordersByWaitingForCustomers;
      case Finished:
        return this.ordersByFinished;
      default:
        return [];
    }
  }

  reset = action(() => {
    this.isDataLoaded = false;
    this.isFinishedLoaded = false;
    this.data = [];
    this.finished = [];
  });
}

export default Model('OrdersModel')(OrdersModel);
