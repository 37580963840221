import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const ArticleCatalog = memo(({ width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 500.000000 500.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M1793 3780 c-18 -10 -38 -29 -46 -42 -8 -13 -77 -170 -152 -350
l-138 -328 -173 0 -174 0 0 -225 0 -225 45 0 45 0 0 -622 c0 -585 2 -625 19
-663 22 -47 64 -89 104 -104 40 -15 2314 -15 2354 0 40 15 82 57 104 104 17
38 19 78 19 663 l0 622 45 0 45 0 0 225 0 225 -174 0 -173 0 -138 328 c-75
180 -144 337 -152 350 -8 13 -28 32 -46 42 -31 19 -56 20 -707 20 -651 0 -676
-1 -707 -20z m1365 -64 c24 -12 48 -61 160 -328 73 -173 132 -317 132 -321 0
-4 -427 -7 -950 -7 -522 0 -950 3 -950 7 0 4 59 148 132 321 107 253 137 316
158 327 38 21 1278 22 1318 1z m-1743 -753 c-35 -82 -31 -126 12 -121 22 2 31
14 54 68 l27 65 992 0 992 0 27 -65 c23 -54 32 -66 54 -68 43 -5 47 39 12 121
-7 16 2 17 114 17 l121 0 0 -145 0 -145 -1320 0 -1320 0 0 145 0 145 121 0
c112 0 121 -1 114 -17z m2305 -970 c0 -602 -1 -619 -20 -651 -40 -65 23 -62
-1201 -62 -1231 0 -1159 -4 -1200 64 -18 29 -19 62 -19 649 l0 617 1220 0
1220 0 0 -617z"
      />
    </g>
  </svg>
));

export default Icon('ArticleCatalog')(ArticleCatalog);
