import { inject, observer } from 'mobx-react';
import { Fragment, useEffect } from 'react';

import View from '@framework/decorators/View';

import RoutesView from '@framework/views/Routes';

import HeaderComponent from '@framework/components/Header';

import LoaderModal from '@framework/modals/Loader';
import ArticleModal from '@framework/modals/Article';
import OrdersProcessingModal from '@framework/modals/OrdersProcessing';
import RequestsProcessingModal from '@framework/modals/RequestsProcessing';
import NotificationModal from '@framework/modals/Notification';
import TrackerModal from '@framework/modals/Tracker';
import BarcodeScannerModal from '@framework/modals/Barcode';
import CustomerProductsModal from '@framework/modals/CustomerProducts';
import CustomerTransactionModal from '@framework/modals/CustomerTransaction';
import CustomerVouchersModal from '@framework/modals/CustomerVouchers';
import CustomerSignatureModal from '@framework/modals/CustomerSignature';
import CartCustomerModal from '@framework/modals/CartCustomer';
import CartArticlesModal from '@framework/modals/CartArticles';
import CartDestinationModal from '@framework/modals/CartDestination';
import CartFormModal from '@framework/modals/CartForm';
import CartReservationModal from '@framework/modals/CartReservation';
import InactivityModal from '@framework/modals/Inactivity';
import InactivityVideoModal from '@framework/modals/InactivityVideo';
import AppointmentFormModal from '@framework/modals/AppointmentForm';
import AppointmentProcessingModal from '@framework/modals/AppointmentProcessing';
import QrCodeModal from '@framework/modals/QrCode';
import ComplaintsCustomerModal from '@framework/modals/ComplaintsCustomer';
import ComplaintsFormModal from '@framework/modals/ComplaintsForm';
import CameraModal from '@framework/modals/Camera';
import ComplaintModal from '@framework/modals/Complaint';
import ComplaintsDestinationModal from '@framework/modals/ComplaintsDestination';
import ImagePreviewModal from '@framework/modals/ImagePreview';

import { WindowContainerStyled } from '@framework/styles/App';

import Sentry from './Sentry';

const AppView = View('App')(props => {
  const { models, store } = props;
  const { localesStore, storageStore, helperStore, networkStore } = store;

  const isAppReady = localesStore.isReady && storageStore.isReady;

  useEffect(() => {
    models.CartModel.getStorage();
  }, []);

  useEffect(() => {
    if (!window.isGoogelMapsConnected) {
      window.googleMapsConnectedClb = () => {
        console.log('Google Maps Module: %cEnabled', 'color: green;');
      };
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${store.googleKey}&libraries=places&callback=googleMapsConnectedClb`;
      document.body.appendChild(script);
      window.isGoogelMapsConnected = true;
    }
  }, []);

  useEffect(() => {
    if (!networkStore.isNativeThread) return;
    networkStore.webview('statusBarColor', {
      color: !store.isKioskMode && !store.isAuthorized ? 'black' : 'white',
    });
  }, [store.isKioskMode, store.isAuthorized]);

  return isAppReady ? (
    <Sentry models={models} store={store}>
      {(store.isAuthorized || store.isKioskMode) && <HeaderComponent />}

      <WindowContainerStyled $withHeader={store.isAuthorized || store.isKioskMode}>
        <RoutesView />
      </WindowContainerStyled>

      {
        !storageStore.isOutlet() ? (
          <>
            <OrdersProcessingModal />
            <RequestsProcessingModal />
            <ArticleModal />
            <CartDestinationModal />
            <CartCustomerModal />
            <CartArticlesModal />
            <CartFormModal />
            <CartReservationModal />
            <QrCodeModal />
          </>
        ) : null
      }

      <AppointmentFormModal />
      <AppointmentProcessingModal />
      <TrackerModal />
      <CustomerProductsModal />
      <CustomerTransactionModal />
      <CustomerVouchersModal />
      <ComplaintsDestinationModal />
      <ComplaintsCustomerModal />
      <ComplaintsFormModal />
      <ComplaintModal />
      <CustomerSignatureModal />
      <BarcodeScannerModal />
      <NotificationModal />
      <CameraModal />
      <ImagePreviewModal />
      <InactivityModal />
      <InactivityVideoModal />
      <LoaderModal $isShowed={helperStore.isLoader}>{localesStore.translate('placeholder.modal.loader')}</LoaderModal>
    </Sentry>
  ) : (
    <LoaderModal />
  );
});

export default inject('models', 'store')(observer(AppView));
