import swal from 'sweetalert';

import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class CartProcessingModel {
  processId = null;

  Customer = {
    Guest: '@guest',
    Editor: '@editor',
    Creation: '@creation',
  };

  isDestinationToggled = false;
  isCustomerToggled = false;
  isFormToggled = false;
  isReservationToggled = false;
  isArticlesToggled = false;

  isSearchComponent = false;

  customer = null;
  destination = null;

  customerData = null;
  orderData = null;

  navigate = () => {};

  options = {};

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      processId: observable,
      isDestinationToggled: observable,
      isCustomerToggled: observable,
      isFormToggled: observable,
      isSearchComponent: observable,
      isReservationToggled: observable,
      isArticlesToggled: observable,
      customer: observable,
      destination: observable,
      customerData: observable,
      orderData: observable,
      navigate: observable,
      options: observable,
    });
  }

  register = action(navigate => {
    this.navigate = navigate;
  });

  toggleDestinationWindow = action(value => {
    this.isDestinationToggled = value;
  });

  toggleCustomerWindow = action((value, options = {}) => {
    this.options = options;
    this.isCustomerToggled = value;
  });

  toggleFormWindow = action(value => {
    this.isFormToggled = value;
  });

  toggleReservation = action(value => {
    this.isReservationToggled = value;
  });

  toggleArticles = action(value => {
    this.isArticlesToggled = value;
  });

  chooseDestinationVariant = action(variant => {
    this.destination = variant;
    this.toggleCustomerWindow(true);
  });

  chooseCustomerVariant = action(variant => {
    const { Creation, Editor, Guest } = this.Customer,
      {
        store: { helperStore },
        FormModel,
        OrdersModel,
      } = this.rootModels;

    this.customer = variant;

    const isCatalog = this.options.type === 'reservation';
    const additionalFields = this.destination === OrdersModel.Type.InStore ? ['addressLine2'] : [];

    if (variant === Editor) {
      this.isSearchComponent = true;
    } else if (variant === Creation) {
      FormModel.setFormSettings(variant, {
        include: isCatalog
          ? ['salutation', 'firstName', 'lastName', 'email']
          : ['salutation', 'firstName', 'lastName', 'email', 'city', 'postalCode', 'street', 'streetNumber'].concat(additionalFields),
        onResolve: (customerId, data) => {
          this.saveCustomerData(customerId, data, !isCatalog).then(
            isCatalog
              ? () => {
                  this.handleWindowsClose();
                  this.toggleReservation(true);
                }
              : () => {
                  this.handleWindowsClose();
                  helperStore.cartAnimationDirection = helperStore.Direction.Ltr;
                  helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
                  this.navigate('/order');
                }
          );
        },
      });
    } else if (variant === Guest) {
      FormModel.setFormSettings(variant, {
        include:
          this.destination === OrdersModel.Type.InStore
            ? ['salutation', 'firstName', 'lastName', 'email', 'city', 'postalCode', 'street', 'streetNumber', 'addressLine2']
            : [],
        exclude: ['isEmailContactAllowed', 'signature'],
        onResolve: (customerId, data) => {
          this.saveCustomerData(customerId, data, !isCatalog).then(
            isCatalog
              ? () => {
                  this.handleWindowsClose();
                  this.toggleReservation(true);
                }
              : () => {
                  this.handleWindowsClose();
                  helperStore.cartAnimationDirection = helperStore.Direction.Ltr;
                  helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
                  this.navigate('/order');
                }
          );
        },
      });
    }

    this.toggleFormWindow(true);
  });

  setCustomerData = action(data => {
    this.customerData = data;
  });

  saveCustomerData = action((customerId, formData, saveToStorage = true) => {
    const {
      Customer: { Creation, Editor, Guest },
      rootModels: { store },
    } = this;

    switch (this.customer) {
      case Creation: {
        return new Promise(resolve => {
          this.orderData = {
            destination: this.destination,
            customer: this.customer,
            details: {
              ...formData,
              customerId: customerId,
              country: formData.country.toUpperCase(),
            },
            deliveryAddress: Object.entries(formData).some(
              ([key, value]) => key.includes('additional') && key !== 'additionalCountry' && Boolean(value)
            )
              ? {
                  birthday: null,
                  country: formData.additionalCountry?.toUpperCase(),
                  lastName: formData.additionalLastName,
                  website: null,
                  streetNumber: formData.additionalStreetNumber,
                  city: formData.additionalCity,
                  addressType: 'MainAddress',
                  created: null,
                  latitude: null,
                  postalCode: formData.additionalPostalCode,
                  title: null,
                  firstName: formData.additionalFirstName,
                  phone: null,
                  street: formData.additionalStreet,
                  customerId: null,
                  addressLine1: formData.additionalAddressLine1,
                  addressLine2: null,
                  salutation: formData.additionalSalutation,
                  region: null,
                  updated: null,
                  objectId: 'InCreation_1681976177.833237',
                  email: null,
                  longitude: null,
                }
              : null,
          };

          if (saveToStorage) store.storageStore.order = this.orderData;

          resolve();
        });
      }

      case Editor: {
        return new Promise(resolve => {
          this.orderData = {
            destination: this.destination,
            customer: this.customer,
            details: {
              ...formData,
              customerId: customerId,
              country: formData.country.toUpperCase(),
            },
            deliveryAddress: Object.entries(formData).some(
              ([key, value]) => key.includes('additional') && key !== 'additionalCountry' && Boolean(value)
            )
              ? {
                  birthday: null,
                  country: formData.additionalCountry?.toUpperCase(),
                  lastName: formData.additionalLastName,
                  website: null,
                  streetNumber: formData.additionalStreetNumber,
                  city: formData.additionalCity,
                  addressType: 'MainAddress',
                  created: null,
                  latitude: null,
                  postalCode: formData.additionalPostalCode,
                  title: null,
                  firstName: formData.additionalFirstName,
                  phone: null,
                  street: formData.additionalStreet,
                  customerId: null,
                  addressLine1: formData.additionalAddressLine1,
                  addressLine2: null,
                  salutation: formData.additionalSalutation,
                  region: null,
                  updated: null,
                  objectId: 'InCreation_1681976177.833237',
                  email: null,
                  longitude: null,
                }
              : null,
          };

          if (saveToStorage) store.storageStore.order = this.orderData;

          resolve();
        });
      }

      case Guest: {
        return new Promise(resolve => {
          this.orderData = {
            destination: this.destination,
            customer: this.customer,
            details: formData,
          };

          if (saveToStorage) store.storageStore.order = this.orderData;

          resolve();
        });
      }
    }
  });

  processParkedData = action(async (data, callback = () => {}) => {
    const {
      rootModels: { store, CartModel },
      resetProcessedData,
      Customer: { Editor },
    } = this;

    store.helperStore.toggleLoader(true);

    resetProcessedData();

    const orderData = {
      processId: data.processId,
      destination: data.type,
      customer: Editor,
      details: {
        birthday: data.invoiceAddress?.birthday,
        country: data.invoiceAddress?.country,
        lastName: data.invoiceAddress?.lastName,
        streetNumber: data.invoiceAddress?.streetNumber,
        city: data.invoiceAddress?.city,
        postalCode: data.invoiceAddress?.postalCode,
        firstName: data.invoiceAddress?.firstName,
        phone: data.invoiceAddress?.phone,
        street: data.invoiceAddress?.street,
        customerId: data.invoiceAddress?.customerId || '',
        addressLine1: data.invoiceAddress?.addressLine1,
        addressLine2: data.invoiceAddress?.addressLine2,
        salutation: data.invoiceAddress?.salutation,
        email: data.invoiceAddress?.email,
      },
      deliveryAddress: data.deliveryAddress,
      invoiceAddress: data.invoiceAddress,
    };

    if (data.invoiceAddress) {
      this.orderData = orderData;
      store.storageStore.order = orderData;
    } else {
      this.processId = data.processId;
    }

    Promise.all(data.articleItems.map(async article => store.helperStore.mapParkedArticleItems(article))).then(data => {
      let isUnfetchedArticles = false;

      CartModel.setCart(
        data.reduce((acc, next) => {
          if (next) {
            acc.push(next);
          } else {
            isUnfetchedArticles = true;
          }

          return acc;
        }, [])
      );

      if (isUnfetchedArticles) {
        store.helperStore.toggleLoader(false);

        swal({
          title: store.localesStore.translate('placeholder.label.warning'),
          text: store.localesStore.translate('notification.parkedError.description'),
          buttons: {
            apply: store.localesStore.translate('placeholder.label.ok'),
          },
        }).then(() => {
          store.helperStore.toggleLoader(true);

          CartModel.getArticles().then(articles => {
            CartModel.setArticles(articles);
            store.helperStore.toggleLoader(false);
            if (typeof callback === 'function') callback();
          });
        });
      } else {
        CartModel.getArticles().then(articles => {
          CartModel.setArticles(articles);
          store.helperStore.toggleLoader(false);
          if (typeof callback === 'function') callback();
        });
      }
    });
  });

  handleReservationCall = action(() => {
    const { OrderModel } = this.rootModels;
    OrderModel.createReservation(this.orderData.details, this.options.product);
  });

  handleDestinationBackCall = action(() => {
    this.customer = null;
    this.toggleCustomerWindow(false);
  });

  handleDestinationClose = action(() => {
    this.reset();
  });

  handleCustomerClose = action(() => {
    this.reset();
  });

  handleFormClose = action(() => {
    this.reset();
  });

  handleReservationClose = action(() => {
    this.reset();
  });

  handleWindowsClose = action(() => {
    this.isSearchComponent = false;
    this.isFormToggled = false;
    this.isDestinationToggled = false;
    this.isCustomerToggled = false;
    this.isReservationToggled = false;
  });

  resetProcessedData = action(() => {
    const {
      rootModels: { OrdersProcessingModel, CartModel },
      reset,
    } = this;

    OrdersProcessingModel.setOrder(null);
    OrdersProcessingModel.setArticle(null);
    CartModel.reset();
    reset();
  });

  handleCatalogCall = action(() => {
    const { helperStore, isKioskMode } = this.rootModels.store;

    helperStore.baseAnimationDirection = helperStore.Direction.Ltr;

    this.isArticlesToggled = false;

    this.navigate(isKioskMode ? '/' : '/catalog');
  });

  handleScannerCall = action(() => {
    const { CatalogModel } = this.rootModels;

    this.isArticlesToggled = false;

    CatalogModel.handleScannerCall();
  });

  handleSearchCall = action(() => {
    const { CatalogModel } = this.rootModels;

    this.handleCatalogCall();

    CatalogModel.handleSearchbarCall();
  });

  reset = action(() => {
    const { FormModel, store } = this.rootModels;

    this.processId = null;
    this.customerData = null;
    this.customer = null;
    this.destination = null;
    this.isCustomerToggled = false;
    this.isDestinationToggled = false;
    this.isReservationToggled = false;
    this.isFormToggled = false;
    this.isSearchComponent = false;
    this.isArticlesToggled = false;
    this.orderData = null;
    this.options = {};

    store.storageStore.order = null;

    FormModel.reset();
  });
}

export default Model('CartProcessingModel')(CartProcessingModel);
