import { v4 } from 'uuid';
import { action, makeObservable, observable } from 'mobx';

import Store from '@framework/decorators/Store';

export class StorageStore {
  isReady = false;

  key = process.env.APPWRITE_ID;

  storage = {
    storeId: '',
    registrationId: '',
    version: '',
    session: '',
    device: null,
    order: {},
    cart: {},
    merchant: {},
    storeData: {},
  };

  pings = 3;

  outlets = [
    '2826',
    '2836',
    '2872',
    '8020',
    '8151',
    '8201',
    '8512',
    '8401',
  ];

  constructor(store) {
    this.store = store;

    makeObservable(this, {
      isReady: observable,
      storage: observable,
    });

    this.init();
  }

  init = action(async () => {
    const { networkStore } = this.store;

    const storeSnapshot = JSON.parse(JSON.stringify(this.storage));

    await this.getStorage();

    if (this.version !== process.env.VERSION) {
      this.storage = {
        ...this.storage,
        ...storeSnapshot,
        storeId: this.storeId,
        registrationId: this.registrationId,
        device: this.device,
        session: this.session,
        version: process.env.VERSION,
        storeData: this.storeData,
      };

     await this.setStorage();
    }

    if (!networkStore.isNativeThread && !this.device) {
      this.deviceRegistration({
        os: 'WP',
        osVersion: this.version,
        deviceId: v4().toUpperCase(),
        deviceToken: (() => {
          const { userAgent } = navigator;

          let title = '',
            parsedAgents = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

          if (/trident/i.test(parsedAgents[1])) {
            title = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (title[1] || '');
          }
          if (parsedAgents[1] === 'Chrome') {
            title = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (title != null) return title.slice(1).join(' ').replace('OPR', 'Opera');
          }
          parsedAgents = parsedAgents[2]
            ? [parsedAgents[1], parsedAgents[2]]
            : [navigator.appName, navigator.appVersion, '-?'];
          if ((title = userAgent.match(/version\/(\d+)/i)) != null) parsedAgents.splice(1, 1, title[1]);
          return parsedAgents.join(' ');
        })(),
      });
    }

    if (!networkStore.isNativeThread || (networkStore.isNativeThread && this.device)) this.isReady = true;
  });

  getStorage = action(async() => {
    const { networkStore } = this.store;

    if(networkStore.isNativeThread) {
      this.storage = JSON.parse((await networkStore.webview('read_user_prefs'))?.storage || 'null') || this.storage;
    } else {
      this.storage = JSON.parse(localStorage.getItem(this.key) || 'null') || this.storage;
    }

    return this.storage;
  });

  setStorage = action(async () => {
    const { networkStore } = this.store;

    if(networkStore.isNativeThread) {
      return await networkStore.webview('write_user_prefs', { storage: JSON.stringify(this.storage) });
    } else {
      return localStorage.setItem(this.key, JSON.stringify(this.storage));
    }
  });

  isOutlet = () => {
    return this.outlets.includes(this.storeId);
  }

  updateStorage = async () => {
    await this.setStorage();
    await this.getStorage();
  };

  deviceRegistration = deviceData => {
    const { deviceId, deviceToken, os, osVersion } = deviceData;

    if (this.device) {
      if (this.device.deviceId === deviceId && this.device.deviceToken === deviceToken) {
        this.isReady = true;
        return;
      };

      this.updateRegistration(deviceData);
    } else {
      this.device = {
        os: os,
        osVersion: osVersion,
        deviceToken: deviceToken,
        deviceId: deviceId,
      };

      this.isReady = true;
    }
  };

  verifyRegistration = async () => {
    try {
      if (!this.registrationId) return false;

      const data = await fetch([process.env.DEVICE_API, this.device.deviceId].join('/'), {
        method: 'GET',
        headers: {
          'secret-key': this.store.secretKey,
          'application-id': this.store.applicationId,
        },
      });

      const [response] = await data.json();

      return {
        isVerified:
          this.registrationId === response.id &&
          this.device.deviceId === response.deviceId &&
          this.device.deviceToken === response.deviceToken,
        isSameStore: response.channels.includes('store_' + this.storeId),
      };
    } catch (error) {
      try {
        this.store.networkStore.catchException(JSON.stringify(error), 'Line 156: [StorageStore]')
      } finally {
        if (this.store.debug) console.error(error);
        return false;
      }
    }
  };

  createRegistration = async (forceData = null, isStoreChanged = false) => {
    try {
      const body = JSON.stringify({
        os: forceData?.os ?? this.device.os,
        deviceId: forceData?.deviceId ?? this.device.deviceId,
        osVersion: forceData?.osVersion ?? this.device.osVersion,
        deviceToken: forceData?.deviceToken ?? this.device.deviceToken,
        channels: this.storeId ? ['default', 'store_' + this.storeId] : ['default'],
      });

      if ((forceData && this.registrationId) || isStoreChanged) {
        await this.removeRegistration();
      }

      const data = await fetch(process.env.DEVICE_API, {
        body,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'secret-key': this.store.secretKey,
          'application-id': this.store.applicationId,
        },
      });

      const response = await data.json();

      this.registrationId = response.registrationId;

      if (forceData) {
        this.device = { ...forceData };
      }
    } catch (error) {
      if (this.pings > 0) {
        this.pings -= 1;
        return this.createRegistration(forceData);
      } else {
        try {
          this.store.networkStore.catchException(JSON.stringify(error), 'Line 201: [StorageStore]')
        } finally {
          if (this.store.debug) console.error(error);
          return false;
        }
      }
    } finally {
      await this.setStorage();
    }
  };

  updateRegistration = async (forceData = null) => {
    let isVerified = false,
      isStoreChanged = false;

    if (this.registrationId) {
      const registration = await this.verifyRegistration();

      isVerified = registration.isVerified;
      isStoreChanged = !registration.isSameStore;
    }

    if (!isVerified || forceData || isStoreChanged) this.createRegistration(forceData, isStoreChanged);
  };

  removeRegistration = async () => {
    try {
      if (!this.registrationId) return;

      const data = await fetch([process.env.DEVICE_API, this.device.deviceId].join('/'), {
        method: 'DELETE',
        headers: {
          'secret-key': this.store.secretKey,
          'application-id': this.store.applicationId,
        },
      });

      const response = await data.json();

      if (response.result) this.registrationId = '';

      return response.result;
    } catch (error) {
      try {
        this.store.networkStore.catchException(JSON.stringify(error), 'Line 245: [StorageStore]')
      } finally {
        if (this.store.debug) console.error(error);
        return false;
      }
    }
  };

  set storeId(value) {
    this.storage.storeId = value;
    this.updateStorage();
  }

  get storeId() {
    return this.storage.storeId;
  }

  set registrationId(value) {
    this.storage.registrationId = value;
    this.updateStorage();
  }

  get registrationId() {
    return this.storage.registrationId;
  }

  set expiration(value) {
    sessionStorage.setItem('StoreMind-Expiration', JSON.stringify(value));
  }

  get expiration() {
    return JSON.parse(sessionStorage.getItem('StoreMind-Expiration') || 'null');
  }

  set device(value) {
    this.storage.device = value;
    this.updateStorage();
  }

  get deviceId() {
    return this.storage.device?.deviceId;
  }

  get device() {
    return this.storage.device;
  }

  set cart(value) {
    this.storage.cart[this.storeId] = value;
    this.updateStorage();
  }

  get cart() {
    return this.storage.cart[this.storeId] || [];
  }

  get order() {
    return this.storage.order[this.storeId] || null;
  }

  set order(value) {
    this.storage.order[this.storeId] = value;
    this.updateStorage();
  }

  set session(value) {
    this.storage.session = value;
    this.updateStorage();
  }

  get session() {
    return this.storage.session;
  }

  set merchant(value) {
    this.storage.merchant = value;
    this.updateStorage();
  }

  get merchant() {
    return this.storage.merchant;
  }

  set storeData(value) {
    this.storage.storeData = value;
    this.updateStorage();
  }

  get storeData() {
    return this.storage.storeData;
  }

  get version() {
    return this.storage.version;
  }

  get storeName() {
    return this.storage.storeData?.name;
  }

  reset = action(() => {
    this.order = null;
    this.merchant = {};
    this.cart = [];
  });

  clear = action(() => {
    this.storage = {
      ...this.storage,
      storeId: '',
      session: '',
      order: {},
      cart: {},
      merchant: {},
      storeData: {},
    };

    this.setStorage();
  });
}

export default Store('StorageStore')(StorageStore);
