import { Formik } from 'formik';
import { Box, Fade, useTheme } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { useEffect, Fragment } from 'react';
import { Close } from '@mui/icons-material';

import View from '@framework/decorators/View';

import { ButtonStyled } from '@framework/styles/App';

import {
  AuthOverlayStyled,
  AuthFormStyled,
  AuthLogoStyled,
  AuthInputWrapperStyled,
  AuthInputStyled,
  AuthInputMessageStyled,
  AuthButtonStyled,
  AuthDescriptionStyled,
} from '@framework/styles/Auth';

const AuthView = View('Auth')(props => {
  const {
    models: { AuthModel },
    store: { debug, localesStore, helperStore, storageStore, isMasterMate },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile(),
    {
      isMaintenance,
      isVerified,
      isServerError,
      verifySession,
      handleValidateAuthForm,
      handleSubmitAuthForm,
      handleValidateMerchantForm,
      handleSubmitMerchantForm,
      handleEnterKioskMode,
      handleChangeStore,
    } = AuthModel;

  useEffect(() => {
    verifySession();
  }, []);

  return (
    <AuthOverlayStyled $dev={debug} $url={!isMaintenance ? "images/bg-login.png" : null}>
      <Fade in key={isVerified}>
        <div>
          {isMaintenance ? (
            <Box sx={{ display: 'grid', width: '100%', height: '100%', gridTemplateRows: '80px 1fr' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  borderBottom: '1px solid rgba(0, 0, 0, .25)'
                }}
              >
                <Box
                  sx={
                    debug
                      ? {
                        position: 'relative',
                        display: 'flex',
                        height: '40px',
                        '& > img': {
                          opacity: '0.3',
                        },
                        '&::after': {
                          content: '"[prelive]"',
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          width: '100%',
                          height: '100%',
                          textAlign: 'center',
                          letterSpacing: '5px',
                          lineHeight: '2',
                          fontSize: '20px',
                          fontWeight: '400',
                          color: theme.application.palette.danger,
                        },
                      }
                      : {
                        display: 'flex',
                        height: '40px',
                      }
                  }
                >
                  <img
                    height="100%"
                    src="images/ig-logotype-dark.png"
                    alt=""
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  overflow: 'hidden',
                }}
              >
                <Box
                  component="img"
                  src="/images/bg-maintenance.png"
                  sx={{
                    width: isMobile ? '200%' : '100%',
                  }}
                />
                <Box sx={{ marginTop: '20px', }}>
                  Die App macht eine kurze Pause zur Wartung.
                </Box>
                <Box sx={{ marginTop: '20px', width: '320px', height: '50px' }}>
                   <ButtonStyled $fullWidth onClick={() => document.location.reload()}>
                      {localesStore.translate('placeholder.controls.cancel')}
                    </ButtonStyled>
                </Box>
              </Box>
            </Box>
          ) : !isVerified ? (
            <Formik
              initialValues={{ email: '', password: '' }}
              validateOnChange={false}
              validateOnBlur={false}
              validate={handleValidateAuthForm}
              onSubmit={handleSubmitAuthForm}
            >
              {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                <AuthFormStyled onSubmit={handleSubmit}>
                  <AuthLogoStyled>
                    <img src="images/ig-logotype-white.png" alt="" />
                  </AuthLogoStyled>
                  <AuthInputWrapperStyled>
                    <AuthInputStyled
                      name="email"
                      placeholder={localesStore.translate('auth.label.email')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={Boolean(errors.email) || isServerError}
                    />
                    <Fade in={Boolean(errors.email)}>
                      <AuthInputMessageStyled>{errors.email || '_'}</AuthInputMessageStyled>
                    </Fade>
                  </AuthInputWrapperStyled>
                  <AuthInputWrapperStyled>
                    <AuthInputStyled
                      type="password"
                      name="password"
                      placeholder={localesStore.translate('auth.label.password')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={Boolean(errors.password) || isServerError}
                    />
                    <Fade in={Boolean(errors.password)}>
                      <AuthInputMessageStyled>{errors.password || '_'}</AuthInputMessageStyled>
                    </Fade>
                  </AuthInputWrapperStyled>
                  <AuthButtonStyled type="submit">{localesStore.translate('auth.controls.enter')}</AuthButtonStyled>
                </AuthFormStyled>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={{ merchant: '' }}
              validateOnChange={false}
              validateOnBlur={false}
              validate={handleValidateMerchantForm}
              onSubmit={handleSubmitMerchantForm}
            >
              {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                <Fragment>
                  {
                    !storageStore.isOutlet() ? (
                      <Box
                        onClick={handleEnterKioskMode}
                        sx={{
                          cursor: 'pointer',
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          left: '25px',
                          top: '25px',
                          color: theme.application.palette.white,
                        }}
                      >
                        <Close />
                        <Box component="span" sx={{ marginLeft: '5px' }}>
                          {localesStore.translate('placeholder.controls.cancel')}
                        </Box>
                      </Box>
                    ) : null
                  }

                  <AuthFormStyled onSubmit={handleSubmit}>
                    <AuthLogoStyled>
                      <img src="images/ig-logotype-white.png" alt="" />
                    </AuthLogoStyled>
                    <AuthDescriptionStyled>{localesStore.translate('auth.label.description')}</AuthDescriptionStyled>
                    <AuthInputWrapperStyled>
                      <AuthInputStyled
                        name="merchant"
                        placeholder={localesStore.translate('auth.label.merchant')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={Boolean(errors.merchant) || isServerError}
                      />
                      <Fade in={Boolean(errors.merchant)}>
                        <AuthInputMessageStyled>{errors.merchant || '_'}</AuthInputMessageStyled>
                      </Fade>
                    </AuthInputWrapperStyled>
                    <AuthButtonStyled type="submit">{localesStore.translate('auth.controls.login')}</AuthButtonStyled>
                  </AuthFormStyled>
                  {isMasterMate && (
                    <Box
                      onClick={() => handleChangeStore()}
                      sx={{
                        cursor: 'pointer',
                        position: 'absolute',
                        bottom: '30px',
                        width: '100%',
                        textAlign: 'center',
                        color: 'white',
                        fontSize: '14px',
                      }}
                    >
                      Store ändern
                    </Box>
                  )}
                </Fragment>
              )}
            </Formik>
          )}
        </div>
      </Fade>
    </AuthOverlayStyled>
  );
});

export default inject('models', 'store')(observer(AuthView));
