import { css, styled } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const AppointmentsListStyled = Styled(
  'Appointments',
  'AppointmentsListStyled'
)(styled('ul')`
  position: absolute;
  left: 0;
  padding: 0;
  width: 280px;
  display: flex;
  flex-direction: column;
  list-style: none;

  & li + li {
    margin-top: 5px;
  }

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      width: 100%;
    }
  `}
`);

export const AppointmentsListItemStyled = Styled(
  'Appointments',
  'AppointmentsListItemStyled'
)(styled('li')`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
    border-bottom: 2px solid ${application.palette.whitesmoke};

    & > span:last-child {
      color: ${application.palette.darkgray};
    }
  `}
`);

export const AppointmentsFieldsetStyled = Styled(
  'Appointments',
  'AppointmentsFieldsetStyled'
)(
  styled(({ title, children, className }) => (
    <div className={className}>
      <h2> {title} </h2>
      {children}
    </div>
  ))`
    overflow: hidden;

    & h2 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  `
);
