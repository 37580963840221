import { Fragment, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { Close, ExitToAppOutlined, Menu, QrCode2 } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {Box, useTheme} from '@mui/material';

import Component from '@framework/decorators/Component';

import ShoppingCart from '@framework/icons/ShoppingCart';

import NavbarComponent from '@framework/components/Navbar';

import {
  HeaderStyled,
  HeaderContainerStyled,
  HeaderSectionStyled,
  HeaderLogotypeStyled,
  HeaderMenuButtonStyled,
  HeaderLabelStyled,
  HeaderCartButtonStyled,
  HeaderCartCounterStyled,
} from '@framework/styles/Header';

const HeaderComponent = Component('FormComponent')(props => {
  const {
    models: { HeaderModel, CartModel, AuthModel },
    store: { debug, isKioskMode, localesStore, helperStore, storageStore },
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();

  const { total, handleQrCodeToggle } = CartModel,
    { isToggled, toggleMenuBar, handleMenuBtnCall } = HeaderModel,
    { handleLeaveKioskMode } = AuthModel;

  const handleLogoClick = useCallback(() => {
    helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
    helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
    helperStore.cartAnimationDirection = helperStore.Direction.Rtl;

    helperStore.setPreviousSearchValue('');

    if (isToggled) toggleMenuBar();

    navigate('/');
  }, [navigate, isToggled]);

  const handleCartBtnCall = useCallback(() => {
    helperStore.baseAnimationDirection = helperStore.Direction.Ltr;
    helperStore.screensAnimationDirection = helperStore.Direction.Ltr;
    helperStore.cartAnimationDirection = helperStore.Direction.Rtl;

    helperStore.setPreviousLocation(location.pathname);
    helperStore.setPreviousLocationSearch(location.search);
    helperStore.setPreviousSearchValue('');

    if (isToggled) toggleMenuBar();

    navigate('/cart');
  }, [navigate, isToggled]);

  return (
    <Fragment>
      <HeaderStyled>
        <HeaderContainerStyled>
          <HeaderSectionStyled data-section="start">
            {isKioskMode ? (
              <HeaderMenuButtonStyled onClick={() => handleLeaveKioskMode()}>
                <ExitToAppOutlined />
                <Box
                  sx={{
                    fontSize: '10px',
                    textAlign: 'center',
                    color: theme.application.palette.darkgray,
                  }}
                >
                  {`v. ${storageStore.version}`}
                </Box>
              </HeaderMenuButtonStyled>
            ) : (
              <HeaderMenuButtonStyled onClick={handleMenuBtnCall}>
                {isToggled ? <Close /> : <Menu />}
              </HeaderMenuButtonStyled>
            )}
            <HeaderLabelStyled>
              {storageStore.storeName ?? localesStore.translate('placeholder.label.storeName')}
            </HeaderLabelStyled>
          </HeaderSectionStyled>
          <HeaderSectionStyled $dev={debug} data-section="center">
            <HeaderLogotypeStyled src="images/ig-logotype-dark.png" onClick={handleLogoClick} />
          </HeaderSectionStyled>
          <HeaderSectionStyled data-section="end">
            {!storageStore.isOutlet() && total > 0 && (
              <Box
                onClick={handleQrCodeToggle}
                sx={{
                  cursor: 'pointer',
                  marginRight: '15px',
                  lineHeight: '0',
                  '& > svg': {
                    width: '30px',
                    height: '30px',
                  },
                }}
              >
                <QrCode2 />
              </Box>
            )}
            <Box
              sx={{
                opacity: storageStore.isOutlet() ? '0.3' : '1',
                pointerEvents: storageStore.isOutlet() ? 'none' : 'all',
              }}
            >
              <HeaderCartButtonStyled onClick={handleCartBtnCall}>
                <ShoppingCart />
                <HeaderCartCounterStyled>{total}</HeaderCartCounterStyled>
              </HeaderCartButtonStyled>
            </Box>
          </HeaderSectionStyled>
        </HeaderContainerStyled>
      </HeaderStyled>

      <NavbarComponent />
    </Fragment>
  );
});

export default inject('store', 'models')(observer(HeaderComponent));
