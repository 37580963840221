import getSymbolFromCurrency from 'currency-symbol-map';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { useEffect } from 'react';

import View from '@framework/decorators/View';

import ProductDetailsComponent from '@framework/components/ProductDetails';

import {
  PageContainerStyled,
  LayoutContainerStyled,
  ToolbarStyled,
  BackButtonStyled,
  ButtonStyled,
} from '@framework/styles/App';

import { CartButtonStyled } from '@framework/styles/Cart';

const CartView = View('Cart')(props => {
  const {
    models: { CartModel, CartProcessingModel, ArticleModel },
    store: { helperStore, localesStore },
  } = props;

  const theme = useTheme(),
    navigate = useNavigate(),
    isMobile = helperStore.isMobile();

  const { cart, articles, total, cartMounted } = CartModel,
    { previousLocation, previousLocationSearch, setPreviousLocation, setPreviousLocationSearch } = helperStore;

  useEffect(() => {
    cartMounted();
  }, [cart]);

  useEffect(() => {
    CartProcessingModel.register(navigate);
  }, []);

  return (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.cartAnimationDirection)}>
      <LayoutContainerStyled $scrollable>
        <ToolbarStyled $centered>
          <BackButtonStyled
            onClick={e => {
              e.preventDefault();

              if (previousLocation === '/cart') {
                setPreviousLocation('/');
                setPreviousLocationSearch('');
              }

              helperStore.cartAnimationDirection = helperStore.Direction.Rtl;

              navigate(previousLocation + previousLocationSearch);
            }}
          >
            {localesStore.translate('placeholder.label.back')}
          </BackButtonStyled>
          <h2>{localesStore.translate('page.title.cart')}</h2>
          {!isMobile && (
            <Box display="flex" flexDirection="column" position="absolute" right="0">
              <Box>{`${localesStore.translate('cart.label.price')}:`}</Box>
              <Box display="flex" mt="10px" textAlign="right" justifyContent="flex-end">
                {CartModel.total + ' ' + localesStore.translate('placeholder.label.article')}
                <Box ml="10px" fontWeight={600}>
                  {CartModel.cart
                    .reduce((acc, next) => acc + next.price * next.quantity, 0)
                    .toFixed(2)
                    .replace('.', ',') +
                    ' ' +
                    (CartModel.articles?.[0]?.currency ? getSymbolFromCurrency(CartModel.articles?.[0]?.currency) : '')}
                </Box>
              </Box>
            </Box>
          )}
        </ToolbarStyled>
        {!cart.length && !articles.length ? (
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            overflow="auto"
            bgcolor={theme.application.palette.whitesmoke}
          >
            {localesStore.translate('cart.label.empty')}
          </Box>
        ) : (
          <>
            <Box height="20px" bgcolor={theme.application.palette.whitesmoke}></Box>
            <Box flex={1} height="100%" overflow="auto" p="0 20px" bgcolor={theme.application.palette.whitesmoke}>
              {articles?.map(item => (
                <ProductDetailsComponent
                  key={item.gtin}
                  store={{ helperStore, localesStore }}
                  models={{ CartModel, ArticleModel }}
                  item={{
                    ...item,
                    ...(cart.find(article => article.gtin === item.gtin) || {}),
                  }}
                />
              ))}
            </Box>
            <Box height="20px" bgcolor={theme.application.palette.whitesmoke}></Box>
          </>
        )}

        <Box mt="20px" display="flex" justifyContent={isMobile ? 'center' : 'space-between'} flexWrap="wrap">
          <Box width={isMobile ? '100%' : '300px'} height="60px">
            <CartButtonStyled
              onClick={() => {
                CartProcessingModel.toggleArticles(true);
              }}
            >
              {localesStore.translate('cart.controls.add')}
            </CartButtonStyled>
          </Box>
          <Box width={isMobile ? '100%' : '300px'} m={isMobile ? '20px 0px 0px 0px' : '0px'} height="60px">
            <ButtonStyled
              $fullWidth
              disabled={total === 0}
              onClick={() => {
                if (CartProcessingModel.orderData) {
                  helperStore.cartAnimationDirection = helperStore.Direction.Ltr;
                  helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
                  navigate('/order');
                } else {
                  CartProcessingModel.toggleDestinationWindow(true);
                }
              }}
            >
              {localesStore.translate('cart.controls.next')}
            </ButtonStyled>
          </Box>
        </Box>
      </LayoutContainerStyled>
    </PageContainerStyled>
  );
});

export default inject('models', 'store')(observer(CartView));
