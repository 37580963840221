import { Fragment, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { Close } from '@mui/icons-material';
import { format } from 'date-fns';

import Modal from '@framework/decorators/Modal';

import {
  DialogStyled,
  ModalAnimation,
  DialogTitleStyled,
  ButtonOutlinedStyled,
  ButtonStyled,
  DialogContentStyled,
} from '@framework/styles/App';

import { FormDatePickerStyled } from '@framework/styles/Form';

import {
  AppointmentsListItemStyled,
  AppointmentsListStyled,
  AppointmentsFieldsetStyled,
} from '@framework/styles/Appointments';

const ButtonsGroup = Modal(
  'AppointmentsProcessingModal',
  'ButtonsGroup'
)(props => {
  const {
    store: { localesStore, AppointmentsProcessingModel },
    Status,
    current,
  } = props;

  const {
    editable,
    handleEditableCall,
    handleEditableClose,
    handleRescheduleCall,
    handleCustomerCall,
    handleCancelCall,
    handleConfirmCall,
  } = AppointmentsProcessingModel;

  if (current !== Status.Opened && current !== Status.Confirmed) return null;

  return editable ? (
    <Box
      sx={{
        display: 'flex',
        height: '50px',
        '& > *': {
          width: '250px !important',
        },
        '& > * + *': {
          marginLeft: '20px',
        },
      }}
    >
      <ButtonStyled onClick={handleRescheduleCall}>
        {localesStore.translate('modal.appointment.processing.controls.reschedule.confirm')}
      </ButtonStyled>
      <ButtonOutlinedStyled onClick={handleEditableClose}>
        {localesStore.translate('modal.appointment.processing.controls.reschedule.cancel')}
      </ButtonOutlinedStyled>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '50px',
        '& > *': {
          width: '250px !important',
        },
      }}
    >
      <ButtonOutlinedStyled onClick={handleEditableCall}>
        {localesStore.translate('modal.appointment.processing.controls.reschedule')}
      </ButtonOutlinedStyled>

      {current === Status.Opened && (
        <ButtonOutlinedStyled onClick={handleCustomerCall}>
          {localesStore.translate('modal.appointment.processing.controls.customer')}
        </ButtonOutlinedStyled>
      )}

      <ButtonStyled onClick={current === Status.Opened ? handleConfirmCall : handleCancelCall}>
        {localesStore.translate(
          current === Status.Opened
            ? 'modal.appointment.processing.controls.confirm'
            : 'modal.appointment.processing.controls.cancel'
        )}
      </ButtonStyled>
    </Box>
  );
});

const AppointmentsProcessingModal = Modal('AppointmentsProcessingModal')(props => {
  const {
    models: { AppointmentsProcessingModel, AppointmentsModel },
    store: { helperStore, localesStore },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile();

  const { isToggled, appointment, date, time, editable, handleDateChange, handleTimeChange, handleWindowClose } =
      AppointmentsProcessingModel,
    { Status, Settings, appointmentsByConfirmed } = AppointmentsModel;

  const otherScheduledAppointments = useMemo(
    () => appointmentsByConfirmed.filter(item => item.objectId !== appointment?.objectId),
    [appointmentsByConfirmed, appointment]
  );

  return (
    <DialogStyled
      fullScreen={isMobile}
      fullWidth
      maxWidth="lg"
      open={isToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleWindowClose}
    >
      {isToggled ? (
        <Fragment>
          <DialogTitleStyled>
            <Box
              component="span"
              sx={
                isMobile
                  ? {
                      display: 'block',
                      fontSize: '21px',
                      textAlign: 'center',
                      width: '100%',
                    }
                  : {}
              }
            >
              {localesStore.translate('modal.appointment.processing.label.title') +
                ' - ' +
                localesStore.translate(`modal.appointment.processing.label.${appointment.type}`)}
            </Box>
            <Box sx={{ cursor: 'pointer' }} onClick={handleWindowClose}>
              <Close />
            </Box>
          </DialogTitleStyled>
          <DialogContentStyled grid>
            <Box display="grid" gridTemplateColumns="100%" gridTemplateRows="max-content 1fr max-content" flexGrow={1}>
              <Box display="grid" gridTemplateColumns="1fr 320px" columnGap="7%" width="100%">
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gridTemplateRows="minmax(125px, 1fr) max-content max-content"
                  p="20px 20px 0"
                  rowGap="20px"
                  columnGap="20px"
                  maxHeight="325px"
                  backgroundColor={theme.application.palette.whitesmoke}
                >
                  <AppointmentsFieldsetStyled
                    title={localesStore.translate('modal.appointment.processing.fieldset.label.details')}
                  >
                    <Box
                      sx={{
                        display: 'grid',
                        gridAutoFlow: 'row',
                        rowGap: '5px',
                        width: '100%',
                        '& > *': {
                          width: '100%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                    >
                      <span>{`${localesStore.translate(`placeholder.label.salutation.${appointment.salutation}`)} ${
                        appointment.firstName
                      } ${appointment.lastName}`}</span>
                      <span>{appointment.email}</span>
                      <span>{appointment.phone}</span>
                    </Box>
                  </AppointmentsFieldsetStyled>
                  <AppointmentsFieldsetStyled
                    title={localesStore.translate('modal.appointment.processing.fieldset.label.notes')}
                  >
                    {appointment.note?.length > 80 ? appointment.note.slice(0, 80) + '...' : appointment.note}
                  </AppointmentsFieldsetStyled>
                  <AppointmentsFieldsetStyled
                    title={localesStore.translate('modal.appointment.processing.fieldset.label.status')}
                  >
                    {localesStore.translate(`appointments.status.${appointment.status}`)}
                  </AppointmentsFieldsetStyled>
                  <AppointmentsFieldsetStyled title={''}>
                    {''}
                  </AppointmentsFieldsetStyled>
                  <FormDatePickerStyled
                    variant="date"
                    onChange={({ target }) => handleDateChange(target.value)}
                    value={date}
                    settings={{ ...Settings, time }}
                    disabled={!editable}
                  />
                  <FormDatePickerStyled
                    variant="time"
                    onChange={({ target }) => handleTimeChange(target.value)}
                    value={time}
                    settings={{ ...Settings, date }}
                    disabled={!editable}
                  />
                </Box>
                <Box p={isMobile ? '0px' : '10px 0px 0px 10px'} display="flex" flexDirection="column" width="100%">
                  <Box component="h2" fontWeight="normal" mb="10px" fontSize="20px">
                    {localesStore.translate('modal.appointment.form.label.scheduled')}
                  </Box>
                  <Box
                    position="relative"
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                    overflow="hidden auto"
                    mb="10px"
                    width="100%"
                  >
                    {Boolean(otherScheduledAppointments.length) ? (
                      <AppointmentsListStyled>
                        {otherScheduledAppointments
                          .filter(item => item.objectId !== appointment.objectId)
                          .map(item => (
                            <AppointmentsListItemStyled key={item.objectId}>
                              <span>{format(+item.appointmentDate, 'dd.MM.yyyy')}</span>
                              <span>
                                {format(+item.appointmentDate, 'HH:mm') +
                                  ' ' +
                                  localesStore.translate('placeholder.label.time')}
                              </span>
                            </AppointmentsListItemStyled>
                          ))}
                      </AppointmentsListStyled>
                    ) : (
                      <Box
                        width={isMobile ? '100%' : '280px'}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        flexGrow={1}
                        color={theme.application.palette.gray}
                      >
                        {localesStore.translate('modal.appointment.form.label.empty')}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box display="flex" justifyContent="center" alignItems="center" textAlign="center">
                {localesStore.translate('modal.appointment.processing.label.description')}
              </Box>

              <ButtonsGroup
                store={{
                  localesStore,
                  helperStore,
                  AppointmentsProcessingModel,
                }}
                current={appointment.status}
                Status={Status}
              />
            </Box>
          </DialogContentStyled>
        </Fragment>
      ) : null}
    </DialogStyled>
  );
});

export default inject('store', 'models')(observer(AppointmentsProcessingModal));
