import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const Home = memo(({ width, height, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width ?? '116'} height={height ?? '132'} viewBox="0 0 116 132">
    <path
      fillRule="evenodd"
      fill={fill ?? '#000000'}
      d="M115.984 43.221l-.008-.018-.039-.146-.027-.099-.019-.067-.008-.018a2.063 2.063 0 0 1-.065-.123v-.002l.001.002-.019-.029-.064-.092-.037-.057-24.493-31.057a1.225 1.225 0 0 0-.973-.475L62.571 0l.105 11.04H25.767c-.374 0-.738.177-.975.477L.301 42.572a.758.758 0 0 1-.037.055c-.024.038-.046.07-.065.095l-.034.051-.006.01-.001.002-.043.085-.025.092-.027.096-.047.162a1.107 1.107 0 0 0-.016.135v87.416C0 131.448.552 132 1.23 132h113.539c.69 0 1.231-.54 1.231-1.229V43.355c0-.027-.007-.079-.016-.134zm-4.43-1.956l-46.688-18.37-.211-19.681 24.983 10.259 21.916 27.792zM26.388 13.473h30.394v28.653H3.764l22.624-28.653zm87.151 116.068H2.461V44.586h111.078v84.955z"
    />
  </svg>
));

export default Icon('Home')(Home);
