import { css, styled } from '@mui/material';
import { forwardRef, useMemo, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { ButtonStyled, DialogContentStyled } from '@framework/styles/App';

import ZoomImage from '@framework/components/ZoomImage';
import Styled from '@framework/decorators/Styled';

export const ArticleContentStyled = Styled(
  'Article',
  'ArticleContentStyled'
)(styled(DialogContentStyled)`
  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      padding: 0 20px;
      overflow-y: auto;
    }
  `}
`);

export const ArticleButtonStyled = Styled(
  'Article',
  'ArticleButtonStyled'
)(styled(ButtonStyled)`
  width: 100%;
  text-align: left;
  padding: 10px;
  font-weight: normal;
  font-size: 16px;

  & span {
    display: block;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ theme: { application }, $isAvailabilityScreen }) => css`
    color: ${$isAvailabilityScreen ? application.palette.white : application.palette.black};
    background-color: ${$isAvailabilityScreen ? application.palette.black : application.palette.white};
  `}

  ${({ theme: { application }, $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
`);

export const ArticleImageStyled = Styled(
  'Article',
  'ArticleImageStyled'
)(styled(
  forwardRef(({ className, src, alternateSrc, isResizable }, forwardedRef) => {
    const [sizes, setSizes] = useState(null);

    const observer = useMemo(
      () =>
        new ResizeObserver(entries => {
          if (!entries.length) return;

          const { contentRect } = entries[0];

          setSizes({
            width: contentRect.width,
            height: contentRect.height,
          });
        }),
      []
    );

    return (
      <div
        className={className}
        ref={ref => {
          if (isResizable && ref && !sizes) {
            setSizes({
              width: ref.offsetWidth,
              height: ref.offsetHeight,
            });
            observer.observe(ref);
          }
          if (typeof forwardedRef === 'function') forwardedRef(ref);
        }}
      >
        {isResizable && sizes ? (
          <ZoomImage src={alternateSrc} scale={isResizable ? 4 : 1} {...sizes} />
        ) : (
          <img src={src} alt="" />
        )}
      </div>
    );
  })
)`
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }

  ${({ theme: { application } }) => css`
    & img {
      @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
        object-position: center;
      }
    }
  `}
`);

export const AvailabilityBlockStyled = Styled(
  'Article',
  'AvailabilityBlockStyled'
)(styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 12px 0 0;
  margin-right: 20px;
  height: calc(100% - 40px);

  &:after {
    height: 20px;
    width: 20px;
    position: absolute;
    content: '';
    border-top-width: 0px;
    border-left-width: 0px;
    right: -12px;
    top: 50%;
    transform: translateY(-50%) rotate(315deg);
  }

  ${({ theme: { application } }) => css`
    border-right: 2px solid ${application.palette.whitesmoke};

    &:after {
      background: ${application.palette.white};
      border: 2px solid ${application.palette.whitesmoke};
    }

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      padding: 0px;
      margin-right: 0px;
      border-right: none;

      &:after {
        display: none;
      }
    }
  `}
`);

export const AvailabilityButtonStyled = Styled(
  'Article',
  'AvailabilityButtonStyled'
)(styled(ButtonStyled)`
  text-align: left;
  padding: 10px 40px;
  font-weight: normal;
  font-size: 16px;

  & span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
    background-color: ${application.palette.white};
  `}
`);
