import { inject, observer } from 'mobx-react';
import { Box, Dialog, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';

import CardIcon from '@framework/icons/Card';
import CustomerIcon from '@framework/icons/Customer';
import GuestIcon from '@framework/icons/Guest';

import {
  DialogStyled,
  ModalAnimation,
  BackButtonStyled,
  ButtonOutlinedStyled,
  DialogContentStyled,
  DialogTitleStyled,
} from '@framework/styles/App';

import Modal from '@framework/decorators/Modal';

const CartCustomerModal = Modal('CartCustomerModal')(props => {
  const {
    store: { helperStore, localesStore, isKioskMode },
    models: { CartProcessingModel, CartModel },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile();

  const {
    Customer: { Editor, Creation, Guest },
    isCustomerToggled,
    handleCustomerClose,
    handleDestinationBackCall,
    chooseCustomerVariant,
    options,
  } = CartProcessingModel;

  return isMobile ? (
    <Dialog
      fullScreen
      open={isCustomerToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleCustomerClose}
    >
      <DialogTitleStyled>
        <Box position="relative" display="flex" alignItems="center">
          {options.type !== 'reservation' && (
            <BackButtonStyled
              to=""
              onClick={e => {
                e.preventDefault();
                handleDestinationBackCall();
              }}
            />
          )}
          <Box component="span" pl={options.type !== 'reservation' ? '40px' : '0px'} fontSize={24}>
            {localesStore.translate(`modal.cartProcessing.customer.label.${options.type ?? 'title'}`)}
          </Box>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={handleCustomerClose}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box display="grid" rowGap="20px">
          <ButtonOutlinedStyled
            $icon
            onClick={() => {
              if (isKioskMode) {
                CartModel.generateCode(options.product).then(url => {
                  helperStore.toggleNotification({
                    type: 'inverse',
                    title: localesStore.translate('placeholder.label.notice'),
                    body: url ? (
                      <Box
                        component="img"
                        src={url}
                        sx={{
                          display: 'block',
                          width: '140px',
                          height: '140px',
                          marginBottom: '10px',
                        }}
                      />
                    ) : null,
                    description: localesStore.translate('modal.kiosk.warn.label.description'),
                    confirm: localesStore.translate('placeholder.label.ok'),
                    onConfirm: () => {
                      helperStore.toggleNotification(null);
                    },
                  });
                });
              } else {
                chooseCustomerVariant(Editor);
              }
            }}
          >
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <CardIcon height="70" width="70" />
            </Box>
            {localesStore.translate('modal.cartProcessing.customer.controls.editor')}
          </ButtonOutlinedStyled>
          <ButtonOutlinedStyled $icon onClick={() => chooseCustomerVariant(Creation)}>
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <CustomerIcon height="70" width="70" />
            </Box>
            {localesStore.translate('modal.cartProcessing.customer.controls.creation')}
          </ButtonOutlinedStyled>
          <ButtonOutlinedStyled $icon onClick={() => chooseCustomerVariant(Guest)}>
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <GuestIcon height="70" width="70" />
            </Box>
            {localesStore.translate('modal.cartProcessing.customer.controls.guest')}
          </ButtonOutlinedStyled>
        </Box>
      </DialogContentStyled>
    </Dialog>
  ) : (
    <DialogStyled
      fullWidth
      maxWidth="lg"
      open={isCustomerToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleCustomerClose}
    >
      <DialogTitleStyled>
        <Box position="relative" display="flex" alignItems="center">
          {options.type !== 'reservation' && (
            <BackButtonStyled
              to=""
              onClick={e => {
                e.preventDefault();
                handleDestinationBackCall();
              }}
            />
          )}
          <Box component="span" pl={options.type !== 'reservation' ? '40px' : '0px'}>
            {localesStore.translate(`modal.cartProcessing.customer.label.${options.type ?? 'title'}`)}
          </Box>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={handleCustomerClose}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          gridTemplateRows="100%"
          height="100%"
          columnGap="20px"
          flexGrow={1}
        >
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <CardIcon width="150" height="150" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled
                onClick={() => {
                  if (isKioskMode) {
                    CartModel.generateCode(options.product).then(url => {
                      helperStore.toggleNotification({
                        type: 'inverse',
                        title: localesStore.translate('placeholder.label.notice'),
                        body: url ? (
                          <Box
                            component="img"
                            src={url}
                            sx={{
                              display: 'block',
                              width: '140px',
                              height: '140px',
                              marginBottom: '10px',
                            }}
                          />
                        ) : null,
                        description: localesStore.translate('modal.kiosk.warn.label.description'),
                        confirm: localesStore.translate('placeholder.label.ok'),
                        onConfirm: () => {
                          helperStore.toggleNotification(null);
                        },
                      });
                    });
                  } else {
                    chooseCustomerVariant(Editor);
                  }
                }}
              >
                {localesStore.translate('modal.cartProcessing.customer.controls.editor')}
              </ButtonOutlinedStyled>
            </Box>
          </Box>
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <CustomerIcon width="150" height="150" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled onClick={() => chooseCustomerVariant(Creation)}>
                {localesStore.translate('modal.cartProcessing.customer.controls.creation')}
              </ButtonOutlinedStyled>
            </Box>
          </Box>
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <GuestIcon width="150" height="150" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled onClick={() => chooseCustomerVariant(Guest)}>
                {localesStore.translate('modal.cartProcessing.customer.controls.guest')}
              </ButtonOutlinedStyled>
            </Box>
          </Box>
        </Box>
      </DialogContentStyled>
    </DialogStyled>
  );
});

export default inject('store', 'models')(observer(CartCustomerModal));
