import Styled from '@framework/decorators/Styled';

const RootTheme = Styled('RootTheme')({
  breakpoints: {
    xs: '(max-width: 600px)',
    sm: '(min-width: 600px) and (max-width: 900px)',
    md: '(min-width: 900px) and (max-width: 1200px)',
    lg: '(min-width: 1200px) and (max-width: 1536px)',
  },
  palette: {
    white: '#FFFFFF',
    black: '#000000',
    danger: '#DD5353',
    gray: '#A0A0A0',
    whitesmoke: '#F3F3F3',
    grayline: '#B2B2B2',
    disabled: '#777777',
    lightgray: '#CECECE',
    darkgray: '#ADADAD',
    gainsboro: '#D8D8D8',
    goldenrod: '#DCB73B',
    darkgreen: '#17710F',
    aliceblue: '#EDF1F9',
    firebrick: '#AF0916',
    grayboro: '#797979',
    blue: 'rgb(144, 202, 249)',
  },
});

export default RootTheme;
