import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const ComplaintOnline = memo(({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000" height={height}
       width={width} version="1.1" id="Capa_1" viewBox="0 0 60 60" xmlSpace="preserve">
    <g>
      <path
        d="M31.5,36v24h24V36H31.5z M53.5,58h-20V38h9v12.586l-4.293-4.293l-1.414,1.414l6.707,6.707l6.707-6.707l-1.414-1.414   L44.5,50.586V38h9V58z" />
      <path d="M11.333,18h13c0.552,0,1-0.447,1-1s-0.448-1-1-1h-13c-0.552,0-1,0.447-1,1S10.781,18,11.333,18z" />
      <path d="M11.333,46c-0.552,0-1,0.447-1,1s0.448,1,1,1h11c0.552,0,1-0.447,1-1s-0.448-1-1-1H11.333z" />
      <path d="M40.333,22h-29c-0.552,0-1,0.447-1,1s0.448,1,1,1h29c0.552,0,1-0.447,1-1S40.886,22,40.333,22z" />
      <path d="M28.333,34h-17c-0.552,0-1,0.447-1,1s0.448,1,1,1h17c0.552,0,1-0.447,1-1S28.886,34,28.333,34z" />
      <path d="M26.333,41c0-0.553-0.448-1-1-1h-14c-0.552,0-1,0.447-1,1s0.448,1,1,1h14C25.886,42,26.333,41.553,26.333,41z" />
      <path d="M11.333,28c-0.552,0-1,0.447-1,1s0.448,1,1,1h14c0.552,0,1-0.447,1-1s-0.448-1-1-1H11.333z" />
      <path d="M40.333,28h-7c-0.552,0-1,0.447-1,1s0.448,1,1,1h7c0.552,0,1-0.447,1-1S40.886,28,40.333,28z" />
      <path
        d="M28.63,28.29c-0.19,0.189-0.3,0.449-0.3,0.71c0,0.26,0.11,0.52,0.3,0.71c0.18,0.18,0.44,0.29,0.7,0.29   c0.27,0,0.53-0.11,0.71-0.29c0.19-0.19,0.29-0.45,0.29-0.71c0-0.261-0.1-0.521-0.29-0.71C29.67,27.92,29,27.92,28.63,28.29z" />
      <path
        d="M27.5,56h-21V8h7v2c0,0.553,0.448,1,1,1h23c0.552,0,1-0.447,1-1V8h7v23c0,0.553,0.448,1,1,1s1-0.447,1-1V7   c0-0.553-0.448-1-1-1h-8V4c0-0.553-0.448-1-1-1h-6V1c0-0.553-0.448-1-1-1h-9c-0.552,0-1,0.447-1,1v2h-6c-0.552,0-1,0.447-1,1v2h-8   c-0.552,0-1,0.447-1,1v50c0,0.553,0.448,1,1,1h22c0.552,0,1-0.447,1-1S28.052,56,27.5,56z M22.5,2h7v2v2h-7V4V2z M15.5,5h5v2   c0,0.553,0.448,1,1,1h9c0.552,0,1-0.447,1-1V5h5v2v2h-21V7V5z" />
    </g>
  </svg>
));

export default Icon('ComplaintOnline')(ComplaintOnline);
