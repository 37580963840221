import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const Barcode = memo(() => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270 255" width="24px" height="24px">
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      d="M247.5 254.999V0H270v254.999h-22.5zM172.5 0h37.499v254.999H172.5V0zm-52.499 0H135v254.999h-14.999V0zM75 0h22.5v254.999H75V0zM0 0h52.5v254.999H0V0z"
      fill="#000"
    />
  </svg>
));

export default Icon('Barcode')(Barcode);
