import i18nIsoCountries from 'i18n-iso-countries';
import { inject, observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import View from '@framework/decorators/View';

import Loader from '@framework/icons/Loader';
import Transaction from '@framework/icons/Transaction';

import SelectComponent from '@framework/components/Select';

import { PageContainerStyled, LayoutContainerStyled, ToolbarStyled, BackButtonStyled } from '@framework/styles/App';

import {
  DetailsContainerStyled,
  DetailsEditButtonStyled,
  DetailsInfoStyled,
  DetailsListItemStyled,
  DetailsListStyled,
  DetailsShoppingProductListStyled,
  DetailsTabStyled,
  DetailsTitleStyled,
  DetailsShoppingProductStyled,
} from '@framework/styles/CustomerDetails';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const CustomerDetailsView = View('CustomerDetails')(props => {
  const {
    models: { CustomerDetailsModel, CustomerProductsModel, CustomerTransactionModel, FormModel },
    store: { helperStore, localesStore },
  } = props;

  const translates = i18nIsoCountries.getNames(localesStore.currentLocale, {
      select: 'official',
    }),
    navigate = useNavigate(),
    location = useLocation(),
    theme = useTheme(),
    isMobile = helperStore.isMobile();

  const {
      Tabs: { Recently, History },
      isLoading,
      tab,
      list,
      customerId,
      userDetailsMounted,
      userData,
      transactions,
      transactionDateIndex,
      transactionDates,
      register,
      reset,
      setUserData,
      setCurrentTab,
      fetchShoppingHistory,
      handleTransactionDateChange,
    } = CustomerDetailsModel,
    { setTransaction } = CustomerTransactionModel;

  useEffect(() => {
    register(navigate, location);
    return () => {
      setUserData(null);
      reset();
    };
  }, []);
  useEffect(() => userDetailsMounted(), [userData]);
  useEffect(() => {
    if (userData) fetchShoppingHistory(userData?.id ?? userData?.customerId);
  }, [transactionDateIndex]);

  const transactionDatesData = useMemo(
    () => [
      {
        value: transactionDates[0],
        label: '3 ' + localesStore.translate('placeholder.label.month'),
      },
      {
        value: transactionDates[1],
        label: '6 ' + localesStore.translate('placeholder.label.month'),
      },
      {
        value: transactionDates[2],
        label: '12 ' + localesStore.translate('placeholder.label.month'),
      },
    ],
    [transactionDates]
  );

  const handleUserEdit = e => {
    e.preventDefault();

    helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
    helperStore.screensAnimationDirection = helperStore.Direction.Ltr;

    FormModel.setFormSettings(FormModel.Type.Editor, {
      prevData: userData,
      data: {
        salutation: userData.salutation || '',
        birthday: userData.birthday ? format(+userData.birthday, 'yyyy-MM-dd') : '',
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        phone: userData.phone,
        street: userData.address?.street || '',
        streetNumber: userData.address?.streetNumber || '',
        city: userData.address?.city || '',
        addressLine1: userData.address?.addressLine1 || '',
        addressLine2: userData.address?.addressLine2 || '',
        email: userData.address?.email || '',
        postalCode: userData.address?.postalCode || '',
        store: userData?.favouriteStoreId || '',
        language: userData?.preferredLanguage ?? 'DE',
        country: userData.address?.country ?? localesStore.currentLocale,
        isEmailContactAllowed: Boolean(userData.isEmailContactAllowed),
        isPhoneContactAllowed: Boolean(userData.isPhoneContactAllowed),
        isSMSMMSContactAllowed: Boolean(userData.isSMSMMSContactAllowed),
        armLength: userData?.additionalProperties?.armLength || '',
        cut: userData?.additionalProperties?.cut || '',
        size: userData?.additionalProperties?.size || '',
        userAgreementAllowed: true,
      },
      exclude: ['signature'],
      onResolve: customerId => {
        navigate(`/customer/details?${customerId}`);
      },
    });

    navigate(`/customer/edit?${userData.id}`);
  };

  return (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.screensAnimationDirection)}>
      <LayoutContainerStyled $fullHeight>
        <ToolbarStyled $centered>
          <BackButtonStyled
            onClick={e => {
              e.preventDefault();

              helperStore.baseAnimationDirection = helperStore.Direction.Ltr;
              helperStore.screensAnimationDirection = helperStore.Direction.Rtl;

              reset();

              navigate('/customer/search');
            }}
          >
            {localesStore.translate('placeholder.label.back')}
          </BackButtonStyled>
          <h2>
            {isMobile
              ? localesStore.translate('page.title.customer.cockpit')
              : localesStore.translate('page.title.prefix') + localesStore.translate('page.title.customer.cockpit')}
          </h2>
        </ToolbarStyled>
        {userData &&
          (isMobile ? (
            <Box sx={{ padding: '10px' }}>
              <Box sx={{ paddingBottom: '20px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <DetailsTitleStyled>
                    {`${localesStore.translate(`placeholder.label.salutation.${userData.salutation}`)} ${
                      userData.formattedFields?.fullName || ''
                    }`}
                  </DetailsTitleStyled>

                  {userData?.formattedFields?.birthday && (
                    <Box component="span" sx={{ fontSize: '14px' }}>
                      {localesStore.translate('customer.details.label.birthday') +
                        ' ' +
                        userData?.formattedFields.birthday}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: 'grid', rowGap: '20px' }}>
                <Box>
                  <DetailsInfoStyled>
                    <h3>{localesStore.translate('customer.details.label.address')}</h3>
                    {(userData?.address.street || userData?.address.streetNumber) && (
                      <Box mb="10px">
                        {userData?.address.street} {userData?.address.streetNumber}
                      </Box>
                    )}
                    {(userData?.address.postalCode || userData?.address.city) && (
                      <Box mb="10px">
                        {userData?.address.postalCode} {userData?.address.city}
                      </Box>
                    )}
                    <span>{translates[userData?.address.country]}</span>
                  </DetailsInfoStyled>
                </Box>
                <Box>
                  <DetailsInfoStyled>
                    <h3>{localesStore.translate('customer.details.label.contact')}</h3>
                    <Box mb="10px">{userData?.email}</Box>
                    <Box mb="10px">{userData?.phone}</Box>
                    <Box>{userData?.address.addressLine2}</Box>
                  </DetailsInfoStyled>
                </Box>
              </Box>
              <Box sx={{ marginTop: '20px' }}>
                <DetailsEditButtonStyled onClick={handleUserEdit}>
                  {localesStore.translate('customer.details.controls.edit')}
                </DetailsEditButtonStyled>
              </Box>
              <Box
                sx={{
                  padding: '20px',
                  background: theme.application.palette.whitesmoke,
                }}
              >
                <DetailsListStyled>
                  <DetailsListItemStyled>
                    <span>{localesStore.translate('customer.details.label.customer')}</span>
                    {userData?.id}
                  </DetailsListItemStyled>
                  <DetailsListItemStyled>
                    <span>{localesStore.translate('customer.details.label.branch')}</span>
                    {userData?.formattedFields?.favoriteStore || ''}
                  </DetailsListItemStyled>
                  <DetailsListItemStyled>
                    <span>{localesStore.translate('customer.details.label.status')}</span>-
                  </DetailsListItemStyled>
                  <DetailsListItemStyled>
                    <span>{localesStore.translate('customer.details.label.points')}</span>-
                  </DetailsListItemStyled>
                </DetailsListStyled>
              </Box>
              <Box
                sx={{
                  marginTop: '20px',
                  background: theme.application.palette.whitesmoke,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    padding: '20px',
                    flexDirection: 'column',
                    minHeight: '250px',
                  }}
                >
                  <DetailsShoppingProductListStyled>
                    {isLoading ? (
                      <Loader $relative $disableMargin />
                    ) : tab === Recently ? (
                      list.length === 0 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          {localesStore.translate('customer.details.label.transaction')}
                        </Box>
                      ) : (
                        list.map((item, i) => (
                          <DetailsShoppingProductStyled
                            key={i}
                            onClick={() => {
                              CustomerProductsModel.setProduct(item);
                            }}
                          >
                            <img src={item?.productUrl ?? 'images/ig-no-image.png'} alt={item.name} />
                            <p>
                              {localesStore.translate('customer.details.label.purchased_time') +
                                ': ' +
                                format(+item.transactionDate, 'dd.MM.yyyy')}
                            </p>
                          </DetailsShoppingProductStyled>
                        ))
                      )
                    ) : transactions.length === 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        {localesStore.translate('customer.details.label.transaction')}
                      </Box>
                    ) : (
                      transactions.map(transaction => (
                        <Box
                          component="li"
                          onClick={() => setTransaction(transaction)}
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            minWidth: '180px',
                            minHeight: '200px',
                            marginRight: isMobile ? '0px' : '15px',
                            marginTop: isMobile ? '20px' : '0px',
                            padding: '10px',
                            transition: 'background linear 200ms',
                            '&:hover': {
                              background: theme.application.palette.whitesmoke,
                            },
                          }}
                        >
                          <Box sx={{ textAlign: 'center' }}>{transaction.transactionId}</Box>
                          <Transaction count={transaction.transactionPositions.length} />
                          <Box
                            sx={{
                              width: '100%',
                              padding: '5px',
                              textAlign: 'center',
                              color: theme.application.palette.white,
                              background: theme.application.palette.black,
                            }}
                          >
                            {localesStore.translate('customer.details.label.status.purchased')}
                          </Box>
                          <Box sx={{ fontSize: '14px', marginLeft: '0' }}>
                            {transaction.orderedOn
                              ? `${localesStore.translate('customer.details.label.purchased_time')}: ${format(
                                  +transaction.orderedOn,
                                  'dd.MM.yyyy'
                                )}`
                              : null}
                          </Box>
                        </Box>
                      ))
                    )}
                  </DetailsShoppingProductListStyled>
                </Box>
                <Box
                  sx={{
                    zIndex: '1',
                    overflow: 'hidden',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                  }}
                >
                  <DetailsTabStyled $isActive={tab === Recently} onClick={() => setCurrentTab(Recently)}>
                    {localesStore.translate('customer.details.controls.recently')}
                  </DetailsTabStyled>
                  <DetailsTabStyled $isActive={tab === History} onClick={() => setCurrentTab(History)}>
                    {localesStore.translate('customer.details.controls.history')}
                  </DetailsTabStyled>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <Box>
                <DetailsContainerStyled>
                  <Box sx={{ flexGrow: '1' }}>
                    <DetailsTitleStyled>
                      {`${localesStore.translate(`placeholder.label.salutation.${userData.salutation}`)} ${
                        userData?.formattedFields?.fullName || ''
                      }`}
                    </DetailsTitleStyled>

                    {userData?.formattedFields?.birthday && (
                      <Box component="span" fontSize={14}>
                        {localesStore.translate('customer.details.label.birthday') +
                          ' ' +
                          userData?.formattedFields.birthday}
                      </Box>
                    )}

                    <Box sx={{ display: 'flex', marginTop: '30px' }}>
                      <Box sx={{ flexGrow: '1' }}>
                        <DetailsInfoStyled>
                          <h3>{localesStore.translate('customer.details.label.address')}</h3>
                          {(userData?.address.street || userData?.address.streetNumber) && (
                            <Box mb="10px">
                              {userData?.address.street} {userData?.address.streetNumber}
                            </Box>
                          )}
                          {(userData?.address.postalCode || userData?.address.city) && (
                            <Box mb="10px">
                              {userData?.address.postalCode} {userData?.address.city}
                            </Box>
                          )}
                          <span>{translates[userData?.address.country]}</span>
                        </DetailsInfoStyled>
                      </Box>
                      <Box sx={{ flexGrow: '1' }}>
                        <DetailsInfoStyled>
                          <h3>{localesStore.translate('customer.details.label.contact')}</h3>
                          <Box mb="10px">{userData?.email}</Box>
                          <Box mb="10px">{userData?.phone}</Box>
                          <Box>{userData?.address.addressLine2}</Box>
                        </DetailsInfoStyled>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flexGrow: '1',
                      padding: '20px',
                      background: theme.application.palette.whitesmoke,
                    }}
                  >
                    <DetailsListStyled>
                      <DetailsListItemStyled>
                        <span>{localesStore.translate('customer.details.label.customer')}</span>
                        {userData?.id}
                      </DetailsListItemStyled>
                      <DetailsListItemStyled>
                        <span>{localesStore.translate('customer.details.label.branch')}</span>
                        {userData?.formattedFields?.favoriteStore || ''}
                      </DetailsListItemStyled>
                      <DetailsListItemStyled>
                        {/* TODO: Ask about Status, Points */}
                        <span>{localesStore.translate('customer.details.label.status')}</span>-
                      </DetailsListItemStyled>
                      <DetailsListItemStyled>
                        <span>{localesStore.translate('customer.details.label.points')}</span>-
                      </DetailsListItemStyled>
                    </DetailsListStyled>
                  </Box>
                </DetailsContainerStyled>
                <Box
                  sx={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <DetailsEditButtonStyled onClick={handleUserEdit}>
                    {localesStore.translate('customer.details.controls.edit')}
                  </DetailsEditButtonStyled>
                  <Box minWidth="240px">
                    <SelectComponent
                      $isSolid
                      name="transactionDate"
                      quantity={3}
                      data={transactionDatesData}
                      defaultValue={transactionDates[transactionDateIndex]}
                      onChange={handleTransactionDateChange}
                      useDefaultValue
                      noneIndex
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: '15px',
                  background: theme.application.palette.whitesmoke,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    padding: '20px',
                    minHeight: '250px',
                  }}
                >
                  <DetailsShoppingProductListStyled>
                    {isLoading ? (
                      <Loader $relative $disableMargin />
                    ) : tab === Recently ? (
                      list.length === 0 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          {localesStore.translate('customer.details.label.transaction')}
                        </Box>
                      ) : (
                        list.map((item, i) => (
                          <DetailsShoppingProductStyled
                            key={i}
                            onClick={() => {
                              CustomerProductsModel.setProduct(item);
                            }}
                          >
                            <img src={item?.productUrl ?? 'images/ig-no-image.png'} alt={item.name} />
                            <p>
                              {localesStore.translate('customer.details.label.purchased_time') +
                                ': ' +
                                format(+item.transactionDate, 'dd.MM.yyyy')}
                            </p>
                          </DetailsShoppingProductStyled>
                        ))
                      )
                    ) : transactions.length === 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        {localesStore.translate('customer.details.label.transaction')}
                      </Box>
                    ) : (
                      transactions.map(transaction => (
                        <Box
                          component="li"
                          onClick={() => setTransaction(transaction)}
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            minWidth: '180px',
                            marginRight: isMobile ? '0px' : '15px',
                            marginTop: isMobile ? '20px' : '0px',
                            padding: '10px',
                            transition: 'background linear 200ms',
                            '&:hover': {
                              background: theme.application.palette.whitesmoke,
                            },
                          }}
                        >
                          <Box sx={{ textAlign: 'center' }}>{transaction.transactionId}</Box>
                          <Transaction count={transaction.transactionPositions.length} />
                          <Box
                            sx={{
                              width: '100%',
                              padding: '5px',
                              textAlign: 'center',
                              color: theme.application.palette.white,
                              background: theme.application.palette.black,
                            }}
                          >
                            {localesStore.translate('customer.details.label.status.purchased')}
                          </Box>
                          <Box sx={{ fontSize: '14px', marginLeft: '0' }}>
                            {transaction.orderedOn
                              ? `${localesStore.translate('customer.details.label.purchased_time')}: ${format(
                                  +transaction.orderedOn,
                                  'dd.MM.yyyy'
                                )}`
                              : null}
                          </Box>
                        </Box>
                      ))
                    )}
                  </DetailsShoppingProductListStyled>
                </Box>
                <Box
                  sx={{
                    zIndex: '1',
                    overflow: 'hidden',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                  }}
                >
                  <DetailsTabStyled $isActive={tab === Recently} onClick={() => setCurrentTab(Recently)}>
                    {localesStore.translate('customer.details.controls.recently')}
                  </DetailsTabStyled>
                  <DetailsTabStyled $isActive={tab === History} onClick={() => setCurrentTab(History)}>
                    {localesStore.translate('customer.details.controls.history')}
                  </DetailsTabStyled>
                </Box>
              </Box>
            </Box>
          ))}
      </LayoutContainerStyled>
    </PageContainerStyled>
  );
});

export default inject('store', 'models')(observer(CustomerDetailsView));
