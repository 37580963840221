import { observer, inject } from 'mobx-react';
import { Box, Dialog } from '@mui/material';
import { useEffect } from 'react';

import Modal from '@framework/decorators/Modal';

import { DialogWrapperStyled } from '@framework/styles/Loader';
import { ButtonStyled } from '@framework/styles/App';

const QrCodeModal = Modal('QrCodeModal')(props => {
  const {
    store: { localesStore },
    models: { CartModel },
  } = props;

  const { cart, qrcode, isQRToggled, generateCode, handleQrCodeToggle, total } = CartModel;

  useEffect(() => {
    generateCode();
  }, [cart, total]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isQRToggled}
      onClose={handleQrCodeToggle}
      sx={{ zIndex: '100000050 !important' }}
    >
      {qrcode ? (
        <DialogWrapperStyled
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <Box
            component="img"
            src={qrcode}
            sx={{
              display: 'block',
              width: '140px',
              height: '140px',
              marginBottom: '10px',
            }}
          />
          <Box
            sx={{
              fontSize: '18px',
              whiteSpace: 'pre-line',
              textAlign: 'center',
              padding: '10px 20px 30px',
            }}
          >
            {localesStore.translate('modal.qrcode.label.description')}
          </Box>
          <Box display="grid" width="100%" gridTemplateRows="50px">
            <ButtonStyled $fullWidth onClick={handleQrCodeToggle}>
              {localesStore.translate('modal.qrcode.controls.close')}
            </ButtonStyled>
          </Box>
        </DialogWrapperStyled>
      ) : null}
    </Dialog>
  );
});

export default inject('store', 'models')(observer(QrCodeModal));
