import { Link } from 'react-router-dom';
import { styled, css, Container, keyframes, Box, Input } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const CockpitToolbarStyled = Styled(
  'Cockpit',
  'CockpitToolbarStyled'
)(styled(Container)`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  width: 100%;
  min-height: 80px;

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      grid-template-columns: 1fr max-content;
    }
  `}
`);

export const CockpitToolbarBoxStyled = Styled(
  'Cockpit',
  'CockpitToolbarBoxStyled'
)(styled('div')`
  display: flex;
  align-items: center;
  flex-grow: 1;

  &[data-section='employer'] {
    justify-content: flex-start;

    & svg {
      cursor: pointer;
      margin: 0 15px;
      font-size: 24px;
    }
  }

  &[data-section='heading'] {
    justify-content: center;

    h2 {
      font-size: 28px;
    }
  }

  &[data-section='scanner'] {
    justify-content: flex-end;

    span {
      margin-right: 10px;
    }
  }

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      &[data-section='employer'] {
        display: none;
      }

      &[data-section='heading'] {
        justify-content: flex-start;
      }

      &[data-section='scanner'] {
        & span {
          display: none;
        }
      }
    }
  `}
`);

export const CockpitSearchStyled = Styled(
  'Cockpit',
  'CockpitSearchStyled'
)(styled(Input)`
  width: 100%;
  margin-bottom: 20px;

  & > input {
    &::placeholder {
      opacity: 0.7;
    }
  }

  &::before {
    display: none;
  }

  ${({ theme: { application } }) => css`
    color: ${application.palette.white};

    &::after {
      transform: scale(1);
      border-color: ${application.palette.white};
    }
  `}
`);

export const CockpitGridStyled = Styled(
  'Cockpit',
  'CockpitGridStyled'
)(styled(Container)`
  display: grid;
  padding-top: 20px;
  padding-bottom: 20px;
  grid-auto-columns: 1fr;
  grid-gap: 30px;
  flex-grow: 1;
  grid-template-areas: 'customer catalog processes';
  grid-template-rows: 100%;

  & [data-section='customer'] {
    grid-area: customer;
  }

  & [data-section='catalog'] {
    grid-area: catalog;
  }

  & [data-section='processes'] {
    grid-area: processes;
  }

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      grid-template-areas: 'processes' 'customer' 'catalog';
      grid-template-rows: unset;
    }

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm}, ${application.breakpoints.md} {
      & [data-section='catalog'],
      & [data-section='customer'] {
        min-height: 460px;
      }
    }
  `}
`);

export const CockpitModuleStyled = Styled(
  'Cockpit',
  'CockpitModuleStyled'
)(styled('div')`
  display: flex;
  padding: 15px 25px 25px 25px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  ${props =>
    props.$url
      ? css`
          background-image: url(${props.$url});
          background-repeat: no-repeat;
          background-position: top center;
          background-size: cover;
        `
      : css`
          background-color: ${props.theme.application.palette.white};
        `}
`);

export const CockpitEmployerStyled = Styled(
  'Cockpit',
  'CockpitEmployerStyled'
)(styled('div')`
  font-weight: 600;

  & span {
    font-weight: 400;
    padding-right: 5px;
  }
`);

export const CockpitModuleTitleStyled = Styled(
  'Cockpit',
  'CockpitModuleTitleStyled'
)(styled('h3')`
  padding: 10px 0;
  font-size: 22px;
  font-weight: 700;

  ${({ theme: { application } }) => css`
    color: ${application.palette.white};
  `}
`);

export const CockpitModuleTileGroupStyled = Styled(
  'Cockpit',
  'CockpitModuleTileGroupStyled'
)(styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  height: 150px;
`);

export const CockpitModuleTileStyled = Styled(
  'Cockpit',
  'CockpitModuleTileStyled'
)(styled(Link)`
  position: relative;
  padding: 20px;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  word-break: break-word;
  vertical-align: middle;
  transition: background-color linear 200ms;

  & > span {
    margin-top: 3px;
  }

  ${props =>
    props.$isDirection &&
    css`
      & > span {
        margin-top: 10px;
      }

      flex-direction: column;
    `}

  ${props =>
    props.$isLoading &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
    background-color: ${application.palette.white};
  `}
`);

export const CockpitModuleButtonStyled = Styled(
  'Cockpit',
  'CockpitModuleButtonStyled'
)(styled(CockpitModuleTileStyled)`
  margin-top: 10px;
  height: 50px;
`);

export const CockpitModuleTileLoaderStyled = Styled(
  'Cockpit',
  'CockpitModuleTileLoaderStyled'
)(styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & > span {
    position: relative;
    padding-left: 90px;
    padding-right: 10px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      width: 70px;
      height: 70px;
      left: 0;
      top: calc(50% - 35px);
      border-radius: 50%;
    }

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      width: 70px;
      height: 70px;
      border: 4px solid transparent;
      left: 0;
      top: calc(50% - 35px);
      border-right-color: #000;
      border-radius: 50%;
      animation: ${keyframes`
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
      `} linear 1400ms infinite;
    }

    ${({ theme: { application } }) => css`
      &::before {
        border: 4px solid ${application.palette.gray};
      }

      &::after {
        border-right-color: ${application.palette.black};
      }
    `}
  }
`);

export const CockpitProcessesListStyled = Styled(
  'Cockpit',
  'CockpitProcessesListStyled'
)(styled('ul')`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;

  & li + li {
    margin-top: 5px;
  }

  ${props =>
    props.$isLoading
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `};
`);

export const CockpitProcessesListItemStyled = Styled(
  'Cockpit',
  'CockpitProcessesListItemStyled'
)(styled(({ children, to, className, onClick }) => (
  <li>
    <Link to={to || '/orders'} className={className} onClick={onClick}>
      {children}
    </Link>
  </li>
))`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    font-weight: bold;
  }

  ${({ theme: { application } }) => css`
    color: ${application.palette.white};
    border-bottom: 2px solid ${application.palette.white};
  `}
`);

export const CockpitRequestsStyled = Styled(
  'Cockpit',
  'CockpitRequestsStyled'
)(styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 3px 15px;
  margin-top: 10px;
  margin-bottom: 30px;

  & > span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    border-radius: 50%;
    width: 32px;
    height: 32px;
  }

  ${props =>
    props.$isLoading
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `};

  ${({ theme: { application } }) => css`
    color: ${application.palette.white};
    border: 2px solid ${application.palette.white};

    & > span {
      color: ${application.palette.black};
      background-color: ${application.palette.white};
    }
  `}
`);
