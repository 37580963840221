import { action, makeObservable, observable } from 'mobx';

import { BrowserCodeReader, BrowserMultiFormatOneDReader, BrowserQRCodeReader } from '@zxing/browser';

import Model from '@framework/decorators/Model';

export class BarcodeScannerModel {
  isToggled = false;
  isCameraReady = false;

  subtitle = null;

  inputValue = '';
  stream = null;

  streams = [];

  codeReader = {};
  cameraList = [];
  currentCamera = 0;

  resolve = () => {};
  reject = () => {};

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
      isCameraReady: observable,
      subtitle: observable,
      stream: observable,
      inputValue: observable,
      currentCamera: observable,
      cameraList: observable,
      codeReader: observable,
      streams: observable,
    });
  }

  barcodeScannerMounted = action(videoRef => {
    if (!videoRef || !this.cameraList.length) return;

    this.codeReader
      .decodeFromVideoDevice(this.getCurrentCamera(), videoRef, scannedValue => {
        if (!scannedValue) return;
        this.resolve(scannedValue?.text);
      })
      .then(stream => {
        this.streams.push(stream);

        if (this.QRReader) {
          this.QRReader.decodeFromVideoDevice(this.getCurrentCamera(), videoRef, scannedValue => {
            if (!scannedValue) return;

            const isCartCode = scannedValue?.text?.includes('-SCTRSF-');

            if (isCartCode) {
              const isReservationCode = scannedValue?.text?.includes('SINGLE');

              if (isReservationCode) {
                const articlesString = scannedValue?.text?.split('-SINGLE-SCTRSF-');
                this.resolve(articlesString[1]);
              } else {
                const articlesString = scannedValue?.text?.split('-SCTRSF-');

                const articles = articlesString[1]?.split(';').reduce((acc, articleString) => {
                  if (!articleString) return acc;

                  const [gtin, quantity, articleNumber, price, currency] = articleString?.split(':');

                  acc.push({
                    gtin,
                    quantity,
                    articleNumber,
                    price: parseFloat(price.replace(',', '.')),
                    currency,
                  });

                  return acc;
                }, []);

                this.resolve({ type: 'ShoppingCart', data: articles });
              }
            } else {
              this.resolve(scannedValue?.text);
            }
          }).then(stream => {
            this.streams.push(stream);
            this.enableStreaming(stream);
          });
        } else {
          this.enableStreaming(stream);
        }
      });
  });

  init = action(async subtitle => {
    const { store } = this.rootModels;
    this.subtitle = subtitle;

    try {
      await this.getCameraAccess();
      this.codeReader = new BrowserMultiFormatOneDReader();
      this.QRReader = new BrowserQRCodeReader();
      this.cameraList = await BrowserCodeReader.listVideoInputDevices();
      this.isCameraReady = true;
    } catch (error) {
      if (store.debug) console.error(error);
    } finally {
      this.isToggled = true;
    }
  });

  register = action((resolve, reject) => {
    this.resolve = value => {
      this.closeBarcodeScannerPromise();
      resolve(value);
    };

    this.reject = value => {
      this.closeBarcodeScannerPromise();
      reject(value);
    };
  });

  enableStreaming = action(stream => {
    this.stream = stream;
  });

  getCameraAccess = action(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    stream.getTracks().forEach(track => track.stop());
  });

  switchCamera = action(() => {
    this.currentCamera = this.currentCamera ? 0 : 1;
  });

  getCurrentCamera = action(() => {
    return this.cameraList[this.currentCamera]?.deviceId;
  });

  callBarcodeScannerPromise = action((subtitle, QRSupport = false) => {
    const {
      store: { debug, networkStore },
    } = this.rootModels;

    if (networkStore.isNativeThread) {
      return new Promise(async (resolve, reject) => {
        try {
          const { code } = await networkStore.webview('scanner');

          const isCartCode = code?.includes('-SCTRSF-');

          if (isCartCode) {
            const isReservationCode = code?.includes('SINGLE');

            if (isReservationCode) {
              const articlesString = code?.split('-SINGLE-SCTRSF-');
              resolve(articlesString[1]);
            } else {
              const articlesString = code?.split('-SCTRSF-');

              const articles = articlesString[1]?.split(';').reduce((acc, articleString) => {
                if (!articleString) return acc;

                const [gtin, quantity, articleNumber, price, currency] = articleString?.split(':');

                acc.push({
                  gtin,
                  quantity,
                  articleNumber,
                  price: parseFloat(price.replace(',', '.')),
                  currency,
                });

                return acc;
              }, []);

              resolve({ type: 'ShoppingCart', data: articles });
            }
          } else {
            resolve(code);
          }
        } catch (error) {
          if (debug) console.error(error);
          reject(error);
        }
      });
    } else {
      this.init(subtitle, QRSupport);
      return new Promise((resolve, reject) => this.register(resolve, reject));
    }
  });

  closeBarcodeScannerPromise = action(() => {
    if (this.streams.length > 0) {
      this.streams.forEach(stream => stream.stop());
      this.streams = [];
    }

    this.stream = null;
    this.subtitle = null;

    this.resolve = () => {};
    this.reject = () => {};

    this.cameraList = [];
    this.inputValue = '';

    this.isToggled = false;
  });

  handleInputChange = action(({ target: { value } }) => {
    this.inputValue = value;
  });

  handleInputClear = action(() => {
    this.inputValue = '';
  });

  handleInputSubmit = action(() => {
    if (this.inputValue.length) this.resolve(this.inputValue);
  });

  handleCloseCall = action(() => {
    this.reject('exit');
  });
}

export default Model('BarcodeScannerModel')(BarcodeScannerModel);
