import getSymbolFromCurrency from 'currency-symbol-map';
import { Fragment, memo, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, useTheme } from '@mui/material';
import { Check, Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { format } from 'date-fns';

import Modal from '@framework/decorators/Modal';

import Barcode from '@framework/icons/Barcode';

import {
  DialogStyled,
  DialogTitleStyled,
  DialogContentStyled,
  ModalAnimation,
  ButtonStyled,
  CounterStyled,
  RectangleWrapperStyled,
} from '@framework/styles/App';

import { AnswerStyled, DisabledOverlayStyled } from '@framework/styles/Requests';

const Details = inject(
  'store',
  'models'
)(
  observer(
    Modal(
      'RequestsProcessingModal',
      'Details'
    )(props => {
      const {
        store: { helperStore, localesStore },
        models: { RequestsProcessingModel },
      } = props;

      const theme = useTheme(),
        isMobile = helperStore.isMobile(),
        { isInfoToggled, request, handleInfoToggle } = RequestsProcessingModel;

      return isMobile ? (
        <Box
          display="grid"
          gridTemplateRows="max-content max-content"
          bgcolor={theme.application.palette.whitesmoke}
          p="20px"
        >
          <Box display="grid" gridTemplateColumns="3fr 1fr" fontSize={14}>
            <Box display="grid" alignItems="start" rowGap="5px" fontSize={16}>
              <Box component="span">{localesStore.translate('modal.requestsProcessing.label.customer')}:</Box>
              <Box component="span" fontWeight="bold">
                {`${
                  localesStore.translate(`placeholder.label.salutation.${request.invoiceAddress.salutation}`) || ''
                } ${request.invoiceAddress.firstName || ''} ${request.invoiceAddress.lastName || ''}`}
              </Box>
              <Box component="span">{request.invoiceAddress.email || ''}</Box>
              <Box component="span">{request.invoiceAddress.phone || ''}</Box>
            </Box>

            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {isInfoToggled ? (
                <KeyboardArrowUp onClick={handleInfoToggle} />
              ) : (
                <KeyboardArrowDown onClick={handleInfoToggle} />
              )}
            </Box>
          </Box>

          {isInfoToggled && (
            <>
              <Box
                display="grid"
                gridTemplateColumns="max-content max-content"
                justifyContent="start"
                alignItems="center"
                columnGap="10px"
                fontSize={16}
                mt="10px"
              >
                <Box display="grid" alignItems="start" height="100%" rowGap="5px">
                  <Box component="span">
                    {localesStore.translate(`modal.requestsProcessing.label.${request.type}`)}:
                  </Box>
                  <Box component="span">{localesStore.translate('placeholder.time.at')}:</Box>
                  <Box component="span">{localesStore.translate('placeholder.time.around')}:</Box>
                </Box>
                <Box display="grid" alignItems="start" height="100%" rowGap="5px">
                  <Box component="span">{request.processId ?? '-'}</Box>
                  <Box component="span">{request.created ? format(+request.created, 'dd.MM.yyyy') : '-'}</Box>
                  <Box component="span">
                    {request.created
                      ? format(+request.created, 'HH:mm') + ' ' + localesStore.translate('placeholder.label.time')
                      : '-'}
                  </Box>
                </Box>
              </Box>

              <Box display="grid" alignItems="start" rowGap="5px" fontSize={16} mt="10px">
                <Box component="span" fontWeight="bold">
                  {request.deliveryAddress.firstName || ''}
                </Box>
                <Box component="span">{`${request.deliveryAddress.street || ''} ${
                  request.deliveryAddress.streetNumber || ''
                }`}</Box>
                <Box component="span">{`${request.deliveryAddress.postalCode || ''} ${
                  request.deliveryAddress.city || ''
                }`}</Box>
                <Box component="span">{request.deliveryAddress.phone || ''}</Box>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          columnGap="10px"
          bgcolor={theme.application.palette.whitesmoke}
          p="20px"
        >
          <Box display="grid" alignItems="start" rowGap="5px" fontSize={16}>
            <Box component="span" fontWeight="bold">
              {request.deliveryAddress.firstName || ''}
            </Box>
            <Box component="span">{`${request.deliveryAddress.street || ''} ${
              request.deliveryAddress.streetNumber || ''
            }`}</Box>
            <Box component="span">{`${request.deliveryAddress.postalCode || ''} ${
              request.deliveryAddress.city || ''
            }`}</Box>
            <Box component="span">{request.deliveryAddress.phone || ''}</Box>
          </Box>

          <Box display="grid" alignItems="start" rowGap="5px" fontSize={16}>
            <Box component="span">{localesStore.translate('modal.requestsProcessing.label.customer')}:</Box>
            <Box component="span" fontWeight="bold">
              {`${localesStore.translate(`placeholder.label.salutation.${request.invoiceAddress.salutation}`) || ''} ${
                request.invoiceAddress.firstName || ''
              } ${request.invoiceAddress.lastName || ''}`}
            </Box>
            <Box component="span">{request.invoiceAddress.email || ''}</Box>
            <Box component="span">{request.invoiceAddress.phone || ''}</Box>
          </Box>

          <Box
            display="grid"
            gridTemplateColumns="max-content max-content"
            justifyContent="end"
            alignItems="center"
            columnGap="5px"
            fontSize={16}
          >
            <Box display="grid" alignItems="start" height="100%">
              <Box component="span" textAlign="right">
                {localesStore.translate(`modal.requestsProcessing.label.${request.type}`)}:
              </Box>
              <Box component="span" textAlign="right"></Box>
              <Box component="span" textAlign="right">
                {localesStore.translate('placeholder.time.at')}:
              </Box>
              <Box component="span" textAlign="right">
                {localesStore.translate('placeholder.time.around')}:
              </Box>
            </Box>
            <Box display="grid" alignItems="start" height="100%">
              <Box component="span">{request.processId ?? '-'}</Box>
              <Box component="span"></Box>
              <Box component="span">{request.created ? format(+request.created, 'dd.MM.yyyy') : '-'}</Box>
              <Box component="span">
                {request.created
                  ? format(+request.created, 'HH:mm') + ' ' + localesStore.translate('placeholder.label.time')
                  : '-'}
              </Box>
            </Box>
          </Box>
        </Box>
      );
    })
  )
);

const Article = inject(
  'store',
  'models'
)(
  observer(
    Modal(
      'RequestsProcessingModal',
      'Article'
    )(props => {
      const {
        article,
        store: { helperStore, localesStore },
        models: { RequestsProcessingModel, RequestsModel },
      } = props;

      const theme = useTheme(),
        isMobile = helperStore.isMobile(),
        { request, answers, getAnswer, setAnswer, handleArticleClick } = RequestsProcessingModel,
        {
          Status: { Confirmed, Closed, Open },
        } = RequestsModel;

      return isMobile ? (
        <Box
          display="flex"
          mt="20px"
          width="100%"
          justifyContent="space-between"
          borderBottom={`1px solid ${theme.application.palette.darkgray}`}
          p="10px"
          flexBasis="100%"
          onClick={() => handleArticleClick({ gtin: article.gtin, size: article.size, color: article.color })}
        >
          <Box display="flex" flexDirection="column" mb="10px">
            <Box display="grid" gridAutoFlow="row" rowGap="5px" alignItems="start" pr="10px" mb="10px">
              <Box component="span" fontSize={14} fontWeight={600} maxWidth={320}>
                {article.name}
              </Box>
              <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                {localesStore.translate('placeholder.label.articleNumber').slice(0, 6) +
                  '.' +
                  ': ' +
                  article.articleNumber}
              </Box>
              <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                {`${localesStore.translate('placeholder.label.ean')}: ${article.gtin}`}
              </Box>
              <Box component="span" fontSize={12}>
                {localesStore.translate('placeholder.label.quantity') + ' : ' + article.quantity}
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" alignItems="center" sx={{ '& > *': { marginRight: '10px' } }}>
              {article?.color && <Box fontSize={12}>{article.color}</Box>}

              <Box height="20px" width="20px" border={`solid 1px ${theme.application.palette.black}`}>
                <img
                  width="100%"
                  height="100%"
                  src={article.colorImageUrl ?? article.imageUrl ?? 'images/ig-placeholder.png'}
                  alt=""
                />
              </Box>

              {article?.size && <CounterStyled>{article.size}</CounterStyled>}
            </Box>
            <Box component="span" fontSize={16} fontWeight={600} mt="10px" display="flex" alignItems="center">
              {`${article.price} ${getSymbolFromCurrency(article.currency)}`}
              {request.status === Closed && article.status === Confirmed && (
                <Box component="span" color="green" ml="10px">
                  <Check />
                </Box>
              )}
            </Box>
            {request.status === Open && (
              <Box onClick={(e) => e.stopPropagation()} display="grid" gridAutoFlow="column" columnGap="20px" alignItems="center" mt="10px">
                <Box component="span" fontSize={13}>
                  {localesStore.translate(`modal.requestsProcessing.label.article.${request.type}`)}:{' '}
                </Box>
                <AnswerStyled
                  $isActive={getAnswer(article.gtin) === 'Yes'}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnswer(article.gtin, 'Yes')
                  }}
                >
                  {localesStore.translate('placeholder.label.yes')}
                </AnswerStyled>
                <AnswerStyled
                  $isActive={getAnswer(article.gtin) === 'No'}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnswer(article.gtin, 'No')
                  }}
                >
                  {localesStore.translate('placeholder.label.no')}
                </AnswerStyled>
              </Box>
            )}
          </Box>
          <Box height="auto" width="100px">
            <img width="100%" src={article?.imageUrl || 'images/ig-placeholder.png'} alt="" />
          </Box>
        </Box>
      ) : (
        <Box
          display="grid"
          gridTemplateColumns="max-content max-content max-content 1fr"
          columnGap="20px"
          mt="20px"
          borderBottom={`1px solid ${theme.application.palette.darkgray}`}
          p="10px"
          onClick={() => handleArticleClick({ gtin: article.gtin, size: article.size, color: article.color })}
        >
          <Box display="flex" justifyContent="center" height="100px" width="100px">
            <img height="100%" src={article?.imageUrl || 'images/ig-placeholder.png'} alt="" />
          </Box>

          <Box display="grid" gridAutoFlow="row" rowGap="5px">
            <Box component="span" fontSize={14} fontWeight={600} maxWidth={320}>
              {article.name}
            </Box>
            <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
              {localesStore.translate('placeholder.label.articleNumber') + ': ' + article.articleNumber}
            </Box>
            <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
              {`${localesStore.translate('placeholder.label.ean')}: ${article.gtin}`}
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              pt="10px"
              mt="10px"
              sx={{ '& > *': { marginRight: '10px' } }}
            >
              {article?.color && <Box>{article.color}</Box>}

              <Box height="25px" width="25px" border={`solid 1px ${theme.application.palette.dark}`} mr="10px">
                <img
                  width="100%"
                  height="100%"
                  src={article.colorImageUrl ?? article.imageUrl ?? 'images/ig-placeholder.png'}
                  alt=""
                />
              </Box>

              {article?.size && <CounterStyled>{article.size}</CounterStyled>}
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" p="0 20px">
            <Box component="span" fontSize={20} fontWeight={600}>
              {`${article.price} ${getSymbolFromCurrency(article.currency)}`}
            </Box>
          </Box>
          {request.status === Open && (
            <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-end">
              <Box component="span">
                {localesStore.translate('placeholder.label.quantity') + ': ' + article.quantity}
              </Box>
              <Box onClick={(e) => e.stopPropagation()} display="grid" gridAutoFlow="column" columnGap="50px" alignItems="center">
                <Box component="span">
                  {localesStore.translate(`modal.requestsProcessing.label.article.${request.type}`)}:{' '}
                </Box>
                <AnswerStyled
                  $isActive={getAnswer(article.gtin) === 'Yes'}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnswer(article.gtin, 'Yes')
                  }}
                >
                  {localesStore.translate('placeholder.label.yes')}
                </AnswerStyled>
                <AnswerStyled
                  $isActive={getAnswer(article.gtin) === 'No'}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnswer(article.gtin, 'No')
                  }}
                >
                  {localesStore.translate('placeholder.label.no')}
                </AnswerStyled>
              </Box>
            </Box>
          )}

          {request.status === Closed && article.status === Confirmed && (
            <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-end">
              <Box component="span">
                {localesStore.translate('placeholder.label.quantity') + ': ' + article.quantity}
              </Box>
              <Box color="green">
                <Check />
              </Box>
            </Box>
          )}
        </Box>
      );
    })
  )
);

const Request = inject(
  'store',
  'models'
)(
  observer(
    Modal(
      'RequestsProcessingModal',
      'Request'
    )(props => {
      const {
        store: { helperStore, localesStore },
        models: { RequestsModel, RequestsProcessingModel },
      } = props;

      const isMobile = helperStore.isMobile(),
        {
          Status: { Open, Closed },
        } = RequestsModel,
        { request, answers, handleAcceptCall, handleDeclineCall } = RequestsProcessingModel;

      return (
        <Fragment>
          <Box display="flex" flexDirection="column" width="100%" flexGrow={1} overflow="auto" flexShrink={0}>
            {request?.articleItems?.map(article => (
              <Article key={article.gtin} article={article} />
            ))}
          </Box>

          {request.status === Open && (
            <Box
              sx={
                isMobile
                  ? {
                      marginTop: '15px',
                      display: 'flex',
                      justifyContent: 'center',
                      minHeight: '40px',
                    }
                  : {
                      marginTop: '30px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      minHeight: '60px',
                    }
              }
            >
              {Object.values(answers).length !== 0 && Object.values(answers).every(value => value === 'No') ? (
                <ButtonStyled onClick={handleDeclineCall}>
                  {localesStore.translate(`modal.requestsProcessing.controls.${request.type}.decline`)}
                </ButtonStyled>
              ) : (
                <ButtonStyled
                  onClick={handleAcceptCall}
                  disabled={Object.values(answers).length < request.articleItems.length}
                >
                  {localesStore.translate(`modal.requestsProcessing.controls.${request.type}.accept`)}
                </ButtonStyled>
              )}
            </Box>
          )}

          {request.status === Closed && request.updated && (
            <Box
              sx={
                isMobile
                  ? {
                      marginTop: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '14px',
                    }
                  : {
                      marginTop: '10px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingRight: '20px',
                    }
              }
            >
              {localesStore.translate(`modal.requestsProcessing.label.completed.${request.type}`) +
                ' ' +
                localesStore.translate('placeholder.time.at')?.toLowerCase() +
                ' ' +
                format(+request.updated, 'dd.MM.yyyy') +
                ', ' +
                localesStore.translate('placeholder.time.around')?.toLowerCase() +
                ' ' +
                format(+request.updated, 'HH:mm') +
                ' ' +
                localesStore.translate('placeholder.label.time')}
            </Box>
          )}
        </Fragment>
      );
    })
  )
);

const Stepper = inject(
  'store',
  'models'
)(
  observer(
    Modal(
      'RequestsProcessingModal',
      'Stepper'
    )(props => {
      const {
        models: { RequestsProcessingModel, RequestsModel },
        store: { helperStore, localesStore },
      } = props;

      const theme = useTheme(),
        isMobile = helperStore.isMobile();

      const {
          Type: { Wholesale, Reservation },
        } = RequestsModel,
        { request, answers, step, tracker, stepperMounted, handleShipping, handleScanner, handleSubmitCall } =
          RequestsProcessingModel;

      const filteredItems = request.articleItems.filter(article => Object.keys(answers).includes(article.gtin));

      useEffect(() => {
        stepperMounted();
      }, [answers]);

      return isMobile ? (
        <Fragment>
          {step !== 1 && (
            <Box position="relative" display="flex" flexDirection="column" height="100%">
              <Box
                bgcolor={theme.application.palette.whitesmoke}
                p="20px"
                display="flex"
                alignItems="start"
                justifyContent="space-between"
                minHeight="120px"
              >
                <Box display="grid" rowGap="10px">
                  <Box component="span" fontWeight={600} fontSize={18}>
                    {localesStore.translate('placeholder.label.step')} 1
                  </Box>
                  <Box component="span" sx={{ lineHeight: '1.5' }}>
                    {localesStore.translate(`modal.requestsProcessing.label.${request.type}.step1`)}
                  </Box>
                </Box>
                <Box alignSelf="center">
                  <RectangleWrapperStyled onClick={handleScanner}>
                    <Barcode />
                  </RectangleWrapperStyled>
                </Box>
              </Box>
              <Box height="20px" bgcolor={theme.application.palette.light}></Box>
              <Box overflow="auto" flexGrow="1">
                {filteredItems.map(item => (
                  <Box
                    p="10px 20px"
                    display="flex"
                    justifyContent="space-between"
                    mt="20px"
                    borderBottom={`1px solid ${theme.application.palette.darkgray}`}
                    key={item.objectId}
                  >
                    <Box display="grid" gridAutoFlow="row" rowGap="5px">
                      <Box component="span" fontSize={14} fontWeight={600} maxWidth={320}>
                        {item?.name}
                      </Box>
                      <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                        {localesStore.translate('placeholder.label.articleNumber').slice(0, 6) +
                          '.' +
                          ': ' +
                          item?.articleNumber}
                      </Box>
                      <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                        {`${localesStore.translate('placeholder.label.ean')}: ${item?.gtin}`}
                      </Box>

                      <Box
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        columnGap={isMobile ? '10px' : '15px'}
                        rowGap="10px"
                        mt="10px"
                      >
                        {Boolean(item?.color) && <Box>{item.color}</Box>}

                        <Box height="25px" width="25px" border={`solid 1px ${theme.application.palette.dark}`}>
                          <img
                            width="100%"
                            height="100%"
                            src={item.colorImageUrl ?? item.imageUrl ?? 'images/ig-placeholder.png'}
                            alt=""
                          />
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        columnGap={isMobile ? '10px' : '15px'}
                        rowGap="10px"
                        mt="10px"
                      >
                        {Boolean(item?.size) && <CounterStyled>{item.size}</CounterStyled>}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      align-items="end"
                      ml="10px"
                    >
                      <Box display="flex" justifyContent="center" height="100px" width="100px">
                        <img height="100%" src={item?.imageUrl || 'images/ig-placeholder.png'} alt="" />
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mt="10px">
                        <Box
                          color="green"
                          sx={{
                            opacity: answers[item?.objectId] ? '1' : '0.3',
                          }}
                        >
                          <Check />
                        </Box>
                        <Box component="span" fontSize={14} fontWeight={600}>
                          {`${item?.price ? item.price.toFixed(2).replace('.', ',') : ''} ${
                            item?.currency ? getSymbolFromCurrency(item.currency) : ''
                          }`}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {step === 1 && (
            <Fragment>
              <Box position="relative">
                {request.type === Wholesale ? (
                  <Box>
                    <Box>
                      <Box
                        display="grid"
                        rowGap="10px"
                        bgcolor={theme.application.palette.whitesmoke}
                        p="20px"
                        minHeight="120px"
                      >
                        <Box component="span" fontWeight={600} fontSize={18}>
                          {localesStore.translate('placeholder.label.step')} 2
                        </Box>
                        <Box component="span" sx={{ lineHeight: '1.5' }}>
                          {localesStore.translate(`modal.requestsProcessing.label.${request.type}.step2`)}
                        </Box>
                      </Box>
                      <Box display="grid" rowGap="10px" p="20px 15px">
                        <Box component="span" fontWeight={600}>
                          {request.deliveryAddress.firstName}
                        </Box>
                        <Box component="span">{`${request.deliveryAddress.street} ${request.deliveryAddress.streetNumber}`}</Box>
                        <Box component="span">{`${request.deliveryAddress.postalCode} ${request.deliveryAddress.city}`}</Box>
                        <Box component="span">{request.deliveryAddress.phone}</Box>
                      </Box>
                    </Box>
                    <Box
                      bgcolor={theme.application.palette.whitesmoke}
                      p="20px"
                      display="flex"
                      alignItems="start"
                      justifyContent="space-between"
                      minHeight="120px"
                    >
                      <Box display="grid" rowGap="10px">
                        <Box component="span" fontWeight={600} fontSize={18}>
                          {localesStore.translate('placeholder.label.step')} 3
                        </Box>
                        <Box component="span" sx={{ lineHeight: '1.5' }}>
                          {localesStore.translate(`modal.requestsProcessing.label.${request.type}.step3`)}
                        </Box>
                        <Box display="flex" alignItems="center" mt="10px">
                          <Box mr="10px" color="green" sx={{ opacity: Boolean(tracker) ? '1' : '0.3' }}>
                            <Check />
                          </Box>
                          <Box component="span">{tracker}</Box>
                        </Box>
                      </Box>
                      <Box alignSelf="center">
                        <RectangleWrapperStyled onClick={handleShipping}>
                          <Barcode />
                        </RectangleWrapperStyled>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box>
                      <Box
                        display="grid"
                        rowGap="10px"
                        bgcolor={theme.application.palette.whitesmoke}
                        p="20px"
                        minHeight="120px"
                      >
                        <Box component="span" fontWeight={600} fontSize={18}>
                          {localesStore.translate('placeholder.label.step')} 2
                        </Box>
                        <Box component="span" sx={{ lineHeight: '1.5' }}>
                          {localesStore.translate(`modal.requestsProcessing.label.${request.type}.step2`)}
                        </Box>
                      </Box>
                      <Box display="grid" rowGap="10px" p="20px 15px">
                        <Box
                          component="span"
                          fontWeight={600}
                        >{`${request.invoiceAddress.firstName} ${request.invoiceAddress.lastName}`}</Box>
                        <Box component="span">{`${request.invoiceAddress.street} ${request.invoiceAddress.streetNumber}`}</Box>
                        <Box component="span">{`${request.invoiceAddress.postalCode} ${request.invoiceAddress.city}`}</Box>
                        <Box component="span">{request.invoiceAddress.phone}</Box>
                        <Box component="span">{request.invoiceAddress.email}</Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>

              <Box mt="30px" height="60px" display="flex" flexDirection="column">
                <ButtonStyled onClick={handleSubmitCall}>
                  {localesStore.translate(`modal.requestsProcessing.controls.${request.type}.finish`)}
                </ButtonStyled>
              </Box>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Box display="grid" gridTemplateColumns="2fr 1fr" columnGap="20px" height="100%" flexGrow="1">
          <Box position="relative" display="flex" flexDirection="column" height="100%" overflow="auto">
            <Box
              bgcolor={theme.application.palette.whitesmoke}
              p="20px"
              display="flex"
              alignItems="start"
              justifyContent="space-between"
              minHeight="120px"
              sx={step === '1' ? { opacity: '0.5', pointerEvents: 'none' } : {}}
            >
              <Box display="grid" rowGap="10px">
                <Box component="span" fontWeight={600} fontSize={18}>
                  {localesStore.translate('placeholder.label.step')} 1
                </Box>
                <Box component="span" sx={{ lineHeight: '1.5' }}>
                  {localesStore.translate(`modal.requestsProcessing.label.${request.type}.step1`)}
                </Box>
              </Box>
              <Box alignSelf="center">
                <RectangleWrapperStyled onClick={handleScanner}>
                  <Barcode />
                </RectangleWrapperStyled>
              </Box>
            </Box>
            <Box height="20px" bgcolor={theme.application.palette.light}></Box>
            <Box overflow="auto" flexGrow="1">
              {filteredItems?.map(item => (
                <Box
                  p="10px 20px"
                  display="grid"
                  gridTemplateColumns="max-content 1fr max-content"
                  columnGap="20px"
                  mt="20px"
                  borderBottom={`1px solid ${theme.application.palette.darkgray}`}
                  key={item.objectId}
                >
                  <Box display="flex" justifyContent="center" height="100px" width="100px">
                    <img height="100%" src={item.imageUrl ?? 'images/ig-placeholder.png'} alt="" />
                  </Box>
                  <Box display="grid" gridAutoFlow="row" rowGap="5px">
                    <Box component="span" fontSize={14} fontWeight={600} maxWidth={320}>
                      {item.name}
                    </Box>
                    <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                      {localesStore.translate('placeholder.label.articleNumber') + ': ' + item.articleNumber}
                    </Box>
                    <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                      {`${localesStore.translate('placeholder.label.ean')}: ${item.gtin}`}
                    </Box>

                    <Box
                      display="flex"
                      flexWrap="wrap"
                      alignItems="center"
                      columnGap={isMobile ? '10px' : '15px'}
                      rowGap="10px"
                      mt="10px"
                    >
                      {Boolean(item.color) && <Box>{item.color}</Box>}

                      <Box height="25px" width="25px" border={`solid 1px ${theme.application.palette.dark}`}>
                        <img
                          width="100%"
                          height="100%"
                          src={item.colorImageUrl ?? item.imageUrl ?? 'images/ig-placeholder.png'}
                          alt=""
                        />
                      </Box>

                      {Boolean(item.size) && <CounterStyled>{item.size}</CounterStyled>}
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column" justifyContent="start" alignItems="end" pl="20px">
                    <Box mb="10px" color="green" sx={{ opacity: answers[item.gtin] ? '1' : '0.3' }}>
                      <Check />
                    </Box>
                    <Box component="span" fontSize={20} fontWeight={600}>
                      {`${item?.price ? item.price.toFixed(2).replace('.', ',') : ''} ${
                        item?.currency ? getSymbolFromCurrency(item.currency) : ''
                      }`}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box position="relative" overflow={step !== 1 ? 'hidden' : 'auto'}>
            {step !== 1 && <DisabledOverlayStyled />}

            {request.type === Wholesale ? (
              <Box>
                <Box>
                  <Box
                    display="grid"
                    rowGap="10px"
                    bgcolor={theme.application.palette.whitesmoke}
                    p="20px"
                    minHeight="120px"
                  >
                    <Box component="span" fontWeight={600} fontSize={18}>
                      {localesStore.translate('placeholder.label.step')} 2
                    </Box>
                    <Box component="span" sx={{ lineHeight: '1.5' }}>
                      {localesStore.translate(`modal.requestsProcessing.label.${request.type}.step2`)}
                    </Box>
                  </Box>
                  <Box display="grid" rowGap="15px" p="30px 15px">
                    <Box component="span" fontWeight={600}>
                      {request.deliveryAddress.firstName}
                    </Box>
                    <Box component="span">{`${request.deliveryAddress.street} ${request.deliveryAddress.streetNumber}`}</Box>
                    <Box component="span">{`${request.deliveryAddress.postalCode} ${request.deliveryAddress.city}`}</Box>
                    <Box component="span">{request.deliveryAddress.phone}</Box>
                  </Box>
                </Box>
                <Box
                  bgcolor={theme.application.palette.whitesmoke}
                  p="20px"
                  display="flex"
                  alignItems="start"
                  justifyContent="space-between"
                  minHeight="120px"
                >
                  <Box display="grid" rowGap="10px">
                    <Box component="span" fontWeight={600} fontSize={18}>
                      {localesStore.translate('placeholder.label.step')} 3
                    </Box>
                    <Box component="span" sx={{ lineHeight: '1.5' }}>
                      {localesStore.translate(`modal.requestsProcessing.label.${request.type}.step3`)}
                    </Box>
                    <Box display="flex" alignItems="center" mt="10px">
                      <Box mr="10px" color="green" sx={{ opacity: Boolean(tracker) ? '1' : '0.3' }}>
                        <Check />
                      </Box>
                      <Box component="span">{tracker}</Box>
                    </Box>
                  </Box>
                  <Box alignSelf="center">
                    <RectangleWrapperStyled onClick={handleShipping}>
                      <Barcode />
                    </RectangleWrapperStyled>
                  </Box>
                </Box>
              </Box>
            ) : request.type === Reservation ? (
              <Box>
                <Box>
                  <Box
                    display="grid"
                    rowGap="10px"
                    bgcolor={theme.application.palette.whitesmoke}
                    p="20px"
                    minHeight="120px"
                  >
                    <Box component="span" fontWeight={600} fontSize={18}>
                      {localesStore.translate('placeholder.label.step')} 2
                    </Box>
                    <Box component="span" sx={{ lineHeight: '1.5' }}>
                      {localesStore.translate(`modal.requestsProcessing.label.${request.type}.step2`)}
                    </Box>
                  </Box>
                  <Box display="grid" rowGap="15px" p="30px 15px">
                    <Box component="span" fontWeight={600}>{`${request.deliveryAddress.firstName || ''} ${
                      request.deliveryAddress.lastName || ''
                    }`}</Box>
                    <Box component="span">{`${request.deliveryAddress.street || ''} ${
                      request.deliveryAddress.streetNumber || ''
                    }`}</Box>
                    <Box component="span">{`${request.deliveryAddress.postalCode || ''} ${
                      request.deliveryAddress.city || ''
                    }`}</Box>
                    <Box component="span">{request.deliveryAddress.phone || ''}</Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box>
                <Box>
                  <Box
                    display="grid"
                    rowGap="10px"
                    bgcolor={theme.application.palette.whitesmoke}
                    p="20px"
                    minHeight="120px"
                  >
                    <Box component="span" fontWeight={600} fontSize={18}>
                      {localesStore.translate('placeholder.label.step')} 2
                    </Box>
                    <Box component="span" sx={{ lineHeight: '1.5' }}>
                      {localesStore.translate(`modal.requestsProcessing.label.${request.type}.step2`)}
                    </Box>
                  </Box>
                  <Box display="grid" rowGap="15px" p="30px 15px">
                    <Box
                      component="span"
                      fontWeight={600}
                    >{`${request.invoiceAddress.firstName} ${request.invoiceAddress.lastName}`}</Box>
                    <Box component="span">{`${request.invoiceAddress.street} ${request.invoiceAddress.streetNumber}`}</Box>
                    <Box component="span">{`${request.invoiceAddress.postalCode} ${request.invoiceAddress.city}`}</Box>
                    <Box component="span">{request.invoiceAddress.phone}</Box>
                    <Box component="span">{request.invoiceAddress.email}</Box>
                  </Box>
                </Box>
              </Box>
            )}

            <Box mt="30px" height="60px" display="flex" flexDirection="column">
              <ButtonStyled onClick={handleSubmitCall}>
                {localesStore.translate(`modal.requestsProcessing.controls.${request.type}.finish`)}
              </ButtonStyled>
            </Box>
          </Box>
        </Box>
      );
    })
  )
);

const RequestsProcessingModal = Modal('RequestsProcessingModal')(props => {
  const {
    models: { RequestsProcessingModel, RequestsModel },
    store: { helperStore, localesStore },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile(),
    {
      Type: { Reservation, ClickAndReserve },
      Status: { Declined },
    } = RequestsModel,
    { isToggled, isStepper, request, handleModalClose } = RequestsProcessingModel;

  const Title = memo(({ $type, $status }) => {
    let title = '';

    switch ($type) {
      case Reservation: {
        title = localesStore.translate(
          `modal.requestsProcessing.type.Reservation.${isStepper ? 'stepView' : 'default'}`
        );
        break;
      }

      case ClickAndReserve: {
        switch ($status) {
          case Declined: {
            title = localesStore.translate('modal.requestsProcessing.type.ClickAndReserve.declined');
            break;
          }
          default: {
            title = localesStore.translate(
              `modal.requestsProcessing.type.ClickAndReserve.${isStepper ? 'stepView' : 'default'}`
            );
          }
        }
      }
    }

    return (
      <Box
        sx={
          isMobile
            ? {
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                fontSize: '18px',
                textAlign: 'center',
              }
            : null
        }
      >
        {title}
      </Box>
    );
  });

  return (
    <DialogStyled
      fullScreen={isMobile}
      fullWidth
      maxWidth="lg"
      open={isToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleModalClose}
    >
      {isToggled ? (
        <Fragment>
          <DialogTitleStyled>
            <Title $type={request.type} $status={request.status} />

            <Box
              sx={isMobile ? { cursor: 'pointer', position: 'absolute', right: '20px' } : { cursor: 'pointer' }}
              onClick={handleModalClose}
            >
              <Close />
            </Box>
          </DialogTitleStyled>
          <DialogContentStyled>
            {isStepper ? (
              <Stepper />
            ) : (
              <Fragment>
                <Details />
                {!isMobile && <Box height="20px" bgcolor={theme.application.palette.white} />}
                <Request />
              </Fragment>
            )}
          </DialogContentStyled>
        </Fragment>
      ) : null}
    </DialogStyled>
  );
});

export default inject('store', 'models')(observer(RequestsProcessingModal));
