import getSymbolFromCurrency from 'currency-symbol-map';
import i18nIsoCountries from 'i18n-iso-countries';

import { format } from 'date-fns';
import { Box, useTheme } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Fragment, useCallback, useEffect, useRef } from 'react';
import { Close, KeyboardArrowDown, KeyboardArrowUp, DeleteOutline } from '@mui/icons-material';

import Modal from '@framework/decorators/Modal';

import History from '@framework/icons/History';

import { RowStyled, HistoryBlockStyled } from '@framework/styles/Orders';
import {
  DialogStyled,
  DialogTitleStyled,
  DialogContentStyled,
  ModalAnimation,
  CounterStyled,
  ButtonStyled,
} from '@framework/styles/App';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const ActionButtons = inject(
  'store',
  'models'
)(
  observer(
    Modal(
      'OrdersProcessing',
      'ActionButtons'
    )(props => {
      const {
        store: { localesStore, networkStore, helperStore },
        models: { OrdersModel, OrdersProcessingModel, CustomerSignatureModel },
        $type,
        $status,
      } = props;

      const { Status, Type } = OrdersModel,
        {
          order,
          updateStatus,
          handleParkedCall,
          handleTrackerCall,
          handleOverdueProcess,
          handleSignatureCall,
          handleReturnCall,
        } = OrdersProcessingModel;

      switch ($type) {
        case Type.Wholesale: {
          switch ($status) {
            case Status.Parked:
            case Status.Creation:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: '60px',
                    owGap: '15px',
                  }}
                >
                  <ButtonStyled onClick={handleParkedCall}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.Wholesale.Parked')}
                  </ButtonStyled>
                </Box>
              );
            case Status.Requested:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(2, 60px)',
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled disabled>
                    {localesStore.translate('modal.ordersProcessing.controls.type.Wholesale.Requested.Received')}
                  </ButtonStyled>
                  <ButtonStyled
                    disabled={order.disabled.secondButton}
                    onClick={() => updateStatus(order.processId, Status.Revoked)}
                  >
                    {localesStore.translate('modal.ordersProcessing.controls.type.Wholesale.Requested.Revoked')}
                  </ButtonStyled>
                </Box>
              );
            case Status.Sent:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: `repeat(${order.trackingUrl ? 2 : 1}, 60px)`,
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled onClick={() => updateStatus(order.processId, Status.Received)}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.Wholesale.Sent.Received')}
                  </ButtonStyled>
                  {Boolean(order.trackingUrl) && (
                    <ButtonStyled onClick={() => handleTrackerCall(order.trackingUrl)}>
                      {localesStore.translate('modal.ordersProcessing.controls.type.Wholesale.Sent.Tracking')}
                    </ButtonStyled>
                  )}
                </Box>
              );
            case Status.Received:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: '60px',
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled onClick={() => {
                      CustomerSignatureModel.signatureModalCall(null)
                      .then(async (signature) => {
                        helperStore.toggleLoader(true);
                        
                        const base64String = signature.url.split('data:image/png;base64,')[1];
                        const fileName = `${order.firstName}_${order.lastName}_${order.processId}_${format(
                          Date.now(),
                          'yyyy-MM-dd_hh.mm.ss'
                        )}`;
                        
                        const { response: signatureResponse } = await networkStore.post('UploadDigitalSignature', {
                          fileName: fileName,
                          base64Image: base64String
                        });
                        
                        const { data } = JSON.parse(signatureResponse);
                        
                        const { response: processResponse } = await networkStore.post('addCustomerDigitalInfoToProcess', {
                          processId: order.processId,
                          customerDigitalInfo: {
                            digitalInfoPath: data,
                          }
                        });
                        
                         updateStatus(order.processId, Status.Delivered)
                      })
                      .catch(console.error);
                  }}>
                    
                    {localesStore.translate('modal.ordersProcessing.controls.type.Wholesale.Received')}
                  </ButtonStyled>
                </Box>
              );
            default:
              return null;
          }
        }
        case Type.InStore: {
          switch ($status) {
            case Status.Parked:
            case Status.Creation:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: '60px',
                    owGap: '15px',
                  }}
                >
                  <ButtonStyled onClick={handleParkedCall}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.InStore.Parked')}
                  </ButtonStyled>
                </Box>
              );
            case Status.Requested:
              return (
                <Box
                  sx={{
                    rowGap: '15px',
                    display: 'grid',
                    gridTemplateRows: '60px',
                  }}
                >
                  <ButtonStyled
                    disabled={order.disabled.firstButton}
                    onClick={() => updateStatus(order.processId, Status.Revoked)}
                  >
                    {localesStore.translate('modal.ordersProcessing.controls.type.Wholesale.Requested.Revoked')}
                  </ButtonStyled>
                </Box>
              );
            case Status.Sent:
              return (
                Boolean(order.trackingUrl) && (
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateRows: '60px',
                      owGap: '15px',
                    }}
                  >
                    <ButtonStyled onClick={() => handleTrackerCall(order.trackingUrl)}>
                      {localesStore.translate('modal.ordersProcessing.controls.type.InStore.Sent')}
                    </ButtonStyled>
                  </Box>
                )
              );
            default:
              return null;
          }
        }
        case Type.ClickAndReserve: {
          switch ($status) {
            case Status.Received:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(2, 60px)',
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled onClick={() => updateStatus(order.processId, Status.Delivered)}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.ClickAndReserve.Received.Delivered')}
                  </ButtonStyled>
                  <ButtonStyled onClick={() => handleOverdueProcess()}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.ClickAndReserve.Received.Without')}
                  </ButtonStyled>
                </Box>
              );
            case Status.Overdue:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: '60px',
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled onClick={() => updateStatus(order.processId, Status.Expired)}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.ClickAndReserve.OVERDUE')}
                  </ButtonStyled>
                </Box>
              );
            default:
              return null;
          }
        }
        case Type.ClickAndCollect: {
          switch ($status) {
            case Status.Requested:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: '60px',
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled disabled={true}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.ClickAndCollect.Requested')}
                  </ButtonStyled>
                </Box>
              );
            case Status.Sent:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: `repeat(${Boolean(order.trackingUrl) ? 2 : 1}, 60px)`,
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled onClick={() => updateStatus(order.processId, Status.Received)}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.ClickAndCollect.Sent.Package')}
                  </ButtonStyled>
                  {Boolean(order.trackingUrl) && (
                    <ButtonStyled onClick={() => handleTrackerCall(order.trackingUrl)}>
                      {localesStore.translate('modal.ordersProcessing.controls.type.ClickAndCollect.Sent.Tracking')}
                    </ButtonStyled>
                  )}
                </Box>
              );
            case Status.Received:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: '60px',
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled
                    onClick={() =>
                      handleSignatureCall(() => {
                        updateStatus(order.processId, Status.Delivered);
                      }, order)
                    }
                  >
                    {localesStore.translate('modal.ordersProcessing.controls.type.ClickAndCollect.Received')}
                  </ButtonStyled>
                </Box>
              );
            case Status.Delivered:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: '60px',
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled onClick={() => handleReturnCall()}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.ClickAndCollect.Delivered')}
                  </ButtonStyled>
                </Box>
              );
            case Status.Overdue:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(2, 60px)',
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled
                    onClick={() =>
                      handleSignatureCall(() => {
                        updateStatus(order.processId, Status.Delivered);
                      }, order)
                    }
                  >
                    {localesStore.translate('modal.ordersProcessing.controls.type.ClickAndCollect.Received')}
                  </ButtonStyled>
                  <ButtonStyled onClick={() => updateStatus(order.processId, Status.Expired)}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.ClickAndCollect.OVERDUE')}
                  </ButtonStyled>
                </Box>
              );
            default:
              return null;
          }
        }
        case Type.Reservation: {
          switch ($status) {
            case Status.Requested:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(2, 60px)',
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled onClick={() => updateStatus(order.processId, Status.Rejected)}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.Reservation.Rejected')}
                  </ButtonStyled>
                  <ButtonStyled disabled={true}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.Reservation.Requested')}
                  </ButtonStyled>
                </Box>
              );
            case Status.Sent:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(1,60px)',
                    rowGap: '15px',
                  }}
                >
                  {/*<ButtonStyled disabled={true}>*/}
                  {/*  {localesStore.translate('modal.ordersProcessing.controls.type.Reservation.Rejected')}*/}
                  {/*</ButtonStyled>*/}
                  <ButtonStyled onClick={() => updateStatus(order.processId, Status.Received)}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.Reservation.Sent')}
                  </ButtonStyled>
                </Box>
              );
            case Status.Received:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: 'repeat(2, 60px)',
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled
                    onClick={() =>
                      handleSignatureCall(() => {
                        updateStatus(order.processId, Status.Delivered);
                      })
                    }
                  >
                    {localesStore.translate('modal.ordersProcessing.controls.type.Reservation.Received.Delivered')}
                  </ButtonStyled>
                  <ButtonStyled onClick={() => handleOverdueProcess()}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.Reservation.Received.Canceled')}
                  </ButtonStyled>
                </Box>
              );
            case Status.Overdue:
              return (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: '60px',
                    rowGap: '15px',
                  }}
                >
                  <ButtonStyled onClick={() => updateStatus(order.processId, Status.Expired)}>
                    {localesStore.translate('modal.ordersProcessing.controls.type.Reservation.OVERDUE')}
                  </ButtonStyled>
                </Box>
              );
            default:
              return null;
          }
        }
        default:
          return null;
      }
    })
  )
);

const ArticleItem = inject('store')(
  observer(
    Modal(
      'OrdersProcessing',
      'ArticleItem'
    )(props => {
      const {
        store: { localesStore },
        data,
        index,
        isMobile,
        onArticleClick,
        rootArticle,
        theme,
      } = props;

      const {
        logs,
        gtin,
        name,
        color,
        size,
        price,
        status,
        currency,
        quantity,
        objectId,
        imageUrl,
        colorCode,
        articleNumber,
        colorImageUrl,
      } = data;

      const isActive = Boolean(logs.length) && objectId === rootArticle?.objectId;

      return (
        <RowStyled key={objectId} $isActive={isActive} onClick={() => onArticleClick(index, data)}>
          <Box display="flex" justifyContent="center" height="100px" width="100px">
            <img height="100%" src={imageUrl || 'images/ig-placeholder.png'} alt="" />
          </Box>

          <Box
            display="grid"
            gridTemplateColumns={isMobile ? '1fr max-content' : '1fr max-content'}
            columnGap={isMobile ? '5px' : '10px'}
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Box display="grid" gridTemplateRows="repeat(3, auto)" alignItems="center" rowGap="8px">
                <Box component="span" fontSize={14} fontWeight={600} color="">
                  {name}
                </Box>
                <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                  {(isMobile
                    ? localesStore.translate('placeholder.label.articleNumber').slice(0, 6) + '.'
                    : localesStore.translate('placeholder.label.articleNumber')) +
                    ': ' +
                    articleNumber}
                </Box>
                <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                  {`${localesStore.translate('placeholder.label.ean')}: ${gtin}`}
                </Box>
              </Box>

              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                mt="10px"
                pt="10px"
                sx={{
                  '& > * ': {
                    marginTop: '10px',
                    marginRight: isMobile ? '10px' : '15px',
                  },
                }}
              >
                {color && <Box fontSize={14}>{color}</Box>}

                <Box height="25px" width="25px" border={`solid 1px ${theme.application.palette.black}`}>
                  <img
                    width="100%"
                    height="100%"
                    src={colorImageUrl ?? imageUrl ?? 'images/ig-placeholder.png'}
                    alt=""
                  />
                </Box>

                {Boolean(size) && <CounterStyled>{size}</CounterStyled>}

                {isMobile && (
                  <Box component="span" fontSize={14}>
                    {localesStore.translate('placeholder.label.quantity') + ': ' + quantity}
                  </Box>
                )}
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="space-between">
              <Box display="flex" justifyContent="end">
                <History status={status} />
              </Box>

              <Box component="span" fontSize={isMobile ? 16 : 20} fontWeight={600}>
                {`${typeof price === 'number' ? price.toFixed(2).replace('.', ',') : ''} ${
                  currency ? getSymbolFromCurrency(currency) || '' : currency
                }`}
              </Box>
              {!isMobile && (
                <Box component="span" fontSize={14}>
                  {localesStore.translate('placeholder.label.quantity') + ': ' + quantity}
                </Box>
              )}
            </Box>
          </Box>
        </RowStyled>
      );
    })
  )
);

const OrdersProcessingModal = Modal('OrdersProcessingModal')(props => {
  const {
    models: { OrdersProcessingModel, OrdersModel },
    store: { helperStore, localesStore },
  } = props;

  const theme = useTheme(),
    navigate = useNavigate(),
    isMobile = helperStore.isMobile(),
    translations = i18nIsoCountries.getNames(localesStore.currentLocale, {
      select: 'official',
    });

  const detailsBoxRef = useRef(null),
    scrollableBoxRef = useRef(null);

  const {
      Status: { Overdue, Declined, Created, Parked },
    } = OrdersModel,
    {
      isToggled,
      isInfoToggled,
      position,
      order,
      register,
      article: rootArticle,
      handleArticleCall,
      handleTrackerCall,
      handleInfoToggle,
      handleProcessRemove,
      handleCloseCall,
    } = OrdersProcessingModel;

  useEffect(() => register(navigate), []);

  const handleArticleClick = useCallback((refIndex, article) => {
    let position = 0;

    if (!isMobile) {
      const detailedInfoBlockHeight = detailsBoxRef.current?.getBoundingClientRect().height || 0;
      const arrowIconHalfHeight = 10;
      const orderBlockHeight = 15 + 100 + 15;
      const scrollHeight = scrollableBoxRef.current?.scrollTop || 0;

      position =
        detailedInfoBlockHeight -
        arrowIconHalfHeight +
        orderBlockHeight / 2 +
        orderBlockHeight * refIndex -
        scrollHeight;
    }

    handleArticleCall(article, position);
  }, []);

  return (
    <DialogStyled
      fullScreen={isMobile}
      fullWidth
      maxWidth="lg"
      open={isToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleCloseCall}
    >
      {isToggled ? (
        <Fragment>
          <DialogTitleStyled>
            <Box
              component="span"
              sx={
                isMobile
                  ? {
                      fontSize: '18px',
                      textAlign: 'center',
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                    }
                  : { display: 'flex' }
              }
            >
              {localesStore.translate(`orders.type.${order.type}`)}
              {
                (
                  order.status === Created ||
                  order.status === Parked
                ) && (
                <Box
                  sx={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={handleProcessRemove}
                  color={theme.application.palette.danger}
                >
                  <DeleteOutline />
                </Box>
              )}
            </Box>
            <Close
              sx={
                isMobile
                  ? {
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '20px',
                    }
                  : { cursor: 'pointer' }
              }
              onClick={handleCloseCall}
            />
          </DialogTitleStyled>
          <DialogContentStyled>
            {isMobile ? (
              rootArticle?.logs.length ? (
                <Fragment>
                  <Box
                    position="relative"
                    flex="1"
                    overflow="auto"
                    mb="20px"
                    height={`calc(100% - ${rootArticle.trackingUrl ? 100 : 50}px)`}
                  >
                    {rootArticle.logs.map(({ objectId, title, message, afterState }) => (
                      <Box
                        key={objectId}
                        display="grid"
                        gridTemplateColumns="1fr max-content"
                        columnGap="5px"
                        padding="20px 0"
                        borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                      >
                        <Box display="grid" rowGap="5px">
                          <Box fontSize={18} fontWeight={600}>
                            {title}
                          </Box>
                          <Box fontSize={14}>{message}</Box>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                          <History status={afterState} />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateRows={`repeat(${rootArticle.trackingUrl ? 2 : 1}, 50px)`}
                    rowGap="15px"
                  >
                    {rootArticle.trackingUrl && (
                      <ButtonStyled $isActive onClick={() => handleTrackerCall(rootArticle.trackingUrl)}>
                        {localesStore.translate('modal.ordersProcessing.controls.tracking')}
                      </ButtonStyled>
                    )}
                    <ButtonStyled $isActive onClick={() => handleArticleCall(null)}>
                      {localesStore.translate('modal.ordersProcessing.controls.history')}
                    </ButtonStyled>
                  </Box>
                </Fragment>
              ) : (
                <Fragment>
                  <Box
                    display="grid"
                    gridTemplateRows="max-content max-content"
                    bgcolor={theme.application.palette.whitesmoke}
                    p="20px"
                  >
                    <Box display="grid" gridTemplateColumns="1fr 1fr" fontSize={14}>
                      <Box display="grid" rowGap="5px">
                        <Box component="span" fontWeight="bold">
                          {`${localesStore.translate(`placeholder.label.salutation.${order.salutation}`) || ''} ${
                            order.firstName || ''
                          } ${order.lastName || ''}`}
                        </Box>
                        <span>{`${order.street || ''} ${order.streetNumber || ''}`}</span>
                        <span>{`${order.postalCode || ''} ${order.city || ''} ${
                          translations[order.country] || ''
                        }`}</span>
                        <span>{order.email || ''}</span>
                        <span>{order.phone || ''}</span>
                        <span>{order.mobile || ''}</span>
                      </Box>

                      <Box display="flex" justifyContent="flex-end" alignItems="center" onClick={handleInfoToggle}>
                        {!isInfoToggled ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                      </Box>
                    </Box>
                    {isInfoToggled && (
                      <Box display="grid" gridTemplateColumns="max-content 1fr" columnGap="5px" fontSize={14} mt="10px">
                        <Box display="grid" alignItems="center" rowGap="5px" height="100%">
                          <Box component="span">{localesStore.translate('modal.ordersProcessing.label.process')}:</Box>
                          <Box component="span">{localesStore.translate('modal.ordersProcessing.label.customer')}:</Box>
                          <Box component="span">{localesStore.translate('modal.ordersProcessing.label.merchant')}:</Box>
                          <Box component="span">{localesStore.translate('modal.ordersProcessing.label.date')}:</Box>
                          <Box component="span">{localesStore.translate('modal.ordersProcessing.label.total')}:</Box>
                        </Box>

                        <Box display="grid" alignItems="center" rowGap="5px" height="100%" gridAutoRows="1fr">
                          <Box component="span" sx={{
                            fontSize: order.processId?.length > 10 ? '12px' : '16px',
                          }}>{order.processId ?? '-'}</Box>
                          <Box
                            sx={{
                              fontSize: order.customerId?.length > 10 ? '12px' : '16px',
                            }}
                          >
                            {order.customerId ?? '-'}
                          </Box>
                          <Box
                            sx={{
                              fontSize: order.sourceStoreSellerId?.length > 10 ? '12px' : '16px',
                            }}
                          >
                            {order.sourceStoreSellerId ?? '-'}
                          </Box>
                          <span>{order.created ? format(order.created, 'dd.MM.yyyy HH:mm') : '-'}</span>
                          <span>
                            {`${
                              typeof order?.totalPrice === 'number' ? order.totalPrice.toFixed(2).replace('.', ',') : ''
                            } ${order?.currency ? getSymbolFromCurrency(order.currency) || '' : ''}`}
                          </span>
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Box
                    display="grid"
                    rowGap="15px"
                    borderBottom={`2px solid ${theme.application.palette.whitesmoke}`}
                    p="15px"
                    mb="15px"
                  >
                    <Box
                      component="span"
                      fontSize={24}
                      color={
                        [Overdue, Declined].includes(order.status)
                          ? theme.application.palette.danger
                          : theme.application.palette.black
                      }
                    >
                      {localesStore.translate(`orders.status.${order.type}.${order.status}`)}
                    </Box>
                    {isInfoToggled && (
                      <Box fontSize={14} lineHeight={1.3}>
                        {localesStore.translate(`modal.ordersProcessing.type.${order.type}.${order.status}`)}
                      </Box>
                    )}
                  </Box>

                  <Box>
                    {order.articleItems.map((article, index) => (
                      <ArticleItem
                        key={index}
                        index={index}
                        theme={theme}
                        data={article}
                        isMobile={isMobile}
                        onArticleClick={handleArticleClick}
                      />
                    ))}
                  </Box>

                  <Box display="flex" flexDirection="column" justifyContent="space-between" mt="10px">
                    <ActionButtons $type={order.type} $status={order.status} />
                  </Box>
                </Fragment>
              )
            ) : (
              <Box
                display="grid"
                gridTemplateColumns="2fr 1fr"
                columnGap="20px"
                gridTemplateRows="100%"
                height="100%"
                flexGrow={1}
              >
                <Box display="grid" gridTemplateRows="max-content 1fr" height="100%">
                  <Box
                    ref={detailsBoxRef}
                    display="grid"
                    gridTemplateColumns="1fr 1fr"
                    columnGap="10px"
                    bgcolor={theme.application.palette.whitesmoke}
                    p="20px"
                  >
                    <Box display="grid" alignItems="center" rowGap="5px" fontSize={16}>
                      <Box component="span" fontWeight="bold">
                        {`${localesStore.translate(`placeholder.label.salutation.${order.salutation}`) || ''} ${
                          order.firstName || ''
                        } ${order.lastName || ''}`}
                      </Box>
                      <span>{`${order.street || ''} ${order.streetNumber || ''}`}</span>
                      <span>{`${order.postalCode || ''} ${order.city || ''} ${
                        translations[order.country] || ''
                      }`}</span>
                      <span>{order.email || ''}</span>
                      <span>{order.phone || ''}</span>
                      <span>{order.mobile || ''}</span>
                    </Box>
                    <Box
                      display="grid"
                      gridTemplateColumns="max-content 1fr"
                      justifyContent="end"
                      alignItems="center"
                      columnGap="5px"
                      fontSize={16}
                    >
                      <Box display="grid" alignItems="center" rowGap="5px" height="100%">
                        <Box component="span" textAlign="right">
                          {localesStore.translate('modal.ordersProcessing.label.process')}:
                        </Box>
                        <Box component="span" textAlign="right">
                          {localesStore.translate('modal.ordersProcessing.label.customer')}:
                        </Box>
                        <Box component="span" textAlign="right">
                          {localesStore.translate('modal.ordersProcessing.label.merchant')}:
                        </Box>
                        <Box component="span" textAlign="right">
                          {localesStore.translate('modal.ordersProcessing.label.date')}:
                        </Box>
                        <Box component="span" textAlign="right">
                          {localesStore.translate('modal.ordersProcessing.label.total')}:
                        </Box>
                      </Box>
                      <Box display="grid" alignItems="center" rowGap="5px" height="100%" overflow="hidden" gridAutoRows="1fr">
                        <Box
                          sx={{
                            fontSize: order.processId?.length > 10 ? '12px' : '16px',
                          }}
                        >{order.processId ?? '-'}</Box>
                        <Box
                          sx={{
                            fontSize: order.customerId?.length > 10 ? '12px' : '16px',
                          }}
                        >
                          {order.customerId ?? '-'}
                        </Box>
                        <Box
                          sx={{
                            fontSize: order.sourceStoreSellerId?.length > 10 ? '12px' : '16px',
                          }}
                        >
                          {order.sourceStoreSellerId ?? '-'}
                        </Box>
                        <span>{order.created ? format(order.created, 'dd.MM.yyyy HH:mm') : '-'}</span>
                        <span>
                          {`${
                            typeof order?.totalPrice === 'number' ? order.totalPrice.toFixed(2).replace('.', ',') : ''
                          } ${order?.currency ? getSymbolFromCurrency(order.currency) || '' : ''}`}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                  <Box ref={scrollableBoxRef} position="relative" overflow="auto" mt="10px" height="100%">
                    <Box position="absolute" width="100%" height="100%">
                      {order.articleItems.map((article, index) => (
                        <ArticleItem
                          key={index}
                          index={index}
                          theme={theme}
                          data={article}
                          isMobile={isMobile}
                          onArticleClick={handleArticleClick}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
                {Boolean(rootArticle?.logs.length) ? (
                  <HistoryBlockStyled $position={position}>
                    <Fragment>
                      <Box
                        position="relative"
                        flex="1"
                        overflow="auto"
                        mb="20px"
                        height={`calc(100% - ${rootArticle.trackingUrl ? 100 : 50}px)`}
                      >
                        {rootArticle.logs.map(({ objectId, title, message, afterState }) => (
                          <Box
                            key={objectId}
                            display="grid"
                            gridTemplateColumns="1fr max-content"
                            columnGap="5px"
                            padding="20px 0"
                            borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                          >
                            <Box display="grid" rowGap="5px">
                              <Box fontSize={18} fontWeight={600}>
                                {title}
                              </Box>
                              <Box fontSize={14}>{message}</Box>
                            </Box>
                            <Box display="flex" flexDirection="column" justifyContent="center">
                              <History status={afterState} />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                      <Box
                        display="grid"
                        gridTemplateRows={`repeat(${rootArticle.trackingUrl ? 2 : 1}, 50px)`}
                        rowGap="15px"
                      >
                        {Boolean(rootArticle.trackingUrl) && (
                          <ButtonStyled $isActive onClick={() => handleTrackerCall(rootArticle.trackingUrl)}>
                            {localesStore.translate('modal.ordersProcessing.controls.tracking')}
                          </ButtonStyled>
                        )}
                        <ButtonStyled $isActive onClick={() => handleArticleCall(null)}>
                          {localesStore.translate('modal.ordersProcessing.controls.history')}
                        </ButtonStyled>
                      </Box>
                    </Fragment>
                  </HistoryBlockStyled>
                ) : (
                  <Box display="grid" gridTemplateRows="1fr 3fr">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderTop={`2px solid ${theme.application.palette.whitesmoke}`}
                      borderBottom={`2px solid ${theme.application.palette.whitesmoke}`}
                      height="100%"
                    >
                      <Box
                        component="span"
                        fontSize={24}
                        textAlign="center"
                        color={
                          [Overdue, Declined].includes(order.status)
                            ? theme.application.palette.danger
                            : theme.application.palette.black
                        }
                      >
                        {localesStore.translate(`orders.status.${order.type}.${order.status}`)}
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="column" justifyContent="space-between" mt="30px">
                      <Box component="span" sx={{ whiteSpace: 'pre-line' }}>
                        {localesStore.translate(`modal.ordersProcessing.type.${order.type}.${order.status}`)}
                      </Box>
                      <ActionButtons $type={order.type} $status={order.status} />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </DialogContentStyled>
        </Fragment>
      ) : null}
    </DialogStyled>
  );
});

export default inject('store', 'models')(observer(OrdersProcessingModal));
