import { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import View from '@framework/decorators/View';

import SearchComponent from '@framework/components/Search';
import Barcode from '@framework/icons/Barcode';

import {
  PageContainerStyled,
  LayoutContainerStyled,
  BackButtonStyled,
  ToolbarStyled,
  RectangleWrapperStyled,
} from '@framework/styles/App';

const CustomerSearchView = View('CustomerSearch')(props => {
  const {
    models: { SearchModel },
    store: { helperStore, localesStore },
  } = props;

  const navigate = useNavigate(),
    location = useLocation(),
    isMobile = helperStore.isMobile();

  const { register, viewMounted, handleBarcodeScanner } = SearchModel;

  useEffect(() => {
    register(navigate, location);
  }, []);

  useEffect(() => viewMounted(), []);

  return (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.baseAnimationDirection)}>
      <LayoutContainerStyled $scrollable>
        <ToolbarStyled $centered>
          <BackButtonStyled
            onClick={e => {
              e.preventDefault();
              helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
              navigate('/');
            }}
          >
            {localesStore.translate('placeholder.label.back')}
          </BackButtonStyled>
          <h2>
            {isMobile
              ? localesStore.translate('page.title.customer.search')
              : localesStore.translate('page.title.prefix') + localesStore.translate('page.title.customer.search')}
          </h2>
          <Box display="flex" position="absolute" right="0">
            <RectangleWrapperStyled onClick={handleBarcodeScanner}>
              <Barcode />
            </RectangleWrapperStyled>
          </Box>
        </ToolbarStyled>
        <SearchComponent />
      </LayoutContainerStyled>
    </PageContainerStyled>
  );
});

export default inject('store', 'models')(observer(CustomerSearchView));
