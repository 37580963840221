import { keyframes } from '@mui/material';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

export const slideIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`;

export const slideOut = keyframes`
  from {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
  }
`;

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;
