import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import settings from '@framework/settings';

import RootStore from '@framework/stores/RootStore';
import RootModels from '@framework/models/RootModels';
import RootTheme from '@framework/styles/RootTheme';
import RootStyles from '@framework/styles/RootStyles';

import AppView from '@framework/views/App';

const store = new RootStore(settings);

window.onerror = store.networkStore.catchException;

const models = new RootModels(store);

const RootDOM = ReactDOM.createRoot(document.getElementById('root'));

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    if (settings.worker.enabled) {
      navigator.serviceWorker
        .register(`/sw.js?services=${encodeURIComponent(JSON.stringify(settings.worker.services))}`, { scope: '/' })
        .then(registration => {
          registration.onupdatefound = () => {
            const installedWorker = registration.installing;

            installedWorker.onstatechange = () => {
              if (
                installedWorker.state === 'installed' &&
                navigator.serviceWorker.controller
              )
                window.showUpdateNotification(registration.waiting);
            };
          };

          console.log('Service Worker Module: %cEnabled', 'color: green;');
        })
        .catch(() => console.log('Service Worker Module: %cFailed', 'color: red;'));
    } else {
      navigator.serviceWorker
        .getRegistration('/')
        .then(
          registration => new Promise(resolve => (registration ? registration?.unregister().then(resolve) : resolve()))
        )
        .then(() => console.log('Service Worker Module: %cDisabled', 'color: red;'));
    }
  });
}

RootDOM.render(
  <>
    <RootStyles />
    <BrowserRouter>
      <ThemeProvider theme={RootTheme}>
        <Provider store={store} models={models}>
          <AppView />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </>
);
