import { css, Dialog, DialogContent, styled } from '@mui/material';

import Styled from '@framework/decorators/Styled';

const CustomerProductsButtonStyled = Styled(
  'CustomerProducts',
  'CustomerProductsButtonStyled'
)(styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100px;
  height: 100%;

  & > svg {
    font-size: 32px;
  }

  ${({ theme: { application } }) => css`
    color: ${application.palette.white};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      color: ${application.palette.black};
      transform: translateX(0%) !important;
      width: 20px;
    }
  `}
`);

export const CustomerProductsDialogStyled = Styled(
  'CustomerProducts',
  'CustomerProductsDialogStyled'
)(styled(Dialog)`
  ${({ $isMobile }) =>
    !$isMobile &&
    css`
      & .MuiDialog-paper {
        width: calc(100% - 200px);
        height: calc(100% - 164px);
        max-height: 700px;
        padding: 30px;
        border-radius: 0;
        margin: 100px 132px 0 132px;
        overflow: unset;
      }
    `}
`);

export const CustomerProductsRightButtonStyled = Styled(
  'CustomerProducts',
  'CustomerProductsRightButtonStyled'
)(styled(CustomerProductsButtonStyled)`
  right: 0;
  transform: translateX(100%);
`);

export const CustomerProductsLeftButtonStyled = Styled(
  'CustomerProducts',
  'CustomerProductsLeftButtonStyled'
)(styled(CustomerProductsButtonStyled)`
  left: 0;
  transform: translateX(-100%);
`);

export const CustomerProductsTitleStyled = Styled(
  'CustomerProducts',
  'CustomerProductsTitleStyled'
)(styled('div')`
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  margin-bottom: 20px;

  & > h2 {
    font-size: 28px;
    font-weight: 600;
  }

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      font-size: 14px;

      & > h2 {
        font-size: 22px;
      }
    }
  `}
`);

export const CustomerProductsCounterStyled = Styled(
  'CustomerProducts',
  'CustomerProductsCounterStyled'
)(styled('div')`
  margin-top: 15px;
  position: relative;
  display: inline-flex;
  align-items: center;

  &::before {
    content: ' ';
    position: absolute;
    left: -10px;
    transform: translateX(-100%);
    width: 20px;
    height: 2px;
  }

  &::after {
    content: ' ';
    position: absolute;
    right: -10px;
    transform: translateX(100%);
    width: 20px;
    height: 2px;
  }

  ${({ theme: { application } }) => css`
    &::before {
      background-color: ${application.palette.black};
    }

    &::after {
      background-color: ${application.palette.black};
    }
  `}
`);

export const CustomerProductsContentStyled = Styled(
  'CustomerProducts',
  'CustomerProductsContentStyled'
)(styled(DialogContent)`
  display: flex;
  padding: 5px;
  word-break: break-all;
  height: calc(100% - 100px);
  overflow-x: hidden;
  overflow-y: auto;

  & img {
    height: 100%;
    object-fit: contain;
    object-position: top;
  }

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      flex-direction: column;
      padding: 15px;

      & img {
        width: 100%;
        height: 100%;
        min-height: auto;
        object-fit: contain;
        object-position: top;
      }
    }
  `}
`);

export const CustomerProductsHeaderStyled = Styled(
  'CustomerProducts',
  'CustomerProductsHeaderStyled'
)(styled('div')`
  position: absolute;
  top: 0;
  left: -100px;
  width: calc(100% + 200px);
  height: 100px;
  transform: translateY(-100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;

  & > h2 {
    font-size: 28px;
    text-align: center;
    font-weight: 400;
  }

  ${({ theme: { application } }) => css`
    & > * {
      color: ${application.palette.white} !important;
    }

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      position: static;
      width: 100%;
      padding: 10px 15px;
      transform: translateY(0%);
      margin-bottom: 0px;
      height: 80px;
      border-bottom: 1px solid ${application.palette.grayline};

      & > * {
        color: ${application.palette.black} !important;
      }

      & > h2 {
        font-size: 21px;
      }
    }
  `}
`);

export const CustomerProductsDetailsButtonStyled = Styled(
  'CustomerProducts',
  'CustomerProductsDetailsButtonStyled'
)(styled('button')`
  cursor: pointer;
  appearance: none;
  border: 2px solid transparent;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  

  ${({ theme: { application }, disabled }) => css`
    background-color: ${application.palette.black};
    color: ${application.palette.white};
    opacity: ${disabled ? '0.3' : '1'};
    pointer-events: ${disabled ? 'none' : 'all'};
  `}
`);

export const DetailsListStyled = Styled(
  'CustomerProducts',
  'DetailsListStyled'
)(styled('ul')`
  list-style: none;
  padding: 0;

  & li + li {
    margin-top: 20px;
  }
`);

export const DetailsListItemStyled = Styled(
  'CustomerProducts',
  'DetailsListItemStyled'
)(styled('li')`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  & > div {
    width: 100%;
  }

  ${({ theme: { application } }) => css`
    & > span:first-child {
      display: block;
      text-align: left;
      margin-bottom: 10px;
      min-width: 35%;
      font-size: 16px;
      font-weight: 400;
      color: ${application.palette.grayboro};
    }

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      align-items: flex-start;
    }
  `}
`);
