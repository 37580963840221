import { Fragment } from 'react';
import { Box, IconButton, Table, TableBody, TableHead, useTheme } from '@mui/material';
import { ArrowForwardIos, Close, MoreHoriz } from '@mui/icons-material';
import { inject, observer } from 'mobx-react';
import { Formik } from 'formik';

import Component from '@framework/decorators/Component';

import {
  HighlightMatchStyled,
  CircleWrapperStyled,
  ButtonStyled,
  FormControlStyled,
  OutlinedInputStyled,
} from '@framework/styles/App';

import {
  TableRowHeadStyled,
  TableCellHeadStyled,
  TableRowBodyStyled,
  TableCellBodyStyled,
  TableCardStyled,
} from '@framework/styles/Table';

const SearchComponent = Component('SearchComponent')(props => {
  const {
    store: { helperStore, localesStore },
    models: { SearchModel },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile();

  const { data, searchData, searchResultsValue, verifySearch, handleSearchCall, handleDetailsCall } = SearchModel;

  return (
    <Fragment>
      <Box
        display="grid"
        p={isMobile ? '10px' : '20px'}
        mb={isMobile ? '20px' : '20px'}
        bgcolor={theme.application.palette.whitesmoke}
      >
        <Formik
          initialValues={
            helperStore.previousSearchValue || {
              email: '',
              firstName: '',
              lastName: '',
              zipCode: '',
            }
          }
          validate={verifySearch}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={data => handleSearchCall(data)}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Fragment>
              <Box
                display="grid"
                gridTemplateColumns={isMobile ? '100%' : '1fr 200px'}
                gridTemplateRows={isMobile && '35px'}
                columnGap={isMobile ? '10px' : '20px'}
              >
                <FormControlStyled>
                  <OutlinedInputStyled
                    name="email"
                    placeholder={localesStore.translate('customer.search.controls.input.email') + ' *'}
                    value={values.email}
                    onChange={handleChange}
                    onKeyPress={({ key }) => key === 'Enter' && handleSubmit()}
                    endAdornment={
                      values.email.length > 0 && (
                        <IconButton
                          onClick={() =>
                            handleChange({
                              target: { name: 'email', value: '' },
                            })
                          }
                        >
                          <Close />
                        </IconButton>
                      )
                    }
                  />
                </FormControlStyled>
                {!isMobile && (
                  <ButtonStyled onClick={handleSubmit} disabled={'general' in verifySearch(values)}>
                    {localesStore.translate('customer.search.controls.search')}
                  </ButtonStyled>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px 0',
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: '1',
                      height: '1px',
                      background: theme.application.palette.darkgray,
                    }}
                  />
                  <Box m="0 20px" color={theme.application.palette.darkgray}>
                    {localesStore.translate('placeholder.label.or')}
                  </Box>
                  <Box
                    sx={{
                      flexGrow: '1',
                      height: '1px',
                      background: theme.application.palette.darkgray,
                    }}
                  />
                </Box>
              </Box>
              <Box display="grid" gridTemplateColumns={isMobile ? '100%' : '1fr 200px'} columnGap="20px">
                <Box
                  display="grid"
                  fontSize="14px"
                  gridTemplateColumns={isMobile ? '1fr 1fr 70px' : 'repeat(3, 1fr)'}
                  gridTemplateRows={isMobile && '35px'}
                  columnGap={isMobile ? '10px' : '20px'}
                >
                  <FormControlStyled>
                    <OutlinedInputStyled
                      name="firstName"
                      placeholder={localesStore.translate('customer.search.controls.input.firstName') + ' *'}
                      value={values.firstName}
                      onChange={handleChange}
                      onKeyPress={({ key }) => key === 'Enter' && handleSubmit()}
                      endAdornment={
                        values.firstName.length > 0 && (
                          <IconButton
                            onClick={() =>
                              handleChange({
                                target: { name: 'firstName', value: '' },
                              })
                            }
                          >
                            <Close />
                          </IconButton>
                        )
                      }
                    />
                  </FormControlStyled>
                  <FormControlStyled>
                    <OutlinedInputStyled
                      name="lastName"
                      placeholder={localesStore.translate('customer.search.controls.input.lastName') + ' *'}
                      value={values.lastName}
                      onChange={handleChange}
                      onKeyPress={({ key }) => key === 'Enter' && handleSubmit()}
                      endAdornment={
                        values.lastName.length > 0 && (
                          <IconButton
                            onClick={() =>
                              handleChange({
                                target: { name: 'lastName', value: '' },
                              })
                            }
                          >
                            <Close />
                          </IconButton>
                        )
                      }
                    />
                  </FormControlStyled>
                  <FormControlStyled>
                    <OutlinedInputStyled
                      name="zipCode"
                      placeholder={localesStore.translate('customer.search.controls.input.zipCode') + ' *'}
                      value={values.zipCode}
                      onChange={handleChange}
                      onKeyPress={({ key }) => key === 'Enter' && handleSubmit()}
                      endAdornment={
                        values.zipCode.length > 0 && (
                          <IconButton
                            onClick={() =>
                              handleChange({
                                target: { name: 'zipCode', value: '' },
                              })
                            }
                          >
                            <Close />
                          </IconButton>
                        )
                      }
                    />
                  </FormControlStyled>
                </Box>
                <Box display="flex" alignItems="center">
                  {!isMobile && searchResultsValue > 0 && (
                    <Fragment>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '45px',
                          height: '30px',
                          borderRadius: '15px',
                          fontSize: searchResultsValue > 100 ? '14px' : '16px',
                          background: theme.application.palette.black,
                          color: theme.application.palette.white,
                        }}
                      >
                        {searchResultsValue}
                      </Box>
                      <Box ml="10px">
                        {localesStore.translate(
                          `placeholder.label.customer.${searchResultsValue !== 0 ? 'result' : 'results'}`
                        )}
                      </Box>
                    </Fragment>
                  )}
                </Box>

                {isMobile && (
                  <Box mt="20px" display="grid" gridTemplateColumns="100%" gridTemplateRows="35px" columnGap="10px">
                    <ButtonStyled onClick={handleSubmit} disabled={'general' in verifySearch(values)}>
                      {localesStore.translate('customer.search.controls.search')}
                    </ButtonStyled>
                  </Box>
                )}
              </Box>
            </Fragment>
          )}
        </Formik>
      </Box>
      <Box
        overflow="auto"
        p={isMobile ? '10px' : '0px'}
        bgcolor={isMobile ? theme.application.palette.whitesmoke : null}
      >
        {isMobile && searchResultsValue > 0 && (
          <Box
            sx={{
              position: 'fixed',
              left: '50%',
              bottom: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '30px',
              borderRadius: '15px',
              padding: '0 10px',
              transform: 'translateX(-50%)',
              fontSize: searchResultsValue > 100 ? '12px' : '14px',
              background: theme.application.palette.black,
              color: theme.application.palette.white,
              opacity: '0.5',
            }}
          >
            {`${searchResultsValue} ${localesStore.translate(
              `placeholder.label.customer.${searchResultsValue !== 0 ? 'result' : 'results'}`
            )}`}
          </Box>
        )}
        {isMobile ? (
          data.map(item => (
            <TableCardStyled
              key={item.customerId}
              $grid={'1fr'}
              onClick={handleDetailsCall.bind(this, item.customerId)}
            >
              <Box>
                <Box p="5px 0 15px" borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}>
                  <Box fontSize={16} fontWeight={600}>
                    <HighlightMatchStyled
                      value={`${localesStore.translate(`placeholder.label.salutation.${item.salutation}`)} ${
                        item.firstName
                      } ${item.lastName}`}
                      regexp={
                        searchData.firstName && searchData.lastName
                          ? searchData.firstName + ' ' + searchData.lastName
                          : ''
                      }
                    />
                  </Box>
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  p="15px 0"
                  borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                >
                  <Box fontSize={12}>{localesStore.translate('customer.search.label.customer')}:</Box>
                  <Box fontSize={12} textAlign="right">
                    {item.customerId}
                  </Box>
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  p="15px 0"
                  borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                >
                  <Box fontSize={12}>{localesStore.translate('customer.search.label.address')}:</Box>
                  <Box fontSize={12} textAlign="right" display="flex" flexDirection="column">
                    <span>{`${item.addresses[0]?.street} ${item.addresses[0]?.streetNumber}`}</span>
                    <span>
                      <HighlightMatchStyled
                        regexp={searchData.zipCode || ''}
                        value={item.addresses[0]?.postalCode || ''}
                      />
                      {` ${item.addresses[0]?.city}`}
                    </span>
                    <span>{item.phone}</span>
                  </Box>
                </Box>

                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  p="15px 0"
                  borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                >
                  <Box fontSize={12}>{localesStore.translate('customer.search.label.email')}:</Box>
                  <Box fontSize={12} textAlign="right">
                    <HighlightMatchStyled regexp={searchData.email || ''} value={item.email} />
                  </Box>
                </Box>

                <Box display="flex" justifyContent="flex-end" p="15px 0 0">
                  <CircleWrapperStyled>
                    <MoreHoriz />
                  </CircleWrapperStyled>
                </Box>
              </Box>
            </TableCardStyled>
          ))
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRowHeadStyled>
                <TableCellHeadStyled>{localesStore.translate('customer.search.label.name')}</TableCellHeadStyled>
                <TableCellHeadStyled>{localesStore.translate('customer.search.label.customer')}</TableCellHeadStyled>
                <TableCellHeadStyled>{localesStore.translate('customer.search.label.address')}</TableCellHeadStyled>
                <TableCellHeadStyled>{localesStore.translate('customer.search.label.email')}</TableCellHeadStyled>
                <TableCellHeadStyled />
              </TableRowHeadStyled>
            </TableHead>
            {searchResultsValue > 0 && (
              <TableBody>
                {data.map(item => (
                  <TableRowBodyStyled
                    padding="30px 20px"
                    key={item.customerId}
                    onClick={handleDetailsCall.bind(this, item.customerId)}
                  >
                    <TableCellBodyStyled>
                      <HighlightMatchStyled
                        value={`${localesStore.translate(`placeholder.label.salutation.${item.salutation}`)} ${
                          item.firstName
                        } ${item.lastName}`}
                        regexp={
                          searchData.firstName && searchData.lastName
                            ? searchData.firstName + ' ' + searchData.lastName
                            : ''
                        }
                      />
                    </TableCellBodyStyled>
                    <TableCellBodyStyled>{item.customerId}</TableCellBodyStyled>
                    <TableCellBodyStyled>
                      <Box display="flex" flexDirection="column">
                        <span>{`${item.addresses[0]?.street} ${item.addresses[0]?.streetNumber}`}</span>
                        <span>
                          <HighlightMatchStyled
                            regexp={searchData.zipCode || ''}
                            value={item.addresses[0]?.postalCode || ''}
                          />
                          {` ${item.addresses[0]?.city}`}
                        </span>
                        <span>{item.phone}</span>
                      </Box>
                    </TableCellBodyStyled>
                    <TableCellBodyStyled>
                      <HighlightMatchStyled regexp={searchData.email || ''} value={item.email} />
                    </TableCellBodyStyled>
                    <TableCellBodyStyled>
                      <ArrowForwardIos />
                    </TableCellBodyStyled>
                  </TableRowBodyStyled>
                ))}
              </TableBody>
            )}
          </Table>
        )}
      </Box>
      {searchResultsValue === 0 && (
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={isMobile ? theme.application.palette.whitesmoke : null}
        >
          {localesStore.translate('customer.search.label.description')}
        </Box>
      )}
    </Fragment>
  );
});

export default inject('store', 'models')(observer(SearchComponent));
