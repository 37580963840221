import {inject, observer} from "mobx-react";
import {Fragment} from "react";
import {Box, useTheme} from "@mui/material";
import {Close, CloudDownloadOutlined} from "@mui/icons-material";
import i18nIsoCountries from "i18n-iso-countries";
import {format} from "date-fns";

import { FormCheckboxStyled } from "@framework/styles/Form";
import {DialogContentStyled, DialogStyled, DialogTitleStyled, ModalAnimation} from "@framework/styles/App";

const ComplaintModal = inject(
    'store',
    'models'
  )(
    observer(props => {
      const {
        store: { helperStore, localesStore },
        models: { ComplaintsModel, ComplaintsProcessingModel },
      } = props;
      
      const translations = i18nIsoCountries.getNames(localesStore.currentLocale, { select: 'official' });

      const theme = useTheme(),
        isMobile = helperStore.isMobile(),
        {
          isDetailsToggled,
          isInfoToggled,
          details,
          handleWindowClose,
        } = ComplaintsModel;
      
      const {
        Destination
      } = ComplaintsProcessingModel;
      
      const {
        images,
        customerAddress,
        comment,
      } = details ?? { };
      
      const customerSignature = (images ?? []).find(({ type }) => type === 'customerSignature');
      const merchantSignature = (images ?? []).find(({ type }) => type === 'merchantSignature');
      const articleImages = (images ?? []).filter(({ type, image }) => type !== 'customerSignature' && type !== 'merchantSignature' && image);
      
      return (
        <DialogStyled
          fullScreen={isMobile}
          fullWidth
          maxWidth="lg"
          open={isDetailsToggled}
          TransitionComponent={ModalAnimation}
          keepMounted
          onClose={handleWindowClose}
        >
          {isDetailsToggled && (
            <Fragment>
              <DialogTitleStyled>
                <Box
                  sx={
                    isMobile
                      ? {
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          fontSize: '18px',
                          textAlign: 'center',
                        }
                      : {}
                  }
                >
                  Details der Reklamation
                </Box>
                <Box
                  onClick={handleWindowClose}
                  sx={
                    isMobile
                      ? {
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '20px',
                        }
                      : { cursor: 'pointer' }
                  }
                >
                  <Close />
                </Box>
              </DialogTitleStyled>
              <DialogContentStyled grid overflow>
                <Box
                  sx={{
                    gap: '20px',
                    display: 'grid',
                    gridTemplateColumns: isMobile ? '100%' : '3fr minmax(320px, 1fr)',
                    gridTemplateRows: isMobile ? 'max-content max-content' : 'auto',
                  }}
                >
                  <Box
                    sx={{
                      gap: '20px',
                      display: 'grid',
                      gridTemplateRows: 'max-content max-content'
                    }}
                  >
                    {
                      customerAddress ? (
                        <Box
                          sx={{
                            padding: '20px',
                            background: theme.application.palette.whitesmoke,
                            gap: '15px',
                            display: 'flex',
                            fontSize: '15px',
                            flexDirection: 'column',
                            '& div': {
                              overflow: 'hidden',
                              wordWrap: 'nowrap',
                              textOverflow: 'ellipsis'
                            }
                          }}
                        >
                          <div><strong>Kunden-Nummer:</strong> {customerAddress?.customerId ?? '-'}</div>
                          <Box sx={{display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', gap: isMobile ? '15px' : '20px'}}>
                            <div><strong>Vorname:</strong> {customerAddress?.firstName ?? '-'}</div>
                            <div><strong>Nachname:</strong> {customerAddress?.lastName ?? '-'}</div>
                          </Box>
                          <Box sx={{display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', gap:  isMobile ? '15px' : '20px'}}>
                            <div><strong>Mobil:</strong> {customerAddress?.addressLine2 ?? '-'}</div>
                            <div><strong>E-mail:</strong> {customerAddress?.email ?? '-'}</div>
                          </Box>
                          <Box sx={{display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', gap:  isMobile ? '15px' : '20px'}}>
                            <div><strong>Strasse:</strong> {customerAddress?.street || customerAddress?.streetNumber ? [
                              customerAddress?.street || '',
                              customerAddress?.streetNumber || ''
                            ].join(' ') : '-'}</div>
                            <Box sx={{display: 'grid', gridTemplateColumns: '3fr 2fr', gap: '10px'}}>
                              <div><strong>Land:</strong> {customerAddress?.country ? translations[customerAddress?.country] : '-'}</div>
                              <div><strong>PLZ:</strong> {customerAddress?.postalCode ?? '-'}</div>
                            </Box>
                          </Box>
                        </Box>
                      ) : null
                    }
                    
                    <Box
                      sx={{
                         padding: '20px',
                         background: theme.application.palette.whitesmoke,
                      }}
                    >
                      <Box sx={{ fontSize: '18px', marginBottom: '15px' }}><strong>Details zum Artikel</strong></Box>
                      <Box sx={{ display: 'flex', flexDirection: !isMobile ? 'row' : 'column', flexWrap: 'wrap', gap: '20px', justifyContent: 'space-between' }}>
                        <Box sx={{display: 'grid', gridTemplateColumns: 'repeat(2, max-content)', gap: '10px'}}>
                          <Box sx={{
                            display: 'grid', gridTemplateColumns: isMobile ? '100%' : 'max-content 1fr', gap: '10px', fontSize: '15px',
                            '& > *': {
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}>
                            {
                              isMobile ? (
                                 <>
                                  <strong>Artikel:</strong><span>{details.articleNumber}</span>
                                  <strong>Farbe:</strong><span>{details.articleColor}</span>
                                  <strong>Größe:</strong><span>{details.articleSize}</span>
                                  <strong>EAN:</strong><span>{details.articleGtin ?? '-'}</span>
                                  <strong>Kaufdatum:</strong><span>{details.complaintType !== Destination.ComplaintOnline ? format(+details.boughtDate, 'dd MMM, yyyy') : '-'}</span>
                                  <strong>Reklamationsgrund:</strong><span>{details.complaintReason}</span>
                                </>
                              ) : (
                                <>
                                  <span>Artikel:</span><span>{details.articleNumber}</span>
                                  <span>Farbe:</span><span>{details.articleColor}</span>
                                  <span>Größe:</span><span>{details.articleSize}</span>
                                  <span>EAN:</span><span>{details.articleGtin ?? '-'}</span>
                                  <span>Kaufdatum:</span><span>{details.complaintType !== Destination.ComplaintOnline ?  format(+details.boughtDate, 'dd MMM, yyyy') : '-'}</span>
                                  <span>Reklamationsgrund:</span><span>{details.complaintReason}</span>
                                </>
                              )
                            }
                          </Box>
                        </Box>
                        <Box
                          sx={{display: 'grid', width: '100%', maxWidth: '270px', gridAutoRows: '50px', gap: '10px'}}>
                          {
                            articleImages.map(({type, image}) => (
                              <Box onClick={() => helperStore.toggleUrl(image)} sx={{
                                display: 'grid',
                                gridTemplateColumns: '50px 1fr',
                                alignItems: 'center',
                                gap: '5px',
                                padding: '5px', background: 'white', cursor: 'pointer' }}>
                                <Box sx={{ minWidth: '30px', height: '30px', opacity: '0.4', '& svg':{ width: '100%', height: '100%' } }}>
                                  <CloudDownloadOutlined />
                                </Box>
                                <Box sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, .5)'  }}>
                                  {localesStore.translate(`placeholder.image.${type}`)}
                                </Box>
                              </Box>
                            ))
                          }
                        </Box>
                      </Box>
                      <Box sx={{ fontSize: '15px', marginTop: '10px' }}>
                        Kommentar: {comment ?? '-'}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      padding: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      background: theme.application.palette.whitesmoke,
                    }}
                   >
                     <Box>
                       <Box sx={{ fontSize: '18px', marginBottom: '15px' }}><strong>Details zur Reklamation</strong></Box>
                       <Box sx={{
                         display: 'grid', gridTemplateColumns: 'max-content 1fr', gap: '10px', fontSize: '15px',
                         '& > *': {
                           width: '100%',
                           whiteSpace: 'nowrap',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                         },
                       }}>
                         <span>Datum:</span><span>{format(+details.created, 'dd MMM, yyyy') + ' ' + format(+details.created, 'HH:mm') + ' ' + localesStore.translate('placeholder.label.time')}</span>
                         <span>Warenebene:</span><span>{details.purchaseType || '-'}</span>
                         {
                           details.ordNr ? (
                             <>
                               <span>ORD-Nummer:</span><span>{details.ordNr}</span>
                             </>
                           ) : null
                         }
                         <span>Kaufebene:</span><span>{details.complaintType}</span>
                         <span>Mitarbeiter:</span><span>{details.merchantId}</span>
                         <span>Vorgang:</span><span>{details.complaintId}</span>
                       </Box>
                     </Box>
                    <Box sx={{ display: 'grid', gridTemplateRows: 'max-content max-content max-content' }}>
                       <Box sx={{ margin: '10px 0', height: '50px', '& *' :{ whiteSpace: 'pre-line !important', fontSize: '14px !important' } }}>
                        <FormCheckboxStyled
                          name='returnBookedAtCheckout'
                          value={details.returnBookedAtCheckout}
                          placeholder='Retoure an Kasse gebucht'
                          onChange={() => {}}
                        />
                      </Box>
                      {
                        customerSignature?.image ? (
                          <Box>
                            <Box sx={{ fontSize: '14px', color: 'black', opacity: '0.7', marginBottom: '10px', }}>Unterschrift des Kunden:</Box>
                            <Box sx={{ width: '100%', height: isMobile ? '150px' : '100px', background: 'white' }}>
                              <Box component="img" sx={{ objectFit: 'contain', objectPosition: 'center', width: '100%', height: '100%' }} src={customerSignature.image} />
                            </Box>
                          </Box>
                        ) : null
                      }
                      <Box sx={{ marginTop: '10px' }}>
                        <Box sx={{ fontSize: '14px', color: 'black', opacity: '0.7', marginBottom: '10px', }}>Unterschrift des Mitarbeiter:</Box>
                        <Box sx={{ width: '100%', height: isMobile ? '150px' : '100px', background: 'white' }}>
                          <Box component="img" sx={{ objectFit: 'contain', objectPosition: 'center', width: '100%', height: '100%' }} src={merchantSignature?.image} />
                        </Box>
                      </Box>
                     </Box>
                   </Box>
                   {
                      isMobile ? (
                        <Box sx={{ height: '20px' }} />
                      ) : null
                    }
              </Box>
              </DialogContentStyled>
            </Fragment>
          )}
        </DialogStyled>
      );
    })
);

export default ComplaintModal;
