import Webcam from "react-webcam";
import { inject, observer } from 'mobx-react';
import {Fragment, useCallback, useRef} from 'react';
import { Box, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';

import Modal from '@framework/decorators/Modal';

import {
  BarcodeScannerDialogStyled,
  BarcodeScannerTitleStyled,
  BarcodeScannerContentStyled,

} from '@framework/styles/Barcode';

import { ModalAnimation } from '@framework/styles/App';

const BarcodeScannerModal = Modal('BarcodeScannerModal')(props => {
  const {
    models: { CameraModel },
    store: { debug, helperStore },
  } = props;

  const theme = useTheme();
  
  const {
    isToggled,
    handleCaptureCall,
    handleCloseCall
  } = CameraModel;
  
  const webcamRef = useRef(null);
  
  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      handleCaptureCall(imageSrc);
    },
    [webcamRef, handleCaptureCall]
  );

  return (
    <BarcodeScannerDialogStyled
      fullScreen
      open={isToggled}
      onClose={handleCloseCall}
      TransitionComponent={ModalAnimation}
      sx={{ zIndex: '10000001' }}
    >
      {isToggled ? (
        <Fragment>
          <BarcodeScannerTitleStyled>
            <Box display="flex" justifyContent="center" width="100%">
              <Box
                sx={
                  debug
                    ? {
                      position: 'relative',
                      display: 'flex',
                      height: '40px',
                      '& > img': {
                        opacity: '0.3',
                      },
                      '&::after': {
                        content: '"[prelive]"',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        letterSpacing: '5px',
                        lineHeight: '2',
                        fontSize: '20px',
                        fontWeight: '400',
                        color: theme.application.palette.danger,
                      },
                    }
                    : {
                      display: 'flex',
                      height: '40px',
                    }
                }
              >
                <img
                  height="100%"
                  src="images/ig-logotype-dark.png"
                  alt=""
                />
              </Box>
            </Box>

            <Box
              onClick={handleCloseCall}
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                right: '20px',
                lineHeight: 0,
              }}
            >
              <Close />
            </Box>
          </BarcodeScannerTitleStyled>
          <BarcodeScannerContentStyled>
             <Box sx={{ width: '100%', height: '100%', '& video': { width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' } }}>
               <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={1}
                  forceScreenshotSourceSize={true}
                  videoConstraints={{
                    facingMode: 'environment',
                    width: { ideal: 1920 },
                    height: { ideal: 1080 }
                  }}
               />
             </Box>
          </BarcodeScannerContentStyled>
          <Box
            onClick={capture}
            sx={{
              position: 'absolute',
              bottom: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
              cursor: 'pointer',
              borderRadius: '50%',
              width: '45px',
              height: '45px',
              padding: '8px',
              background: 'white',
            }}
          >
            <Box
             sx={{
                background: 'white',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                border: '2px solid black'
              }}
            />
          </Box>
        </Fragment>
      ) : null}
    </BarcodeScannerDialogStyled>
  );
});

export default inject('store', 'models')(observer(BarcodeScannerModal));
