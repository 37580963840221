import swal from 'sweetalert';
import i18nIsoCountries from 'i18n-iso-countries';

import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme, Collapse } from '@mui/material';
import { Fragment, useEffect } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import View from '@framework/decorators/View';

import ShoppingCart from '@framework/icons/ShoppingCart';
import Shop from '@framework/icons/Shop';
import Home from '@framework/icons/Home';

import ProductDetailsComponent from '@framework/components/ProductDetails';

import {
  BackButtonStyled,
  ButtonOutlinedStyled,
  ButtonStyled,
  LayoutContainerStyled,
  PageContainerStyled,
  ToolbarStyled,
} from '@framework/styles/App';

import { DetailsVoucherButtonStyled } from '@framework/styles/CustomerDetails';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const OrderView = View('Order')(props => {
  const {
    models: { OrderModel, OrdersModel, CartProcessingModel, CartModel, CustomerDetailsModel, ArticleModel },
    store: { helperStore, localesStore, storageStore },
  } = props;

  const theme = useTheme(),
    navigate = useNavigate(),
    isMobile = helperStore.isMobile(),
    translations = i18nIsoCountries.getNames(localesStore.currentLocale, {
      select: 'official',
    });

  const {
      Customer: { Guest },
      orderData,
    } = CartProcessingModel,
    {
      Status: { Creation, Deleted, Parked },
      Type: { Wholesale, InStore },
    } = OrdersModel,
    { isCartToggled, handleToggleCart, create } = OrderModel;

  useEffect(() => {
    if (!orderData) navigate('/cart');
  }, []);

  useEffect(() => {
    CartModel.cartMounted();
    handleToggleCart();
  }, [CartModel.cart]);

  return orderData ? (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.screensAnimationDirection)}>
      <LayoutContainerStyled $minHeight>
        <ToolbarStyled $centered>
          <BackButtonStyled
            onClick={e => {
              e.preventDefault();

              if (CartModel.total === 0) {
                helperStore.cartAnimationDirection = helperStore.Direction.Ltr;
                return navigate('/cart');
              }

              swal({
                title: localesStore.translate('modal.checkout.back.label.title'),
                text: localesStore.translate('modal.checkout.back.label.description'),
                content: {
                  element: 'button',
                  attributes: {
                    className: 'swal-button--crossexit',
                    onclick: () => swal.close(),
                  },
                },
                buttons: {
                  back: localesStore.translate('modal.checkout.back.controls.cancel'),
                  apply: localesStore.translate('modal.checkout.back.controls.apply'),
                },
              }).then(value => {
                if (value === 'apply') {
                  create(Parked, () => {
                    helperStore.cartAnimationDirection = helperStore.Direction.Rtl;
                    navigate('/');
                    CartProcessingModel.reset();
                    CartModel.reset();
                  });
                } else if (value === 'back') {
                  if (CartProcessingModel.orderData.processId) {
                    create(Deleted, () => {
                      helperStore.cartAnimationDirection = helperStore.Direction.Rtl;
                      navigate('/');
                      CartProcessingModel.reset();
                      CartModel.reset();
                    });
                  } else {
                    helperStore.cartAnimationDirection = helperStore.Direction.Rtl;
                    navigate('/');
                    CartProcessingModel.reset();
                    CartModel.reset();
                  }
                }
              });
            }}
          >
            {localesStore.translate('placeholder.label.back')}
          </BackButtonStyled>
          <h2>{localesStore.translate('page.title.order.user')}</h2>
        </ToolbarStyled>
        <Box p={isMobile ? '0 10px' : '0 20px'} bgcolor={theme.application.palette.whitesmoke}>
          <Box p="10px" bgcolor={theme.application.palette.whitesmoke} />
          <Box
            p={isMobile ? '20px 15px' : '30px 40px'}
            bgcolor={theme.application.palette.white}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Box width={isMobile ? '24px' : '40px'} height={isMobile ? '24px' : '40px'}>
                <ShoppingCart />
              </Box>

              {!isMobile && (
                <Box component="span" ml="30px">
                  {localesStore.translate('page.title.cart')}
                </Box>
              )}
            </Box>

            <Box fontSize={isMobile ? 14 : 16}>
              {CartModel.total + ' ' + localesStore.translate('placeholder.label.article')}
            </Box>

            <Box display="flex" alignItems="center">
              <Box fontWeight={600} fontSize={isMobile ? 14 : 16}>
                {CartModel.cart
                  .reduce((acc, next) => acc + next.price * next.quantity, 0)
                  .toFixed(2)
                  .replace('.', ',') +
                  ' ' +
                  (CartModel.articles?.[0]?.currency ? getSymbolFromCurrency(CartModel.articles?.[0]?.currency) : '') +
                  ' ' +
                  localesStore.translate('modal.article.label.price')}
              </Box>

              <Box ml="30px" display="flex" alignItems="center" sx={{ cursor: 'pointer' }} onClick={handleToggleCart}>
                <Box mr="5px" lineHeight="0">
                  {isCartToggled ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </Box>
                {!isMobile &&
                  localesStore.translate(isCartToggled ? 'placeholder.label.reduce' : 'placeholder.label.more')}
              </Box>
            </Box>
          </Box>
          <Box p="10px" bgcolor={theme.application.palette.whitesmoke} />
          <Collapse in={isCartToggled}>
            <Box bgcolor={theme.application.palette.whitesmoke}>
              {CartModel.articles.map(item => (
                <ProductDetailsComponent
                  key={item.gtin}
                  store={{ helperStore, localesStore }}
                  models={{ CartModel, ArticleModel }}
                  item={{
                    ...item,
                    ...(CartModel.cart.find(article => article.gtin === item.gtin) || {}),
                  }}
                />
              ))}
            </Box>
          </Collapse>
        </Box>

        <Box
          display="grid"
          m="20px 0"
          gridTemplateColumns={isMobile ? '1fr' : 'minmax(300px, 30%) 1fr'}
          gridTemplateRows={!isMobile && '100%'}
          flexGrow="1"
          minHeight={isMobile ? 'unset' : '360px'}
        >
          <Box
            p="20px"
            height={isMobile ? 'auto' : '100%'}
            overflow={isMobile ? 'unset' : 'hidden auto'}
            bgcolor={theme.application.palette.whitesmoke}
          >
            {orderData.destination === Wholesale ? (
              <Fragment>
                <Box fontWeight={600}>{localesStore.translate('placeholder.label.invoice') + ': '}</Box>

                <Box m="20px 0">
                  {localesStore.translate(`placeholder.label.salutation.${orderData.details?.salutation}`)}
                  <br />
                  {orderData.details?.firstName + ' ' + orderData.details?.lastName}
                  <br />
                  {orderData.details?.street + ' ' + orderData.details?.streetNumber}
                  <br />
                  {orderData.details?.postalCode + ' ' + orderData.details?.city}
                  <br />
                  {translations && orderData.details?.country && translations[orderData.details?.country?.toUpperCase()]
                    ? translations[orderData.details?.country?.toUpperCase()]
                    : orderData.details?.country}
                  <br />
                </Box>

                <Box fontWeight={600}>{localesStore.translate('placeholder.label.delivery') + ': '}</Box>

                <Box m="20px 0">
                  {storageStore.storeData.name || ''} <br />
                  <br />
                  {storageStore.storeData.addresses?.[0]?.street ??
                    '' + ' ' + storageStore.storeData.addresses?.[0]?.streetNumber ??
                    ''}
                  <br />
                  {storageStore.storeData.addresses?.[0]?.addressLine1 || ''}
                  <br />
                  {(storageStore.storeData.addresses?.[0]?.postalCode || '') +
                    ' ' +
                    (storageStore.storeData.addresses?.[0]?.city || '')}
                  <br />
                  {translations && translations[storageStore.storeData.addresses?.[0]?.country?.toUpperCase()]
                    ? translations[storageStore.storeData.addresses?.[0]?.country]
                    : storageStore.storeData.addresses?.[0]?.country}
                  <br />
                  {storageStore.storeData.addresses?.[0]?.phone} <br />
                </Box>
              </Fragment>
            ) : orderData.destination === InStore ? (
              <Fragment>
                <Box fontWeight={600}>
                  {!orderData.deliveryAddress
                    ? `${localesStore.translate('placeholder.label.invoice')} / ${localesStore.translate(
                        'placeholder.label.delivery'
                      )}: `
                    : `${localesStore.translate('placeholder.label.invoice')}: `}
                </Box>

                <Box m="20px 0">
                  {localesStore.translate(`placeholder.label.salutation.${orderData.details?.salutation}`)} <br />
                  {orderData.details?.firstName + ' ' + orderData.details?.lastName}
                  <br />
                  {orderData.details?.street + ' ' + orderData.details?.streetNumber}
                  {orderData.details?.addressLine1 ? orderData.details?.addressLine1 : ''}
                  {orderData.details?.addressLine1 ? <br /> : ''}
                  <br />
                  {orderData.details?.postalCode + ' ' + orderData.details?.city}
                  <br />
                  {orderData.details?.addressLine2 || ''}
                  <br/>
                  {translations && translations[orderData.details?.country?.toUpperCase()]
                    ? translations[orderData.details?.country?.toUpperCase()]
                    : orderData.details?.country?.toUpperCase()}
                  <br />
                </Box>

                {Boolean(orderData.deliveryAddress) && (
                  <Fragment>
                    <Box fontWeight={600}>{`${localesStore.translate('placeholder.label.delivery')}: `}</Box>
                    <Box m="20px 0">
                      {localesStore.translate(`placeholder.label.salutation.${orderData.deliveryAddress?.salutation}`)}
                      <br />
                      {orderData.deliveryAddress?.firstName + ' ' + orderData.deliveryAddress?.lastName}
                      <br />
                      {orderData.deliveryAddress?.street + ' ' + orderData.deliveryAddress?.streetNumber}
                      <br />
                      {orderData.deliveryAddress?.addressLine1 ? orderData.deliveryAddress?.addressLine1 : ''}
                      {orderData.deliveryAddress?.addressLine1 ? <br /> : ''}
                      {orderData.deliveryAddress?.postalCode + ' ' + orderData.deliveryAddress?.city}
                      <br />
                      {translations && translations[orderData.deliveryAddress?.country?.toUpperCase()]
                        ? translations[orderData.deliveryAddress?.country?.toUpperCase()]
                        : orderData.deliveryAddress?.country?.toUpperCase()}
                      <br />
                    </Box>
                  </Fragment>
                )}
              </Fragment>
            ) : null}
          </Box>
          <Box
            p={isMobile ? '10px' : '20px 40px'}
            height={isMobile ? 'auto' : '100%'}
            overflow={isMobile ? 'unset' : 'hidden auto'}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {orderData.destination === Wholesale ? (
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
                <Box m={isMobile ? '20px 0' : '0px'}>
                  <Shop width="120" height="120" />
                </Box>

                <Box
                  ml={isMobile ? '0px' : '50px'}
                  textAlign={isMobile ? 'center' : 'left'}
                  flexGrow="1"
                  dangerouslySetInnerHTML={{
                    __html: localesStore.translate('order.label.Wholesale.description'),
                  }}
                ></Box>
              </Box>
            ) : orderData.destination === InStore ? (
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
                <Box m={isMobile ? '20px 0' : '0px'}>
                  <Home width="120" height="120" />
                </Box>

                <Box
                  ml={isMobile ? '0px' : '50px'}
                  textAlign={isMobile ? 'center' : 'left'}
                  flexGrow="1"
                  dangerouslySetInnerHTML={{
                    __html: localesStore.translate('order.label.InStore.description'),
                  }}
                ></Box>
              </Box>
            ) : null}
          </Box>
        </Box>

        <Box mt="20px" display="flex" justifyContent={isMobile ? 'center' : 'space-between'} flexWrap="wrap">
          <Box width={isMobile ? '100%' : '300px'} height="60px">
            <ButtonOutlinedStyled
              onClick={() => {
                swal({
                  title: localesStore.translate('modal.checkout.restart.label.title'),
                  text: localesStore.translate(
                    orderData.destination === InStore && Boolean(orderData.deliveryAddress)
                      ? 'modal.checkout.restart.label.additional'
                      : 'modal.checkout.restart.label.description'
                  ),
                  content: {
                    element: 'button',
                    attributes: {
                      className: 'swal-button--crossexit',
                      onclick: () => swal.close(),
                    },
                  },
                  buttons: {
                    back: localesStore.translate('modal.checkout.restart.controls.cancel'),
                    apply: localesStore.translate(
                      orderData.destination === InStore && Boolean(orderData.deliveryAddress)
                        ? 'modal.checkout.restart.controls.reset'
                        : 'modal.checkout.restart.controls.apply'
                    ),
                  },
                }).then(value => {
                  if (value === 'apply') {
                    helperStore.cartAnimationDirection = helperStore.Direction.Ltr;
                    navigate('/cart');
                    CartProcessingModel.reset();
                  } else if (value === 'back') {
                    helperStore.cartAnimationDirection = helperStore.Direction.Ltr;
                    navigate('/cart');
                  }
                });
              }}
            >
              {localesStore.translate('order.controls.back')}
            </ButtonOutlinedStyled>
          </Box>
          <Box width={isMobile ? '100%' : '300px'} m={isMobile ? '20px 0px 0px 0px' : '0px'} height="60px">
            <ButtonStyled
              $fullWidth
              disabled={CartModel.total === 0}
              onClick={() => {
                let articleNumber = null;

                if (
                  orderData.destination !== Wholesale &&
                  CartModel.articles.some(article => {
                    articleNumber = article.articleNumber;
                    return article.quantity > article.stores.online;
                  })
                ) {
                  return swal({
                    title: localesStore.translate('order.label.error'),
                    text: localesStore.translate('order.label.product').replace('{{articleNumber}}', articleNumber),
                  });
                }

                create(Creation, () => {
                  helperStore.cartAnimationDirection = helperStore.Direction.Rtl;
                  navigate('/');
                  CartProcessingModel.reset();
                  CartModel.reset();
                });
              }}
            >
              {localesStore.translate('order.controls.proceed')}
            </ButtonStyled>
          </Box>
        </Box>
      </LayoutContainerStyled>
    </PageContainerStyled>
  ) : null;
});

export default inject('models', 'store')(observer(OrderView));
