import { createGlobalStyle } from 'styled-components';
import { createTheme, styled } from '@mui/material';

import { deepMerge, isPlainArray, isPlainObject, isReactComponent } from './helpers';

const Styled = (styledModel, target = 'default', debug = false) => {
  let importedModel = null;

  try {
    importedModel = require(`@tenant/styles/${styledModel}`)[target];
  } catch (error) {
    if (debug) console.error(error);
  }

  return rootModel => {
    switch (styledModel) {
      case 'RootStyles': {
        return createGlobalStyle`
          ${rootModel}
          ${isPlainArray(importedModel) ? importedModel : ''}
        `;
      }

      case 'RootTheme': {
        return createTheme({
          application: isPlainObject(importedModel) ? deepMerge(rootModel, importedModel) : rootModel,
        });
      }

      default: {
        return isPlainArray(importedModel)
          ? styled(rootModel)`
              ${importedModel}
            `
          : isReactComponent(importedModel)
          ? importedModel
          : rootModel;
      }
    }
  };
};

export default Styled;
