import { observer, inject } from 'mobx-react';
import { Box, Dialog } from '@mui/material';
import { useCallback, useState } from "react";
import { Close, CloseFullscreen, OpenInFull } from '@mui/icons-material';

import Modal from '@framework/decorators/Modal';

import { DialogWrapperStyled } from '@framework/styles/Loader';
import { ButtonStyled, SignatureContainerStyled, RectangleWrapperStyled } from '@framework/styles/App';

import { ArticleImageStyled } from '@framework/styles/Article';

const CustomerSignatureModal = Modal('CustomerSignatureModal')(props => {
  const {
    store: { helperStore, localesStore },
    models: { CameraModel },
  } = props;
  
  const [isResizable, setResizable] = useState(false);

  const isMobile = helperStore.isMobile();
  
  const {
    image,
    resolve,
    isPreviewToggled,
    handleCloseCall,
    handleRetakeCall,
  } = CameraModel;
  
  const handleResizeToggle = useCallback(() => {
    setResizable(prev => !prev);
  }, []);

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
      open={isPreviewToggled}
      onClose={handleCloseCall}
      sx={{ zIndex: 10000010, '& .MuiDialog-paper': { height: '100%' } }}
    >
      <DialogWrapperStyled
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        p="20px"
        height="100%"
      >
        <Box display="flex" justifyContent="center" alignItems="center" position="relative" width="100%">
          <Box fontSize={28}>{localesStore.translate('modal.imagePreview.label.title')}</Box>
          <Box position="absolute" right="0px" lineHeight={0} sx={{ cursor: 'pointer' }} onClick={handleCloseCall}>
            <Close />
          </Box>
        </Box>
        <SignatureContainerStyled>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              padding: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '& img': {
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'cover !important',
                objectPosition: 'center !important',
              }
          }}>
            <Box
              onClick={handleResizeToggle}
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'rgb(243, 243, 243)',
                zIndex: '10',
              }}
            >
              <RectangleWrapperStyled>
                {!isResizable ? <OpenInFull /> : <CloseFullscreen />}
              </RectangleWrapperStyled>
            </Box>
          
            <ArticleImageStyled
              src={image}
              alternateSrc={image}
              isResizable={isResizable}
            />
          </Box>
        </SignatureContainerStyled>
        <Box
          display="grid"
          width="100%"
          gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
          columnGap="100px"
          rowGap="10px"
          gridTemplateRows={isMobile ? '60px 60px' : '60px'}
        >
          <ButtonStyled $isActive onClick={handleRetakeCall}>
            {localesStore.translate('modal.imagePreview.controls.cancel')}
          </ButtonStyled>
          <ButtonStyled onClick={() => resolve(image)}>
            {localesStore.translate('modal.imagePreview.controls.confirm')}
          </ButtonStyled>
        </Box>
      </DialogWrapperStyled>
    </Dialog>
  );
});

export default inject('models', 'store')(observer(CustomerSignatureModal));
