import { action, computed, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class AppointmentsModel {
  Type = {
    Phone: 'Phone',
    Store: 'Store',
  };

  Status = {
    Opened: 'Open',
    Confirmed: 'Confirmed',
    Callback: 'Callback',
    Refused: 'Refused',
    DeclinedByStore: 'DeclinedByStore',
    DeclinedByStoreCallNeeded: 'DeclinedByStoreCallNeeded',
  };

  Tabs = {
    Opened: 1,
    Confirmed: 2,
    Canceled: 3,
  };

  Settings = {
    PastDisabled: true,
    MinTime: '10',
    MaxTime: '20',
    TimeStep: '15',
    variant: 'international',
  };

  isLoading = false;
  isDataLoaded = false;
  currentTab = this.Tabs.Opened;
  appointments = [];

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isLoading: observable,
      currentTab: observable,
      appointments: observable,
      appointmentsByStatus: computed,
    });
  }

  viewMounted = action(() => {
    const { store } = this.rootModels;

    if (!this.isLoading && !this.isDataLoaded) {
      store.helperStore.toggleLoader(true);
      this.fetchAppointments().catch(error => store.debug && console.error(error));
    }
  });

  fetchAppointments = action(async () => {
    this.isLoading = true;

    const {
      store: { debug, networkStore, storageStore, helperStore },
    } = this.rootModels;

    try {
      const currentDate = new Date().setHours(0, 0, 0, 0);

      const { status, response } = await networkStore.post('addLoadObjectsOfClass', {
        whereClause: `storeId = '${storageStore.storeId}' AND appointmentDate > ${currentDate}`,
        offset: 0,
        orderByName: 'appointmentDate',
        orderByType: 'ASC',
        pageSize: 100,
        relations: [],
        clazz: 'com.innomos.baas.common.model.StoreAppointment',
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "SearchStoreAppointments" Failed!');

      const {
        data: { data },
      } = JSON.parse(response);
      this.appointments = data;
      this.isDataLoaded = true;
    } catch (error) {
      if (debug) console.error(error);
    } finally {
      this.isLoading = false;
      helperStore.toggleLoader(false);
    }
  });

  get appointmentsByOpened() {
    const { Opened } = this.Status;

    const useStatusFilter = item => item.status === Opened;

    return this.appointments.filter(useStatusFilter);
  }

  get appointmentsByConfirmed() {
    const { Confirmed } = this.Status;

    const useStatusFilter = item => item.status === Confirmed;

    return this.appointments.filter(useStatusFilter);
  }

  get appointmentsByCanceled() {
    const { DeclinedByStore, DeclinedByStoreCallNeeded } = this.Status;

    const useStatusFilter = item => item.status === DeclinedByStore || item.status === DeclinedByStoreCallNeeded;

    return this.appointments.filter(useStatusFilter);
  }

  get appointmentsByStatus() {
    const {
      Tabs: { Opened, Confirmed, Canceled },
      currentTab,
    } = this;

    switch (currentTab) {
      case Opened:
        return this.appointmentsByOpened;
      case Confirmed:
        return this.appointmentsByConfirmed;
      case Canceled:
        return this.appointmentsByCanceled;
      default:
        return [];
    }
  }

  handleTabCall = action(tab => {
    this.currentTab = tab;
  });
}

export default Model('AppointmentsModel')(AppointmentsModel);
