import { Container, styled, css } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const HeaderStyled = Styled(
  'Header',
  'HeaderStyled'
)(styled('header')`
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 10;

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.white};
    border-bottom: 1px solid ${application.palette.grayline};
  `}
`);

export const HeaderContainerStyled = Styled(
  'Header',
  'HeaderContainerStyled'
)(styled(Container)`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  height: inherit;
`);

export const HeaderSectionStyled = Styled(
  'Header',
  'HeaderSectionStyled'
)(styled('div')`
  display: flex;
  align-items: center;
  height: inherit;

  &[data-section='start'] {
    justify-content: flex-start;
  }

  &[data-section='center'] {
    justify-content: center;

    ${props =>
            props.$dev &&
            css`
            position: relative;

            & > img {
              opacity: 0.3;
            }

            &::after {
              content: '[prelive]';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              text-align: center;
              letter-spacing: 5px;
              line-height: 4;
              font-size: 20px;
              pointer-events: none;
              color: ${props.theme.application.palette.danger};
            }
          `}
  }

  &[data-section='end'] {
    justify-content: flex-end;
    align-items: center;
  }
`);

export const HeaderLogotypeStyled = Styled(
  'Header',
  'HeaderLogotypeStyled'
)(styled('img')`
  cursor: pointer;
  display: block;
  height: 40px;
`);

export const HeaderMenuButtonStyled = Styled(
  'Header',
  'HeaderMenuButtonStyled'
)(styled('button')`
  padding: 0;
  height: 25px;
  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
  `}
`);

export const HeaderLabelStyled = Styled(
  'Header',
  'HeaderLabelStyled'
)(styled('span')`
  margin: 0 10px;
  font-size: 16px;
  line-height: 1.15;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      display: none;
    }
  `}
`);

export const HeaderCartButtonStyled = Styled(
  'Header',
  'HeaderCartButtonStyled'
)(styled('button')`
  position: relative;
  padding: 0;
  width: 30px;
  height: 30px;

  & > svg {
    font-size: 30px;
  }
`);

export const HeaderCartCounterStyled = Styled(
  'Header',
  'HeaderCartCounterStyled'
)(styled('span')`
  position: absolute;
  display: block;
  top: 3px;
  right: -10px;
  font-size: 11px;
  font-weight: 700;
  padding: 1px 10px;
  border-radius: 10px;
  background-color: #000;
  color: #fff;

  ${({ theme: { application } }) => css`
    color: ${application.palette.white};
    background-color: ${application.palette.black};
  `}
`);
