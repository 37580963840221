import { styled, css, TableRow, TableCell } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const TableHeadGapStyled = Styled(
  'Table',
  'TableHeadGapStyled'
)(styled('div')`
  padding: 5px;

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.whitesmoke};
  `}
`);

export const TableBodyGapStyled = Styled(
  'Table',
  'TableBodyGapStyled'
)(styled(({ className }) => (
  <TableRow className={className}>
    <TableCell colSpan={7} />
  </TableRow>
))`
  & > td {
    padding: 5px;
  }

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.whitesmoke};
  `}
`);

export const TableContainerStyled = Styled(
  'Table',
  'TableContainerStyled'
)(styled('div')`
  padding: 0 10px 10px;
  overflow: auto;
  flex: 1;

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.whitesmoke};
  `}
`);

export const TableRowHeadStyled = Styled(
  'Table',
  'TableRowHeadStyled'
)(styled(TableRow)`
  ${({ theme: { application } }) => css`
    background-color: ${application.palette.black};
  `}
`);

export const TableCellHeadStyled = Styled(
  'Table',
  'TableCellHeadStyled'
)(styled(TableCell)`
  padding: 15px 20px;
  white-space: nowrap;

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.black};
    color: ${application.palette.white};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      padding: 10px;
    }
  `}
`);

export const TableCellBodyStyled = Styled(
  'Table',
  'TableCellBodyStyled'
)(styled(TableCell)`
  cursor: pointer;
  padding: 20px;

  ${({ $isEllipsed }) =>
    $isEllipsed &&
    css`
      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    `}
`);

export const TableRowBodyStyled = Styled(
  'Table',
  'TableCellBodyStyled'
)(styled(TableRow)`
  padding: 20px;

  & > ${TableCellBodyStyled} {
    ${({ theme: { application }, $default, padding, $isHighlighted }) => css`
      background-color: ${application.palette.white};

      ${padding
        ? css`
            padding: ${padding};
          `
        : ''}

      ${$isHighlighted &&
      css`
        border-top: 2px solid ${application.palette.danger};
        border-bottom: 2px solid ${application.palette.danger};

        ${!$default &&
        css`
          &:first-child {
            border-left: 2px solid ${application.palette.danger};
          }

          &:last-child {
            border-right: 2px solid ${application.palette.danger};
          }
        `} @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
          padding: 5px;
        }
      `}
    `}
  }
`);

export const TableCardStyled = Styled(
  'Table',
  'TableCardStyled'
)(styled('div')`
  cursor: pointer;
  display: grid;
  column-gap: 15px;
  padding: 15px;
  margin-bottom: 20px;

  ${({ $isHighlighted, theme: { application }, $grid }) => css`
    grid-template-columns: ${$grid ? $grid : 'max-content 1fr max-content'};
    background-color: ${application.palette.white};

    ${$isHighlighted &&
    css`
      border: 2px solid ${application.palette.danger};
    `}
  `}
`);
