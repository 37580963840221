import { action, computed, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';
import swal from 'sweetalert';
import { format } from 'date-fns';

export class SearchModel {
  isReservation = false;
  isModalWindow = false;

  data = [];

  searchData = null;

  location = {};
  navigate = () => {};

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isModalWindow: observable,
      isReservation: observable,

      data: observable,
      searchData: observable,
      searchResultsValue: computed,

      location: observable,
      navigate: observable,
    });
  }

  get searchResultsValue() {
    return this.data.length;
  }

  register = action((navigate, location) => {
    this.navigate = navigate;
    this.location = location;
  });

  setup = action((isModalWindow = false, isReservation = false) => {
    this.isModalWindow = isModalWindow;
    this.isReservation = isReservation;
  });

  viewMounted = action(() => {
    const {
      store: { helperStore },
    } = this.rootModels;

    if (helperStore.previousSearchValue) {
      this.handleSearchCall(helperStore.previousSearchValue);
    }

    return () => this.reset();
  });

  verifySearch = values => {
    const allFieldsDisabled = !values.email && !values.firstName && !values.lastName && !values.zipCode,
      emailFieldNotEmpty = values.email && (values.firstName || values.lastName || values.zipCode),
      infoFieldsNotEmpty = !values.email && (!values.firstName || !values.lastName || !values.zipCode);

    if (allFieldsDisabled || emailFieldNotEmpty || infoFieldsNotEmpty) return { general: 'Search disabled!' };

    return {};
  };

  fetchSearchResults = action(async (searchFilters, withoutAssign = false) => {
    const {
      store: { debug, networkStore, helperStore },
    } = this.rootModels;

    try {
      helperStore.toggleLoader(true);
      const { status, response } = await networkStore.post('searchCustomer', {
        offset: 0,
        filterParameters: searchFilters,
        pageSize: 100,
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "searchCustomer" failed!');

      const {
        data: { data },
      } = JSON.parse(response);

      if(!withoutAssign) this.data = data;

      return helperStore.mapSearchData(data);
    } catch (error) {
      if (debug) console.error(error);
      return null;
    } finally {
      helperStore.toggleLoader(false);
    }
  });

  fetchResultDetails = action(async customerId => {
    const {
      store: { debug, helperStore, networkStore },
    } = this.rootModels;

    try {
      helperStore.toggleLoader(true);

      const { status, response } = await networkStore.get(`CustomerByCustomerId?customerId=%22${customerId}%22`);

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "CustomerByCustomerId" failed!');

      const {
        data: { data },
      } = JSON.parse(response);

      return await helperStore.mapDetailsData(data[0]);
    } catch (error) {
      if (debug) console.error(error);
      return null;
    } finally {
      helperStore.toggleLoader(false);
    }
  });

  handleSearchCall = action(data => {
    this.searchData = data;
    this.fetchSearchResults(data);
  });

  handleBarcodeScanner = action(() => {
    const { BarcodeScannerModel, store } = this.rootModels;

    const subtitle = store.localesStore.translate('modal.scanner.label.subtitle.customer');

    BarcodeScannerModel.callBarcodeScannerPromise(subtitle).then(value => {
      if(!value) return;
      
      this.handleDetailsCall(value, true, (isError, value) => {
         store.networkStore.trackAnalytics(isError ? 'barcode_scan_not_successful' : 'barcode_scan_successful', 'Customer', value);
      });
    });
  });

  handleDetailsCall = action((customerId, isScanner, callback = () => {}) => {
    const {
      store: { helperStore, localesStore },
      CustomerDetailsModel,
      CartProcessingModel,
      ComplaintsProcessingModel,
      FormModel,
      OrdersModel,
    } = this.rootModels;

    if(this.isComplaints) {
      this.fetchResultDetails(customerId).then(data => {
        if (!data) return;
        
        ComplaintsProcessingModel.isSearchComponent = false;
        
        FormModel.setFormSettings(FormModel.Type.Editor, {
          complaints: true,
          prevData: data,
          include: ['firstName', 'lastName', 'email', 'addressLine2'],
          exclude: ['isEmailContactAllowed', 'signature', 'salutation', 'city', 'postalCode', 'street', 'streetNumber', 'userAgreementAllowed'],
          data: {
            salutation: data.salutation || '',
            birthday: data.birthday ? format(+data.birthday, 'yyyy-MM-dd') : '',
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            phone: data.phone || '',
            store: data.favouriteStoreId || '',
            language: data.preferredLanguage ?? 'DE',
            street: data.address?.street || '',
            streetNumber: data.address?.streetNumber || '',
            city: data.address?.city || '',
            addressLine1: data.address?.addressLine1 || '',
            addressLine2: data.address?.addressLine2 || '',
            email: data.address?.email || '',
            postalCode: data.address?.postalCode || '',
            country: data.address?.country ?? localesStore.currentLocale,
            isEmailContactAllowed: Boolean(data.isEmailContactAllowed),
            isPhoneContactAllowed: Boolean(data.isPhoneContactAllowed),
            isSMSMMSContactAllowed: Boolean(data.isSMSMMSContactAllowed),
            armLength: data?.additionalProperties?.armLength || '',
            cut: data?.additionalProperties?.cut || '',
            size: data?.additionalProperties?.size || '',
            userAgreementAllowed: true,
          },
          onResolve: (customerId, data) => {
            ComplaintsProcessingModel.saveCustomerData(customerId, data).then(() => {
              ComplaintsProcessingModel.navigate('/complaints/create');
              ComplaintsProcessingModel.handleWindowsClose();
            });
          },
        });
        
        this.reset();
      });
    } else if (this.isModalWindow) {
      const isCatalog = this.isReservation;

      this.fetchResultDetails(customerId).then(data => {
        if (!data) return;
        
        if (customerId.includes('CT')) {
            swal({
                title: localesStore.translate('placeholder.label.notice'),
                text: localesStore.translate('modal.migration.label.desc'),
                content: {
                  element: 'button',
                  attributes: {
                    className: 'swal-button--crossexit',
                    onclick: () => swal.close(),
                  },
                },
                buttons: {
                  apply: localesStore.translate('modal.migration.label.button'),
                },
              }).then((cmd) => {
                if (cmd !== 'apply') return;
                
                CartProcessingModel.isSearchComponent = false;
                
                CartProcessingModel.customer = '@creation';
            
                FormModel.setFormSettings(FormModel.Type.Creation, {
                  include: [
                    'salutation',
                    'firstName',
                    'lastName',
                    'email',
                    'city',
                    'postalCode',
                    'street',
                    'streetNumber',
                  ].concat(CartProcessingModel.destination === OrdersModel.Type.InStore ? ['addressLine2'] : []),
                  migration: true,
                  data: {
                    salutation: data.salutation || '',
                    birthday: data.birthday ? format(+data.birthday, 'yyyy-MM-dd') : '',
                    firstName: data.firstName || '',
                    lastName: data.lastName || '',
                    phone: data.phone,
                    street: data.address?.street || '',
                    streetNumber: data.address?.streetNumber || '',
                    city: data.address?.city || '',
                    addressLine1: data.address?.addressLine1 || '',
                    addressLine2: data.address?.addressLine2 || '',
                    email: data.address?.email || '',
                    postalCode: data.address?.postalCode || '',
                    store: data.favouriteStoreId || '',
                    language: data.preferredLanguage ?? 'DE',
                    country: data.address?.country ?? localesStore.currentLocale,
                    isEmailContactAllowed: Boolean(data.isEmailContactAllowed),
                    isPhoneContactAllowed: Boolean(data.isPhoneContactAllowed),
                    isSMSMMSContactAllowed: Boolean(data.isSMSMMSContactAllowed),
                    armLength: data?.additionalProperties?.armLength || '',
                    cut: data?.additionalProperties?.cut || '',
                    size: data?.additionalProperties?.size || '',
                    userAgreementAllowed: true,
                  },
                  exclude: ['signature'],
                  onResolve: (customerId, data) => {
                    CartProcessingModel.saveCustomerData(customerId, data, !isCatalog).then(
                      isCatalog
                        ? () => {
                            CartProcessingModel.handleWindowsClose();
                            CartProcessingModel.toggleReservation(true);
                          }
                        : () => {
                            CartProcessingModel.handleWindowsClose();
                            helperStore.cartAnimationDirection = helperStore.Direction.Ltr;
                            helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
                            this.navigate('/order');
                          }
                    );
                  },
                  onReject: async (rejectedData) => {
                    let customerId;
                    
                    if (this.data.length > 0) {
                      const user = this.data.find(({ customerId }) => customerId.includes('KT'));
                      customerId = user.customerId;
                    } else {
                      const results = await this.fetchSearchResults({ email: rejectedData.email }, true);
                      const user = results.find(({ customerId }) => customerId.includes('KT'));
                      customerId = user.customerId;
                    }
                    
                    const data = await this.fetchResultDetails(customerId);
                    
                    CartProcessingModel.saveCustomerData(customerId, {
                      salutation: data.salutation || '',
                      birthday: data.birthday ? format(+data.birthday, 'yyyy-MM-dd') : '',
                      firstName: data.firstName || '',
                      lastName: data.lastName || '',
                      phone: data.phone,
                      street: data.address?.street || '',
                      streetNumber: data.address?.streetNumber || '',
                      city: data.address?.city || '',
                      addressLine1: data.address?.addressLine1 || '',
                      addressLine2: data.address?.addressLine2 || '',
                      email: data.address?.email || '',
                      postalCode: data.address?.postalCode || '',
                      store: data.favouriteStoreId || '',
                      language: data.preferredLanguage ?? 'DE',
                      country: data.address?.country ?? localesStore.currentLocale,
                      isEmailContactAllowed: Boolean(data.isEmailContactAllowed),
                      isPhoneContactAllowed: Boolean(data.isPhoneContactAllowed),
                      isSMSMMSContactAllowed: Boolean(data.isSMSMMSContactAllowed),
                      armLength: data?.additionalProperties?.armLength || '',
                      cut: data?.additionalProperties?.cut || '',
                      size: data?.additionalProperties?.size || '',
                      userAgreementAllowed: true,
                    }, !isCatalog).then(
                      isCatalog
                        ? () => {
                            CartProcessingModel.handleWindowsClose();
                            CartProcessingModel.toggleReservation(true);
                          }
                        : () => {
                            CartProcessingModel.handleWindowsClose();
                            helperStore.cartAnimationDirection = helperStore.Direction.Ltr;
                            helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
                            this.navigate('/order');
                          }
                    );
                  }
                });
              });
        } else {
          CartProcessingModel.isSearchComponent = false;
          
          FormModel.setFormSettings(FormModel.Type.Editor, {
            prevData: data,
            include: [
              'salutation',
              'firstName',
              'lastName',
              'email',
              'city',
              'postalCode',
              'street',
              'streetNumber',
            ].concat(CartProcessingModel.destination === OrdersModel.Type.InStore ? ['addressLine2'] : []),
            data: {
              salutation: data.salutation || '',
              birthday: data.birthday ? format(+data.birthday, 'yyyy-MM-dd') : '',
              firstName: data.firstName || '',
              lastName: data.lastName || '',
              phone: data.phone,
              street: data.address?.street || '',
              streetNumber: data.address?.streetNumber || '',
              city: data.address?.city || '',
              addressLine1: data.address?.addressLine1 || '',
              addressLine2: data.address?.addressLine2 || '',
              email: data.address?.email || '',
              postalCode: data.address?.postalCode || '',
              store: data.favouriteStoreId || '',
              language: data.preferredLanguage ?? 'DE',
              country: data.address?.country ?? localesStore.currentLocale,
              isEmailContactAllowed: Boolean(data.isEmailContactAllowed),
              isPhoneContactAllowed: Boolean(data.isPhoneContactAllowed),
              isSMSMMSContactAllowed: Boolean(data.isSMSMMSContactAllowed),
              armLength: data?.additionalProperties?.armLength || '',
              cut: data?.additionalProperties?.cut || '',
              size: data?.additionalProperties?.size || '',
              userAgreementAllowed: true,
            },
            exclude: ['signature'],
            onResolve: (customerId, data) => {
              CartProcessingModel.saveCustomerData(customerId, data, !isCatalog).then(
                isCatalog
                  ? () => {
                      CartProcessingModel.handleWindowsClose();
                      CartProcessingModel.toggleReservation(true);
                    }
                  : () => {
                      CartProcessingModel.handleWindowsClose();
                      helperStore.cartAnimationDirection = helperStore.Direction.Ltr;
                      helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
                      this.navigate('/order');
                    }
              );
            },
          });
        }
        
        this.reset();
      });
    } else if (isScanner) {
      this.fetchResultDetails(customerId)
        .then(data => {
          if (!data) throw "User doesn't exist!";
          
          if (customerId.includes('CT')) {
             swal({
                title: localesStore.translate('placeholder.label.notice'),
                text: localesStore.translate('modal.migration.label.desc'),
                content: {
                  element: 'button',
                  attributes: {
                    className: 'swal-button--crossexit',
                    onclick: () => swal.close(),
                  },
                },
                buttons: {
                  apply: localesStore.translate('modal.migration.label.button'),
                },
              }).then((cmd) => {
                if(cmd !== 'apply') return;
                
                FormModel.setFormSettings(FormModel.Type.Creation, {
                    data: {
                      salutation: data.salutation || '',
                      birthday: data.birthday ? format(+data.birthday, 'yyyy-MM-dd') : '',
                      firstName: data.firstName || '',
                      lastName: data.lastName || '',
                      phone: data.phone,
                      street: data.address?.street || '',
                      store: data.favouriteStoreId || '',
                      language: data.preferredLanguage ?? 'DE',
                      streetNumber: data.address?.streetNumber || '',
                      city: data.address?.city || '',
                      addressLine1: data.address?.addressLine1 || '',
                      addressLine2: data.address?.addressLine2 || '',
                      email: data.address?.email || '',
                      postalCode: data.address?.postalCode || '',
                      country: data.address?.country ?? localesStore.currentLocale,
                      isEmailContactAllowed: Boolean(data.isEmailContactAllowed),
                      isPhoneContactAllowed: Boolean(data.isPhoneContactAllowed),
                      isSMSMMSContactAllowed: Boolean(data.isSMSMMSContactAllowed),
                      armLength: data?.additionalProperties?.armLength || '',
                      cut: data?.additionalProperties?.cut || '',
                      size: data?.additionalProperties?.size || '',
                      userAgreementAllowed: true,
                    },
                    migration: true,
                    onResolve: async (customerId, _, userData) => {
                      const customerData = await helperStore.mapDetailsData(userData);
                      CustomerDetailsModel.setUserData(customerData);
                      helperStore.baseAnimationDirection = helperStore.Direction.Ltr;
                      this.navigate(`/customer/details?${customerId}`);
                      
                      CartProcessingModel.toggleFormWindow(false);
                    },
                    onReject: async (rejectedData) => {
                      let customerId;
                      
                      console.log(rejectedData);
                      
                      if (this.data.length > 0) {
                        const user = this.data.find(({ customerId }) => customerId.includes('KT'));
                        customerId = user.customerId;
                      } else {
                        const results = await this.fetchSearchResults({ email: rejectedData.email }, true);
                        const user = results.find(({ customerId }) => customerId.includes('KT'));
                        customerId = user.customerId;
                      }
                      
                      // swal({
                      //   title: localesStore.translate('placeholder.label.notice'),
                      //   text: localesStore.translate('modal.migration.label.desc'),
                      //   content: {
                      //     element: 'button',
                      //     attributes: {
                      //       className: 'swal-button--crossexit',
                      //       onclick: () => swal.close(),
                      //     },
                      //   },
                      //   buttons: {
                      //     apply: localesStore.translate('modal.migration.label.button'),
                      //   },
                      // }).then((cmd) => {
                      //   if (cmd !== 'apply') return;
                      // }
                      
                      helperStore.baseAnimationDirection = helperStore.Direction.Ltr;
                      this.navigate(`/customer/details?${customerId}`);
                      
                      CartProcessingModel.toggleFormWindow(false);
                    }
                 });
                
                CartProcessingModel.toggleFormWindow(true);
             });
          } else {
            CustomerDetailsModel.setUserData(data);
            helperStore.baseAnimationDirection = helperStore.Direction.Ltr;
            this.navigate(`/customer/details?${customerId}`);
          }
          
          callback(false, customerId);
        })
        .catch(() => {
          callback(true, customerId);
          return swal('', localesStore.translate('modal.scanner.error.label.customer'), 'error');
        });
    } else {
      if (customerId.includes('CT')) {
        this.fetchResultDetails(customerId).then((data) => {
           swal({
              title: localesStore.translate('placeholder.label.notice'),
              text: localesStore.translate('modal.migration.label.desc'),
              content: {
                element: 'button',
                attributes: {
                  className: 'swal-button--crossexit',
                  onclick: () => swal.close(),
                },
              },
              buttons: {
                apply: localesStore.translate('modal.migration.label.button'),
              },
          }).then((cmd) => {
            if (cmd !== 'apply') return;
            
            FormModel.setFormSettings(FormModel.Type.Creation, {
                migration: true,
                data: {
                  store: data.favouriteStoreId || '',
                  language: data.preferredLanguage ?? 'DE',
                  salutation: data.salutation || '',
                  birthday: data.birthday ? format(+data.birthday, 'yyyy-MM-dd') : '',
                  firstName: data.firstName || '',
                  lastName: data.lastName || '',
                  phone: data.phone,
                  street: data.address?.street || '',
                  streetNumber: data.address?.streetNumber || '',
                  city: data.address?.city || '',
                  addressLine1: data.address?.addressLine1 || '',
                  addressLine2: data.address?.addressLine2 || '',
                  email: data.address?.email || '',
                  postalCode: data.address?.postalCode || '',
                  country: data.address?.country ?? localesStore.currentLocale,
                  isEmailContactAllowed: Boolean(data.isEmailContactAllowed),
                  isPhoneContactAllowed: Boolean(data.isPhoneContactAllowed),
                  isSMSMMSContactAllowed: Boolean(data.isSMSMMSContactAllowed),
                  armLength: data?.additionalProperties?.armLength || '',
                  cut: data?.additionalProperties?.cut || '',
                  size: data?.additionalProperties?.size || '',
                  userAgreementAllowed: true,
                },
                exclude: ['signature'],
                onResolve: async (customerId, _, userData) => {
                  const customerData = await helperStore.mapDetailsData(userData);
                  CustomerDetailsModel.setUserData(customerData);
                  helperStore.baseAnimationDirection = helperStore.Direction.Ltr;
                  helperStore.setPreviousSearchValue(this.searchData);
                  this.navigate(`/customer/details?${customerId}`);
                }
              });
          });
        })
      } else {
        helperStore.baseAnimationDirection = helperStore.Direction.Ltr;
        helperStore.setPreviousSearchValue(this.searchData);
        this.navigate(`/customer/details?${customerId}`);
      }
    }
  });

  reset = action(() => {
    this.data = [];
    this.searchData = null;
    this.isModalWindow = false;
    this.isReservation = false;
    this.isComplaints = false;
  });
}

export default Model('SearchModel')(SearchModel);
