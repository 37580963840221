import swal from 'sweetalert';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Box } from '@mui/material';
import { action, computed, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class ArticleModel {
  isToggled = false;
  isLoading = false;
  isDetailsOnlyWindow = false;
  isAvailabilityLoading = false;
  isColorsBarToggled = false;
  isComplaintsWindow = false;

  loadedImages = [];

  currentArticleGroups = [];
  currentArticleGroup = null;
  currentArticle = null;
  currentGroup = null;

  currentArticleGroupsIndex = 0;
  currentGalleryIndex = 0;

  isSimilarArticlesLoading = false;
  similarArticles = [];

  isAvailabilityScreen = false;
  availableStores = [];
  sizeFilter = '';

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
      isLoading: observable,
      isDetailsOnlyWindow: observable,
      isColorsBarToggled: observable,
      isComplaintsWindow: observable,
      
      currentGroup: observable,
      currentArticle: observable,
      currentArticleGroup: observable,
      currentArticleGroups: observable,
      currentGalleryIndex: observable,
      currentArticleGroupsIndex: observable,

      isSimilarArticlesLoading: observable,
      similarArticles: observable,

      sizeFilter: observable,

      isAvailabilityLoading: observable,
      availableStores: observable,
      isAvailabilityScreen: observable,

      articleColorName: computed,
      articleColors: computed,
      articlePrice: computed,
      articleMainImage: computed,
      articleSizes: computed,
      articleTotalQuantity: computed,
      articleCurrency: computed,
      articleRecommendations: computed,

      loadedImages: observable,
      cleaningImages: computed,
      galleryImages: computed,
    });
  }

  get articleColorName() {
    if (!this.currentArticleGroup) return '';

    const article = this.currentArticleGroup['categories']?.filter(category => category.type.includes('color'))[0];

    return article?.name || '';
  }

  get articleMainImage() {
    const placeholder = { url: 'images/ig-placeholder.png' };

    if (!this.currentArticleGroup || !this.currentArticleGroup['images']) return placeholder;

    const image = this.currentArticleGroup['images']?.find(value => value.type.includes('Main'));

    return image ?? placeholder;
  }

  get articlePrice() {
    if (!this.currentArticle) return 0;

    const { currentPrice, generalPrice } = this.currentArticle;
    const { price: minPrice } = currentPrice?.[0] ?? { price: 0 },
      { price: maxPrice } = generalPrice?.[0] ?? { price: 0 };

    return { minPrice, maxPrice };
  }

  get articleCurrency() {
    if (!this.currentArticle) return 'n/a';

    const { currentPrice, generalPrice } = this.currentArticle;
    const { currency: minCurrency } = currentPrice?.[0] ?? { currency: 'n/a' },
      { currency: maxCurrency } = generalPrice?.[0] ?? { currency: 'n/a' };

    return (minCurrency && minCurrency !== 'n/a') || (maxCurrency && maxCurrency !== 'n/a')
      ? getSymbolFromCurrency(minCurrency ?? maxCurrency)
      : 'n/a';
  }

  get isGreenChoice() {
    if (!this.currentArticleGroup) return false;
    return this.currentArticleGroup['shortDescr'].includes('MADE IN GREEN');
  }

  get availabilityType() {
    const { CatalogModel } = this.rootModels;

    const filters = CatalogModel.selectedFilters['availability_filters'];

    if(Array.isArray(filters) && filters.length > 0) {
      const isOtherStores = this.isSomeStoreAvailable && filters.includes('RETAIL_STORES');
      const isCurrentStore = this.isAvailableInCurrentStore && filters.includes('THIS_STORE');

      return isCurrentStore ? 'current' : isOtherStores ? 'stores' : 'open';
    }

    return 'open';
  }

  get isSomeStoreAvailable() {
    return this.availableStores.some(store => store.storeId !== '-1' && store.available > 0);
  }

  get isStore2Store() {
    const isOnlineStoreEmpty = (this.availableStores.find(store => store.storeId === '-1')?.available ?? 0) === 0;

    return isOnlineStoreEmpty && this.isSomeStoreAvailable;
  }

  get isAvailableInCurrentStore() {
    const { storageStore } = this.rootModels.store;

    const current = this.availableStores.find(store => store.storeId === storageStore.storeId);

    return current?.available > 0;
  }

  get cleaningImages() {
    return this.getImagesByType('Cleaning');
  }

  get galleryImages() {
    return this.getImagesByType('Gallery', this.articleMainImage);
  }

  get articleColors() {
    if (!this.currentArticleGroups) return [];

    const colors = this.currentArticleGroups.reduce((acc, next, index) => {
      const { imageUrl } = next['categories']?.filter(category => category.type.includes('color'))?.[0] ?? {};
      const { url, thumbnailUrl } = next['images']?.find(value => value.type.includes('Main')) ?? {};

      return [
        ...acc,
        {
          itemIndex: index,
          data: next,
          imageUrl: imageUrl ?? thumbnailUrl ?? url,
        },
      ];
    }, []);

    return colors;
  }

  get articleSizes() {
    if (!this.currentArticleGroup) return [];

    const letteredSizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '2XL', 'XXXL', '3XL', '4XL', '5XL', '6XL'];

    const sizes = this.currentArticleGroup['categories']
      .reduce((acc, next) => {
        if (!next.type.includes('size')) return acc;

        return [
          ...acc,
          {
            value: next.name,
            label: next.name,
          },
        ];
      }, [])
      .sort((a, b) => {
        const firstItem = parseInt(a.value);
        const secondItem = parseInt(b.value);

        if (isNaN(firstItem) && isNaN(secondItem)) {
          return letteredSizes.findIndex(item => item === a.value) - letteredSizes.findIndex(item => item === b.value);
        } else {
          return firstItem - secondItem;
        }
      });

    if (sizes.every(item => item.value.includes('/'))) {
      sizes.sort((a, b) => {
        const [a_width, a_length] = a.value.split('/');
        const [b_width, b_length] = b.value.split('/');

        if (a_width === b_width) {
          return parseInt(a_length) - parseInt(b_length);
        } else {
          return parseInt(a_width) - parseInt(b_width);
        }
      });
    }

    return sizes;
  }

  get articleCare() {
    if (!this.currentArticleGroup) return '';

    const material = this.currentArticleGroup['categories'].find(item => item.type === 'material')?.name || '';

    return { material, wash: this.currentArticleGroup?.wash || '' };
  }

  get articleTotalQuantity() {
    return this.availableStores.reduce((acc, next) => acc + Number(next.available), 0);
  }

  get articleRecommendations() {
    return this.similarArticles.reduce((acc, { gtin, article }, id) => {
      if (!article) return acc;

      const { articleGroups } = article;

      const recommendedGroup = articleGroups.find(({ articles }) => articles.find(article => article?.gtin === gtin));

      const image = recommendedGroup?.images?.find(image => image?.type === 'Main');

      acc.push({
        id,
        imageUrl: image?.thumbnailUrl ?? image?.url ?? 'images/ig-placeholder.png',
      });

      return acc;
    }, []);
  }

  galleryMounted = action(() => {
    if (this.currentArticleGroup && !this.loadedImages.length) {
      Promise.all(
        this.galleryImages.map(
          ({ url, thumbnailUrl }) =>
            new Promise(resolve => {
              const image = new Image();
              image.src = thumbnailUrl;
              image.onload = () => resolve(<Box component="img" src={thumbnailUrl} alternateSrc={url} sx={{ height: '100%' }} />);
            })
        )
      ).then(data => {
        this.loadedImages = data;
      });
    }

    return () => {
      this.loadedImages = [];
    };
  });

  articleMounted = action(() => {
    this.similarArticles = [];

    if (this.articleSizes.length) {
      this.setArticleBySize(this.sizeFilter ?? this.articleSizes[0].value)
    } else {
      this.setArticleByIndex(0);
    };

    if (this.currentArticleGroup?.relatedArticleGtins) {
      this.isSimilarArticlesLoading = true;

      const similarArticlesGtins = this.currentArticleGroup?.relatedArticleGtins.split('|');

      similarArticlesGtins.forEach(
        action(async (gtin, index) => {
          const article = await this.fetchArticleGroupsByGtin(gtin, {}, true);
          this.similarArticles.push({ gtin, article });
          if (index === similarArticlesGtins.length - 1) this.isSimilarArticlesLoading = false;
        })
      );
    }

    return () => {
      this.sizeFilter = '';
    };
  });

  availabilityMounted = action(() => {
    this.fetchAvailableStores();
  });

  fetchArticleGroups = action(async (groupId, articleId) => {
    const { store, CatalogModel } = this.rootModels;
    const { networkStore, helperStore, storageStore } = store;

    helperStore.toggleLoader(true);

    try {
      this.isLoading = true;

      const { status, response } = await networkStore.get(`FilteredProductById?objectId="${groupId}"`);

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "FilteredProductById" Failed!');

      const { data } = JSON.parse(response);

      this.currentGroup = groupId;
      this.currentArticleGroups = (data.articleGroups ?? []).map(helperStore.mapBackendData).map((group) => {
        if(Array.isArray(group.articles)) {
          group.articles = group.articles.filter(({ availabilities }) => {
            const filters = CatalogModel.filterCategories['availability_filter'];

            if(Array.isArray(filters) && filters.length > 0) {
              const isOnlineAvailable = availabilities.some(({ stockId, availableCount }) => stockId === '-1' && availableCount > 0);
              const isCurrentStoreAvailable = availabilities.some(({ stockId, availableCount }) => stockId === storageStore.storeId && availableCount > 0);
              const isOtherStoreAvailable = availabilities.some(({ stockId, availableCount }) => stockId !== storageStore.storeId && stockId !== '-1' && availableCount > 0);

              const available = {
                'ONLINE': isOnlineAvailable,
                'THIS_STORE': isCurrentStoreAvailable,
                'RETAIL_STORES': isOtherStoreAvailable
              }

              return filters.every(filter => available[filter]);
            }

            return true;
          })
        }

        return group;
      })

      this.setArticleGroupByIndex(this.currentArticleGroups.findIndex(group => group.objectId === articleId));
    } catch (error) {
      if (store.debug) console.error(error);
    } finally {
      helperStore.toggleLoader(false);
      this.isLoading = false;
    }
  });

  fetchArticleGroupsByExistingArticle = action(async article => {
    const { store } = this.rootModels;
    const { networkStore, helperStore } = store;

    try {
      if(!article) throw Error('Incorrect article value!');

      this.isLoading = true;
      helperStore.toggleLoader(true);

      const { status, response } = await networkStore.get(`FilteredProductById?objectId="${article.productId}"`);

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "FilteredProductById" Failed!');

      const { data } = JSON.parse(response);

      this.currentGroup = article.productId;
      this.currentArticleGroups = (data.articleGroups ?? []).map(helperStore.mapBackendData);

      this.setArticleGroupByIndex(
        this.currentArticleGroups.findIndex(group => group.articles.some(item => item.gtin === article.gtin))
      );

      if (article.size) this.sizeFilter = String(article.size).toUpperCase();
    } catch (error) {
      if (store.debug) console.error(error);
      networkStore.log('[ArticleModel]:[fetchArticleGroupsByExistingArticle]', error)
    } finally {
      this.isLoading = false;
      helperStore.toggleLoader(false);
    }
  });
  
  // fetchArticleGroupsByArticleNumber = action(async (articleNumber) => {
  //   const { response } = await store.networkStore.post('LoadObjectsOfClass', {
  //       whereClause: `articleNumber = "${articleNumber}"`,
  //       offset: 0,
  //       orderByType: 'DESC',
  //       orderByName: 'created',
  //       pageSize: 200,
  //       clazz: 'com.innomos.baas.common.model.Article',
  //   });
  //
  //   console.log(response);
  // })

  fetchArticleGroupsByBarcode = action(async (barcode, options = {}) => {
    const { CatalogModel, store } = this.rootModels;
    const { networkStore, helperStore, storageStore } = store;

    try {
      this.isLoading = true;
      helperStore.toggleLoader(true);

      const { status, response } = await networkStore.get(
        `FilteredArticleByArticleGtinAndCategory?articleGtin="${barcode}"&storeId="${storageStore.storeId}"&categoryObjectId="${CatalogModel.categoryRootId}"`
      );

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "FilteredArticleByArticleGtinAndCategory" Failed!');

      const { data } = JSON.parse(response);

      const size = data.categories.find(category => category.type === 'size_filter')?.name;
      const colorCode = data.categories.find(category => category.type === 'color')?.code;

      this.fetchArticleGroupsByGtin(barcode, {
        error: false,
        skipOnError: false,
        colorCode,
        size,
        onSuccess: () => {
          helperStore.toggleLoader(false);
          this.toggleWindow();
        },
        onError: type => {
          if(!this.isComplaintsWindow) {
            helperStore.toggleNotification({
              type: 'inverse',
              title: store.localesStore.translate('placeholder.label.notice'),
              description: store.localesStore.translate(
                type === 'unavailable' ? 'modal.article.unavailable.label.description' : 'modal.products.label.error'
              ),
              confirm: store.localesStore.translate('placeholder.label.ok'),
              onConfirm: () => {
                store.helperStore.toggleNotification(null);
              },
            });
          }

          helperStore.toggleLoader(false);
        },
        ...options,
      });
    } catch (error) {
      helperStore.toggleLoader(false);

      if (store.debug) console.error(error);
      networkStore.log('[ArticleModel]:[fetchArticleGroupsByBarcode]', error)
      return error;
    } finally {
      this.isLoading = false;
    }
  });

  fetchAvailableStores = action(async article => {
    if (!this.currentArticle && !article) return;

    const { store } = this.rootModels;
    const { networkStore, storageStore, localesStore } = store;

    try {
      this.isAvailabilityLoading = true;
      this.availableStores = [];

      const gtin = this.currentArticle?.['gtin'] ?? article['gtin'];

      const { status, response } = await networkStore.get(
        `AvailStoresByGtin?gtin="${gtin}"&storeId="${storageStore.storeId}"`
      );

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "AvailStoresByGtin" failed!');

      const { data } = JSON.parse(response);

      const outlets = [
        '2826',
        '2836',
        '2872',
        '8020',
        '8151',
        '8201',
        '8512',
        '8401',
      ];

      const order = Array.from(
        new Set(
          data.reduce(
            (acc, next) => {
              const { storeId } = next;

              return [...acc, storeId];
            },
            ['-1', storageStore.storeId]
          )
        )
      ).filter(storeId => !outlets.includes(storeId));

      const stores = order.map(id => {
        const store =
          data.find(store => store.storeId === id) ||
          (id === storageStore.storeId && storageStore.storeData) ||
          (id === '-1' && {
            name: localesStore.translate('placeholder.label.online_shop'),
            availCount: 0,
            storeId: '-1',
          });

        if (store.storeId === '-1') store.name = localesStore.translate('placeholder.label.online_shop');

        const { availCount, name, phone, addresses, storeId } = store;

        const storeLocation = addresses?.length ? addresses[0].postalCode + ' ' + addresses[0].city : '';

        return {
          storeId,
          storeName: name,
          storePhone: phone,
          available: availCount,
          storeLocation,
        };
      });

      this.availableStores = stores;

      return this.availableStores;
    } catch (error) {
      if (store.debug) console.error(error);
      networkStore.log('[ArticleModel]:[fetchAvailableStores]', error)
    } finally {
      this.isAvailabilityLoading = false;
    }
  });

  fetchArticleGroupsByGtin = action(
    async (gtin, { colorCode, size, onSuccess, onError, error = true, skipOnError = true } = {}, isReturn = false) => {

      const { CatalogModel, store } = this.rootModels;
      const { networkStore, helperStore, localesStore } = store;

      try {
        this.isLoading = true;

        const { status, response } = await networkStore.get(
          `FilteredProductByArticleGtinAndCategory?articleGtin="${gtin}"&categoryObjectId="${CatalogModel.categoryRootId}"`
        );

        if (
          status !== 'completed' ||
          (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
        )
          throw Error('Network Store: Request "FilteredProductByArticleGtinAndCategory" Failed!');

        const { data } = JSON.parse(response);

        if (isReturn) return data;

        if (!data?.articleGroups.length) {
          if (error) {
            return swal('', localesStore.translate('modal.article.label.error'), 'error');
          } else {
            if (typeof onError === 'function') onError('unavailable');
            return;
          }
        }

        this.currentGroup = data.productId;

        this.currentArticleGroups = data.articleGroups.map(helperStore.mapBackendData);

        const currentArticleGroup = colorCode
          ? this.currentArticleGroups.findIndex(group => group.colorNumber === colorCode)
          : -1;

        if (size) this.sizeFilter = String(size).toUpperCase();

        const articleIndex = this.currentArticleGroups.findIndex(group =>
          group.articles.some(item => item.gtin === gtin)
        );

        if (!error && articleIndex === -1 && typeof onError === 'function') {
          if (skipOnError) {
            return onError();
          } else {
            onError();
          }
        }

        this.setArticleGroupByIndex(articleIndex === -1 ? currentArticleGroup : articleIndex);

        if (typeof onSuccess === 'function') onSuccess();
      } catch (error) {
        if (store.debug) console.error(error);
        networkStore.log('[ArticleModel]:[fetchArticleGroupsByGtin]', error)
        if (typeof onError === 'function') onError('unavailable');
        if (isReturn) return null;
      } finally {
        this.isLoading = false;
      }
    }
  );

  setArticleGroupByIndex = action(index => {
    if (typeof index === 'number') {
      this.currentArticleGroupIndex = index <= 0 ? 0 : index;
    } else {
      this.currentArticleGroupIndex =
        this.currentArticleGroupsIndex && this.currentArticleGroupsIndex !== -1 ? this.currentArticleGroupIndex : 0;
    }

    this.currentGalleryIndex = 0;
    this.currentArticleGroup = this.currentArticleGroups[this.currentArticleGroupIndex];
  });

  setArticleBySize = action(size => {
    if (!this.currentArticleGroup) return;
    
    const { selectedFilters } = this.rootModels.CatalogModel;

    let currentArticle = null;
    
    const articlesForCurrentArticleGroup = this.currentArticleGroup['articles'].filter(article => !!article);
    
    if (selectedFilters['size_filter']) {
      let sizeFilter = null;
      const filters = selectedFilters['size_filter'];
      
       currentArticle = articlesForCurrentArticleGroup.find(article => {
          const [size] = article.categories.filter(category => category.type.includes('size'));
          
          const isActiveFilter = filters.includes(size.objectId);
          
          if (isActiveFilter) {
            sizeFilter = size.name
          };
          
          return isActiveFilter;
       });
       
       if(currentArticle) {
          this.sizeFilter = sizeFilter;
       }
    } else if (size) {
      currentArticle = articlesForCurrentArticleGroup.find(article => {
        const sizeByCategory = article.categories.filter(category => category.type.includes('size'));
        return sizeByCategory[0].name === size;
      });

      this.sizeFilter = size;
    } else {
      currentArticle = articlesForCurrentArticleGroup.find(item => {
        const size = item.categories.find(category => category.type.includes('size'));
        return size.name === this.articleSizes[0].value;
      });

      this.sizeFilter = this.articleSizes[0].value;
    }

    this.currentArticle = currentArticle ?? articlesForCurrentArticleGroup[0];
  });

  setArticleByIndex = (index) => {
    if (!this.currentArticleGroup) return;

    const articlesForCurrentArticleGroup = this.currentArticleGroup['articles'].filter(article => !!article);

    this.currentArticle = articlesForCurrentArticleGroup[index] ?? articlesForCurrentArticleGroup[0];
  }

  getImagesByType = action((type, mainImage) => {
    if (!this.currentArticleGroup) return [];

    const images = this.currentArticleGroup['images']?.reduce(
      (acc, next) => {
        if (!next.type.includes(type)) return acc;
        return [...acc, next];
      },
      mainImage ? [mainImage] : []
    );

    return images;
  });

  addProductToCart = action(() => {
    if (!this.currentArticle || !this.currentArticleGroup || !this.currentArticleGroups) return;

    const imageUrl = this.currentArticleGroup?.['images'].find(image => image?.type === 'Main')?.url || null;
    const colorFilter = this.currentArticleGroup?.['categories'].find(category => category.type === 'color');
    const size = this.currentArticle?.['categories'].find(category => category.type === 'size_filter')?.name;
    
    const productData = {
      productId: this.currentGroup,
      articleMpn: this.currentArticle['mpn'] ?? null,
      articleNumber: this.currentArticleGroup['articleNumberText'] ?? null,
      color: colorFilter?.name ?? null,
      material: this.articleCare.material ?? null,
      colorCode: this.currentArticleGroup['colorNumber'] ?? colorFilter?.code ?? null,
      colorImageUrl: colorFilter?.imageUrl ?? null,
      created: this.currentArticle['created'] ?? null,
      price: this.currentArticle?.['currentPrice'][0].price ?? null,
      currency: this.currentArticle?.['currentPrice'][0].currency ?? null,
      descriptionLong: this.currentArticleGroup['descr'] ?? null,
      descriptionShort: this.currentArticleGroup['shortDescr'] ?? null,
      gtin: this.currentArticle['gtin'] ?? null,
      imageUrl: imageUrl,
      name: this.currentArticleGroup['title'] ?? null,
      size: size ?? null,
      objectId: null,
      quantity: 1,
      returnQuantity: 0,
      returnReason: null,
      status: null,
      trackingUrl: null,
      updated: this.currentArticle['updated'] ?? null,
      additionalProperties: null,
      productURL: null,
    };

    this.rootModels.CartModel.addToCart(productData);

    this.rootModels.store.helperStore.toggleNotification({
      type: 'inverse',
      title: this.rootModels.store.localesStore.translate('modal.catalog.cart.label.title'),
      description: this.rootModels.store.localesStore.translate('modal.catalog.cart.label.description'),
      confirm: this.rootModels.store.localesStore.translate('placeholder.label.ok'),
      onConfirm: () => {
        this.rootModels.store.helperStore.toggleNotification(null);
      },
    });

    this.reset();
  });
  
  handleComplaintsClick = () => {
    const { ComplaintsProcessingModel } = this.rootModels;

    if (!this.currentArticle || !this.currentArticleGroup || !this.currentArticleGroups) return;

    const imageUrl = this.currentArticleGroup?.['images'].find(image => image?.type === 'Main')?.url || null;
    const colorFilter = this.currentArticleGroup?.['categories'].find(category => category.type === 'color');
    const size = this.currentArticle?.['categories'].find(category => category.type === 'size_filter')?.name;

    const productData = {
      articleMpn: this.currentArticle['mpn'] ?? null,
      articleNumber: this.currentArticleGroup['articleNumberText'] ?? null,
      color: colorFilter?.name ?? null,
      colorCode: colorFilter?.code ?? null,
      colorImageUrl: colorFilter?.imageUrl ?? null,
      created: this.currentArticle['created'] ?? null,
      price: this.currentArticle?.['currentPrice'][0].price ?? null,
      currency: this.currentArticle?.['currentPrice'][0].currency ?? null,
      descriptionLong: this.currentArticleGroup['descr'] ?? null,
      descriptionShort: this.currentArticleGroup['shortDescr'] ?? null,
      gtin: this.currentArticle['gtin'] ?? null,
      imageUrl: imageUrl,
      name: this.currentArticleGroup['title'] ?? null,
      size: size ?? null,
      objectId: null,
      quantity: 1,
      returnQuantity: 0,
      returnReason: null,
      status: null,
      trackingUrl: null,
      updated: this.currentArticle['updated'] ?? null,
      additionalProperties: null,
      productURL: null,
    };

    ComplaintsProcessingModel.setArticle(productData);
    
    this.handleCloseCall();
  }

  toggleWindow = action((isDetailsOnlyWindow = false, isComplaintsWindow = false) => {
    this.isDetailsOnlyWindow = isDetailsOnlyWindow;
    this.isComplaintsWindow = isComplaintsWindow;
    this.isToggled = !this.isToggled;
  });

  toggleColorBar = action(() => {
    this.isColorsBarToggled = !this.isColorsBarToggled;
  });

  handleRecommendationClick = action(id => {
    const {
      store: { helperStore },
    } = this.rootModels;

    const {
      gtin,
      article: { articleGroups },
    } = this.similarArticles[id];

    const groupIndex = articleGroups.findIndex(({ articles }) => articles.find(article => article.gtin === gtin));

    this.currentGroup = articleGroups[groupIndex].objectId;
    this.currentArticleGroups = (articleGroups ?? []).map(helperStore.mapBackendData);
    this.setArticleGroupByIndex(groupIndex);
  });

  handleLtrSwipe = action(() => {
    if (this.currentGalleryIndex === 0) return (this.currentGalleryIndex = this.loadedImages.length - 1);

    return (this.currentGalleryIndex = this.currentGalleryIndex - 1);
  });

  handleRtlSwipe = action(() => {
    if (this.currentGalleryIndex === this.loadedImages.length - 1) return (this.currentGalleryIndex = 0);

    return (this.currentGalleryIndex = this.currentGalleryIndex + 1);
  });

  handleCloseCall = action(() => {
    this.isDetailsOnlyWindow = false;
    this.toggleWindow();
    this.reset();
  });

  handleColorsCall = action(() => {
    this.toggleColorBar();
  });

  handleAddCartCall = action(() => {
    this.addProductToCart();
  });

  handleReservationCall = action(() => {
    const { CartProcessingModel } = this.rootModels;

    if (!this.currentArticle || !this.currentArticleGroup || !this.currentArticleGroups) return;

    const imageUrl = this.currentArticleGroup?.['images'].find(image => image?.type === 'Main')?.url || null;
    const colorFilter = this.currentArticleGroup?.['categories'].find(category => category.type === 'color');
    const size = this.currentArticle?.['categories'].find(category => category.type === 'size_filter')?.name;

    const productData = {
      articleMpn: this.currentArticle['mpn'] ?? null,
      articleNumber: this.currentArticleGroup['articleNumberText'] ?? null,
      color: colorFilter?.name ?? null,
      colorCode: colorFilter?.code ?? null,
      colorImageUrl: colorFilter?.imageUrl ?? null,
      created: this.currentArticle['created'] ?? null,
      price: this.currentArticle?.['currentPrice'][0].price ?? null,
      currency: this.currentArticle?.['currentPrice'][0].currency ?? null,
      descriptionLong: this.currentArticleGroup['descr'] ?? null,
      descriptionShort: this.currentArticleGroup['shortDescr'] ?? null,
      gtin: this.currentArticle['gtin'] ?? null,
      imageUrl: imageUrl,
      name: this.currentArticleGroup['title'] ?? null,
      size: size ?? null,
      objectId: null,
      quantity: 1,
      returnQuantity: 0,
      returnReason: null,
      status: null,
      trackingUrl: null,
      updated: this.currentArticle['updated'] ?? null,
      additionalProperties: null,
      productURL: null,
    };

    CartProcessingModel.toggleCustomerWindow(true, {
      type: 'reservation',
      product: productData,
    });
    this.handleCloseCall();
  });

  handleImageChange = action(index => {
    this.currentGalleryIndex = index;
  });

  handleSizeChange = action(value => {
    this.setArticleBySize(value);
  });

  handleToggleAvailability = action(() => {
    this.isAvailabilityScreen = !this.isAvailabilityScreen;
  });

  reset = action(() => {
    this.currentArticleGroups = [];
    this.currentArticleGroupIndex = 0;
    this.currentArticleGroup = null;
    this.currentArticle = null;
    this.currentGalleryIndex = 0;
    this.isAvailabilityScreen = false;
    this.isDetailsOnlyWindow = false;
    this.isLoading = false;
    this.availableStores = [];
    this.isToggled = false;
    this.isColorsBarToggled = false;
    this.sizeFilter = '';
  });
}

export default Model('ArticleModel')(ArticleModel);
