import swal from 'sweetalert';

import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class OrderModel {
  isCartToggled = false;

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isCartToggled: observable,
    });
  }

  create = action(async (orderStatus, callback = () => {}) => {
    const {
      store: { debug, helperStore, networkStore, storageStore, localesStore, isKioskMode },
      OrdersModel,
      CartProcessingModel,
      CartModel,
    } = this.rootModels;

    const {
      Status: { Deleted },
      Type: { Wholesale, InStore },
      fetchOrders,
    } = OrdersModel;

    try {
      const created = Date.now();
      helperStore.toggleLoader(true);

      let checkoutData = {
        ___class: 'com.innomos.baas.common.model.OmniChannelProcess',
        ___jsonclass: 'com.innomos.baas.common.model.OmniChannelProcess',
        orderId: null,
        objectId: null,
        processId: CartProcessingModel.orderData?.processId ?? CartProcessingModel.processId ?? null,
        type: CartProcessingModel.orderData?.destination ?? Wholesale,
        status: orderStatus,
        desiredSeller: storageStore?.deviceId,
        sourceStoreSellerId: storageStore?.merchant.id,
        targetStoreId: storageStore?.storeId,
        invoiceAddress: null,
        deliveryAddress: null,
        articleItems: [],
        orderDocuments: [],
        notes: null,
        guestLastName: null,
        shippingType: null,
        paymentType: null,
        sourceStoreId: null,
        isCustomerInformViaMail: null,
        guestEmail: null,
        guestFirstName: null,
        loyaltyAccountId: null,
        updated: null,
        guestPhoneNumber: null,
        created: created ? created.toString() : null,
        createdDate: created ? created.toString() : null,
      };

      if (CartProcessingModel.orderData?.details) {
        checkoutData.invoiceAddress = {
          birthday:
            CartProcessingModel.orderData?.details?.birthday > 0
              ? new Date(CartProcessingModel.orderData.details.birthday).getTime()
              : null,
          country: CartProcessingModel.orderData?.details?.country?.toUpperCase(),
          lastName: CartProcessingModel.orderData?.details?.lastName,
          website: null,
          streetNumber: CartProcessingModel.orderData?.details?.streetNumber,
          city: CartProcessingModel.orderData?.details?.city,
          addressType: 'MainAddress',
          created: null,
          latitude: 0,
          postalCode: CartProcessingModel.orderData?.details?.postalCode,
          title: CartProcessingModel.orderData?.details?.title ?? null,
          firstName: CartProcessingModel.orderData?.details?.firstName,
          phone: CartProcessingModel.orderData?.details?.phone,
          street: CartProcessingModel.orderData?.details?.street,
          customerId: CartProcessingModel.orderData?.details?.customerId ?? null,
          addressLine1: CartProcessingModel.orderData?.details?.addressLine1,
          addressLine2: CartProcessingModel.orderData?.details?.addressLine2,
          salutation: CartProcessingModel.orderData?.details?.salutation,
          region: null,
          updated: null,
          objectId: 'InCreation_1681976194.666421',
          email: CartProcessingModel.orderData?.details?.email,
          longitude: 0,
        };
      }

      if (CartProcessingModel.orderData?.destination === InStore) {
        checkoutData.sourceStoreId = storageStore.storeId;
        checkoutData.sourceStore = {
          cashSystemIp: storageStore.storeData.cashSystemIp,
          addresses: storageStore.storeData.addresses,
          openingTimesWednesday: storageStore.storeData.openingTimesWednesday,
          openingTimesSaturday: storageStore.storeData.openingTimesSaturday,
          openingTimesSunday: storageStore.storeData.openingTimesSunday,
          created: storageStore.storeData.created,
          gln: storageStore.storeData.gln,
          externalDisplayName: storageStore.storeData.externalDisplayName,
          storeId: storageStore.storeData.storeId,
          openingTimesFriday: storageStore.storeData.openingTimesFriday,
          openingTimesThursday: storageStore.storeData.openingTimesThursday,
          paymentGroup: storageStore.storeData.paymentGroup,
          storeURL: storageStore.storeData.storeURL,
          imageURL: storageStore.storeData.imageURL,
          openingTimesMonday: storageStore.storeData.openingTimesMonday,
          name: storageStore.storeData.name,
          additionalInfo: storageStore.storeData.additionalInfo,
          openingTimesTuesday: storageStore.storeData.openingTimesTuesday,
          updated: storageStore.storeData.updated,
          email: storageStore.storeData.email,
          objectId: storageStore.storeData.objectId,
        };
        checkoutData.deliveryAddress = CartProcessingModel.orderData?.deliveryAddress ?? checkoutData.invoiceAddress;
      } else if (CartProcessingModel.orderData?.destination === Wholesale || isKioskMode) {
        const [address] = storageStore.storeData.addresses || [{}];
        checkoutData.targetStore = {
          cashSystemIp: storageStore.storeData.cashSystemIp,
          addresses: storageStore.storeData.addresses,
          openingTimesWednesday: storageStore.storeData.openingTimesWednesday,
          openingTimesSaturday: storageStore.storeData.openingTimesSaturday,
          openingTimesSunday: storageStore.storeData.openingTimesSunday,
          created: storageStore.storeData.created,
          gln: storageStore.storeData.gln,
          externalDisplayName: storageStore.storeData.externalDisplayName,
          storeId: storageStore.storeData.storeId,
          openingTimesFriday: storageStore.storeData.openingTimesFriday,
          openingTimesThursday: storageStore.storeData.openingTimesThursday,
          paymentGroup: storageStore.storeData.paymentGroup,
          storeURL: storageStore.storeData.storeURL,
          imageURL: storageStore.storeData.imageURL,
          openingTimesMonday: storageStore.storeData.openingTimesMonday,
          name: storageStore.storeData.name,
          additionalInfo: storageStore.storeData.additionalInfo,
          openingTimesTuesday: storageStore.storeData.openingTimesTuesday,
          updated: storageStore.storeData.updated,
          email: storageStore.storeData.email,
          objectId: storageStore.storeData.objectId,
        };
        checkoutData.deliveryAddress = {
          birthday: address.birthday,
          country: address.country?.toUpperCase(),
          lastName: address.lastName,
          website: address.website,
          streetNumber: address.streetNumber,
          city: address.city,
          addressType: address.addressType,
          created: address.created,
          latitude: address.latitude,
          postalCode: address.postalCode,
          title: address.title,
          firstName: address.firstName,
          phone: address.phone,
          street: address.street,
          customerId: address.customerId,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          salutation: address.salutation,
          region: address.region,
          updated: address.updated,
          objectId: 'InCreation_1681976177.833237',
          email: address.email,
          longitude: address.longitude,
        };
      }

      const articles = await CartModel.getArticles();

      checkoutData.articleItems = articles.map(article => ({
        articleMpn: article.articleMpn,
        gtin: article.gtin,
        quantity: article.quantity,
        returnQuantity: article.returnQuantity,
        color: article.color,
        created: article.created,
        descriptionLong: article.descriptionLong,
        colorImageUrl: article.colorImageUrl,
        descriptionShort: article.descriptionShort,
        size: article.size,
        articleNumber: article.articleNumber,
        returnReason: article.returnReason,
        price: article.price,
        imageUrl: article.imageUrl,
        name: article.name,
        currency: article.currency,
        colorCode: article.colorCode,
        productURL: article.productURL,
        updated: article.updated,
        objectId: article.objectId,
        status: article.status,
      }));

      const { status, response } = await networkStore.post('CreateNewProcess', checkoutData);

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code)
      ) {
        const message = JSON.parse(response || '{}')?.data?.message;
        throw message
          ? { message: localesStore.translate(message) || message }
          : new Error('Network Store: Request "Customer" failed!');
      }

      await fetchOrders();

      if (orderStatus === Deleted) {
        swal({
          title: localesStore.translate('modal.checkout.delete.label.title'),
          text: localesStore.translate('modal.checkout.delete.label.description'),
        }).then(() => {
          if (callback && typeof callback === 'function') callback();
        });
      } else {
        swal({
          icon: 'success',
          content: {
            element: 'img',
            attributes: {
              src: 'images/ig-logotype-dark.png',
              style: 'height: 70px; margin-top: 20px;',
            },
          },
        }).then(() => {
          if (callback && typeof callback === 'function') callback();
        });
      }
    } catch (error) {
      if (debug) console.error(error);

      if (typeof error === 'object') {
        swal({
          title: '',
          text: typeof error === 'object' ? error.message : localesStore.translate('order.label.wrong'),
          buttons: {
            apply: localesStore.translate('placeholder.label.ok'),
          },
        });
      } else {
        swal('', localesStore.translate('order.label.wrong'), 'error');
      }
    } finally {
      helperStore.toggleLoader(false);
    }
  });

  createReservation = action(async (data, article) => {
    const {
      store: { debug, helperStore, networkStore, storageStore, localesStore },
      OrdersModel,
      CartProcessingModel,
    } = this.rootModels;

    try {
      helperStore.toggleLoader(true);
      const created = Date.now();

      article.objectId = null;

      const [address] = storageStore.storeData.addresses || [{}];

      let finalData = {
        objectId: null,
        status: OrdersModel.Status.Requested,
        desiredSeller: storageStore?.deviceId,
        sourceStoreSellerId: storageStore?.merchant.id,
        created: created,
        createdDate: created,
        targetStoreId: storageStore?.storeId,
        sourceStoreId: storageStore?.storeId,
        type: OrdersModel.Type.Reservation,
        processId: CartProcessingModel.orderData?.processId ?? null,
        targetStore: {
          cashSystemIp: storageStore.storeData.cashSystemIp,
          addresses: storageStore.storeData.addresses,
          openingTimesWednesday: storageStore.storeData.openingTimesWednesday,
          openingTimesSaturday: storageStore.storeData.openingTimesSaturday,
          openingTimesSunday: storageStore.storeData.openingTimesSunday,
          created: storageStore.storeData.created,
          gln: storageStore.storeData.gln,
          externalDisplayName: storageStore.storeData.externalDisplayName,
          storeId: storageStore.storeData.storeId,
          openingTimesFriday: storageStore.storeData.openingTimesFriday,
          openingTimesThursday: storageStore.storeData.openingTimesThursday,
          paymentGroup: storageStore.storeData.paymentGroup,
          storeURL: storageStore.storeData.storeURL,
          imageURL: storageStore.storeData.imageURL,
          openingTimesMonday: storageStore.storeData.openingTimesMonday,
          name: storageStore.storeData.name,
          additionalInfo: storageStore.storeData.additionalInfo,
          openingTimesTuesday: storageStore.storeData.openingTimesTuesday,
          updated: storageStore.storeData.updated,
          email: storageStore.storeData.email,
          objectId: storageStore.storeData.objectId,
        },
        deliveryAddress: {
          birthday: address.birthday,
          country: address.country?.toUpperCase(),
          lastName: address.lastName,
          website: address.website,
          streetNumber: address.streetNumber,
          city: address.city,
          addressType: address.addressType,
          created: address.created,
          latitude: address.latitude,
          postalCode: address.postalCode,
          title: address.title,
          firstName: address.firstName ?? storageStore.storeData.name,
          phone: address.phone ?? storageStore.storeData.phone,
          street: address.street,
          storeId: storageStore.storeData.storeId,
          customerId: address.customerId ?? storageStore.storeData.storeId,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          salutation: address.salutation,
          region: address.region,
          updated: address.updated,
          objectId: 'InCreation_1681976177.833237',
          email: address.email ?? storageStore.storeData.email,
          longitude: address.longitude,
        },
        invoiceAddress: {
          birthday: CartProcessingModel.orderData?.details?.birthday
            ? new Date(CartProcessingModel.orderData.details.birthday).getTime()
            : null,
          country: CartProcessingModel.orderData?.details?.country?.toUpperCase(),
          lastName: CartProcessingModel.orderData?.details?.lastName,
          website: null,
          streetNumber: CartProcessingModel.orderData?.details?.streetNumber,
          city: CartProcessingModel.orderData?.details?.city,
          addressType: 'MainAddress',
          created: null,
          latitude: 0,
          postalCode: CartProcessingModel.orderData.details?.postalCode,
          title: CartProcessingModel.orderData.details?.title ?? null,
          firstName: CartProcessingModel.orderData.details?.firstName,
          phone: CartProcessingModel.orderData.details?.phone,
          street: CartProcessingModel.orderData.details?.street,
          customerId: CartProcessingModel.orderData.details?.customerId ?? null,
          addressLine1: CartProcessingModel.orderData.details?.addressLine1,
          addressLine2: CartProcessingModel.orderData.details?.addressLine2,
          salutation: CartProcessingModel.orderData.details?.salutation,
          additionalProperties: null,
          region: null,
          updated: null,
          objectId: null,
          email: CartProcessingModel.orderData.details?.email,
          longitude: 0,
          ___dates___: [Date.now().toString()],
        },
        articleItems: [article],
      };

      const { status, response } = await networkStore.post('addCreateNewProcess', finalData);

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "CreateNewProcess" failed!');

      swal({
        icon: 'success',
        content: {
          element: 'img',
          attributes: {
            src: 'images/ig-logotype-dark.png',
            style: 'height: 70px; margin-top: 20px;',
          },
        },
      }).then(() => CartProcessingModel.reset());
    } catch (error) {
      if (debug) console.error(error);
      swal('', localesStore.translate('order.label.wrong'), 'error');
    } finally {
      helperStore.toggleLoader(false);
    }
  });

  handleToggleCart = action(() => {
    this.isCartToggled = !this.isCartToggled;
  });
}

export default Model('OrderModel')(OrderModel);
