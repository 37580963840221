import { css, styled } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const FilterTitleStyled = Styled(
  'Filter',
  'FilterTitleStyled'
)(styled('span')`
  cursor: pointer;

  ${({ theme: { application }, $isActive }) => css`
    font-weight: ${$isActive ? 700 : 400};
    color: ${application.palette.black};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      font-size: 14px;
    }
  `}
`);
