import { css, DialogContent, styled } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const TrackerDialogTitleStyled = Styled(
  'Tracker',
  'TrackerDialogTitleStyled'
)(styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 32px;
  line-height: 1.19;
  font-weight: 600;
  min-height: 80px;
  padding: 20px;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
    border-bottom: 1px solid ${application.palette.grayline};
  `}
`);

export const TrackerDialogContentStyled = Styled(
  'Tracker',
  'TrackerDialogContentStyled'
)(styled(DialogContent)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  height: calc(100% - 80px);
  overflow-y: hidden;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      overflow-y: auto;
    }
  `}
`);

export const TrackerIframeStyled = Styled(
  'Tracker',
  'TrackerIframeStyled'
)(styled('iframe')`
  height: 100%;
  width: 100%;
  border: 0;
  flex-grow: 1;
`);
