import swal from 'sweetalert';
import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class RequestsProcessingModel {
  isToggled = false;
  isInfoToggled = false;
  isStepper = false;
  isLoading = false;

  step = 0;
  request = null;
  answers = {};
  tracker = '';

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
      isInfoToggled: observable,
      isStepper: observable,
      isLoading: observable,
      step: observable,
      request: observable,
      answers: observable,
      tracker: observable,
    });
  }

  stepperMounted = action(() => {
    const {
      store: { networkStore, helperStore, localesStore },
    } = this.rootModels;

    const isVerified = Object.values(this.answers).every(item => !!item);

    if (isVerified && !this.isLoading) {
      this.step = 1;
    }
  });

  getRequestsByProcess = action(async processId => {
    const {
      store: { debug, networkStore, helperStore },
    } = this.rootModels;

    try {
      const { status, response } = await networkStore.post('LoadObjectsOfClass', {
        whereClause: `processId = '${processId}' AND status = 'Open'`,
        offset: 0,
        orderByName: 'processId',
        orderByType: 'ASC',
        pageSize: 1000000,
        relations: [],
        clazz: 'com.innomos.baas.common.model.OmniChannelRequest',
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "LoadObjectsOfClass" Failed!');

      const {
        data: { data },
      } = JSON.parse(response);

      return data;
    } catch (error) {
      if (debug) console.error(error);
      helperStore.toggleLoader(false);
    }
  });

  updateStatus = action(async data => {
    const articleItems = [],
      { processId, requestId, status } = data,
      {
        store: { networkStore, helperStore, debug },
        RequestsModel: {
          isProcessedLoaded,
          fetchRequests,
          fetchProcessedRequests,
          Status: { Refused, Confirmed },
        },
        OrdersModel,
      } = this.rootModels;

    switch (status) {
      case Refused:
        this.request.articleItems.forEach(item => {
          articleItems.push({
            status: Refused,
            omniChannelArticleItemId: item.objectId,
            trackingNumber: this.tracker ?? null,
          });
        });
        break;

      case Confirmed:
        const confirmedKeys = Object.keys(this.answers);

        this.request.articleItems.forEach(item => {
          if (confirmedKeys.includes(item.gtin)) {
            articleItems.push({
              status: Confirmed,
              omniChannelArticleItemId: item.objectId,
              trackingNumber: this.tracker ?? null,
            });
          } else {
            articleItems.push({
              status: Refused,
              omniChannelArticleItemId: item.objectId,
              trackingNumber: this.tracker ?? null,
            });
          }
        });
        break;
    }

    try {
      helperStore.toggleLoader(true);

      const { status, response } = await networkStore.post('updateStatusArticleItem', {
        requestId,
        processId,
        articleItems,
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "updateStatusArticleItem" failed!');

      await fetchRequests(true);

      if (isProcessedLoaded) await fetchProcessedRequests(true);

      OrdersModel.fetchOrders().catch(error => debug && console.error(error));
    } catch (error) {
      if (debug) console.error(error);
    } finally {
      helperStore.toggleLoader(false);
    }
  });

  setRequest = action(request => {
    this.request = request;
  });

  setAnswer = action((key, value) => {
    this.answers = {
      ...this.answers,
      [key]: value,
    };
  });

  getAnswer = action(key => this.answers[key]);

  handleShipping = action(() => {
    const { BarcodeScannerModel, store } = this.rootModels;

    BarcodeScannerModel.callBarcodeScannerPromise()
      .then(value => {
        this.tracker = value;
      })
      .catch(type => {
        if (type === 'exit') return;
        swal('', store.localesStore.translate('modal.scanner.label.failed'), 'error');
      });
  });
  
  handleArticleClick = action(({ gtin, color, size }) => {
    const { store, ArticleModel } = this.rootModels;

    store.helperStore.toggleLoader(true);

    ArticleModel.fetchArticleGroupsByGtin(gtin, {
      error: false,
      colorCode: color,
      size: size,
      skipOnError: false,
      onSuccess: () => {
        store.helperStore.toggleLoader(false);
        ArticleModel.toggleWindow(true);
      },
      onError: type => {
        store.helperStore.toggleLoader(false);
        
        store.helperStore.toggleNotification({
          type: 'inverse',
          title: store.localesStore.translate('placeholder.label.notice'),
          description: store.localesStore.translate(
            type === 'unavailable' ? 'modal.article.unavailable.label.description' : 'modal.products.label.error'
          ),
          confirm: store.localesStore.translate('placeholder.label.ok'),
          onConfirm: () => {
            store.helperStore.toggleNotification(null);
          },
        });
      },
    }).catch(error => store.debug && console.error(error));
  })

  handleScanner = action(() => {
    const {
      BarcodeScannerModel,
      store: { localesStore, networkStore },
    } = this.rootModels;

    BarcodeScannerModel.callBarcodeScannerPromise()
      .then(async value => {
        if (typeof this.answers[value] !== 'boolean') {
          networkStore.trackAnalytics('barcode_scan_not_successful', 'Request', value);
          
          throw 'incorrect'
        };
        
        
        this.setAnswer(value, true);
        
        networkStore.trackAnalytics('barcode_scan_successful', 'Request', value);
      })
      .catch(type => {
        if (type === 'exit') return;
        swal('', localesStore.translate('modal.requestsProcessing.label.wrongEan'), 'error');
      });
  });

  handleRequestToggle = action(request => {
    this.request = request;
    this.isToggled = true;
  });

  handleInfoToggle = action(() => {
    this.isInfoToggled = !this.isInfoToggled;
  });

  handleAcceptCall = action(() => {
    Object.entries(this.answers).forEach(data => {
      const [key, value] = data;
      if (value !== 'No') this.setAnswer(key, false);
    });

    this.isStepper = true;
  });

  handleSubmitCall = action(() => {
    const {
        RequestsModel: {
          Status: { Confirmed },
        },
      } = this.rootModels,
      { processId, objectId } = this.request;

    this.updateStatus({
      processId: processId,
      requestId: objectId,
      status: Confirmed,
    });

    this.handleModalClose();
  });

  handleDeclineCall = action(() => {
    const {
        RequestsModel: {
          Status: { Refused },
        },
      } = this.rootModels,
      { processId, objectId } = this.request;

    this.updateStatus({
      processId: processId,
      requestId: objectId,
      status: Refused,
    });

    this.handleModalClose();
  });

  handleModalClose = action(() => {
    this.isToggled = false;
    this.isInfoToggled = false;
    this.isStepper = false;
    this.step = 0;
    this.request = null;
    this.reset();
  });

  reset = action(() => {
    this.answers = {};
  });
}

export default Model('RequestsProcessingModel')(RequestsProcessingModel);
