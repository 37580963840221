import { Box, css, keyframes, Modal, styled } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const DialogBodyStyled = Styled(
  'Loader',
  'DialogBodyStyled'
)(styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.white};
  `}
`);

export const DialogWrapperStyled = Styled(
  'Loader',
  'DialogWrapperStyled'
)(styled(Box)`
  display: flex;
  align-items: center;
`);

export const DialogStyled = Styled(
  'Loader',
  'DialogStyled'
)(styled(Modal)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 11000 !important;

  & > * {
    outline: unset !important;
  }

  ${({ $isWithContent }) => css`
    & ${DialogWrapperStyled} {
      padding: ${$isWithContent ? '30px 60px' : '30px'};
    }

    & ${DialogBodyStyled} {
      width: ${$isWithContent ? '350px' : 'auto'};
    }
  `}
`);

export const LoadingStyled = Styled(
  'Loader',
  'LoadingStyled'
)(styled('div')`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-top: 4px solid #e0e1dd;
  animation: ${keyframes`
    to {
      transform: rotate(360deg);
    }
  `} 1s linear infinite;

  ${({ theme: { application } }) => css`
    border: 4px solid ${application.palette.gray};
    border-left: 4px solid ${application.palette.black};
  `}
`);

export const InactivityStyled = Styled(
  'Loader',
  'InactivityStyled'
)(styled(LoadingStyled)`
  width: 100px;
  height: 100px;
`);
