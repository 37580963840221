import { inject, observer } from 'mobx-react';
import { Box, Dialog, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';

import Modal from '@framework/decorators/Modal';

import Shop from '@framework/icons/Shop';
import Home from '@framework/icons/Home';

import {
  DialogStyled,
  ModalAnimation,
  ButtonOutlinedStyled,
  DialogContentStyled,
  DialogTitleStyled,
} from '@framework/styles/App';

const CustomerDestinationModal = Modal('CustomerDestinationModal')(props => {
  const {
    store: { helperStore, localesStore },
    models: { CartModel, CartProcessingModel, OrdersModel },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile();

  const { isDestinationToggled, handleDestinationClose, chooseDestinationVariant } = CartProcessingModel,
    { articles } = CartModel,
    {
      Type: { Wholesale, InStore },
    } = OrdersModel;

  return isMobile ? (
    <Dialog
      fullScreen
      open={isDestinationToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleDestinationClose}
    >
      <DialogTitleStyled>
        <Box component="span" fontSize={24}>
          {localesStore.translate('modal.cartProcessing.destination.label.title')}
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={handleDestinationClose}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box display="grid" rowGap="20px">
          <ButtonOutlinedStyled $icon onClick={() => chooseDestinationVariant(Wholesale)}>
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <Shop height="70" width="70" />
            </Box>
            {localesStore.translate('modal.cartProcessing.destination.controls.store')}
          </ButtonOutlinedStyled>

          <ButtonOutlinedStyled
            $icon
            disabled={articles.length && articles.some(article => article.quantity > article.stores?.online)}
            onClick={() => chooseDestinationVariant(InStore)}
          >
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <Home
                height="70"
                width="70"
                fill={
                  articles.length && articles.some(article => article.quantity > article.stores?.online)
                    ? theme.application.palette.darkgray
                    : theme.application.palette.black
                }
              />
            </Box>
            {localesStore.translate('modal.cartProcessing.destination.controls.home')}
          </ButtonOutlinedStyled>
        </Box>
        {articles.length && articles.some(article => article.quantity > article.stores?.online) ? (
          <Box
            mt="20px"
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: localesStore.translate('modal.cartProcessing.destination.label.unavailable'),
            }}
          />
        ) : null}
      </DialogContentStyled>
    </Dialog>
  ) : (
    <DialogStyled
      fullWidth
      maxWidth="md"
      open={isDestinationToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleDestinationClose}
    >
      <DialogTitleStyled>
        <span>{localesStore.translate('modal.cartProcessing.destination.label.title')}</span>
        <Box sx={{ cursor: 'pointer' }} onClick={handleDestinationClose}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="100%"
          height="100%"
          columnGap="20px"
          flexGrow={1}
        >
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <Shop width="160" height="160" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled onClick={() => chooseDestinationVariant(Wholesale)}>
                {localesStore.translate('modal.cartProcessing.destination.controls.store')}
              </ButtonOutlinedStyled>
            </Box>
          </Box>
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              {articles.length && articles.some(article => article.quantity > article.stores?.online) ? (
                <Box
                  p="80px"
                  textAlign="center"
                  dangerouslySetInnerHTML={{
                    __html: localesStore.translate('modal.cartProcessing.destination.label.unavailable'),
                  }}
                />
              ) : (
                <Home width="160" height="160" />
              )}
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled
                disabled={articles.length && articles.some(article => article.quantity > article.stores?.online)}
                onClick={() => chooseDestinationVariant(InStore)}
              >
                {localesStore.translate('modal.cartProcessing.destination.controls.home')}
              </ButtonOutlinedStyled>
            </Box>
          </Box>
        </Box>
      </DialogContentStyled>
    </DialogStyled>
  );
});

export default inject('store', 'models')(observer(CustomerDestinationModal));
