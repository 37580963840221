import { isPrototypeClass } from './helpers';

const Store = (storeModel, target = 'default', debug = false) => {
  let importedModel = null;

  try {
    importedModel = require(`@tenant/stores/${storeModel}`)[target];
  } catch (error) {
    if (debug) console.error(error);
  }

  return rootModel => (isPrototypeClass(importedModel, rootModel) ? importedModel : rootModel);
};

export default Store;
