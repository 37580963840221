import { inject, observer } from 'mobx-react';
import { Box, Dialog, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';

import Modal from '@framework/decorators/Modal';

import ComplaintsOnlineIcon from '@framework/icons/ComplaintOnline';
import ComplaintsCustomerIcon from '@framework/icons/ComplaintCustomer';
import ComplaintsDoneIcon from '@framework/icons/ComplaintDone';

import {
  DialogStyled,
  ModalAnimation,
  ButtonOutlinedStyled,
  DialogContentStyled,
  DialogTitleStyled,
} from '@framework/styles/App';

const ComplaintsDestinationModal = Modal('ComplaintsDestinationModal')(props => {
  const {
    store: { helperStore },
    models: { ComplaintsProcessingModel },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile();

  const {
    Destination,
    isDestinationToggled,
    handleDestinationClose,
    setComplaintType,
  } = ComplaintsProcessingModel;

  return isMobile ? (
    <Dialog
      fullScreen
      open={isDestinationToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleDestinationClose}
    >
      <DialogTitleStyled>
        <Box position="relative" display="flex" alignItems="center">
          <Box component="span" fontSize={24}>
            Kaufebene
          </Box>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={handleDestinationClose}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box display="grid" rowGap="20px">
          <ButtonOutlinedStyled
            $icon
            onClick={() => setComplaintType(Destination.ComplaintOnline)}
          >
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <ComplaintsOnlineIcon width="50" height="50" />
            </Box>
            Lagerware
          </ButtonOutlinedStyled>
          <ButtonOutlinedStyled $icon onClick={() => setComplaintType(Destination.ComplaintCustomer)}>
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <ComplaintsCustomerIcon width="50" height="50" />
            </Box>
            Kundeneigentum
          </ButtonOutlinedStyled>
          <ButtonOutlinedStyled $icon onClick={() => setComplaintType(Destination.ComplaintDone)}>
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <ComplaintsDoneIcon width="50" height="50" />
            </Box>
            Reklamation bereits anerkannt
          </ButtonOutlinedStyled>
        </Box>
      </DialogContentStyled>
    </Dialog>
  ) : (
    <DialogStyled
      fullWidth
      maxWidth="lg"
      open={isDestinationToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleDestinationClose}
    >
      <DialogTitleStyled>
        <Box position="relative" display="flex" alignItems="center">
          <Box component="span">
            Kaufebene
          </Box>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={handleDestinationClose}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          gridTemplateRows="100%"
          height="100%"
          columnGap="20px"
          flexGrow={1}
        >
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <ComplaintsOnlineIcon width="180" height="180" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled
                onClick={() => setComplaintType(Destination.ComplaintOnline)}
              >
                Lagerware
              </ButtonOutlinedStyled>
            </Box>
          </Box>
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <ComplaintsCustomerIcon width="180" height="180" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled onClick={() => setComplaintType(Destination.ComplaintCustomer)}>
                Kundeneigentum
              </ButtonOutlinedStyled>
            </Box>
          </Box>
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <ComplaintsDoneIcon width="180" height="180" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled onClick={() => setComplaintType(Destination.ComplaintDone)}>
                Reklamation bereits anerkannt
              </ButtonOutlinedStyled>
            </Box>
          </Box>
        </Box>
      </DialogContentStyled>
    </DialogStyled>
  );
});

export default inject('store', 'models')(observer(ComplaintsDestinationModal));
