import { action, makeObservable, observable } from 'mobx';
import { format } from 'date-fns';

import Model from '@framework/decorators/Model';

export class AppointmentsProcessingModal {
  isToggled = false;
  appointment = null;

  initialDate = '';
  initialTime = '';

  date = '';
  time = '';
  editable = false;

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
      appointment: observable,
      date: observable,
      time: observable,
      editable: observable,
    });
  }

  updateStatus = action(async (appointmentObjectId, newStatus = null, newAppointmentDate = null) => {
    const {
      store: { debug, networkStore, helperStore },
      AppointmentsModel,
    } = this.rootModels;

    try {
      helperStore.toggleLoader(true);

      const { status, response } = await networkStore.put('StoreAppointmentStatus', {
        newStatus,
        newAppointmentDate,
        appointmentObjectId,
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "StoreAppointment" Failed!');

      AppointmentsModel.fetchAppointments().catch(error => debug && console.error(error));
    } catch (error) {
      if (debug) console.error(error);
    }
  });

  setupDatePicker = action(appointmentDate => {
    this.date = this.initialDate = format(+appointmentDate, 'yyyy-LL-dd');
    this.time = this.initialTime = format(+appointmentDate, 'HH:mm');
  });

  handleRescheduleCall = action(() => {
    const { objectId } = this.appointment,
      {
        store: { debug, helperStore, localesStore },
        AppointmentsModel,
      } = this.rootModels;

    const [hours] = this.time.split(':'),
      newAppointmentDate = new Date(`${this.date}T${this.time}`).getTime();

    try {
      if (hours < AppointmentsModel.Settings.MinTime || hours > AppointmentsModel.Settings.MaxTime)
        throw Error('Type of appointmentDate is invalid!');

      helperStore.toggleNotification({
        type: 'inverse',
        title: localesStore.translate('modal.appointment.reschedule.label.title'),
        description: localesStore.translate('modal.appointment.reschedule.label.description'),
        reject: localesStore.translate('modal.appointment.reschedule.controls.cancel'),
        confirm: localesStore.translate('modal.appointment.reschedule.controls.submit'),
        onConfirm: () => {
          helperStore.toggleNotification(null);

          if (
            AppointmentsModel.appointmentsByConfirmed.some(
              appointment => parseInt(appointment.appointmentDate) === newAppointmentDate
            )
          ) {
            swal('', localesStore.translate('modal.appointment.form.field.error.creation'), 'error');
          } else {
            this.updateStatus(objectId, AppointmentsModel.Status.Confirmed, newAppointmentDate).catch(
              error => debug && console.error(error)
            );

            this.handleWindowClose();
          }
        },
        onReject: () => {
          helperStore.toggleNotification(null);
        },
      });
    } catch (error) {
      if (debug) console.error(error);
    }
  });

  handleConfirmCall = action(() => {
    const { objectId } = this.appointment,
      { AppointmentsModel } = this.rootModels;

    this.updateStatus(objectId, AppointmentsModel.Status.Confirmed).then(() => {
      this.handleWindowClose();
      AppointmentsModel.handleTabCall(AppointmentsModel.Tabs.Confirmed);
    });
  });

  handleCustomerCall = action(() => {
    const { objectId } = this.appointment,
      {
        store: { debug, helperStore, localesStore },
        AppointmentsModel,
      } = this.rootModels;

    helperStore.toggleNotification({
      type: 'inverse',
      title: localesStore.translate('modal.appointment.customer.label.title'),
      description: localesStore.translate('modal.appointment.customer.label.description'),
      reject: localesStore.translate('modal.appointment.customer.controls.cancel'),
      confirm: localesStore.translate('modal.appointment.customer.controls.submit'),
      onConfirm: () => {
        this.updateStatus(objectId, AppointmentsModel.Status.DeclinedByStore).catch(
          error => debug && console.error(error)
        );

        helperStore.toggleNotification(null);
        this.handleWindowClose();
      },
      onReject: () => {
        this.updateStatus(objectId, AppointmentsModel.Status.DeclinedByStoreCallNeeded).catch(
          error => debug && console.error(error)
        );

        helperStore.toggleNotification(null);
        this.handleWindowClose();
      },
    });
  });

  handleCancelCall = action(() => {
    const { objectId } = this.appointment,
      {
        store: { debug, helperStore, localesStore },
        AppointmentsModel,
      } = this.rootModels;

    helperStore.toggleNotification({
      type: 'inverse',
      title: localesStore.translate('modal.appointment.cancel.label.title'),
      description: localesStore.translate('modal.appointment.cancel.label.description'),
      reject: localesStore.translate('modal.appointment.cancel.controls.cancel'),
      confirm: localesStore.translate('modal.appointment.cancel.controls.submit'),
      onConfirm: () => {
        this.updateStatus(objectId, AppointmentsModel.Status.DeclinedByStore).catch(
          error => debug && console.error(error)
        );

        helperStore.toggleNotification(null);
        this.handleWindowClose();
      },
      onReject: () => {
        helperStore.toggleNotification(null);
      },
    });
  });

  handleAppointmentCall = action(appointment => {
    this.appointment = appointment;
    this.setupDatePicker(appointment.appointmentDate);
    this.handleWindowCall();
  });

  handleDateChange = action(value => {
    this.date = value;
  });

  handleTimeChange = action(value => {
    this.time = value;
  });

  handleEditableCall = action(() => {
    this.editable = true;
  });

  handleEditableClose = action(() => {
    this.editable = false;
    this.date = this.initialDate;
    this.time = this.initialTime;
  });

  handleWindowCall = action(() => {
    this.isToggled = true;
  });

  handleWindowClose = action(() => {
    this.isToggled = false;
    this.editable = false;
    this.appointment = null;
    this.date = '';
    this.time = '';
  });
}

export default Model('AppointmentsProcessingModal')(AppointmentsProcessingModal);
