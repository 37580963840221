import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class CustomerTransactionModel {
  isToggled = false;
  isInfoToggled = false;

  active = null;
  activeIndex = 0;

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
      isInfoToggled: observable,
      active: observable,
      activeIndex: observable,
    });
  }

  setTransaction = action(data => {
    const { CustomerDetailsModel } = this.rootModels;

    this.isToggled = true;
    this.active = data;
    this.activeIndex = CustomerDetailsModel.transactions.findIndex(
      transaction => transaction.transactionId === data.transactionId
    );
  });

  handleInfoToggle = action(() => {
    this.isInfoToggled = !this.isInfoToggled;
  });

  handleCloseCall = action(() => {
    this.reset();
  });

  handlePrevCall = action(e => {
    e.preventDefault();
    const { CustomerDetailsModel } = this.rootModels;
    const nextProduct = CustomerDetailsModel.transactions[this.activeIndex - 1];
    this.setTransaction(nextProduct);
  });

  handleNextCall = action(e => {
    e.preventDefault();
    const { CustomerDetailsModel } = this.rootModels;
    const nextProduct = CustomerDetailsModel.transactions[this.activeIndex + 1];
    this.setTransaction(nextProduct);
  });

  handleRtlSwipe = action(() => {
    const { CustomerDetailsModel } = this.rootModels;

    if (this.activeIndex === 0) {
      const lastProductIndex = CustomerDetailsModel.transactions.length - 1;
      return this.setTransaction(CustomerDetailsModel.transactions[lastProductIndex]);
    }

    const previousProduct = CustomerDetailsModel.transactions[this.activeIndex - 1];
    return this.setTransaction(previousProduct);
  });

  handleLtrSwipe = action(() => {
    const { CustomerDetailsModel } = this.rootModels;

    if (this.activeIndex === CustomerDetailsModel.transactions.length - 1) {
      const firstProductIndex = 0;
      return this.setTransaction(CustomerDetailsModel.transactions[firstProductIndex]);
    }

    const nextProduct = CustomerDetailsModel.transactions[this.activeIndex + 1];
    return this.setTransaction(nextProduct);
  });

  reset = action(() => {
    this.active = null;
    this.activeIndex = 0;
    this.isToggled = false;
  });
}

export default Model('CustomerTransactionModel')(CustomerTransactionModel);
