import { memo } from 'react';
import { useTheme } from '@mui/material';
import { Check, Close, MoreHoriz } from '@mui/icons-material';

import Icon from '@framework/decorators/Icon';

const HistoryStatus = {
  Confirmed: 'Confirmed',
  Declined: 'Declined',
  Refused: 'Refused',
  Requested: 'Requested',
};

const History = memo(({ status }) => {
  const theme = useTheme();

  switch (status) {
    case HistoryStatus.Confirmed:
      return <Check sx={{ color: theme.application.palette.darkgreen }} />;
    case HistoryStatus.Declined:
      return <Close sx={{ color: theme.application.palette.firebrick }} />;
    case HistoryStatus.Refused:
      return <Close sx={{ color: theme.application.palette.firebrick }} />;
    case HistoryStatus.Requested:
      return <MoreHoriz sx={{ color: theme.application.palette.goldenrod }} />;
    default:
      return null;
  }
});

export default Icon('History')(History);
