import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class CockpitModel {
  isLoading = true;

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isLoading: observable,
    });
  }

  viewMounted = action(() => {
    const {
      store: { debug, helperStore },
      OrdersModel,
      RequestsModel,
      AppointmentsModel,
      ComplaintsModel
    } = this.rootModels;

    helperStore.cockpitAnimationDirection = helperStore.Direction.Ltr;
    helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
    helperStore.screensAnimationDirection = helperStore.Direction.Rtl;

    if (!OrdersModel.isDataLoaded && !OrdersModel.isLoading) {
      OrdersModel.fetchOrders().catch(error => debug && console.error(error));
    }

    if (OrdersModel.isDataLoaded && !RequestsModel.isDataLoaded && !RequestsModel.isLoading) {
      RequestsModel.fetchRequests().catch(error => debug && console.error(error));
    }

    if (AppointmentsModel && !AppointmentsModel.isDataLoaded && !AppointmentsModel.isLoading) {
      AppointmentsModel.fetchAppointments().catch(error => debug && console.error(error));
    }
    
    if (ComplaintsModel && !ComplaintsModel.isDataLoaded && !ComplaintsModel.isLoading) {
      ComplaintsModel.fetchComplaints().catch(error => debug && console.error(error));
    }

    if (OrdersModel.isDataLoaded && RequestsModel.isDataLoaded) this.isLoading = false;
  });
}

export default Model('CockpitModel')(CockpitModel);
