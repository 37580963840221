import { observer, inject } from 'mobx-react';
import { Box, Dialog } from '@mui/material';

import Modal from '@framework/decorators/Modal';

import { ModalAnimation } from '@framework/styles/App';

const InactivityVideoModal = Modal('InactivityVideoModal')(props => {
  const {
    store: { isKioskMode, helperStore },
    models: { AuthModel },
  } = props;
  
  const handleCloseClick = () => {
    helperStore.toggleInactivityVideo(false);
    
    AuthModel.updateActivity();
  }

  return (
    <Dialog
      fullScreen
      open={helperStore.isInactivityVideo}
      TransitionComponent={ModalAnimation}
      sx={{ zIndex: '100000051 !important' }}
    >
      <Box onClick={handleCloseClick} sx={{ width: '100%', height: '100%', overflow: 'hidden', }}>
        <Box
          component="video"
          loop
          autoPlay
          src="https://olymp-salesmate-live.innomos.com/api/32A8F30C-594B-18FE-FF48-E13324DCCE00/v1/files/media/OLYMP10122020.mp4"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </Box>
    </Dialog>
  );
});

export default inject('store', 'models')(observer(InactivityVideoModal));
