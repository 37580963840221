import { inject, observer } from 'mobx-react';
import { Fragment, useState } from 'react';
import { Box, useTheme, Fade } from '@mui/material';
import { ChevronLeft, ChevronRight, Close } from '@mui/icons-material';
import { format } from 'date-fns';

import Modal from '@framework/decorators/Modal';

import { ModalAnimation } from '@framework/styles/App';
import {
  CustomerProductsDialogStyled,
  CustomerProductsHeaderStyled,
  CustomerProductsContentStyled,
  CustomerProductsTitleStyled,
  DetailsListStyled,
  DetailsListItemStyled,
  CustomerProductsDetailsButtonStyled,
  CustomerProductsCounterStyled,
  CustomerProductsLeftButtonStyled,
  CustomerProductsRightButtonStyled,
} from '@framework/styles/CustomerProducts';

const CustomerProductsModal = Modal('CustomerProductsModal')(props => {
  const {
    models: { CustomerProductsModel, CustomerDetailsModel },
    store: { helperStore, localesStore, storageStore },
  } = props;

  const [touchStart, setTouchStart] = useState(0);

  const theme = useTheme(),
    isMobile = helperStore.isMobile();

  const {
    isToggled,
    active,
    activeIndex,
    handleProductDetails,
    handlePrevCall,
    handleNextCall,
    handleRtlSwipe,
    handleLtrSwipe,
    handleCloseCall,
  } = CustomerProductsModel;

  return (
    <CustomerProductsDialogStyled
      $isMobile={isMobile}
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
      open={isToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleCloseCall}
    >
      {isToggled ? (
        <Fragment>
          <CustomerProductsHeaderStyled>
            <h2>{localesStore.translate('modal.products.label.title')}</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: isMobile ? 'flex-end' : 'center',
                alignItems: 'center',
                cursor: 'pointer',
                width: '100px',
              }}
              onClick={handleCloseCall}
            >
              <Close color={theme.application.palette.white} />
            </Box>
          </CustomerProductsHeaderStyled>
          <Box display="flex" flexDirection="column" overflow="hidden" height="100%">
            {isMobile ? (
              <CustomerProductsContentStyled>
                <CustomerProductsTitleStyled>
                  <h2>{active?.name ?? 'Artikel unbekannt'}</h2>
                  <Box display="flex" justifyContent="space-between" mt="10px" mb="10px">
                    <span>{active?.articleNumber}</span>
                    <span>{active?.gtin}</span>
                  </Box>
                </CustomerProductsTitleStyled>
                <Box
                  onTouchStart={e => {
                    setTouchStart(e.targetTouches[0].clientX);
                  }}
                  onTouchEnd={e => {
                    const touchEnd = e.changedTouches[0].clientX;

                    if (touchStart - touchEnd < -75) {
                      handleRtlSwipe();
                    }

                    if (touchStart - touchEnd > 75) {
                      handleLtrSwipe();
                    }
                  }}
                >
                  <Box position="relative">
                    <CustomerProductsLeftButtonStyled
                      onClick={e => {
                        e.stopPropagation();
                        handleRtlSwipe();
                      }}
                    >
                      <ChevronLeft />
                    </CustomerProductsLeftButtonStyled>
                    <Box height="320px" p="0 30px">
                      <Fade key={activeIndex} in={Boolean(activeIndex + 1)}>
                        <img src={active?.productUrl || 'images/ig-no-image.png'} alt="" />
                      </Fade>
                    </Box>
                    <CustomerProductsRightButtonStyled
                      onClick={e => {
                        e.stopPropagation();
                        handleLtrSwipe();
                      }}
                    >
                      <ChevronRight />
                    </CustomerProductsRightButtonStyled>
                  </Box>

                  <Box display="flex" justifyContent="center" alignItems="center">
                    <CustomerProductsCounterStyled>
                      {activeIndex + 1} / {CustomerDetailsModel.list.length}
                    </CustomerProductsCounterStyled>
                  </Box>
                </Box>
                <Box mt="20px" display="flex" flexDirection="column" justifyContent="space-between" textAlign="center">
                  <Box p="20px" bgcolor="whitesmoke" mb="10px">
                    <DetailsListStyled>
                      <DetailsListItemStyled>
                        <span>{localesStore.translate('modal.products.label.at')}</span>
                        {format(+active?.transactionDate, 'dd.MM.yyyy')}
                      </DetailsListItemStyled>
                      <DetailsListItemStyled>
                        <span>{localesStore.translate('modal.products.label.in')}</span>
                        {active?.storeName ?? '-'}
                      </DetailsListItemStyled>
                      <DetailsListItemStyled>
                        <span>{localesStore.translate('modal.products.label.size')}</span>
                        {active?.size}
                      </DetailsListItemStyled>
                      <DetailsListItemStyled>
                        <span>{localesStore.translate('modal.products.label.color')}</span>
                        {active?.color}
                      </DetailsListItemStyled>
                    </DetailsListStyled>
                  </Box>
                  <CustomerProductsDetailsButtonStyled disabled={storageStore.isOutlet() || !active?.gtin} onClick={handleProductDetails}>
                    {localesStore.translate('modal.products.controls.details')}
                  </CustomerProductsDetailsButtonStyled>
                </Box>
              </CustomerProductsContentStyled>
            ) : (
              <Fragment>
                <CustomerProductsTitleStyled>
                  <h2>{active?.name ?? 'Artikel unbekannt'}</h2>
                  <Box display="flex" justifyContent="space-between" mt="10px" mb="10px">
                    <span>{active?.articleNumber}</span>
                    <span>{active?.gtin}</span>
                  </Box>
                </CustomerProductsTitleStyled>
                <CustomerProductsContentStyled>
                  <Box display="flex" justifyContent="center" width="55%" height="100%">
                    <Fade key={activeIndex} in={Boolean(activeIndex + 1)}>
                      <img src={active?.productUrl || 'images/ig-no-image.png'} alt="" />
                    </Fade>
                  </Box>
                  <Box
                    width="45%"
                    display="flex"
                    flexDirection="column"
                    ml="5%"
                    justifyContent="space-between"
                    height="100%"
                    textAlign="center"
                  >
                    <Box p="20px" bgcolor="whitesmoke" mb="10px">
                      <DetailsListStyled>
                        <DetailsListItemStyled>
                          <span>{localesStore.translate('modal.products.label.at')}</span>
                          {format(+active?.transactionDate, 'dd.MM.yyyy')}
                        </DetailsListItemStyled>
                        <DetailsListItemStyled>
                          <span>{localesStore.translate('modal.products.label.in')}</span>
                          {active?.storeName ?? '-'}
                        </DetailsListItemStyled>
                        <DetailsListItemStyled>
                          <span>{localesStore.translate('modal.products.label.size')}</span>
                          {active?.size}
                        </DetailsListItemStyled>
                        <DetailsListItemStyled>
                          <span>{localesStore.translate('modal.products.label.color')}</span>
                          {active?.color}
                        </DetailsListItemStyled>
                      </DetailsListStyled>
                    </Box>
                    <CustomerProductsDetailsButtonStyled disabled={storageStore.isOutlet() || !active?.gtin} onClick={handleProductDetails}>
                      {localesStore.translate('modal.products.controls.details')}
                    </CustomerProductsDetailsButtonStyled>
                  </Box>
                </CustomerProductsContentStyled>
              </Fragment>
            )}
          </Box>
          {!isMobile && (
            <Fragment>
              {activeIndex !== 0 && (
                <CustomerProductsLeftButtonStyled onClick={handlePrevCall}>
                  <ChevronLeft color={theme.application.palette.white} />
                </CustomerProductsLeftButtonStyled>
              )}
              {activeIndex !== CustomerDetailsModel.list.length - 1 && (
                <CustomerProductsRightButtonStyled onClick={handleNextCall}>
                  <ChevronRight color={theme.application.palette.white} />
                </CustomerProductsRightButtonStyled>
              )}
            </Fragment>
          )}
        </Fragment>
      ) : null}
    </CustomerProductsDialogStyled>
  );
});

export default inject('store', 'models')(observer(CustomerProductsModal));
