import { useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { ExitToAppOutlined } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';

import Component from '@framework/decorators/Component';

import {
  MenuStyled,
  MenuBackdropStyled,
  MenuContainerStyled,
  MenuToolbarStyled,
  MenuToolbarContentStyled,
  MenuListStyled,
  MenuLinkStyled,
} from '@framework/styles/Navbar';

const NavbarComponent = Component('NavbarComponent')(props => {
  const {
    models: { HeaderModel, OrdersModel, AuthModel, AppointmentsModel },
    store: { helperStore, localesStore, storageStore, isMasterMate },
  } = props;

  const theme = useTheme();

  const isMobile = helperStore.isMobile(),
    { isToggled, toggleMenuBar } = HeaderModel,
    {
      Tabs: { InCreation, WaitingForDelivery, WaitingForCustomer, Finished },
      handleTabCall: handleOrdersTabCall,
    } = OrdersModel,
    {
      Tabs: { Opened, Confirmed },
      handleTabCall: handleAppointmentsTabCall,
    } = AppointmentsModel;

  const handleLinkCall = useCallback((e, tab = null, appointments = false) => {
      e.stopPropagation();
      if (isToggled) toggleMenuBar();
      helperStore.setPreviousSearchValue('');
      if (tab) {
        if (appointments) return handleAppointmentsTabCall(tab);
        return handleOrdersTabCall(tab);
      }
    }, []),
    handleLogoutCall = useCallback(() => {
      if (isToggled) toggleMenuBar();
      AuthModel.logout();
    }, []);

  return (
    <MenuStyled $isToggled={isToggled}>
      <MenuBackdropStyled onClick={toggleMenuBar} />
      <MenuContainerStyled>
        <MenuListStyled>
          <MenuLinkStyled to="/appointments" onClick={e => handleLinkCall(e, Confirmed, true)}>
            {localesStore.translate('cockpit.label.services')}
            <MenuListStyled>
              <MenuLinkStyled to="/appointments" onClick={e => handleLinkCall(e, Opened, true)}>
                {localesStore.translate('cockpit.appointments.status.Requested')}
              </MenuLinkStyled>
              <MenuLinkStyled to="/appointments" onClick={e => handleLinkCall(e, Confirmed, true)}>
                {localesStore.translate('cockpit.appointments.status.Planned')}
              </MenuLinkStyled>
            </MenuListStyled>
          </MenuLinkStyled>
          <MenuLinkStyled to="/" onClick={e => handleLinkCall(e)}>
            {localesStore.translate('page.title.cockpit')}
          </MenuLinkStyled>
          <Box
            sx={{
              marginTop: '15px',
              opacity: storageStore.isOutlet() ? '0.3' : '1',
              pointerEvents: storageStore.isOutlet() ? 'none' : 'all'
            }}
          >
            <MenuLinkStyled to="/catalog" onClick={e => handleLinkCall(e)}>
              {localesStore.translate('page.title.catalog')}
            </MenuLinkStyled>
            <MenuLinkStyled to="/orders" onClick={e => handleLinkCall(e, InCreation)}>
              {localesStore.translate('page.title.orders')}
              <MenuListStyled>
                <MenuLinkStyled to="/orders" onClick={e => handleLinkCall(e, InCreation)}>
                  {localesStore.translate('cockpit.orders.status.Creation')}
                </MenuLinkStyled>
                <MenuLinkStyled to="/orders" onClick={e => handleLinkCall(e, WaitingForDelivery)}>
                  {localesStore.translate('cockpit.orders.status.Delivery')}
                </MenuLinkStyled>
                <MenuLinkStyled to="/orders" onClick={e => handleLinkCall(e, WaitingForCustomer)}>
                  {localesStore.translate('cockpit.orders.status.Customer')}
                </MenuLinkStyled>
                <MenuLinkStyled to="/orders" onClick={e => handleLinkCall(e, Finished)}>
                  {localesStore.translate('cockpit.orders.status.Finished')}
                </MenuLinkStyled>
              </MenuListStyled>
            </MenuLinkStyled>
          </Box>
        </MenuListStyled>
        <MenuToolbarStyled>
          <MenuToolbarContentStyled>
            <span>
              {isMobile
                ? storageStore.storeName ?? localesStore.translate('placeholder.label.storeName')
                : localesStore.translate('cockpit.label.merchant') + ':'}
            </span>
            <h2>{storageStore.merchant.fullName}</h2>
          </MenuToolbarContentStyled>
          <ExitToAppOutlined onClick={handleLogoutCall} />
        </MenuToolbarStyled>
        <Box
          sx={{
            fontSize: '14px',
            padding: '10px 30px',
            color: theme.application.palette.darkgray,
          }}
        >
          {`${localesStore.translate('placeholder.label.version')}: ${
            storageStore.version
          }`}
        </Box>
        {isMasterMate && (
          <Box
            onClick={() => AuthModel.handleChangeStore()}
            sx={{
              cursor: 'pointer',
              padding: '15px',
              textAlign: 'center',
              borderTop: `1px solid ${theme.application.palette.grayline}`,
            }}
          >
            Store ändern
          </Box>
        )}
      </MenuContainerStyled>
    </MenuStyled>
  );
});

export default inject('store', 'models')(observer(NavbarComponent));
