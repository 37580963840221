import { css, styled } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const RowStyled = Styled(
  'Orders',
  'RowStyled'
)(styled('div')`
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 20px;
  padding: 15px 10px;
  cursor: pointer;

  ${({ theme: { application }, $isActive }) => css`
    background-color: ${$isActive ? application.palette.whitesmoke : application.palette.white};
    border-bottom: 1px solid ${application.palette.whitesmoke};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      column-gap: 10px;
    }
  `}
`);

export const HistoryBlockStyled = Styled(
  'Orders',
  'HistoryBlockStyled'
)(styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 0 20px;

  ${({ theme: { application }, $position }) => css`
    border-left: 2px solid ${application.palette.whitesmoke};

    ${$position !== 0 &&
    css`
      &:after {
        content: '';
        position: absolute;
        height: 20px;
        width: 20px;
        background: ${application.palette.white};
        border: 2px solid ${application.palette.whitesmoke};
        border-top-width: 0px;
        border-left-width: 0px;
        transform: rotate(135deg);
        left: -12px;
        top: ${$position}px;
      }
    `}
  `}
`);
