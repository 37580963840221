import { action, computed, makeObservable, observable } from 'mobx';
import {subDays} from "date-fns";

import Model from '@framework/decorators/Model';

export class ComplaintsModel {
  Filter = {
    All: 0,
    LastTwoDays: 1,
    LastFiveDays: 2,
    LastTenDays: 3,
    LastMonth: 4,
  };

  isLoading = false;
  isDataLoaded = false;
  
  isFilterToggled = false;
  isDetailsToggled = false;

  data = [];
  details = null;

  currentFilter = 0;
  filters = [0, 2, 5, 10, 30];

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isLoading: observable,
      isDataLoaded: observable,
      
      isFilterToggled: observable,
      isDetailsToggled: observable,
      
      data: observable,
      details: observable,

      currentFilter: observable,
      parkedComplaints: computed,
      archivedComplaints: computed
    });
  }
  get complaintsByFilter() {
    const useTimeFilter = item =>
      +item.created >= subDays(new Date(), this.filters[this.currentFilter]).getTime();

    return !this.currentFilter ? this.archivedComplaints : this.archivedComplaints.filter(useTimeFilter);
  }

  get parkedComplaintsByFilter() {
    const useTimeFilter = item =>
      +item.created >= subDays(new Date(), this.filters[this.currentFilter]).getTime();

    return !this.currentFilter ? this.parkedComplaints : this.parkedComplaints.filter(useTimeFilter);
  }

  get parkedComplaints() {
    const useStatusFilter = item => item.status === "PARKED";

    return this.data.filter(useStatusFilter);
  }

  get archivedComplaints() {
    const useStatusFilter = item => item.status !== "PARKED";

    return this.data.filter(useStatusFilter);
  }

  handleFilterCall = action(filter => {
    this.currentFilter = filter;
    this.isFilterToggled = false;
  });

  handleFilterToggle = action(() => {
    this.isFilterToggled = !this.isFilterToggled;
  });

  handleRefreshCall = action(() => {
    this.fetchComplaints().catch(console.error);
  });
  
  handleDetailsCall = action((details) => {
    this.details = details;
    this.isDetailsToggled = true;
  });
  
  handleWindowClose = action(() => {
    this.details = null;
    this.isDetailsToggled = false;
  })

  viewMounted = action(() => {
    if (!this.isLoading && !this.isDataLoaded)
      this.fetchComplaints().catch(console.error);
  });

  fetchComplaints = action(async () => {
    this.isLoading = true;

    const {
      store: { debug, storageStore, networkStore, helperStore },
    } = this.rootModels;

    const isViewModel = document.location.pathname.includes('complaints');

    if (isViewModel) helperStore.toggleLoader(true);

    try {
      const whereClause = `(sourceStoreId = '${storageStore.storeId}')`;

      const { response, status } = await networkStore.post('getComplaintHistory', {
        "offset": 0,
        "pageSize": 100,
        "orderByType": "DESC",
        "orderByName": "created",
        "whereClause": whereClause,
        "relations": ['customerAddress']
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "LoadObjectsOfClass" Failed!');

      const { data: { data } } = JSON.parse(response);

      this.data = data;

      this.isDataLoaded = true;
      if (isViewModel) helperStore.toggleLoader(false);
    } catch (error) {
      if (debug) console.error(error);
      if (isViewModel) helperStore.toggleLoader(false);
    } finally {
      this.isLoading = false;
    }
  });
  
  reset = action(() => {
    this.data = [];
    this.currentFilter = 0;
    this.isDataLoaded = false;
    this.isFilterToggled = false;
    this.isDetailsToggled = false;
    this.details = null;
  });
}

export default Model('ComplaintsModel')(ComplaintsModel);
