import { Fragment } from 'react';
import { Box, useTheme } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { Check, Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import Loader from '@framework/icons/Loader';

import Component from '@framework/decorators/Component';

const SidebarComponent = Component('SidebarComponent')(props => {
  const {
    models: { CatalogModel },
    store: { helperStore, localesStore },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile();

  const {
    filters,
    isFiltersLoading,
    isOptionSelected,
    isFilterToggled,
    selectedFiltersQuantity,
    setSelectedFilters,
    handleFiltersCall,
    handleOptionCall,
    handleResetCall,
  } = CatalogModel;

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      sx={{ '& > * + *': { marginTop: '10px' } }}
      overflow="hidden"
      border={`2px solid ${isMobile ? theme.application.palette.whitesmoke : theme.application.palette.black}`}
    >
      {isFiltersLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <Box
            position="relative"
            component="ul"
            flex={1}
            height={isMobile ? '100%' : 'auto'}
            overflow="auto"
            borderBottom={
              isMobile && !selectedFiltersQuantity ? `2px solid ${theme.application.palette.whitesmoke}` : 'unset'
            }
          >
            {filters?.data.map(filter => {
              const isSomeSelectedOptions = filter.children?.some(isOptionSelected),
                options = filter.children
                  ?.filter(isOptionSelected)
                  .map(option =>
                    filter.type === 'availability_filter'
                      ? localesStore.translate(`catalog.label.filters.type.availability_filter.${option.name}`)
                      : option.name
                  )
                  .join(', '),
                isToggled = isFilterToggled(filter.objectId);

              return (
                <Box
                  key={filter.objectId}
                  component="li"
                  sx={{
                    cursor: 'pointer',
                    padding: '0px',
                    borderBottom: `1px solid ${theme.application.palette[isMobile ? 'whitesmoke' : 'darkgray']}`,
                  }}
                  onClick={({ target }) => {
                    setSelectedFilters(filter.objectId);
                    queueMicrotask(() => {
                      if (!isToggled && target) {
                        target?.closest('div')?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                        });
                      }
                    });
                  }}
                >
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" padding="10px 15px">
                      <Box component="span" fontSize={16} fontWeight={isToggled ? 'bold' : 'unset'}>
                        {localesStore.translate(`catalog.label.filters.type.${filter.type}`)}
                      </Box>
                      {isToggled ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </Box>
                    {isSomeSelectedOptions && !isToggled && (
                      <Box
                        component="span"
                        padding="0px 15px 10px"
                        fontSize={14}
                        color={theme.application.palette.darkgray}
                      >
                        {options}
                      </Box>
                    )}
                  </Box>

                  {isToggled &&
                    filter.children?.map(option => (
                      <Box
                        key={option.objectId}
                        component="li"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        p="10px 0"
                        borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                        onClick={e => {
                          handleOptionCall(e, option);
                          if (isMobile) handleFiltersCall();
                        }}
                      >
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(2, max-content)"
                          alignItems="center"
                          columnGap="10px"
                          padding="0 10px"
                        >
                          <Box display="flex" visibility={isOptionSelected(option) ? 'visible' : 'hidden'}>
                            <Check />
                          </Box>
                          <span>
                            {filter.type === 'availability_filter'
                              ? localesStore.translate(`catalog.label.filters.type.availability_filter.${option.name}`)
                              : option.name}
                          </span>
                        </Box>
                      </Box>
                    ))}
                </Box>
              );
            })}
          </Box>
          {Boolean(selectedFiltersQuantity) && (
            <Box p="10px" borderTop="unset" boxShadow="0px -3px 6px 0px rgba(0, 0, 0, 0.16)" border="unset">
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  background: theme.application.palette.whitesmoke,
                  padding: '10px 15px',
                }}
                onClick={() => {
                  handleResetCall();
                  if (isMobile) handleFiltersCall();
                }}
              >
                <span>{localesStore.translate('placeholder.filters.reset')}</span>
                <Close />
              </Box>
            </Box>
          )}
        </Fragment>
      )}
    </Box>
  );
});

export default inject('store', 'models')(observer(SidebarComponent));
