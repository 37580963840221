import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const Card = memo(({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width ?? '162'} height={height ?? '118'} viewBox="0 0 162 118">
    <path
      fillRule="evenodd"
      d="M151.586-.003H10.414C4.672-.003 0 4.661 0 10.394v97.207c0 5.733 4.672 10.395 10.414 10.395h141.172c5.743 0 10.415-4.662 10.415-10.395V10.394c0-5.733-4.672-10.397-10.415-10.397zm7.202 107.604c0 3.964-3.231 7.188-7.202 7.188H10.414c-3.971 0-7.201-3.224-7.201-7.188V40.412h155.575v67.189zm0-90.917H3.213v-6.29c0-3.964 3.23-7.189 7.201-7.189h141.172c3.971 0 7.202 3.225 7.202 7.189v6.29z"
    />
  </svg>
));

export default Icon('Card')(Card);
