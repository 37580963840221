import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import View from '@framework/decorators/View';

import AuthView from '@framework/views/Auth';
import CockpitView from '@framework/views/Cockpit';
import OrdersView from '@framework/views/Orders';
import NotFoundView from '@framework/views/NotFound';
import RequestsView from '@framework/views/Requests';
import CustomerSearchView from '@framework/views/CustomerSearch';
import CatalogView from '@framework/views/Catalog';
import CustomerDetailsView from '@framework/views/CustomerDetails';
import CartView from '@framework/views/Cart';
import CustomerCreationView from '@framework/views/CustomerCreation';
import OrderView from '@framework/views/Order';
import CustomerEditor from '@framework/views/CustomerEditor';
import AppointmentsView from '@framework/views/Appointments';
import ComplaintsProcessingView from '@framework/views/ComplaintsProcessing';
import ComplaintsView from '@framework/views/Complaints';

const RoutesView = View('Routes')(({ store, models: { AuthModel } }) => {
  const navigate = useNavigate(),
    location = useLocation(),
    { isAuthorized, isKioskMode, storageStore, networkStore } = store;

  useEffect(() => {
    AuthModel.register(navigate);
  }, []);

  useEffect(() => {
    if (!networkStore.isNativeThread) return;

    const anchors = document.querySelectorAll('a');

    const handleLinkClick = (event) => {
      event.preventDefault();
      this.webview('modal', event.target.getAttribute('href'));
    };

    anchors.forEach(anchor => {
      anchor.addEventListener('click', handleLinkClick);
    });

    return () => {
      anchors.forEach(anchor => {
        anchor.removeEventListener('click', handleLinkClick);
      });
    }
  }, [location.href]);

  return (
    <AnimatePresence initial={false} mode="wait">
      <Routes key={location.pathname} location={location}>
        {isAuthorized ? (
          <>
            <Route path="/" element={<CockpitView />} />

            <Route path="/customer/new" element={<CustomerCreationView />} />
            <Route path="/customer/edit" element={<CustomerEditor />} />
            <Route path="/customer/details" element={<CustomerDetailsView />} />
            <Route path="/customer/search" element={<CustomerSearchView />} />

            {
              !storageStore.isOutlet() ? (
                <>
                  <Route path="/catalog" element={<Outlet />}>
                    <Route index element={<CatalogView />} />
                    <Route path=":categoryId" element={<CatalogView />} />
                  </Route>
                  <Route path="/orders" element={<OrdersView />} />
                  <Route path="/requests" element={<RequestsView />} />
                  <Route path="/cart" element={<CartView />} />
                  <Route path="/order" element={<OrderView />} />
                </>
              ) : null
            }

            <Route path="/appointments" element={<AppointmentsView />} />
            <Route path="/complaints/create" element={<ComplaintsProcessingView />} />
            <Route path="/complaints/edit" element={<ComplaintsProcessingView />} />
            <Route path="/complaints/archive" element={<ComplaintsView />} />
            <Route path="/complaints/parked" element={<ComplaintsView />} />

            <Route path="/*" element={<NotFoundView />} />
          </>
        ) : isKioskMode ? (
          <>
            <Route path="/" element={<CatalogView />} />
            <Route path="/cart" element={<CartView />} />
            <Route path="/order" element={<OrderView />} />
            <Route path="/*" element={<NotFoundView />} />
          </>
        ) : (
          <Route path="/*" element={<AuthView />} />
        )}
      </Routes>
    </AnimatePresence>
  );
});

export default inject('store', 'models')(observer(RoutesView));
