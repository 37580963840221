import swal from 'sweetalert';
import { addHours, format } from 'date-fns';
import { v4 } from 'uuid';

import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class OrdersProcessingModel {
  isToggled = false;
  isInfoToggled = false;
  position = 0;
  order = null;
  article = null;

  navigate = () => {};

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
      isInfoToggled: observable,
      position: observable,
      order: observable,
      article: observable,
      navigate: observable,
    });
  }

  register = action(navigate => {
    this.navigate = navigate;
  });

  updateStatus = action(async (processId, processStatus, processPaymentType = null) => {
    const {
      store: { debug, networkStore, helperStore },
      OrdersModel,
    } = this.rootModels;

    try {
      helperStore.toggleLoader(true);
      const { status, response } = await networkStore.put('OmniChannelProcessStatus', {
        processId,
        status: processStatus,
        paymentType: processPaymentType,
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "OmniChannelProcessStatusWithPaymentType" Failed!');

      OrdersModel.fetchOrders().catch(error => debug && console.error(error));

      if (OrdersModel.isFinishedLoaded) {
        OrdersModel.fetchFinishedOrders().catch(error => debug && console.error(error));
      }

      this.setOrder(null).catch(error => debug && console.error(error));
    } catch (error) {
      if (debug) console.error(error);
    } finally {
      helperStore.toggleLoader(false);
    }
  });

  setOrder = action(async order => {
    const {
      store: { helperStore },
      OrdersModel: {
        Type: { Wholesale, InStore },
        Status: { Requested },
      },
    } = this.rootModels;

    if (order) {
      helperStore.toggleLoader(true);

      const { type, status, articleItems, created } = order;
 
      if (type === Wholesale && status === Requested) {
        const availableTill = addHours(new Date(created), 1);
        const firstButton = articleItems.some(item => item.status === Requested),
          secondButton = availableTill < Date.now();

        this.order = { ...order, disabled: { firstButton, secondButton } };

        helperStore.toggleLoader(false);
        this.isToggled = true;
      } else if (type === InStore && status === Requested) {
        const availableTill = addHours(new Date(created), 1);
        
        const firstButton = availableTill < Date.now();

        this.order = { ...order, disabled: { firstButton } };

        helperStore.toggleLoader(false);
        this.isToggled = true;
      } else {
        this.order = {
          ...order,
          disabled: { firstBtn: false, secondBtn: false },
        };

        helperStore.toggleLoader(false);
        this.isToggled = true;
      }
    } else {
      this.order = order;
      this.isToggled = false;
    }
  });

  setArticle = action(article => {
    if (article) {
      article.logs.sort((prev, next) => next.created - prev.created);
      this.article = { ...article };
    } else {
      this.article = article;
    }
  });

  createSignature = action(async (order, data) => {
    const {
      store: { debug, networkStore, storageStore },
    } = this.rootModels;

    try {
      const { merchant } = storageStore;

      if (!data.blob) throw Error('Invalid Signature!');

      const fileName = `${merchant.fullName.replace(' ', '_')}_${order.processId}_${format(
        Date.now(),
        'yyyy-MM-dd_hh.mm.ss'
      )}`;

      const id = v4(),
        file = new File([data.blob], fileName + '.png', { type: 'image/png' });

      return await networkStore.post('CreateSignature', { id, file });
    } catch (error) {
      if (debug) console.error(error);
    }
  });

  handleCloseCall = action(() => {
    this.setOrder(null);
    this.setArticle(null);
    this.isInfoToggled = false;
    this.position = 0;
  });

  handleParkedCall = action(() => {
    const {
      store: { debug, localesStore, helperStore },
      CartModel,
      CartProcessingModel,
      OrdersProcessingModel,
    } = this.rootModels;

    if (CartModel.cart.length !== 0) {
      swal({
        title: localesStore.translate('modal.checkoutWarning.label.title'),
        text: localesStore.translate('modal.checkoutWarning.label.description'),
        buttons: {
          back: localesStore.translate('modal.checkoutWarning.controls.cancel'),
          apply: localesStore.translate('modal.checkoutWarning.controls.apply'),
        },
      }).then(value => {
        if (value !== 'apply') return;

        CartProcessingModel.processParkedData(OrdersProcessingModel.order.processData, () => {
          helperStore.baseAnimationDirection = helperStore.Direction.Ltr;
          this.navigate('/cart');
        }).catch(error => debug && console.error(error));
      });
    } else {
      CartProcessingModel.processParkedData(OrdersProcessingModel.order.processData, () => {
        helperStore.baseAnimationDirection = helperStore.Direction.Ltr;
        this.navigate('/cart');
      }).catch(error => debug && console.error(error));
    }
  });

  handleTrackerCall = action(parcelId => {
    const {
      store: { helperStore },
    } = this.rootModels;

    const url = `https://www.dhl.com/de-de/home/tracking/tracking-parcel.html?tracking-id=${parcelId}&submit=1`;

    helperStore.toggleTracker({
      url,
      onLoad: value => helperStore.toggleLoader(value),
      onClose: () => helperStore.toggleTracker(null),
    });
  });

  handleOverdueProcess = action(() => {
    const { order, updateStatus, rootModels } = this,
      {
        store: { helperStore, localesStore },
        OrdersModel: { Status, Payment },
      } = rootModels;

    helperStore.toggleNotification({
      title: localesStore.translate('placeholder.label.notice'),
      description: localesStore.translate('notification.orderProcessing.description'),
      onConfirm: () => {
        updateStatus(order.processId, Status.Canceled, Payment.Alternative);
        helperStore.toggleNotification(null);
      },
      onReject: () => {
        updateStatus(order.processId, Status.Canceled, Payment.None);
        helperStore.toggleNotification(null);
      },
    });
  });

  handleSignatureCall = action((callback, order) => {
    const {
      store: { debug },
      CustomerSignatureModel,
    } = this.rootModels;

    CustomerSignatureModel.signatureModalCall(null)
      .then(signature => {
        this.createSignature(order, signature);
        if (typeof callback === 'function') callback(signature);
      })
      .catch(error => debug && console.error(error));
  });

  handleReturnCall = action(() => {
    const { order, updateStatus, rootModels } = this,
      {
        store: { localesStore },
        OrdersModel: { Status },
      } = rootModels;

    swal({
      title: localesStore.translate('modal.ordersProcessing.return.label.title'),
      text: localesStore.translate('modal.ordersProcessing.return.label.description'),
      buttons: {
        back: localesStore.translate('modal.ordersProcessing.return.controls.no'),
        apply: localesStore.translate('modal.ordersProcessing.return.controls.yes'),
      },
    }).then(value => {
      if (value !== 'apply') return;
      updateStatus(order.processId, Status.Canceled);
    });
  });

  handleArticleCall = action((article, position) => {
    this.setArticle(article);

    if (typeof position === 'number') this.position = position ?? 0;
  });

  handleInfoToggle = action(() => {
    this.isInfoToggled = !this.isInfoToggled;
  });

  handleProcessRemove = action(() => {
    const { order, updateStatus, rootModels } = this,
      {
        store: { helperStore, localesStore },
        OrdersModel: { Status },
      } = rootModels;

    swal({
      title: localesStore.translate('modal.confirm.delete.label.title'),
      text: localesStore.translate('modal.confirm.delete.label.description'),
      buttons: {
        back: localesStore.translate('modal.confirm.delete.controls.decline'),
        apply: localesStore.translate('modal.confirm.delete.controls.apply'),
      },
    }).then(value => {
      if (value !== 'apply') return;

      updateStatus(order.processId, Status.Deleted);
      this.handleCloseCall();
    });
  });
}

export default Model('OrdersProcessingModel')(OrdersProcessingModel);
