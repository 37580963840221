import swal from 'sweetalert';
import { action, computed, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class CatalogModel {
  Type = {
    Color: 'color_filter',
    Passform: 'passform_filter',
    Season: 'season_filter',
    Size: 'size_filter',
    Style: 'style_filter',
  };

  Order = {
    Asc: 'ASC',
    Desc: 'DESC',
    New: 'NEW',
  };

  articles = [];
  totalArticles = 0;

  isGridView = true;
  isSearchbarToggled = false;
  isToolbarToggled = false;
  isFiltersLoading = false;
  isArticlesLoading = false;
  isCatalogHierarchyLoading = false;
  isFiltersToggled = false;
  isIconsToggled = false;

  categoryRootId = null;

  filters = null;
  selectedFilters = {};
  toggledFilters = [];

  searchTerm = '';
  ordering = this.Order.New;

  page = 0;
  pageSize = 18;
  offset = 0;

  attempt = 1;

  navigate = () => {};

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isSearchbarToggled: observable,
      isToolbarToggled: observable,
      isGridView: observable,
      isFiltersLoading: observable,
      isArticlesLoading: observable,
      isCatalogHierarchyLoading: observable,
      isFiltersToggled: observable,
      isIconsToggled: observable,

      categoryRootId: observable,

      articles: observable,
      totalArticles: observable,

      ordering: observable,
      searchTerm: observable,

      filters: observable,
      selectedFilters: observable,
      toggledFilters: observable,
      navigate: observable,

      isSomeFilterSelected: computed,
      selectedFiltersQuantity: computed,
      filterCategories: computed,
    });
  }

  get isSomeFilterSelected() {
    return Boolean(this.toggledFilters.length);
  }

  get selectedFiltersQuantity() {
    if (this.filters && this.filters?.data) {
      return (this.filters.data || []).reduce((acc, filter) => {
        if (filter.children?.some(option => this.isOptionSelected(option))) {
          acc += 1;
        }
        return acc;
      }, 0);
    }

    return 0;
  }

  get filterCategories() {
    return this.selectedFilters;
  }

  isFilterToggled = id => this.toggledFilters.includes(id);
  isOptionSelected = ({ type, objectId }) => this.selectedFilters[type]?.includes(objectId);

  viewMounted = action(navigate => {
    this.navigate = navigate;
    if (!this.isCatalogHierarchyLoading) this.fetchCategoryHierarchyByFilters();

    return () => {
      if (this.attempt === 0) {
        this.reset();
      } else {
        this.attempt = 0;
      }
    };
  });

  categoryHierarchyLoaded = action(() => {
    const isCategoryHierarchyLoaded = Boolean(this.categoryRootId);
    if (isCategoryHierarchyLoaded) {
      if (!this.isArticlesLoading) this.fetchArticleGroups();
      if (!this.isFiltersLoading) this.fetchFilters();
    }
  });

  setSelectedFilters = action(objectId => {
    if (this.toggledFilters.includes(objectId)) {
      this.toggledFilters = this.toggledFilters.filter(id => id !== objectId);
    } else {
      this.toggledFilters = [...this.toggledFilters, objectId];
    }
  });

  setSelectedOptionsByFilterType = action(({ type, objectId }) => {
    if (this.selectedFilters[type]?.includes(objectId)) {
      this.selectedFilters[type] = this.selectedFilters[type].filter(id => id !== objectId);
    } else {
      this.selectedFilters[type] = Array.isArray(this.selectedFilters[type])
        ? [...this.selectedFilters[type], objectId]
        : [objectId];
    }

    this.fetchFilters();
  });

  fetchFilters = action(async () => {
    const {
      searchTerm,
      rootModels: { store },
      filterCategories,
      categoryRootId,
    } = this;

    const { storageStore, networkStore } = store;

    try {
      this.isFiltersLoading = true;

      const { status, response } = await networkStore.post('Filters', {
        searchTerm: searchTerm,
        filterCategories: filterCategories,
        storeId: storageStore.storeId,
        onlyAvailableForStocks: null,
        filterType: '',
        categoryId: categoryRootId,
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "Filters" Failed!');

      const { data } = JSON.parse(response);

      this.filters = data;
    } catch (error) {
      if (store.debug) console.error(error);
    } finally {
      this.isFiltersLoading = false;
    }
  });

  fetchArticleGroups = action(async () => {
    const { searchTerm, ordering, rootModels, offset, filterCategories, pageSize, categoryRootId } = this;
    const { store } = rootModels;
    const { helperStore, networkStore, storageStore } = store;

    try {
      this.isArticlesLoading = true;
      if (offset === 0) helperStore.toggleLoader(true);

      const { status, response } = await networkStore.post('getArticleGroup', {
        storeId: storageStore.storeId,
        offset: offset,
        searchTerm: searchTerm,
        category: categoryRootId,
        filterCategories: filterCategories,
        pageSize: pageSize,
        orderByName: ordering === this.Order.New ? 'isNew' : 'minPrice',
        orderByType: ordering === this.Order.New ? '' : ordering,
        onlyAvailable: true,
        onlyPricesForCurrentStore: true,
        typeOfAvailabilitiesToShow: '',
        loadPrice: 1,
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "getArticleGroup" Failed!');

      const {
        data: { data, totalObjects },
      } = JSON.parse(response);

      if (offset > 0) {
        this.articles = [...this.articles, ...data];
      } else {
        this.articles = data;
      }

      this.totalArticles = totalObjects;
    } catch (error) {
      if (store.debug) console.error(error);
    } finally {
      this.isArticlesLoading = false;
      helperStore.toggleLoader(false);
    }
  });

  fetchCategoryHierarchyByFilters = action(async () => {
    const {
      rootModels: { store },
      searchTerm,
      filterCategories,
    } = this;

    const { networkStore, helperStore } = store;

    try {
      helperStore.toggleLoader(true);
      this.isCatalogHierarchyLoading = true;

      const { status, response } = await networkStore.post('ArticleCategoryHierarchyByFilters', {
        searchTerm: searchTerm,
        filterCategories: filterCategories,
        withoutChildren: false,
        onlyAvailableForStocks: null,
        filterType: '',
        filterByProperty: true,
        categoryId: null,
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      )
        throw Error('Network Store: Request "ArticleCategoryHierarchyByFilters" Failed!');

      const { data } = JSON.parse(response);

      this.categoryRootId = data.objectId;
    } catch (error) {
      if (store.debug) console.error(error);
    } finally {
      this.isCatalogHierarchyLoading = false;
    }
  });

  fetchNextPage = action(async () => {
    this.page += 1;
    this.offset = this.page * this.pageSize;
    await this.fetchArticleGroups();
  });

  toggleIcons = action(() => {
    this.isIconsToggled = !this.isIconsToggled;
  });

  toggleSearchbar = action(() => {
    this.isSearchbarToggled = !this.isSearchbarToggled;
    this.handleSearchbarClear();
  });

  toggleToolbar = action(() => {
    this.isToolbarToggled = !this.isToolbarToggled;
  });

  toggleGridView = action(() => {
    this.isGridView = !this.isGridView;
  });

  toggleFilters = action(() => {
    this.isFiltersToggled = !this.isFiltersToggled;
  });

  handleScannerCall = action(() => {
    const { store, ArticleModel, BarcodeScannerModel, CartModel } = this.rootModels;

    BarcodeScannerModel.callBarcodeScannerPromise(null, true).then(value => {
      if (value?.type === 'ShoppingCart') {
        CartModel.setCart(value?.data);
        this.navigate('/cart');
      } else {
        ArticleModel.fetchArticleGroupsByBarcode(value).then(error => {
          if (error) {
            store.networkStore.trackAnalytics('barcode_scan_not_successful', 'Article', value);
            
            return swal('', store.localesStore.translate('modal.article.label.error'), 'error')
          }
          
          store.networkStore.trackAnalytics('barcode_scan_successful', 'Article', value);
        });
      }
    });
  });

  handleIconsToggle = action(() => {
    this.toggleIcons();
  });

  handleFiltersCall = action(() => {
    this.toggleFilters();
  });

  handleSearchbarCall = action(withDelay => {
    this.toggleSearchbar();
  });

  handleSearchbarChange = action(e => {
    this.searchTerm = e.target?.value || '';
  });

  handleCockpitSearch = action(e => {
    this.isSearchbarToggled = true;
    this.searchTerm = e.target?.value || '';
  });

  handleSearchbarAction = action(e => {
    if (e.key !== 'Enter') return;
    this.resetValuesForArticleGroupRequest();
    this.fetchArticleGroups();
    this.fetchFilters();
  });

  handleSearchbarClear = action(() => {
    if (this.searchTerm !== '') {
      this.searchTerm = '';
      this.resetValuesForArticleGroupRequest();
      this.fetchArticleGroups();
      this.fetchFilters();
    }
  });

  handleToolbarCall = action(() => {
    this.toggleToolbar();
  });

  handleOrderingChange = action(ordering => {
    this.ordering = ordering;
    this.resetValuesForArticleGroupRequest();
    this.fetchArticleGroups();
  });

  handleGridViewChange = action(() => {
    this.toggleGridView();
  });

  handleOptionCall = action((e, option) => {
    e.stopPropagation();
    this.toggledFilters = [];
    this.setSelectedOptionsByFilterType(option);
    this.resetValuesForArticleGroupRequest();
    this.fetchArticleGroups();
    this.fetchFilters();
  });

  handleResetCall = action(() => {
    this.resetFilters();
    this.resetValuesForArticleGroupRequest();
    this.fetchFilters();
    this.fetchArticleGroups();
  });

  resetValuesForArticleGroupRequest = action(() => {
    this.articles = [];
    this.offset = 0;
    this.page = 0;
  });

  resetFilters = action(() => {
    this.selectedFilters = {};
    this.toggledFilters = [];
  });

  reset = action(() => {
    this.pageSize = 18;
    this.filters = null;
    this.isSearchbarToggled = false;
    this.searchTerm = '';
    this.ordering = this.Order.New;
    this.totalArticles = 0;
    this.attempt = 1;
    this.resetValuesForArticleGroupRequest();
    this.resetFilters();
  });
}

export default Model('CatalogModel')(CatalogModel);
