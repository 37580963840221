import { Component, useState } from 'react';
import { Box, Dialog, useTheme, Zoom } from '@mui/material';

import { ModalAnimation } from "@framework/styles/App";

const useStyles = () => ({
  animated: {
    '& circle': {
      transition:
        'stroke-dashoffset 600ms linear 600ms, stroke-dasharray 600ms linear 600ms',
      strokeDasharray: '200,0',
      strokeDashoffset: '0',
    },
    '& path': {
      transition: 'stroke-dashoffset 180ms linear 880ms',
      strokeDashoffset: '0',
    },
    '& line': {
      transition: 'stroke-width 30ms linear 860ms',
      strokeWidth: '6',
    },
  },
  circle: {
    transition:
      'stroke-dashoffset 600ms linear 260ms, stroke-dasharray 600ms linear 860ms',
    stroke: '#FF9800',
    strokeDasharray: '1,200',
    strokeDashoffset: '2',
  },
  path: {
    transition: 'stroke-dashoffset 180ms linear 600ms',
    strokeDashoffset: '-50px',
    strokeDasharray: '50px',
  },
  line: {
    transition: 'stroke-width 30ms linear 800ms',
    strokeWidth: '0',
  },
});

const WarningIcon = () => {
  const [animated, setAnimated] = useState(false);

  const styles = useStyles();

  return (
    <Zoom in timeout={400} addEndListener={() => setAnimated(true)}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <Box component="g" sx={animated ? styles.animated : {}}>
          <Box
            component="circle"
            fill="none"
            stroke={'#ddd'}
            strokeLinecap="round"
            strokeWidth="3"
            strokeMiterlimit="10"
            cx="32"
            cy="32"
            r="30"
          />
          <Box
            component="circle"
            sx={styles.circle}
            fill="none"
            strokeLinecap="round"
            strokeWidth="3"
            strokeMiterlimit="10"
            cx="32"
            cy="32"
            r="30"
          />
          <Box
            component="path"
            sx={styles.path}
            fill="none"
            stroke={'#FF9800'}
            strokeLinecap="round"
            strokeWidth="4"
            strokeMiterlimit="10"
            d="M32 15v20"
          />
          <Box
            component="line"
            sx={styles.line}
            fill="none"
            stroke={'#FF9800'}
            strokeLinecap="round"
            strokeWidth="6"
            strokeMiterlimit="10"
            x1="32"
            y1="46"
            x2="32"
            y2="46"
          />
        </Box>
      </svg>
    </Zoom>
  );
};

class Sentry extends Component {
  state = { error: null };

  componentDidMount() {
    window.storeMindSentry = this.setState.bind(this);
  }
  
  componentDidCatch(error) {
    this.setState({ error: error });
  }

  action() {
    window.location.reload();
  }
  
  async componentDidUpdate() {
    const { networkStore, helperStore } = this.props.store;
    const { AuthModel } = this.props.models;
    
    if(this.state.error && !AuthModel.isMaintenance) {
       helperStore.toggleLoader(true);
       
       const { response } = await networkStore.get('MaintenancePageState');
      
       const maintenanceResult = JSON.parse(response);
       
       if(maintenanceResult.data.activated) {
          AuthModel.logout();
          AuthModel.isMaintenance = true;
          this.props.store.isKioskMode = false;
          return helperStore.toggleLoader(false);
       } else {
          networkStore.log('[Sentry]:[componentDidCatch]', this.state.error);
       }
    }
  }

  render() {
    const { error } = this.state;
    const { children, models: { AuthModel } } = this.props;

    if (!error || AuthModel.isMaintenance) return children;

    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={!!error}
        onClose={this.action}
        TransitionComponent={ModalAnimation}
        sx={{
          '& .MuiPaper-root': {
            gap: '20px',
            padding: '30px',
            display: 'flex',
            borderRadius: '0',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'sans-serif',
          },
        }}
      >
        <Box
          sx={{
            width: '100px',
            height: '100px',
          }}
        >
          <WarningIcon />
        </Box>
        <Box
          sx={{
            fontSize: '26px',
            textAlign: 'center',
            color: 'black',
          }}
        >
          Da ist etwas schief gelaufen!
        </Box>
        <Box
          sx={{
            fontSize: '18px',
            textAlign: 'center',
            whiteSpace: 'pre-line',
            color: 'black',
          }}
        >
          Wir werden das Problem umgehend untersuchen und beheben.
        </Box>

        {/* TODO: Unify Button Component */}
        <Box
          onClick={this.action}
          sx={{
            cursor: 'pointer',
            appearance: 'none',
            height: 'inherit',
            border: '2px solid transparent',
            background: 'transparent',
            padding: '15px 35px',
            fontWeight: '600',
            fontSize: '15px',
            backgroundColor: '#000',
            color: '#fff',
            transition: 'background-color linear 200ms',
          }}
        >
          Seite neu laden
        </Box>
      </Dialog>
    );
  }
}

export default Sentry;