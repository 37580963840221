import { css, styled } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const DetailsContainerStyled = Styled(
  'CustomerDetails',
  'DetailsContainerStyled'
)(styled('div')`
  display: flex;
  padding: 30px 0;

  ${({ theme: { application } }) => css`
    border-top: 1px solid ${application.palette.lightgray};
  `}
`);

export const DetailsTitleStyled = Styled(
  'CustomerDetails',
  'DetailsTitleStyled'
)(styled('h2')`
  font-size: 21px;
  margin-bottom: 10px;
`);

export const DetailsInfoStyled = Styled(
  'CustomerDetails',
  'DetailsInfoStyled'
)(styled('div')`
  display: flex;
  flex-direction: column;

  & > span + span {
    margin-top: 10px;
  }

  ${({ theme: { application } }) => css`
    & > h3 {
      font-size: 16px;
      margin-bottom: 10px;
      color: ${application.palette.grayboro};
    }
  `}
`);

export const DetailsListStyled = Styled(
  'CustomerDetails',
  'DetailsListStyled'
)(styled('ul')`
  list-style: none;
  padding: 0;

  & li + li {
    margin-top: 20px;
  }
`);

export const DetailsListItemStyled = Styled(
  'CustomerDetails',
  'DetailsListItemStyled'
)(styled('li')`
  display: flex;
  align-items: center;

  & > div {
    width: 65%;
  }

  ${({ theme: { application } }) => css`
    & > span:first-child {
      min-width: 35%;
      font-size: 16px;
      margin-right: 10px;
      font-weight: 600;
      color: ${application.palette.grayboro};
    }

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      flex-direction: column;
      align-items: flex-start;

      & > span:first-child {
        margin-bottom: 10px;
      }
    }
  `}
`);

export const DetailsVoucherButtonStyled = Styled(
  'CustomerDetails',
  'DetailsVoucherButtonStyled'
)(styled('button')`
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;

  padding: 10px 20px;

  & > span:first-child {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    font-weight: bold;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
    border: 2px solid ${application.palette.black};

    & > span:first-child {
      color: ${application.palette.white};
      background-color: ${application.palette.black};
    }

    &:disabled {
      cursor: not-allowed;
      border-color: ${application.palette.darkgray};
      color: ${application.palette.darkgray};

      & > span:first-child {
        background-color: ${application.palette.darkgray};
      }
    }
  `}
`);

export const DetailsEditButtonStyled = Styled(
  'CustomerDetails',
  'DetailsEditButtonStyled'
)(styled('button')`
  text-align: center;
  min-width: 240px;
  padding: 10px 20px;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
    border: 2px solid ${application.palette.black};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      width: 100%;
      margin-bottom: 30px;
    }
  `}
`);

export const DetailsShoppingButtonStyled = Styled(
  'CustomerDetails',
  'DetailsShoppingButtonStyled'
)(styled('button')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 140px;
  text-align: center;

  & > span {
    margin-top: 10px;
  }

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
    border: 2px solid ${application.palette.black};

    &:disabled {
      cursor: not-allowed;
      border-color: ${application.palette.darkgray};
      color: ${application.palette.darkgray};
    }

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      width: 100%;
    }
  `}
`);

export const DetailsVouchersListItemStyled = Styled(
  'CustomerDetails',
  'DetailsVouchersListItemStyled'
)(styled('li')`
  padding: 15px 20px;

  & h3 {
    font-size: 32px;
    font-weight: normal;
  }

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.whitesmoke};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      & h3 {
        font-size: 24px;
      }
    }
  `}
`);

export const DetailsVouchersListStyled = Styled(
  'CustomerDetails',
  'DetailsVouchersListStyled'
)(styled('li')`
  list-style: none;
  padding: 0;

  & ${DetailsVouchersListItemStyled} + ${DetailsVouchersListItemStyled} {
    margin-top: 15px;
  }
`);

export const DetailsVoucherAcceptButtonStyled = Styled(
  'CustomerDetails',
  'DetailsVoucherAcceptButtonStyled'
)(styled('button')`
  cursor: pointer;
  appearance: none;
  height: inherit;
  background-color: transparent;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.5;
  padding: 0 30px;
  max-width: 140px;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};
    border: 2px solid ${application.palette.black};

    &:disabled {
      cursor: not-allowed;
      border-color: ${application.palette.darkgray};
      color: ${application.palette.darkgray};
    }

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      margin-top: 10px;
      width: 100%;
      max-width: 100%;
      padding: 15px;
    }
  `}
`);

export const DetailsShoppingProductStyled = Styled(
  'CustomerDetails',
  'DetailsShoppingProductStyled'
)(styled('li')`
  min-width: 180px;
  padding: 5px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: background-color linear 200ms;

  &:hover {
    cursor: pointer;
    background-color: whitesmoke;
  }

  & > img {
    max-height: 150px;
  }

  & > p {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 14px;
  }

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      min-height: 200px;
    }
  `}
`);

export const DetailsShoppingProductListStyled = Styled(
  'CustomerDetails',
  'DetailsShoppingProductListStyled'
)(styled('ul')`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  list-style: none;
  padding: 10px;
  flex-grow: 1;
  box-shadow: inset 0px -2px 7px -1px rgba(0, 0, 0, 0.3);

  & ${DetailsShoppingProductStyled} + ${DetailsShoppingProductStyled} {
    margin-left: 15px;
  }

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.white};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      margin: 0;
      flex-direction: column;
      height: 360px;

      & ${DetailsShoppingProductStyled} + ${DetailsShoppingProductStyled} {
        margin-left: 0px;
      }
    }
  `}
`);

export const DetailsTabStyled = Styled(
  'CustomerDetails',
  'DetailsTabStyled'
)(styled('div')`
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
  cursor: pointer;

  &:last-child {
    border-right: unset;
  }

  ${({ theme: { application }, $isActive }) => css`
    background-color: ${$isActive ? application.palette.whitesmoke : application.palette.white};
    border-right: 2px solid ${application.palette.whitesmoke};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
      font-size: 14px;
      word-break: break-word;
      text-align: center;
    }
  `}
`);
