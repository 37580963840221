import getSymbolFromCurrency from 'currency-symbol-map';
import i18nIsoCountries from 'i18n-iso-countries';
import { inject, observer } from 'mobx-react';
import { Fragment, useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { ChevronLeft, ChevronRight, Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import Modal from '@framework/decorators/Modal';

import { ModalAnimation, CounterStyled } from '@framework/styles/App';
import {
  CustomerProductsDialogStyled,
  CustomerProductsHeaderStyled,
  CustomerProductsLeftButtonStyled,
  CustomerProductsRightButtonStyled,
} from '@framework/styles/CustomerProducts';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const CustomerTransactionModal = Modal('CustomerTransactionModal')(props => {
  const {
    models: { CustomerTransactionModel, CustomerDetailsModel },
    store: { helperStore, localesStore },
  } = props;

  const [touchStart, setTouchStart] = useState(0);

  const theme = useTheme(),
    isMobile = helperStore.isMobile(),
    translations = i18nIsoCountries.getNames(localesStore.currentLocale, {
      select: 'official',
    });

  const {
      isToggled,
      isInfoToggled,
      active,
      activeIndex,
      handlePrevCall,
      handleNextCall,
      handleRtlSwipe,
      handleLtrSwipe,
      handleCloseCall,
      handleInfoToggle,
    } = CustomerTransactionModel,
    { userData } = CustomerDetailsModel;

  const currency = useMemo(
    () => active?.currency ?? active?.transactionPositions?.find(item => item.currency)?.currency,
    [active]
  );

  return (
    <CustomerProductsDialogStyled
      $isMobile={isMobile}
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
      open={isToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleCloseCall}
    >
      {isToggled ? (
        <Fragment>
          <CustomerProductsHeaderStyled>
            <h2>{localesStore.translate('modal.products.label.history')}</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: isMobile ? 'flex-end' : 'center',
                alignItems: 'center',
                cursor: 'pointer',
                width: '100px',
              }}
              onClick={handleCloseCall}
            >
              <Close color={theme.application.palette.white} />
            </Box>
          </CustomerProductsHeaderStyled>
          <Box
            display="flex"
            flexDirection="column"
            flexGrow="1"
            overflow="hidden"
            onTouchStart={isMobile ? e => setTouchStart(e.targetTouches[0].clientX) : null}
            onTouchEnd={
              isMobile
                ? e => {
                    const touchEnd = e.changedTouches[0].clientX;
                    if (touchStart - touchEnd < -75) handleLtrSwipe();
                    if (touchStart - touchEnd > 75) handleRtlSwipe();
                  }
                : null
            }
          >
            {isMobile ? (
              <Box
                display="grid"
                gridTemplateRows="max-content max-content"
                bgcolor={theme.application.palette.whitesmoke}
                p="20px"
              >
                <Box display="grid" gridTemplateColumns="3fr 1fr" fontSize={14}>
                  <Box display="grid" alignItems="start" rowGap="5px" fontSize={16}>
                    <Box component="span" fontWeight="bold">
                      {`${localesStore.translate(`placeholder.label.salutation.${userData.salutation}`)} ${
                        userData.firstName
                      } ${userData.lastName}`}
                    </Box>
                    <Box component="span">{`${userData.address?.street} ${userData.address?.streetNumber}`}</Box>
                    <Box component="span">{`${userData.address?.postalCode} ${userData.address?.city}`}</Box>
                    <Box component="span">
                      {translations &&
                      userData.address?.country &&
                      translations[userData.address?.country?.toUpperCase()]
                        ? translations[userData.address?.country?.toUpperCase()]
                        : userData.address?.country}
                    </Box>
                    <Box component="span" mt="10px">
                      {userData.address?.email}
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    {isInfoToggled ? (
                      <KeyboardArrowUp onClick={handleInfoToggle} />
                    ) : (
                      <KeyboardArrowDown onClick={handleInfoToggle} />
                    )}
                  </Box>
                </Box>

                {isInfoToggled && (
                  <Box display="grid" alignItems="start" rowGap="5px" mt="20px" fontSize={16}>
                    <Box component="span">
                      {`${localesStore.translate(`placeholder.label.transaction`)}: ${active.transactionId}`}
                    </Box>
                    <Box component="span" mt="10px">
                      {localesStore.translate('customer.details.label.purchased_time') +
                        ': ' +
                        (active.orderedOn ? format(+active.orderedOn, 'dd.MM.yyyy') : '-')}
                    </Box>
                    <Box component="span" mt="10px">
                      {`${localesStore.translate('placeholder.label.amount')}: ${
                        active.totalGross ? active.totalGross.toFixed(2).replace('.', ',') : ''
                      } ${currency ? getSymbolFromCurrency(currency) : ''}`}
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="space-between"
                bgcolor={theme.application.palette.whitesmoke}
                p="20px"
              >
                <Box display="grid" alignItems="start" rowGap="5px" fontSize={16}>
                  <Box component="span" fontWeight="bold">
                    {`${localesStore.translate(`placeholder.label.salutation.${userData.salutation}`)} ${
                      userData.firstName
                    } ${userData.lastName}`}
                  </Box>
                  <Box component="span">{`${userData.address?.street} ${userData.address?.streetNumber}`}</Box>
                  <Box component="span">{`${userData.address?.postalCode} ${userData.address?.city}`}</Box>
                  <Box component="span">
                    {translations && userData.address?.country && translations[userData.address?.country?.toUpperCase()]
                      ? translations[userData.address?.country?.toUpperCase()]
                      : userData.address?.country}
                  </Box>
                  <Box component="span" mt="10px">
                    {userData.address?.email}
                  </Box>
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="max-content max-content"
                  alignItems="start"
                  rowGap="5px"
                  fontSize={16}
                >
                  <Box display="grid" mr="5px">
                    <Box component="span">{`${localesStore.translate(`placeholder.label.transaction`)}: `}</Box>
                    <Box sx={{ visibility: 'hidden' }}>_</Box>
                    <Box sx={{ visibility: 'hidden' }}>_</Box>
                    <Box sx={{ visibility: 'hidden' }}>_</Box>
                    <Box component="span" mt="10px">
                      {localesStore.translate('customer.details.label.purchased_time') + ': '}
                    </Box>
                    <Box component="span" mt="10px" sx={{ textAlign: 'right' }}>
                      {`${localesStore.translate('placeholder.label.amount')}: `}
                    </Box>
                  </Box>
                  <Box display="grid">
                    <Box component="span">{active.transactionId}</Box>
                    <Box sx={{ visibility: 'hidden' }}>_</Box>
                    <Box sx={{ visibility: 'hidden' }}>_</Box>
                    <Box sx={{ visibility: 'hidden' }}>_</Box>
                    <Box component="span" mt="10px">
                      {active.orderedOn ? format(+active.orderedOn, 'dd.MM.yyyy') : '-'}
                    </Box>
                    <Box component="span" mt="10px">
                      {`${active.totalGross ? active.totalGross.toFixed(2).replace('.', ',') : ''} ${
                        currency ? getSymbolFromCurrency(currency) : ''
                      }`}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            <Box overflow="auto" flexGrow="1">
              {active.transactionPositions?.map(item => (
                <Box
                  key={item.transactionId}
                  display="grid"
                  gridTemplateColumns="max-content 1fr max-content"
                  alignItems="center"
                  margin="20px 0"
                  columnGap="20px"
                  p="10px"
                  bgcolor={theme.application.palette.white}
                >
                  <Box display="flex" justifyContent="center" height="90px" width="80px" mr="10px">
                    <img height="100%" src={item?.productUrl ?? 'images/ig-placeholder.png'} alt="" />
                  </Box>

                  <Box display="flex" flexDirection="column">
                    <Box component="span" fontSize={16} fontWeight={600}>
                      {item?.name}
                    </Box>
                    <Box display="grid" mt="10px" rowGap="10px">
                      <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                        {`${localesStore.translate('placeholder.label.articleNumber')}: ${item?.articleNumber ?? '-'}`}
                      </Box>
                      <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                        {`${localesStore.translate('placeholder.label.ean')}: ${item?.gtin ?? '-'}`}
                      </Box>
                    </Box>
                    <Box
                      display="grid"
                      gridAutoColumns="max-content"
                      columnGap="5px"
                      alignItems="center"
                      gridAutoFlow="column"
                      mt="10px"
                    >
                      {Boolean(item?.color) && (
                        <Box mr="15px" fontSize={14}>
                          {`${localesStore.translate('placeholder.label.color')}: ${item.color}`}
                        </Box>
                      )}

                      <Box mr="15px" height="21px" width="21px" border={`solid 1px ${theme.application.palette.black}`}>
                        <img width="100%" height="100%" src={item?.productUrl ?? 'images/ig-placeholder.png'} alt="" />
                      </Box>

                      {Boolean(item?.size) && <CounterStyled>{item.size}</CounterStyled>}
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="center"
                    height="100%"
                  >
                    <Box sx={{ visibility: 'hidden' }}>_</Box>
                    <Box component="span" fontSize={18} fontWeight={600}>
                      {`${item?.total ? item?.total.toFixed(2).replace('.', ',') : ''} ${
                        item?.currency ? getSymbolFromCurrency(item?.currency) : ''
                      }`}
                    </Box>
                    <Box component="span">
                      {`${localesStore.translate('placeholder.label.quantity')}: ${item.quantity}`}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          {!isMobile && (
            <Fragment>
              {activeIndex !== 0 && (
                <CustomerProductsLeftButtonStyled onClick={handlePrevCall}>
                  <ChevronLeft color={theme.application.palette.white} />
                </CustomerProductsLeftButtonStyled>
              )}
              {activeIndex !== CustomerDetailsModel.transactions.length - 1 && (
                <CustomerProductsRightButtonStyled onClick={handleNextCall}>
                  <ChevronRight color={theme.application.palette.white} />
                </CustomerProductsRightButtonStyled>
              )}
            </Fragment>
          )}
        </Fragment>
      ) : null}
    </CustomerProductsDialogStyled>
  );
});

export default inject('store', 'models')(observer(CustomerTransactionModal));
