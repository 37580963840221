import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const ArticleSearch = memo(({ width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 500.000000 500.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M3585 3820 l-90 -90 -531 0 -532 0 -668 -676 c-368 -372 -675 -687
-683 -701 -8 -17 -12 -47 -9 -85 l4 -59 490 -490 c269 -270 501 -497 516 -505
35 -18 101 -18 136 0 15 8 103 91 195 186 l167 171 53 -10 c70 -13 228 -6 302
14 76 20 185 72 250 119 l50 36 29 -30 c28 -28 29 -30 13 -48 -9 -10 -17 -27
-17 -38 0 -27 488 -514 515 -514 26 0 145 119 145 145 0 27 -488 515 -514 515
-11 0 -28 -8 -38 -17 -17 -15 -20 -15 -48 12 l-29 28 34 47 c46 62 100 179
121 260 20 75 23 250 6 320 l-11 45 54 55 55 56 0 554 0 555 96 96 c79 79 95
100 89 118 -4 15 -15 21 -33 21 -21 0 -48 -21 -117 -90z m-279 -279 l-118
-118 -32 15 c-17 9 -56 18 -86 20 -117 8 -218 -82 -228 -203 -10 -125 91 -235
216 -235 95 0 190 68 212 152 14 51 9 123 -12 165 l-15 31 118 118 119 119 0
-501 0 -500 -34 -33 -33 -32 -23 46 c-75 155 -225 283 -410 350 -108 39 -272
46 -388 17 -108 -28 -197 -71 -276 -135 -126 -101 -208 -229 -247 -387 -42
-166 -11 -376 78 -530 54 -93 181 -216 271 -263 l73 -38 -128 -135 c-158 -168
-176 -184 -216 -184 -28 0 -82 50 -515 482 -265 266 -485 493 -488 505 -3 11
0 32 7 45 7 12 307 321 668 685 l656 662 475 1 475 0 -119 -119z m-189 -169
c9 -6 -4 -24 -42 -62 -56 -55 -65 -76 -43 -98 22 -22 43 -13 98 43 60 61 70
59 70 -15 0 -82 -59 -140 -143 -140 -42 0 -53 5 -90 39 -40 38 -42 42 -42 100
0 53 4 63 30 91 17 18 39 36 50 40 26 11 96 12 112 2z m-200 -498 c208 -46
400 -236 459 -454 27 -100 23 -244 -10 -346 -52 -162 -181 -312 -328 -382
-107 -52 -172 -67 -283 -66 -164 1 -307 59 -427 172 -86 82 -135 155 -171 261
-24 71 -30 102 -30 186 -1 121 13 184 65 294 33 68 56 101 122 166 169 168
365 223 603 169z"
      />
      <path
        d="M2660 2803 c-62 -10 -171 -58 -231 -102 -215 -159 -280 -459 -153
-702 46 -87 152 -190 239 -230 221 -104 462 -64 631 105 178 179 213 437 88
666 -37 68 -146 171 -222 209 -102 51 -238 72 -352 54z m215 -78 c196 -51 337
-217 361 -423 14 -125 -43 -279 -139 -375 -99 -98 -254 -157 -379 -143 -205
24 -372 166 -423 361 -89 343 237 669 580 580z"
      />
    </g>
  </svg>
));

export default Icon('ArticleSearch')(ArticleSearch);
