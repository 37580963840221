import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const Orders = memo(() => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
    width="50px"
    height="50px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <image
      width="100"
      height="100"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA2FBMVEX///8dHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRsdHRv///8Ks1jQAAAARnRSTlMAACGg7TrxDOdzAt39nZBJrLRB/tYBIolsa4sI7PMODV+WlWDLKzXAVRP45gV1gN77GkurQL56sPDi9OCcLi1hn/JZaCflFe5JpgAAAAFiS0dEAIgFHUgAAAAHdElNRQfmAwsONA7KZFwUAAABnklEQVRo3u3YWU/CQBQF4A4DBQrI7sbiwl62sgtUUZb+/5/k3AeNJsqd3mJi9J43knPyzUNb0hoGh8PhcDg/EPE5IRn2kIRl6MOAgERMjICYkSBINKZjeF4sGgCJ6xmeF6cjVkLtk6mzo0klVSlhkZE0HDIjkGSglSYjWZjnMCQHrSwVCeXVulDEkGJB1fLnROQCjniJGUJcQe+ahpTKalup4shNBW7JEgm5hQPe4YYQ99CskZA6TBs6SAOadQrShGVLxxCiBd0mAZEwbOshbehK/0jHVrtuTw/pdVXZ7vhG+nC4gZ4hxADafb+INVQrZ6SLjBxVH1o+kTEcbaJrCDGB/lgPmb5lBqP5VDtz6M/efx5FdP8/sDByWmRRC5SFFqJ9B34ZY8DI6RH7IVDs33MJ/w1EyqWqrCQ5KzVfSmkcD9ywa/rFtYZHhoGFEUYYYYQRRhhhhBFG/iviqlb+kWo8wVdeF0U28HphIp/Ov8vzC6w3KFItB30BKldRRGyDIlvUMITYOUEIZ4cb8OVu7x6oxMHdaxgcDofD4VDyCv5Bs35ynzloAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTAzLTExVDExOjUyOjE0KzAzOjAwtz+85gAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wMy0xMVQxMTo1MjoxNCswMzowMMZiBFoAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAAAAElFTkSuQmCC"
    />
  </svg>
));

export default Icon('Orders')(Orders);
