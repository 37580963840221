import { Fragment, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { Close, PriorityHigh } from '@mui/icons-material';
import getSymbolFromCurrency from 'currency-symbol-map';

import Component from '@framework/decorators/Component';

import SelectComponent from '@framework/components/Select';

import { CounterStyled } from '@framework/styles/App';

const ProductDetailsComponent = Component('ProductDetailsComponent')(props => {
  const {
    item,
    store: { helperStore, localesStore },
    models: { CartModel, ArticleModel },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile();

  const [quantity, setQuantity] = useState(+item.quantity);

  const { setArticleQuantity, removeFromCart } = CartModel,
    { toggleWindow, fetchArticleGroupsByBarcode } = ArticleModel;

  const handleQuantityChange = value => {
    if (!item?.gtin) return;
    setArticleQuantity(item?.gtin, +value);
    setQuantity(+value);
    item.quantity = +value;
  };

  const getAvailableQuantity = () => {
    const arr = [];

    if (item?.available === 0) {
      return [{ value: item.quantity.toString(), label: item.quantity.toString() }];
    } else if (quantity > item?.available) {
      handleQuantityChange(item?.available);
    }

    for (let i = 1; i < item?.available + 1; i++) {
      const str = String(i);
      arr.push({ value: str, label: str });
    }

    return arr;
  };

  useEffect(() => {
    setQuantity(item.quantity);
  }, [item.quantity]);

  const availabilitiesQuantity = getAvailableQuantity();

  return isMobile ? (
    <Fragment>
      <Box
        display="grid"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        columnGap="20px"
        gridTemplateColumns="100%"
        p="10px"
        bgcolor={theme.application.palette.white}
        onClick={() => fetchArticleGroupsByBarcode(item.gtin)}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box component="span" fontSize={18} fontWeight={600}>
            {item?.name}
          </Box>

          <Box
            ml="20px"
            sx={{ cursor: 'pointer' }}
            display="flex"
            alignItems="center"
            onClick={e => {
              e.stopPropagation();
              removeFromCart(item.gtin);
            }}
          >
            <Close />
          </Box>
        </Box>

        <Box display="grid" mt="10px" rowGap="10px">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="grid" mt="10px" rowGap="10px">
              <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                {localesStore.translate('placeholder.label.articleNumber') + ': ' + item?.articleNumber}
              </Box>
              <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
                {`${localesStore.translate('placeholder.label.articlePrice')}: ${
                  item?.price ? (item?.price * quantity).toFixed(2).replace('.', ',') : ''
                } ${item?.currency ? getSymbolFromCurrency(item?.currency) : ''}`}
              </Box>
            </Box>
            {Boolean(item?.size) && <CounterStyled>{item.size}</CounterStyled>}
          </Box>
        </Box>

        <Box mt="10px" display="flex" justifyContent="space-between">
          <Box display="flex" justifyContent="center" height="90px" width="80px" mr="10px">
            <img height="100%" src={item?.imageUrl ?? 'images/ig-placeholder.png'} alt="" />
          </Box>

          <Box flexGrow="1" display="flex" flexDirection="column" justifyContent="space-between">
            <Box
              display="grid"
              columnGap="5px"
              justifyContent="space-between"
              alignItems="center"
              gridAutoFlow="column"
              mt="10px"
            >
              <Box fontSize={14}>{item?.material}</Box>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" mt="10px">
              <Box
                width="70px"
                height="30px"
                mr="10px"
                onClick={e => {
                  e.stopPropagation();

                  if (item?.available === 0) {
                    helperStore.toggleNotification({
                      type: 'inverse',
                      title: localesStore.translate('modal.article.unavailable.label.title'),
                      description: localesStore.translate('modal.article.unavailable.label.description'),
                      confirm: localesStore.translate('placeholder.label.ok'),
                      onConfirm: () => {
                        helperStore.toggleNotification(null);
                      },
                    });
                  }
                }}
              >
                <SelectComponent
                  $isThickness
                  name="amount"
                  quantity={3}
                  data={getAvailableQuantity()}
                  defaultValue={item?.quantity.toString() ?? availabilitiesQuantity[0].value}
                  onChange={handleQuantityChange}
                  disabled={item?.available === 0}
                  useDefaultValue
                  noneIndex
                />
              </Box>

              <Box display="flex" justifyContent="center" alignItems="center">
                <Box component="span" fontSize={16} fontWeight={600}>
                  {`${item?.price ? (item?.price * quantity).toFixed(2) : ''} ${
                    item?.currency ? getSymbolFromCurrency(item?.currency) : ''
                  }`}
                </Box>
                <Box
                  ml="10px"
                  visibility={quantity > +item?.stores.online ? 'visible' : 'hidden'}
                  color={theme.application.palette.goldenrod}
                >
                  <PriorityHigh />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box height="20px" bgcolor={theme.application.palette.whitesmoke}></Box>
    </Fragment>
  ) : (
    <Fragment>
      <Box
        display="grid"
        gridTemplateColumns={'max-content 1fr max-content max-content 150px max-content'}
        alignItems="center"
        columnGap="20px"
        p="10px"
        sx={{ cursor: 'pointer' }}
        onClick={() => fetchArticleGroupsByBarcode(item.gtin)}
        bgcolor={theme.application.palette.white}
      >
        <Box display="flex" justifyContent="center" height="100px" width="90px">
          <img height="100%" src={item?.imageUrl ?? 'images/ig-placeholder.png'} alt="" />
        </Box>

        <Box display="grid" gridAutoFlow="row" rowGap="5px">
          <Box component="span" fontSize={14} fontWeight={600}>
            {item?.name}
          </Box>
          <Box display="flex">
            <Box component="span" fontSize={12} color={theme.application.palette.darkgray}>
              {localesStore.translate('placeholder.label.articleNumber') + ': ' + item?.articleNumber}
            </Box>
            <Box component="span" ml="10px" fontSize={12} color={theme.application.palette.darkgray}>
              {`${localesStore.translate('placeholder.label.articlePrice')}: ${
                item?.price ? (item?.price * quantity).toFixed(2).replace('.', ',') : ''
              } ${item?.currency ? getSymbolFromCurrency(item?.currency) : ''}`}
            </Box>
          </Box>

          <Box display="flex" flexWrap="wrap" alignItems="center" pt="10px" mt="10px">
            {item?.material}
          </Box>
        </Box>

        <Box>{Boolean(item?.size) && <CounterStyled>{item.size}</CounterStyled>}</Box>

        <Box
          width="80px"
          height="40px"
          m="0 10px"
          onClick={e => {
            e.stopPropagation();

            if (item?.available === 0) {
              helperStore.toggleNotification({
                type: 'inverse',
                title: localesStore.translate('modal.article.unavailable.label.title'),
                description: localesStore.translate('modal.article.unavailable.label.description'),
                confirm: localesStore.translate('placeholder.label.ok'),
                onConfirm: () => {
                  helperStore.toggleNotification(null);
                },
              });
            }
          }}
        >
          <SelectComponent
            $isThickness
            name="amount"
            quantity={3}
            data={availabilitiesQuantity}
            defaultValue={item?.quantity.toString() ?? availabilitiesQuantity[0].value}
            onChange={handleQuantityChange}
            disabled={item?.available === 0}
            useDefaultValue
            noneIndex
          />
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" p="0 20px">
          <Box component="span" fontSize={20} fontWeight={600}>
            {`${item?.price ? (item?.price * quantity).toFixed(2).replace('.', ',') : ''} ${
              item?.currency ? getSymbolFromCurrency(item?.currency) : ''
            }`}
          </Box>
        </Box>

        <Box
          sx={{ cursor: 'pointer' }}
          display="flex"
          p="10px"
          borderLeft={`2px solid ${theme.application.palette.whitesmoke}`}
          onClick={e => {
            e.stopPropagation();
            removeFromCart(item.gtin);
          }}
        >
          <Close />

          <Box
            ml="10px"
            visibility={quantity > +item?.stores.online ? 'visible' : 'hidden'}
            color={theme.application.palette.goldenrod}
          >
            <PriorityHigh />
          </Box>
        </Box>
      </Box>
      <Box height="20px" bgcolor={theme.application.palette.whitesmoke}></Box>
    </Fragment>
  );
});

export default ProductDetailsComponent;
