import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { ChevronLeft, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Container,
  css,
  Dialog,
  DialogContent,
  FormControl,
  Input,
  OutlinedInput,
  Slide,
  styled,
} from '@mui/material';

import Styled from '@framework/decorators/Styled';

import { spin } from '@framework/styles/Animation';

export const ModalAnimation = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const PageContainerStyled = Styled(
  'App',
  'PageContainerStyled'
)(styled(({ children, className, backward }) => (
  <motion.div
    className={className}
    variants={
      backward
        ? {
            initial: { opacity: 0, x: -100 },
            animate: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -100 },
          }
        : {
            initial: { opacity: 0, x: 100 },
            animate: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: 100 },
          }
    }
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{ duration: 0.25 }}
  >
    {children}
  </motion.div>
))`
  height: 100%;
`);

export const WindowContainerStyled = Styled(
  'App',
  'WindowContainerStyled'
)(styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;

  ${props =>
    props.$withHeader &&
    css`
      padding-top: 80px;
    `}
`);

export const LayoutContainerStyled = Styled(
  'App',
  'LayoutContainerStyled'
)(styled(Container)`
  padding-top: 10px;
  padding-bottom: 10px;

  ${({ theme: { application }, $scrollable, $fullHeight, $minHeight }) => css`
    ${$scrollable &&
    css`
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 100%;
    `}

    ${$fullHeight &&
    css`
      height: calc(100% - 80px);
    `} ${$minHeight &&
    css`
      display: flex;
      flex-direction: column;
      min-height: 100%;
    `} @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      padding: 10px;
    }
  `}
`);

export const CounterStyled = Styled(
  'App',
  'CounterStyled'
)(styled('div')`
  display: flex;
  justify-content: center;
  border-radius: 11px;
  padding: 3px 7px;
  font-size: 14px;

  ${({ theme: { application }, $isActive }) => css`
    border: solid 1px ${application.palette.black};
    color: ${$isActive ? application.palette.white : application.palette.black};
    background-color: ${$isActive ? application.palette.black : application.palette.white};
  `}
`);

export const CircleWrapperStyled = Styled(
  'App',
  'CircleWrapperStyled'
)(styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  ${({ theme: { application } }) => css`
    border: 1px solid ${application.palette.black};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      width: 25px;
      height: 25px;
    }
  `}
`);

export const RectangleWrapperStyled = Styled(
  'App',
  'RectangleWrapperStyled'
)(styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 24px;
  }

  ${({ theme: { application }, padding, $isActive }) => css`
    padding: ${padding || '5px'};
    border: 2px solid ${application.palette.black};

    ${$isActive &&
    css`
      color: ${application.palette.white};
      background-color: ${application.palette.black};
    `}
  `}
`);

export const ToolbarStyled = Styled(
  'App',
  'ToolbarStyled'
)(styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  & h2 {
    font-size: 24px;
    text-align: center;
    font-weight: 400;
  }

  ${({ theme: { application }, $centered, padding }) => css`
    ${padding &&
    css`
      padding: ${padding};
    `};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      ${!$centered &&
      css`
        justify-content: flex-start;
      `}
      & h2 {
        font-size: 21px;
        ${!$centered &&
        css`
          padding-left: 30px;
        `}
      }
    }
  `}
`);

export const BackButtonStyled = Styled(
  'App',
  'BackButtonStyled'
)(styled(({ children, className, to, onClick }) => (
  <Link to={to} className={className} onClick={onClick}>
    <ChevronLeft />
    <span>{children}</span>
  </Link>
))`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.15;
  text-decoration: none;

  & > svg {
    margin-right: 5px;
  }

  ${({ theme: { application }, $relative }) => css`
    ${$relative &&
    css`
      position: relative;
    `};
    color: ${application.palette.black};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      & > span {
        display: none;
      }
    }
  `}
`);

export const TabsStyled = Styled(
  'App',
  'TabsStyled'
)(styled('div')`
  display: grid;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
  overflow: auto;

  ${({ $isMobile, $quantity }) => {
    if ($isMobile) {
      return css`
        grid-template-columns: repeat(2, 1fr);

        ${$quantity === 3 &&
        css`
          & ${TabStyled}:last-child {
            position: relative;
            grid-column: span 2;
            overflow: hidden;

            & > span {
              margin-left: 0;
              position: absolute;
              left: 0;
              width: 100%;
            }
          }
        `}
      `;
    } else {
      return css`
        grid-template-columns: ${`repeat(${$quantity}, 1fr)`};
      `;
    }
  }}
`);

export const TabStyled = Styled(
  'App',
  'TabStyled'
)(styled('div')`
  cursor: pointer;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;

  & > span {
    margin-left: 10px;
    font-size: 14px;
  }

  &:last-child {
    border-right: unset;
  }

  ${({ theme: { application }, $isActive }) => css`
    background-color: ${$isActive ? application.palette.whitesmoke : application.palette.white};
    border-right: 2px solid ${application.palette.whitesmoke};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      border-right: none;

      & > span {
        flex-grow: 1;
        margin-left: 10px;
      }
    }
  `}
`);

export const ButtonStyled = Styled(
  'App',
  'ButtonStyled'
)(styled('button')`
  cursor: pointer;
  appearance: none;
  height: inherit;
  border: 2px solid transparent;
  background: transparent;
  padding: 0px 25px;
  font-weight: 600;
  font-size: 15px;

  ${({ theme: { application }, $isActive, $fullWidth, marginRightAuto }) => css`
    ${marginRightAuto &&
    css`
      margin-right: auto;
    `}

    ${$fullWidth &&
    css`
      width: 100%;
    `}
    border-color: ${application.palette.black};

    &:disabled {
      border-color: ${application.palette.disabled};
      background: ${application.palette.disabled};
    }

    ${$isActive
      ? css`
          background-color: ${application.palette.white};
          color: ${application.palette.black};
        `
      : css`
          background-color: ${application.palette.black};
          color: ${application.palette.white};
        `}
  `}
`);

export const ButtonOutlinedStyled = Styled(
  'App',
  'ButtonOutlinedStyled'
)(styled(ButtonStyled)`
  width: ${props => (props.$auto ? 'auto' : '100%')};

  ${({ theme: { application }, $icon }) => css`
    background-color: ${application.palette.white};
    color: ${application.palette.black};

    &:disabled {
      background-color: ${application.palette.white};
      color: ${application.palette.darkgray};
      border-color: ${application.palette.darkgray};
    }

    ${$icon &&
    css`
      position: relative;
      display: flex;
      height: 100px;
      justify-content: center;
      align-items: center;
      padding-left: 95px;
    `}
  `}
`);

export const HighlightMatchStyled = Styled(
  'App',
  'HighlightMatchStyled'
)(styled(({ regexp, value, className }) => (
  <span
    className={className}
    dangerouslySetInnerHTML={{
      __html: value.replace(new RegExp(regexp, 'gi'), match => `<mark>${match}</mark>`),
    }}
  />
))`
  ${({ theme: { application } }) => css`
    & mark {
      background-color: ${application.palette.lightgray};
    }
  `}
`);

export const FormControlStyled = Styled(
  'App',
  'FormControlStyled'
)(styled(FormControl)`
  ${({ theme: { application } }) => css`
    background: ${application.palette.white};
  `}
`);

export const OutlinedInputStyled = Styled(
  'App',
  'OutlinedInputStyled'
)(styled(OutlinedInput)`
  border-radius: unset;

  & > input {
    text-overflow: ellipsis;
  }

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      font-size: 12px;
      height: 100%;
      padding: 0px;

      & svg {
        width: 20px;
        padding: 5px;
      }
    }
  `}
`);

export const InputStyled = Styled(
  'App',
  'InputStyled'
)(styled(Input)`
  &:after,
  &:before,
  &:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid;
  }
`);

export const DialogStyled = Styled(
  'App',
  'DialogStyled'
)(styled(Dialog)`
  & .MuiPaper-root {
    height: 660px;
    padding: 30px;
    border-radius: 0px;
  }

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      & .MuiPaper-root {
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }
  `}
`);

export const DialogTitleStyled = Styled(
  'App',
  'DialogTitleStyled'
)(styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 32px;
  line-height: 1.19;
  font-weight: 600;
  margin-bottom: 20px;

  ${({ theme: { application } }) => css`
    color: ${application.palette.black};

    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      height: 80px;
      border-bottom: 1px solid ${application.palette.grayline};
      padding: 20px;
      margin-bottom: 0px;
    }
  `}
`);

export const DialogContentStyled = Styled(
  'App',
  'DialogContentStyled'
)(styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: hidden;

  ${({ theme: { application }, grid }) => css`
    color: ${application.palette.black};

    ${grid &&
    css`
      display: grid;
      grid-template-rows: 100%;
    `} @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      padding: 30px 20px;
      overflow-y: auto;
    }
  `}
  
  ${({ overflow }) => overflow && css`overflow: auto`}
`);

export const MoreDetailsStyled = Styled(
  'App',
  'MoreDetailsStyled'
)(styled(props => {
  const [isToggled, setIsToggled] = useState(false);

  const ref = useRef(null),
    { className, ex, disabled, labelValue, value, children, onChange } = props;

  useEffect(() => {
    if (isToggled && typeof onChange === 'function') onChange(ref.current);
  }, [isToggled]);

  return (
    <Box sx={ex} width="100%" ref={ref}>
      <Box
        sx={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onClick={() => !disabled && setIsToggled(prev => !prev)}
      >
        <Box component="span">{labelValue}</Box>
        {!isToggled ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      </Box>
      {isToggled &&
        (value ? (
          <Box className={className} mt="10px" dangerouslySetInnerHTML={{ __html: value }} />
        ) : (
          <Box mt="10px" width="100%">
            {children}
          </Box>
        ))}
    </Box>
  );
})`
  line-height: 1.25;

  & > ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
    list-style-type: disc;
  }
`);

export const DialogDetailsStyled = Styled(
  'App',
  'DialogDetailsStyled'
)(styled(Dialog)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000 !important;
`);

export const DialogSearchStyled = Styled(
  'App',
  'DialogSearchStyled'
)(styled(Dialog)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000 !important;
`);

export const SignatureContainerStyled = Styled(
  'App',
  'SignatureContainerStyled'
)(styled('div')`
  margin: 20px 0;
  width: 100%;
  background: rgb(243, 243, 243);
  border: 1px solid #ccc;
  height: calc(100% - 150px);

  & canvas {
    width: 100%;
    height: 100%;
  }

  ${({ theme: { application } }) => css`
    @media ${application.breakpoints.xs}, ${application.breakpoints.sm} {
      height: 400px;
    }
  `}
`);

export const NotFoundGearStyled = Styled(
  'App',
  'NotFoundGearStyled'
)(styled('div')`
  ${({ theme: { application } }) => css`
    & > * {
      font-size: 94px !important;
      animation: ${spin} 2s linear infinite;
      color: ${application.palette.black};
    }
  `}
`);
