import {Box, Dialog, useTheme} from '@mui/material';
import { Fragment, useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { inject, observer } from 'mobx-react';

import Modal from '@framework/decorators/Modal';

import { ModalAnimation } from '@framework/styles/App';
import { TrackerDialogTitleStyled, TrackerDialogContentStyled, TrackerIframeStyled } from '@framework/styles/Tracker';

const TrackerModal = Modal('TrackerModal')(props => {
  const {
    store: { debug, helperStore, localesStore },
  } = props;

  const theme = useTheme();

  const { tracker } = helperStore;

  useEffect(() => {
    if (tracker) tracker?.onLoad(true);
  }, [tracker]);

  return (
    <Dialog fullScreen open={Boolean(tracker)} onClose={tracker?.onClose} TransitionComponent={ModalAnimation}>
      {tracker ? (
        <Fragment>
          <TrackerDialogTitleStyled>
            <Box display="flex" justifyContent="center" width="100%">
              <Box
                sx={
                  debug
                    ? {
                      position: 'relative',
                      display: 'flex',
                      height: '40px',
                      '& > img': {
                        opacity: '0.3',
                      },
                      '&::after': {
                        content: '"[prelive]"',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        letterSpacing: '5px',
                        lineHeight: '2',
                        fontSize: '20px',
                        fontWeight: '400',
                        color: theme.application.palette.danger,
                      },
                    }
                    : {
                      display: 'flex',
                      height: '40px',
                    }
                }
              >
                <img
                  height="100%"
                  src="images/ig-logotype-dark.png"
                  alt=""
                />
              </Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '20px',
                }}
                onClick={tracker?.onClose}
              >
                <Close />
              </Box>
            </Box>
          </TrackerDialogTitleStyled>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            <span>{localesStore.translate('placeholder.modal.shipping')}</span>
          </Box>
          <TrackerDialogContentStyled>
            <TrackerIframeStyled src={tracker?.url} onLoad={() => tracker?.onLoad(false)} />
          </TrackerDialogContentStyled>
        </Fragment>
      ) : null}
    </Dialog>
  );
});

export default inject('store')(observer(TrackerModal));
