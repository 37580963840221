import { Fragment, useMemo } from 'react';
import { Formik } from 'formik';
import { Box, Fade, useTheme } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { Close } from '@mui/icons-material';
import { format } from 'date-fns';

import Modal from '@framework/decorators/Modal';

import {
  DialogStyled,
  ModalAnimation,
  DialogTitleStyled,
  ButtonOutlinedStyled,
  ButtonStyled,
  DialogContentStyled,
} from '@framework/styles/App';

import {
  FormColumnStyled,
  FormDatePickerStyled,
  FormGenderRadioStyled,
  FormGenderStyled,
  FormHintStyled,
  FormInputStyled,
  FormRowStyled,
  FormSelectStyled,
  FormTextareaStyled,
  getMediaByType,
} from '@framework/styles/Form';

import { AppointmentsListItemStyled, AppointmentsListStyled } from '@framework/styles/Appointments';

const AppointmentFormModal = Modal('AppointmentFormModal')(props => {
  const {
    models: { AppointmentsFormModel, AppointmentsModel },
    store: { helperStore, localesStore },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile();

  const { isToggled, isAppointmentsShowed, verifyForm, handleFormSubmit, handleFormClose, handleToggleAppointments } =
      AppointmentsFormModel,
    { Type, Settings, appointmentsByConfirmed } = AppointmentsModel;

  const typeData = useMemo(
    () => [
      {
        value: '',
        label: localesStore.translate('modal.appointment.form.field.type'),
        inactive: true,
      },
      {
        value: Type.Store,
        label: localesStore.translate(`modal.appointment.form.type.${Type.Store}`),
      },
      {
        value: Type.Phone,
        label: localesStore.translate(`modal.appointment.form.type.${Type.Phone}`),
      },
    ],
    []
  );

  return (
    <DialogStyled
      fullScreen={isMobile}
      fullWidth
      maxWidth="lg"
      open={isToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleFormClose}
    >
      {isToggled ? (
        <Fragment>
          <DialogTitleStyled>
            <Box
              component="span"
              sx={
                isMobile
                  ? {
                      display: 'block',
                      fontSize: '21px',
                      textAlign: 'center',
                      width: '100%',
                    }
                  : {}
              }
            >
              {localesStore.translate('modal.appointment.form.label.title')}
            </Box>
            <Box sx={{ cursor: 'pointer' }} onClick={handleFormClose}>
              <Close />
            </Box>
          </DialogTitleStyled>
          <DialogContentStyled grid>
            <Formik
              initialValues={{
                salutation: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                type: Type.Store,
                date: '',
                time: '',
                note: '',
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validate={verifyForm}
              onSubmit={handleFormSubmit}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <Box
                  display="grid"
                  gridTemplateColumns={isMobile ? '100%' : '1fr 320px'}
                  gridTemplateRows={isMobile ? '1fr max-content' : '100%'}
                  columnGap="2%"
                  rowGap={isMobile ? '20px' : '0px'}
                  width="100%"
                  height={isMobile ? 'auto' : '100%'}
                  flexGrow="1"
                >
                  {(!isMobile || (isMobile && !isAppointmentsShowed)) && (
                    <Fade in>
                      <Box display="flex" flexDirection="column">
                        <Box
                          display="flex"
                          flexDirection="column"
                          p="10px"
                          flexGrow={1}
                          backgroundColor={theme.application.palette.whitesmoke}
                        >
                          <FormRowStyled spacing={2}>
                            <FormColumnStyled media={getMediaByType('full')}>
                              <FormGenderStyled spacing={20} error={errors.salutation}>
                                <FormGenderRadioStyled
                                  name="salutation"
                                  value="MRS"
                                  rootValue={values.salutation}
                                  onChange={handleChange}
                                  placeholder={localesStore.translate('placeholder.label.salutation.MRS')}
                                  required
                                />
                                <FormGenderRadioStyled
                                  name="salutation"
                                  value="MR"
                                  rootValue={values.salutation}
                                  onChange={handleChange}
                                  placeholder={localesStore.translate('placeholder.label.salutation.MR')}
                                  required
                                />
                                <FormGenderRadioStyled
                                  name="salutation"
                                  value="MRX"
                                  rootValue={values.salutation}
                                  onChange={handleChange}
                                  placeholder={localesStore.translate('placeholder.label.salutation.MRX')}
                                  required
                                />
                              </FormGenderStyled>
                            </FormColumnStyled>
                          </FormRowStyled>
                          <FormRowStyled spacing={2}>
                            <FormColumnStyled media={getMediaByType('half')}>
                              <FormInputStyled
                                name="firstName"
                                onChange={handleChange}
                                value={values.firstName}
                                placeholder={localesStore.translate('modal.appointment.form.field.firstName')}
                                error={errors.firstName}
                                required
                              />
                            </FormColumnStyled>
                            <FormColumnStyled media={getMediaByType('half')}>
                              <FormInputStyled
                                name="lastName"
                                onChange={handleChange}
                                value={values.lastName}
                                placeholder={localesStore.translate('modal.appointment.form.field.lastName')}
                                error={errors.lastName}
                                required
                              />
                            </FormColumnStyled>
                          </FormRowStyled>
                          <FormRowStyled spacing={2}>
                            <FormColumnStyled media={getMediaByType('half')}>
                              <FormInputStyled
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                                placeholder={localesStore.translate('modal.appointment.form.field.email')}
                                error={errors.email}
                                required
                              />
                            </FormColumnStyled>
                            <FormColumnStyled media={getMediaByType('half')}>
                              <FormInputStyled
                                name="phone"
                                onChange={handleChange}
                                value={values.phone}
                                error={errors.phone}
                                placeholder={localesStore.translate('modal.appointment.form.field.phone')}
                                required
                              />
                            </FormColumnStyled>
                          </FormRowStyled>
                          <FormTextareaStyled
                            name="note"
                            onChange={handleChange}
                            value={values.note}
                            error={errors.note}
                            placeholder={localesStore.translate('modal.appointment.form.field.notes')}
                          />
                          <FormRowStyled spacing={2}>
                            <FormColumnStyled media={getMediaByType(isMobile ? 'full' : 'half')}>
                              <FormDatePickerStyled
                                variant="date"
                                name="date"
                                onChange={handleChange}
                                value={values.date}
                                error={errors.date}
                                placeholder={localesStore.translate('modal.appointment.form.field.day')}
                                settings={{ ...Settings, time: values.time }}
                                required
                              />
                            </FormColumnStyled>
                            {!isMobile && (
                              <FormColumnStyled media={getMediaByType('half')}>
                                <FormDatePickerStyled
                                  variant="time"
                                  name="time"
                                  value={values.time}
                                  error={errors.time}
                                  onChange={handleChange}
                                  placeholder={localesStore.translate('modal.appointment.form.field.time')}
                                  settings={{ ...Settings, date: values.date }}
                                  required
                                />
                              </FormColumnStyled>
                            )}
                          </FormRowStyled>
                          {isMobile && (
                            <FormRowStyled spacing={2}>
                              <FormColumnStyled media={getMediaByType('full')}>
                                <FormDatePickerStyled
                                  variant="time"
                                  name="time"
                                  value={values.time}
                                  error={errors.time}
                                  onChange={handleChange}
                                  placeholder={localesStore.translate('modal.appointment.form.field.time')}
                                  settings={{ ...Settings, date: values.date }}
                                  required
                                />
                              </FormColumnStyled>
                            </FormRowStyled>
                          )}
                        </Box>
                        <FormHintStyled>{localesStore.translate('placeholder.form.required')}</FormHintStyled>
                      </Box>
                    </Fade>
                  )}
                  {(!isMobile || (isMobile && isAppointmentsShowed)) && (
                    <Fade in>
                      <Box
                        p={isMobile ? '0px' : '10px 0px 26px 10px'}
                        display="flex"
                        flexDirection="column"
                        width="100%"
                      >
                        <Box component="h2" fontWeight="normal" mb="10px" fontSize="20px">
                          {localesStore.translate('modal.appointment.form.label.scheduled')}
                        </Box>
                        <Box
                          position="relative"
                          display="flex"
                          flexDirection="column"
                          flexGrow={1}
                          overflow="hidden auto"
                          mb="10px"
                          width="100%"
                        >
                          {Boolean(appointmentsByConfirmed.length) ? (
                            <AppointmentsListStyled>
                              {appointmentsByConfirmed.map(item => (
                                <AppointmentsListItemStyled key={item.objectId}>
                                  <span>{format(+item.appointmentDate, 'dd.MM.yyyy')}</span>
                                  <span>
                                    {format(+item.appointmentDate, 'HH:mm') +
                                      ' ' +
                                      localesStore.translate('placeholder.label.time')}
                                  </span>
                                </AppointmentsListItemStyled>
                              ))}
                            </AppointmentsListStyled>
                          ) : (
                            <Box
                              width={isMobile ? '100%' : '280px'}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              flexGrow={1}
                              color={theme.application.palette.gray}
                            >
                              {localesStore.translate('modal.appointment.form.label.empty')}
                            </Box>
                          )}
                        </Box>
                        {!isMobile && (
                          <Box display="grid" minHeight="50px" width="280px" gridTemplateRows="50px">
                            <ButtonStyled onClick={handleSubmit}>
                              {localesStore.translate('modal.appointment.form.controls.submit')}
                            </ButtonStyled>
                          </Box>
                        )}
                      </Box>
                    </Fade>
                  )}
                  <Box display="grid" minHeight="50px" width="100%" rowGap="10px" gridTemplateRows="50px 50px">
                    <ButtonOutlinedStyled onClick={handleToggleAppointments}>
                      {localesStore.translate(
                        `modal.appointment.form.controls.${isAppointmentsShowed ? 'hide' : 'show'}`
                      )}
                    </ButtonOutlinedStyled>
                    <ButtonStyled onClick={handleSubmit}>
                      {localesStore.translate('modal.appointment.form.controls.submit')}
                    </ButtonStyled>
                  </Box>
                </Box>
              )}
            </Formik>
          </DialogContentStyled>
        </Fragment>
      ) : null}
    </DialogStyled>
  );
});

export default inject('store', 'models')(observer(AppointmentFormModal));
