import swal from 'sweetalert';
import { format } from 'date-fns';
import { useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { FilterAltOutlined, MoreHoriz, Refresh } from '@mui/icons-material';
import { Table, TableHead, TableBody, Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import View from '@framework/decorators/View';

import Orders from '@framework/icons/Orders';
import Reserving from '@framework/icons/Reserving';
import Barcode from '@framework/icons/Barcode';

import {
  BackButtonStyled,
  ToolbarStyled,
  LayoutContainerStyled,
  PageContainerStyled,
  CounterStyled,
  CircleWrapperStyled,
  RectangleWrapperStyled,
  TabsStyled,
  TabStyled,
} from '@framework/styles/App';

import {
  TableRowHeadStyled,
  TableCellHeadStyled,
  TableHeadGapStyled,
  TableBodyGapStyled,
  TableContainerStyled,
  TableRowBodyStyled,
  TableCellBodyStyled,
  TableCardStyled,
} from '@framework/styles/Table';

import { FilterTitleStyled } from '@framework/styles/Filter';

const RequestsView = View('Requests')(props => {
  const {
    models: { RequestsModel, RequestsProcessingModel, BarcodeScannerModel, OrdersModel },
    store: { helperStore, localesStore, networkStore },
  } = props;

  const theme = useTheme(),
    navigate = useNavigate(),
    isMobile = helperStore.isMobile();

  const {
      Tabs,
      Type,
      Filter,
      isFilterToggled,
      isProcessedLoaded,
      currentTab,
      currentFilter,
      filters,
      viewMounted,
      requestsByOpened,
      requestsByProcessed,
      requestsByStatus,
      handleTabCall,
      handleFilterCall,
      handleFilterToggle,
      handleRefreshCall,
    } = RequestsModel,
    { handleRequestToggle } = RequestsProcessingModel;

  useEffect(() => {
    viewMounted();
  }, [currentTab]);

  return (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.baseAnimationDirection)}>
      <LayoutContainerStyled $scrollable>
        <ToolbarStyled>
          <BackButtonStyled
            onClick={e => {
              e.preventDefault();
              helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
              helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
              navigate('/');
            }}
          >
            {localesStore.translate('placeholder.label.back')}
          </BackButtonStyled>
          <h2>{localesStore.translate('page.title.requests')}</h2>
          <Box display="flex" position="absolute" right="0">
            <RectangleWrapperStyled mr="10px" onClick={handleRefreshCall}>
              <Refresh />
            </RectangleWrapperStyled>
            <RectangleWrapperStyled padding={isMobile ? '5px' : '5px 25px'} mr="10px" onClick={handleFilterToggle}>
              <FilterAltOutlined />
              {!isMobile && (
                <Box component="span" ml="5px">
                  {currentFilter === Filter.All
                    ? localesStore.translate('placeholder.filters.all')
                    : filters[currentFilter] + ' ' + localesStore.translate('placeholder.filters.days')}
                </Box>
              )}
            </RectangleWrapperStyled>
            <RectangleWrapperStyled
              onClick={() => {
                BarcodeScannerModel.callBarcodeScannerPromise().then(value => {
                  OrdersModel.fetchOrderByParcelId(value).then(error => {
                    if (error) {
                      networkStore.trackAnalytics('barcode_scan_not_successful', 'Order', value);
                      
                      return swal('', localesStore.translate('modal.requestsProcessing.label.wrongShippingLabel'), 'error');
                    }
                    
                    networkStore.trackAnalytics('barcode_scan_successful', 'Order', value);
                  });
                });
              }}
            >
              <Barcode />
            </RectangleWrapperStyled>
          </Box>
        </ToolbarStyled>
        {isFilterToggled && (
          <Box
            display="flex"
            justifyContent="center"
            border={`2px solid ${theme.application.palette.black}`}
            mb="10px"
            overflow="hidden"
          >
            <Box
              display="grid"
              gridTemplateColumns={isMobile ? 'repeat(5, max-content)' : 'repeat(6, max-content)'}
              columnGap={isMobile ? '20px' : '30px'}
              padding="40px 20px"
              overflow="auto"
            >
              {!isMobile && (
                <FilterTitleStyled $isActive>
                  {localesStore.translate('placeholder.filters.default')}:
                </FilterTitleStyled>
              )}
              <FilterTitleStyled
                $isActive={currentFilter === Filter.All}
                onClick={handleFilterCall.bind(this, Filter.All)}
              >
                {localesStore.translate('placeholder.filters.all')}
              </FilterTitleStyled>
              <FilterTitleStyled
                $isActive={currentFilter === Filter.LastTwoDays}
                onClick={handleFilterCall.bind(this, Filter.LastTwoDays)}
              >
                2 {localesStore.translate('placeholder.filters.days')}
              </FilterTitleStyled>
              <FilterTitleStyled
                $isActive={currentFilter === Filter.LastFiveDays}
                onClick={handleFilterCall.bind(this, Filter.LastFiveDays)}
              >
                5 {localesStore.translate('placeholder.filters.days')}
              </FilterTitleStyled>
              <FilterTitleStyled
                $isActive={currentFilter === Filter.LastTenDays}
                onClick={handleFilterCall.bind(this, Filter.LastTenDays)}
              >
                10 {localesStore.translate('placeholder.filters.days')}
              </FilterTitleStyled>
              <FilterTitleStyled
                $isActive={currentFilter === Filter.LastMonth}
                onClick={handleFilterCall.bind(this, Filter.LastMonth)}
              >
                30 {localesStore.translate('placeholder.filters.days')}
              </FilterTitleStyled>
            </Box>
          </Box>
        )}
        <TableHeadGapStyled />
        <TableContainerStyled>
          {isMobile ? (
            requestsByStatus.map(item => (
              <TableCardStyled key={item.objectId} onClick={() => handleRequestToggle(item)}>
                <Box>{item.type !== Type.ClickAndReserve ? <Orders /> : <Reserving />}</Box>
                <div>
                  <Box
                    display="grid"
                    rowGap="10px"
                    alignItems="center"
                    p="5px 0 15px"
                    borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                  >
                    <Box fontSize={16} fontWeight={600}>
                      {localesStore.translate(`requests.type.${item.type}`)}
                    </Box>
                    <Box fontSize={12}>{item.processId}</Box>
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr"
                    p="15px 0"
                    borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}
                  >
                    <Box fontSize={12}>
                      {localesStore.translate('placeholder.time.at') + ': ' + format(+item.created, 'dd MMM, yyyy')}
                    </Box>
                    <Box fontSize={12}>
                      {localesStore.translate('placeholder.time.at') +
                        ': ' +
                        format(+item.created, 'HH:mm') +
                        ' ' +
                        localesStore.translate('placeholder.label.time')}
                    </Box>
                  </Box>
                  <Box p="15px 0" borderBottom={`1px solid ${theme.application.palette.whitesmoke}`}>
                    <Box fontSize={12} fontWeight={600} mb="5px">
                      {`${item.invoiceAddress.firstName} ${item.invoiceAddress.lastName}`}
                    </Box>
                    <Box fontSize={12} fontWeight={600} mb="5px">
                      {item.invoiceAddress.email}
                    </Box>
                    <Box fontSize={12} fontWeight={600}>
                      {item.invoiceAddress.phone}
                    </Box>
                  </Box>
                  <Box p="15px 0 0">
                    <Box fontSize={14} fontWeight={600}>
                      {localesStore.translate(`requests.status.${item.status?.toLowerCase()}`)}
                    </Box>
                  </Box>
                </div>
                <Box display="flex" flexDirection="column" justifyContent="space-between" pt="5px">
                  <Box textAlign="center">{item.articleItems.length}</Box>
                  <Box>
                    <CircleWrapperStyled>
                      <MoreHoriz />
                    </CircleWrapperStyled>
                  </Box>
                </Box>
              </TableCardStyled>
            ))
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRowHeadStyled>
                  <TableCellHeadStyled>
                    <span>{localesStore.translate('placeholder.table.process')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled align="center">
                    <span>{localesStore.translate('placeholder.table.processId')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled>
                    <span>{localesStore.translate('placeholder.table.date')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled width="25%">
                    <span>{localesStore.translate('placeholder.table.customer')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled>
                    <span>{localesStore.translate('placeholder.table.amount')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled align="center">
                    <span>{localesStore.translate('placeholder.table.status')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled />
                </TableRowHeadStyled>
              </TableHead>
              <TableBody>
                {requestsByStatus.map(item => (
                  <Fragment key={item.objectId}>
                    <TableBodyGapStyled />
                    <TableRowBodyStyled onClick={() => handleRequestToggle(item)}>
                      <TableCellBodyStyled>
                        <Box display="flex" alignItems="center">
                          {item.type !== Type.ClickAndReserve ? <Orders /> : <Reserving />}
                          <Box ml="15px">{localesStore.translate(`requests.type.${item.type}`)}</Box>
                        </Box>
                      </TableCellBodyStyled>
                      <TableCellBodyStyled align="center">{item.processId}</TableCellBodyStyled>
                      <TableCellBodyStyled sx={{ whiteSpace: 'nowrap' }}>
                        <Box>
                          {localesStore.translate('placeholder.time.at') + ': ' + format(+item.created, 'dd MMM, yyyy')}
                        </Box>
                        <Box>
                          {localesStore.translate('placeholder.time.at') +
                            ': ' +
                            format(+item.created, 'HH:mm') +
                            ' ' +
                            localesStore.translate('placeholder.label.time')}
                        </Box>
                      </TableCellBodyStyled>
                      <TableCellBodyStyled $isEllipsed sx={{ wordBreak: 'break-word' }}>
                        <div>{`${item.invoiceAddress.firstName} ${item.invoiceAddress.lastName}`}</div>
                        <div>{item.invoiceAddress.email}</div>
                        <div>{item.invoiceAddress.phone}</div>
                      </TableCellBodyStyled>
                      <TableCellBodyStyled align="center">{item.articleItems.length}</TableCellBodyStyled>
                      <TableCellBodyStyled>
                        {localesStore.translate(`requests.status.${item.status?.toLowerCase()}`)}
                      </TableCellBodyStyled>
                      <TableCellBodyStyled>
                        <CircleWrapperStyled>
                          <MoreHoriz />
                        </CircleWrapperStyled>
                      </TableCellBodyStyled>
                    </TableRowBodyStyled>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainerStyled>
        <TabsStyled $quantity={2} $isMobile={isMobile}>
          <TabStyled $isActive={currentTab === Tabs.Opened} onClick={handleTabCall.bind(this, Tabs.Opened)}>
            <CounterStyled $isActive={currentTab === Tabs.Opened}>{requestsByOpened.length}</CounterStyled>
            <span>{localesStore.translate('requests.controls.open')}</span>
          </TabStyled>
          <TabStyled $isActive={currentTab === Tabs.Processed} onClick={handleTabCall.bind(this, Tabs.Processed)}>
            <CounterStyled $isActive={currentTab === Tabs.Processed}>
              {isProcessedLoaded ? requestsByProcessed.length : '\u21CB'}
            </CounterStyled>
            <span>{localesStore.translate('requests.controls.processed')}</span>
          </TabStyled>
        </TabsStyled>
      </LayoutContainerStyled>
    </PageContainerStyled>
  );
});

export default inject('store', 'models')(observer(RequestsView));
