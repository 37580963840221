import { useCallback, useMemo, useRef, useState } from 'react';

const ZoomImage = ({ src, scale, width, height, transition = 0.1 }) => {
  const imageRef = useRef(null),
    closestRef = useRef(null),
    isTouchDevice = useMemo(() => matchMedia('(hover: none)').matches, [width, height]);

  const [zoomState, setZoomState] = useState({
    zoom: false,
    mouseX: null,
    mouseY: null,
  });

  const handlePointerStart = useCallback(() => {
      setZoomState(prev => ({ ...prev, zoom: true }));
    }, []),
    handlePointerMove = useCallback(
      e => {
        let { pageX, pageY } = e;
        const { current: imageBox } = imageRef;

        if (e.type === 'touchmove') {
          const [touch] = e.touches;
          pageX = touch.pageX;
          pageY = touch.pageY;
        }

        if (!imageBox || !pageX || !pageY) return;

        if (isTouchDevice) {
          const getScrollParent = node => {
            if (node == null) {
              return null;
            }

            if (node.scrollHeight > node.clientHeight) {
              return node;
            } else {
              return getScrollParent(node.parentNode);
            }
          };

          closestRef.current = getScrollParent(imageBox.parentNode);

          closestRef.current.style.overflow = 'hidden';
        }

        const { height, width } = imageBox.style;
        const { left: offsetLeft, top: offsetTop } = imageBox.getBoundingClientRect();

        const mouseX = ((pageX - offsetLeft) / parseInt(width, 10)) * 100;
        const mouseY = ((pageY - offsetTop) / parseInt(height, 10)) * 100;

        setZoomState(prev => ({ ...prev, mouseX, mouseY }));
      },
      [imageRef, isTouchDevice]
    ),
    handlePointerEnd = useCallback(() => {
      setZoomState(prev => ({ ...prev, zoom: false }));
    }, []);

  const outerDivStyle = {
      height: `${height}px`,
      width: `${width}px`,
      overflow: 'hidden',
    },
    innerDivStyle = {
      height: `${height}px`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'auto 100%',
      transition: `transform ${transition}s ease-out`,
      backgroundImage: `url('${src}')`,
    },
    transformStyle = {
      transformOrigin: `${zoomState.mouseX}% ${zoomState.mouseY}%`,
    },
    handleByDevice = isTouchDevice
      ? {
          onMouseOver: handlePointerStart,
          onTouchMove: handlePointerMove,
          onMouseOut: handlePointerEnd,
          onTouchEnd: () => {
            if (closestRef.current) closestRef.current.style.overflow = '';
          },
        }
      : {
          onMouseOver: handlePointerStart,
          onMouseOut: handlePointerEnd,
          onMouseMove: handlePointerMove,
        };

  return (
    <div ref={imageRef} style={outerDivStyle} onContextMenu={e => e.preventDefault()} {...handleByDevice}>
      <div
        style={{
          ...transformStyle,
          ...innerDivStyle,
          transform: zoomState.zoom ? `scale(${scale})` : 'scale(1.0)',
        }}
      />
    </div>
  );
};

export default ZoomImage;
