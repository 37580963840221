export const isPlainArray = item => item && typeof item === 'object' && Array.isArray(item);

export const isPlainObject = item => item && typeof item === 'object' && !Array.isArray(item);

export const isReactComponent = item => typeof item === 'function' && String(item).includes('react_jsx_runtime');

export const isPrototypeClass = (a, b) =>
  typeof b === 'object' && typeof a === 'object' && (a?.prototype instanceof b || b?.prototype instanceof a);

export const deepMerge = (target, ...sources) => {
  if (!sources.length) return target;

  const source = sources.shift();

  if (isPlainObject(target) && isPlainObject(source)) {
    for (const key in source) {
      if (isPlainObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });

        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepMerge(target, ...sources);
};
