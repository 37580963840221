import getSymbolFromCurrency from 'currency-symbol-map';
import { Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { inject, observer } from 'mobx-react';

import Modal from '@framework/decorators/Modal';

import { VouchersDialogStyled, VouchersTitleStyled, VouchersContentStyled } from '@framework/styles/CustomerVouchers';

import {
  DetailsVouchersListStyled,
  DetailsVouchersListItemStyled,
  DetailsVoucherAcceptButtonStyled,
} from '@framework/styles/CustomerDetails';

import { ModalAnimation } from '@framework/styles/App';

const CustomerVouchersModal = Modal('CustomerVouchersModal')(props => {
  const {
    models: { CustomerVouchersModel, CustomerDetailsModel },
    store: { helperStore, localesStore },
  } = props;

  const isMobile = helperStore.isMobile(),
    { isToggled, handleUseVoucher, handleCloseCall } = CustomerVouchersModel;

  return (
    <VouchersDialogStyled
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
      open={isToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleCloseCall}
    >
      <VouchersTitleStyled>
        <h2>{localesStore.translate('modal.vouchers.label.title')}</h2>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            cursor: 'pointer',
            width: '100px',
          }}
          onClick={handleCloseCall}
        >
          <Close />
        </Box>
      </VouchersTitleStyled>
      <VouchersContentStyled>
        <DetailsVouchersListStyled>
          {CustomerDetailsModel.vouchers.map((item, i) => (
            <DetailsVouchersListItemStyled key={i}>
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between">
                <Box>
                  <h3>
                    {item.value} {getSymbolFromCurrency(item.currency)}
                    {localesStore.translate('modal.vouchers.label.voucher') ?? item.title}
                  </h3>
                  <p>{item.description ?? localesStore.translate('modal.vouchers.label.info')}</p>
                  <p>
                    {localesStore.translate('modal.vouchers.label.validTill')}: {item?.formattedFields?.validTill}
                  </p>
                </Box>
                <DetailsVoucherAcceptButtonStyled
                  disabled={item.formattedFields?.disabled}
                  onClick={() => handleUseVoucher(item)}
                >
                  {localesStore.translate('modal.vouchers.controls.use')}
                </DetailsVoucherAcceptButtonStyled>
              </Box>
            </DetailsVouchersListItemStyled>
          ))}
        </DetailsVouchersListStyled>
      </VouchersContentStyled>
    </VouchersDialogStyled>
  );
});

export default inject('models', 'store')(observer(CustomerVouchersModal));
