import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const ArticleScan = memo(({ width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 500.000000 500.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M1070 3625 l0 -195 35 0 35 0 0 160 0 160 1360 0 1360 0 0 -160 0
-160 35 0 35 0 0 195 0 195 -1430 0 -1430 0 0 -195z"
      />
      <path d="M1840 2815 l0 -185 45 0 45 0 0 185 0 185 -45 0 -45 0 0 -185z" />
      <path d="M2000 2815 l0 -185 130 0 130 0 0 185 0 185 -130 0 -130 0 0 -185z" />
      <path d="M2330 2815 l0 -185 45 0 45 0 0 185 0 185 -45 0 -45 0 0 -185z" />
      <path d="M2500 2815 l0 -185 85 0 85 0 0 185 0 185 -85 0 -85 0 0 -185z" />
      <path d="M2740 2815 l0 -185 45 0 45 0 0 185 0 185 -45 0 -45 0 0 -185z" />
      <path d="M2910 2815 l0 -185 45 0 45 0 0 185 0 185 -45 0 -45 0 0 -185z" />
      <path d="M3070 2815 l0 -185 45 0 45 0 0 185 0 185 -45 0 -45 0 0 -185z" />
      <path d="M1070 2500 l0 -30 1430 0 1430 0 0 30 0 30 -1430 0 -1430 0 0 -30z" />
      <path d="M1840 2185 l0 -185 45 0 45 0 0 185 0 185 -45 0 -45 0 0 -185z" />
      <path d="M2000 2185 l0 -185 130 0 130 0 0 185 0 185 -130 0 -130 0 0 -185z" />
      <path d="M2330 2185 l0 -185 45 0 45 0 0 185 0 185 -45 0 -45 0 0 -185z" />
      <path d="M2500 2185 l0 -185 85 0 85 0 0 185 0 185 -85 0 -85 0 0 -185z" />
      <path d="M2740 2185 l0 -185 45 0 45 0 0 185 0 185 -45 0 -45 0 0 -185z" />
      <path d="M2910 2185 l0 -185 45 0 45 0 0 185 0 185 -45 0 -45 0 0 -185z" />
      <path d="M3070 2185 l0 -185 45 0 45 0 0 185 0 185 -45 0 -45 0 0 -185z" />
      <path
        d="M1070 1375 l0 -195 1430 0 1430 0 0 195 0 195 -35 0 -35 0 0 -160 0
-160 -1360 0 -1360 0 0 160 0 160 -35 0 -35 0 0 -195z"
      />
    </g>
  </svg>
));

export default Icon('ArticleScan')(ArticleScan);
