import { UnfoldMore } from '@mui/icons-material';
import { forwardRef, memo, useCallback, useEffect, useState } from 'react';

import Component from '@framework/decorators/Component';

import {
  SelectStyled,
  SelectLabelStyled,
  SelectActiveOptionStyled,
  SelectWrapperStyled,
  SelectOptionsStyled,
  SelectOptionStyled,
} from '@framework/styles/Select';

const SelectComponent = forwardRef(
  Component('SelectComponent')((props, ref) => {
    const {
      className,
      $isSolid,
      $isThickness,
      name,
      data,
      quantity,
      defaultValue,
      onChange,
      noneIndex,
      keyboardIndex,
      useDefaultValue = false,
      useLowerCaseValue = true,
      error,
      disabled,
    } = props;

    const [isToggled, setIsToggled] = useState(false),
      [selectedItem, setSelectedItem] = useState(null),
      [value, setValue] = useState(defaultValue || '');

    useEffect(() => {
      data.forEach(item => {
        item.selected = false;

        if (item.value?.toLowerCase() === value?.toLowerCase()) {
          item.selected = true;

          if (typeof onChange === 'function') onChange(useLowerCaseValue ? value?.toLowerCase() : value);

          setSelectedItem(item);
        }
      });

      if (!data.some(item => item.selected) && noneIndex && data[0]) data[0].selected = true;
    }, [value]);

    useEffect(() => {
      if (useDefaultValue) setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
      if (!useDefaultValue) {
        setValue(defaultValue || '');
      } else {
        data.forEach(item => {
          item.selected = false;

          if (item.value?.toLowerCase() === value?.toLowerCase()) {
            item.selected = true;
            setSelectedItem(item);
          }
        });

        if (!data.some(item => item.selected) && noneIndex && data[0]) data[0].selected = true;
      }
    }, [data]);

    const handleToggle = useCallback(e => {
        e.preventDefault();
        if (!disabled) setIsToggled(true);
      }, []),
      handleBlur = useCallback(e => {
        setIsToggled(false);
      }, []),
      handleOptionCall = useCallback((e, value) => {
        e.stopPropagation();
        setValue(value);
        setIsToggled(false);
      }, []);

    return (
      <SelectStyled
        error={error}
        className={className}
        $isSolid={$isSolid}
        $isThickness={$isThickness}
        $isToggled={isToggled}
        onMouseLeave={() => {
          if (isToggled) {
            const cleanUpEvent = new Event('cleanup', { bubbles: true });
            document.dispatchEvent(cleanUpEvent);
          }
          
            setIsToggled(false);
        }}
      >
        <label htmlFor={name}>
          <select id={name} name={name}>
            {data.map((item, index) => (
              <option key={index} value={item.value} selected={item.selected}>
                {item.label}
              </option>
            ))}
          </select>
          <SelectLabelStyled
            onClick={handleToggle}
            data-toggled={isToggled}
            data-element="select"
            data-keyboard={keyboardIndex}
            ref={node => {
              if (node) {
                node.removeEventListener('unfocused', handleBlur);
                node.addEventListener('unfocused', handleBlur);
              }

              if (typeof ref === 'function') ref(node);
            }}
          >
            <SelectActiveOptionStyled $isActive={!selectedItem?.inactive}>
              {selectedItem?.label}
            </SelectActiveOptionStyled>
            <UnfoldMore />
          </SelectLabelStyled>
          <SelectWrapperStyled $quantity={quantity}>
            <SelectOptionsStyled>
              {data.map((item, index) => (
                <SelectOptionStyled
                  key={index}
                  value={item.value}
                  $isInActive={item?.inactive}
                  onClick={!item?.disabled ? e => handleOptionCall(e, item.value) : null}
                >
                  <span>{item.label}</span>
                </SelectOptionStyled>
              ))}
            </SelectOptionsStyled>
          </SelectWrapperStyled>
        </label>
      </SelectStyled>
    );
  })
);

export default memo(SelectComponent);
