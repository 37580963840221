import QRCode from 'qrcode';
import { action, computed, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class CartModel {
  isQRToggled = false;
  withDataFlag = false;

  cart = [];
  articles = [];

  qrcode = null;

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isQRToggled: observable,
      withDataFlag: observable,
      cart: observable,
      qrcode: observable,
      articles: observable,
      total: computed,
    });
  }

  generateCode = action(async (article = null) => {
    if (article) {
      return await QRCode.toDataURL(`-SINGLE-SCTRSF-${article.gtin}`);
    } else {
      const url = await QRCode.toDataURL(
        '-SCTRSF-' +
          this.cart
            .map(
              ({ gtin, quantity, articleNumber, price, currency }) =>
                `${gtin}:${quantity}:${articleNumber}:${price.toFixed(2).replace('.', ',')}:${currency}`
            )
            .join(';')
      );

      this.qrcode = url;
    }
  });

  get total() {
    return this.cart.reduce((acc, next) => acc + Number(next.quantity), 0);
  }

  cartMounted = action(() => {
    const {
      store: { helperStore },
      ArticleModel,
    } = this.rootModels;

    if (!ArticleModel.isLoading) {
      helperStore.toggleLoader(true);

      this.getArticles().then(data => {
        this.withDataFlag = false;
        this.setArticles(data.filter(item => Boolean(item)));
        helperStore.toggleLoader(false);
      });
    }

    return () => this.setArticles([]);
  });

  setCart = action(cart => {
    this.withDataFlag = true;
    this.cart = cart;
    this.updateStorage();
  });

  setArticles = action(articles => {
    this.articles = articles;
  });

  addToCart = action(article => {
    const alreadyExistArticle = this.cart.find(item => item.gtin === article.gtin);

    if (alreadyExistArticle) {
      this.setArticleQuantity(alreadyExistArticle.gtin, alreadyExistArticle.quantity + 1);
      this.cart = [...this.cart];
    } else {
      this.cart = [...this.cart, article];

      this.updateStorage();
    }
  });

  removeFromCart = action(gtin => {
    this.cart = this.cart.filter(item => item.gtin !== gtin);
    this.articles = [];
    this.updateStorage();
  });

  setArticleQuantity = action((articleGtin, quantity) => {
    try {
      if (!this.cart.length) return;
      const item = this.cart.find(item => item.gtin === articleGtin);
      item.quantity = quantity;
      this.updateStorage();
    } catch (error) {
      const { store } = this.rootModels;
      store.networkStore.log('[CartModel]:[setArticleQuantity]', error);
    }
  });

  getArticles = async () => Promise.all(this.cart.map(async article => await this.getArticle(article)));

  getArticle = async savedArticle => {
    const available = await this.getAvailable(savedArticle);

    if (!available)
      return {
        ...savedArticle,
        stores: null,
        available: 1,
      };

    if (this.withDataFlag) {
      const index = this.cart.findIndex(item => item.gtin === savedArticle.gtin);
      const data = await this.rootModels.ArticleModel.fetchArticleGroupsByGtin(savedArticle.gtin, {}, true);

      if (!data)
        return {
          ...savedArticle,
          stores: available,
          available: Object.values(available).reduce((acc, next) => acc + next, 0),
        };

      const currentArticleGroup = data?.articleGroups.find(group =>
        group.articles.some(article => article.gtin === savedArticle.gtin)
      );
      const currentArticle = currentArticleGroup?.articles.find(article => article.gtin === savedArticle.gtin);

      if (!currentArticleGroup && !currentArticle)
        return {
          ...savedArticle,
          stores: available,
          available: Object.values(available).reduce((acc, next) => acc + next, 0),
        };

      const material = currentArticleGroup?.['categories']?.find(item => item.type === 'material')?.name || '';
      const imageUrl = currentArticleGroup?.['images']?.find(image => image?.type === 'Main')?.url || null;
      const colorFilter = currentArticleGroup?.['categories']?.find(category => category.type === 'color');
      const size = currentArticle?.['categories']?.find(category => category.type === 'size_filter')?.name;

      this.cart[index] = {
        ...this.cart[index],
        articleMpn: currentArticle['mpn'] ?? null,
        color: colorFilter?.name ?? null,
        material: material ?? null,
        colorCode: currentArticleGroup['colorNumber'] ?? colorFilter?.code ?? null,
        colorImageUrl: colorFilter?.imageUrl ?? null,
        created: currentArticle['created'] ?? null,
        descriptionLong: currentArticleGroup['descr'] ?? null,
        descriptionShort: currentArticleGroup['shortDescr'] ?? null,
        imageUrl: imageUrl,
        name: currentArticleGroup['title'] ?? null,
        size: size ?? null,
        objectId: null,
        returnQuantity: 0,
        returnReason: null,
        status: null,
        trackingUrl: null,
        updated: currentArticle['updated'] ?? null,
        additionalProperties: null,
        productURL: null,
      };

      this.updateStorage();
    }

    return {
      ...savedArticle,
      stores: available,
      available: Object.values(available).reduce((acc, next) => acc + next, 0),
    };
  };

  getAvailable = async article => {
    const { ArticleModel, store } = this.rootModels;

    const available = await ArticleModel.fetchAvailableStores(article);

    const defaultValues = {
      online: 0,
      current: 0,
      other: 0,
    };

    const quantity =
      available?.reduce((acc, next) => {
        if (!next.available) return acc;

        if (next['storeId'] === '-1') {
          acc['online'] += +next.available;
        } else if (next['storeId'] === store.storageStore.storeId) {
          acc['current'] += +next.available;
        } else {
          acc['other'] += +next.available;
        }

        return acc;
      }, defaultValues) || defaultValues;

    return quantity;
  };

  handleQrCodeToggle = action(() => {
    this.isQRToggled = !this.isQRToggled;
  });

  updateStorage = () => (this.rootModels.store.storageStore.cart = this.cart);
  getStorage = () => (this.cart = this.rootModels.store.storageStore.cart);

  reset = () => {
    this.cart = [];
    this.updateStorage();
  };
}

export default Model('CartModel')(CartModel);
