import { inject, observer } from 'mobx-react';
import { Box, Dialog, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';

import CardIcon from '@framework/icons/Card';
import CustomerIcon from '@framework/icons/Customer';
import GuestIcon from '@framework/icons/Guest';

import {
  DialogStyled,
  ModalAnimation,
  BackButtonStyled,
  ButtonOutlinedStyled,
  DialogContentStyled,
  DialogTitleStyled,
} from '@framework/styles/App';

import Modal from '@framework/decorators/Modal';
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const ComplaintsCustomerModal = Modal('ComplaintsCustomerModal')(props => {
  const {
    store: { helperStore, localesStore },
    models: { ComplaintsProcessingModel },
  } = props;

  const
    theme = useTheme(),
    navigate = useNavigate(),
    isMobile = helperStore.isMobile();

  const {
    register,
    Customer: { Editor, Guest },
    isCustomerToggled,
    handleCustomerClose,
    chooseCustomerVariant,
  } = ComplaintsProcessingModel;
  
  useEffect(() => {
    register(navigate);
  }, []);

  return isMobile ? (
    <Dialog
      fullScreen
      open={isCustomerToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleCustomerClose}
    >
      <DialogTitleStyled>
        <Box position="relative" display="flex" alignItems="center">
          <Box component="span" fontSize={24}>
            Reklamation erfassen
          </Box>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={handleCustomerClose}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box display="grid" rowGap="20px">
          <ButtonOutlinedStyled $icon onClick={() => chooseCustomerVariant(Editor)}>
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <CardIcon height="70" width="70" />
            </Box>
            Als Kunde
          </ButtonOutlinedStyled>
          <ButtonOutlinedStyled $icon onClick={() => chooseCustomerVariant(Guest)}>
            <Box
              position="absolute"
              top="5px"
              left="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
              width="85px"
              height="85px"
            >
              <GuestIcon height="70" width="70" />
            </Box>
            Als Gast
          </ButtonOutlinedStyled>
        </Box>
      </DialogContentStyled>
    </Dialog>
  ) : (
    <DialogStyled
      fullWidth
      maxWidth="md"
      open={isCustomerToggled}
      TransitionComponent={ModalAnimation}
      keepMounted
      onClose={handleCustomerClose}
    >
      <DialogTitleStyled>
        <Box position="relative" display="flex" alignItems="center">
          <Box component="span">
            Reklamation erfassen
          </Box>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={handleCustomerClose}>
          <Close />
        </Box>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="100%"
          height="100%"
          columnGap="20px"
          flexGrow={1}
        >
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <CardIcon width="150" height="150" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled onClick={() => chooseCustomerVariant(Editor)}>
                Als Kunde
              </ButtonOutlinedStyled>
            </Box>
          </Box>
          <Box display="grid" gridTemplateRows="1fr max-content" height="100%" rowGap="20px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.application.palette.whitesmoke}
            >
              <GuestIcon width="150" height="150" />
            </Box>
            <Box height="60px">
              <ButtonOutlinedStyled onClick={() => chooseCustomerVariant(Guest)}>
                Als Gast
              </ButtonOutlinedStyled>
            </Box>
          </Box>
        </Box>
      </DialogContentStyled>
    </DialogStyled>
  );
});

export default inject('store', 'models')(observer(ComplaintsCustomerModal));
