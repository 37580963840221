import { deepMerge } from './helpers';

const Settings = (RootSettings, target = 'default', debug = false) => {
  let ImportedSettings = null;

  try {
    ImportedSettings = require(`@tenant/settings`)[target];
  } catch (error) {
    if (debug) console.error(error);
  }

  return typeof ImportedSettings === 'object' ? deepMerge(RootSettings, ImportedSettings) : RootSettings;
};

export default Settings;
