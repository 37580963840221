import { addMinutes } from 'date-fns';
import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';
import { v4 } from 'uuid';

export class AuthModel {
  isVerified = false;
  isServerError = false;
  isInitialAuth = false;
  inactivityTimeout = null;
  screensaverTimeout = null;
  isRegistrationRetried = false;
  isMaintenance = false

  navigate = () => {};

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isVerified: observable,
      isServerError: observable,
      isInitialAuth: observable,
      isMaintenance: observable,
      inactivityTimeout: observable,
      screensaverTimeout: observable,
      navigate: observable,
    });
  }

  register = action(navigate => {
    this.navigate = navigate;
  });

  verifySession = action(async () => {
    const { networkStore, storageStore, helperStore } = this.rootModels.store;

    helperStore.toggleLoader(true);

    if (Boolean(storageStore.session)) {
      try {
        const { $id } = await networkStore.get('Authorization', {
          id: storageStore.session,
        });

        if (!$id) throw Error('Session expired or corrupted!');
        
        const { response } = await networkStore.get('MaintenancePageState');
        
        const maintenanceResult = JSON.parse(response);
        
        if(maintenanceResult.data.activated) {
          this.isMaintenance = true;
          this.isVerified = true;
          return helperStore.toggleLoader(false);
        }

        this.isVerified = true;
        helperStore.toggleLoader(false);
        
        storageStore.updateRegistration();

        if (typeof storageStore.expiration === 'number' && storageStore.expiration > Date.now()) {
          const { objectId, id, fullName } = storageStore.merchant;

          if (Boolean(id && objectId && fullName)) {
            if (id === 'MASTERMATE') {
              this.rootModels.store.isMasterMate = true;
            } else {
              this.rootModels.store.isMasterMate = false;
            }

            await this.login();
          }

          if (Boolean(storageStore.order)) this.rootModels.CartProcessingModel.orderData = storageStore.order;
        } else {
          storageStore.reset();

          if (!this.isInitialAuth) {
            this.isInitialAuth = true;
            this.handleEnterKioskMode();
          }
        }
      } catch (error) {
        networkStore.log('[AuthModel]:[verifySession]', error);
        if (this.rootModels.store.debug) console.error(error);

        if (navigator.onLine) {
          storageStore.storeId = '';
          storageStore.session = '';
        }

        this.isVerified = false;
        networkStore.put('Authorization');
        helperStore.toggleLoader(false);
      }
    } else {
      this.isVerified = false;
      networkStore.put('Authorization');
      helperStore.toggleLoader(false);
    }
  });

  login = action(() => {
    const { store } = this.rootModels;

    store.isAuthorized = true;

    store.helperStore.cockpitAnimationDirection = store.helperStore.Direction.Rtl;

    this.updateActivity();
    document.addEventListener('click', this.handleActivityChanges);
    document.addEventListener('visibilitychange', this.handleVisibilityChanges);
  });

  logout = action(() => {
    const { store, RequestsModel, OrdersModel, CartProcessingModel, CartModel } = this.rootModels;

    store.helperStore.toggleInactivity(false);

    store.isAuthorized = false;
    store.storageStore.reset();

    RequestsModel.reset();
    OrdersModel.reset();
    CartProcessingModel.reset();
    CartModel.reset();

    this.closeModalOverlays();

    this.navigate('/');

    if (!store.isKioskMode) this.handleEnterKioskMode();

    document.removeEventListener('click', this.handleActivityChanges);
    document.removeEventListener('visibilitychange', this.handleVisibilityChanges);
  });

  updateActivity = action(() => {
    const {
      store: { storageStore, helperStore, isKioskMode, isAuthorized },
    } = this.rootModels;

    storageStore.expiration = addMinutes(new Date(), 10).getTime();

    if (this.inactivityTimeout) clearTimeout(this.inactivityTimeout);
    if (this.screensaverTimeout) clearTimeout(this.screensaverTimeout);
    
    if (isKioskMode && helperStore.isIpadPro) {
      this.screensaverTimeout = setTimeout(() => {
        helperStore.toggleInactivityVideo(true)
      }, 600000);
    } else if (isAuthorized) {
      this.inactivityTimeout = setTimeout(() => {
        helperStore.toggleInactivity(true);
      }, 600000);
    }
  });

  handleActivityChanges = action(() => {
    this.updateActivity();
  });

  handleVisibilityChanges = action(() => {
    const {
      store: { storageStore, isKioskMode },
    } = this.rootModels;

    if (typeof storageStore.expiration === 'number' && storageStore.expiration < Date.now()) {
      this.logout();
      if (!isKioskMode) this.handleEnterKioskMode();
    }
  });

  handleValidateAuthForm = values => {
    const { localesStore } = this.rootModels.store,
      { email: emailValue, password: passwordValue } = values;

    if (!emailValue || emailValue.trim() === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailValue))
      return {
        email: localesStore.translate('auth.error.email'),
      };

    if (!passwordValue || passwordValue.trim() === '')
      return {
        password: localesStore.translate('auth.error.password'),
      };

    return {};
  };

  handleValidateMerchantForm = values => {
    const { localesStore } = this.rootModels.store,
      { merchant: merchantValue } = values;

    if (!merchantValue || merchantValue.trim() === '')
      return {
        merchant: localesStore.translate('auth.error.merchant'),
      };

    return {};
  };

  handleEnterKioskMode = action(() => {
    const { store } = this.rootModels;
    
    store.isKioskMode = !store.storageStore.isOutlet();
    
    if (store.isKioskMode) this.updateActivity();
  });

  handleLeaveKioskMode = action((isInactivity = false) => {
    const { store, CartModel, CartProcessingModel, OrderModel, OrdersModel } = this.rootModels;

    if (CartModel.cart.length > 0 && !isInactivity) {
      swal({
        title: store.localesStore.translate('modal.kiosk.logout.label.title'),
        text: store.localesStore.translate('modal.kiosk.logout.label.description'),
        buttons: {
          back: store.localesStore.translate('modal.kiosk.logout.controls.save'),
          apply: store.localesStore.translate('modal.kiosk.logout.controls.login'),
        },
      }).then(type => {
        if (type !== 'back' && type !== 'apply') return;

        if (type === 'back') {
          return OrderModel.create(OrdersModel.Status.Parked, () => {
            store.isKioskMode = false;
            CartProcessingModel.reset();
            CartModel.reset();
            this.navigate('/');
          });
        }

        store.isKioskMode = false;
        CartProcessingModel.reset();
        CartModel.reset();
        this.navigate('/');
      });
    } else {
      store.isKioskMode = false;
      CartProcessingModel.reset();
      CartModel.reset();
      this.navigate('/');
    }
  });

  handleSubmitAuthForm = action(async values => {
    const { networkStore, storageStore, helperStore } = this.rootModels.store;

    try {
      this.isServerError = false;
      helperStore.toggleLoader(true);

      const { $id, userId } = await networkStore.post('Authorization', values);

      storageStore.storeId = userId;
      storageStore.session = $id;

      if (networkStore.isNativeThread) networkStore.webview('store', { storeId: userId });
      
      const { response } = await networkStore.get('MaintenancePageState');
      
      const maintenanceResult = JSON.parse(response);
      
      if(maintenanceResult.data.activated) {
        this.isMaintenance = true;
        this.isVerified = true;
        return helperStore.toggleLoader(false);
      }

      this.isVerified = true;

      storageStore.updateRegistration();
    } catch {
      this.isServerError = true;
    } finally {
      helperStore.toggleLoader(false);
    }
  });

  handleSubmitMerchantForm = action(async values => {
    const { merchant } = values,
      { networkStore, storageStore, helperStore } = this.rootModels.store;

    try {
      helperStore.toggleLoader(true);

      const { response, status } = await networkStore.post('merchantAdvancedLogin', {
        storeId: storageStore.storeId,
        login: merchant,
        password: merchant,
        deviceId: storageStore.deviceId,
        deviceCode: storageStore.deviceId,
      });

      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      ) {
        const data = JSON.parse(response)?.data;
        throw data ?? new Error('Network Store: Request "merchantAdvancedLogin" Failed!');
      }

      const {
        data: { objectId, firstName, lastName, login, token },
      } = JSON.parse(response);

      storageStore.merchant = {
        id: login,
        userToken: token,
        objectId: objectId,
        fullName: firstName + ' ' + lastName,
      };

      if (Object.keys(storageStore.storeData).length === 0 || storageStore.storeId !== storageStore.storeData.id) {
        try {
          const { response } = await networkStore.post(
            'LoadObjectsOfClass',
            {
              whereClause: `storeId='${storageStore.storeId}'`,
              offset: 0,
              orderByType: 'ASC',
              pageSize: 100,
              relations: ['addresses'],
              clazz: 'com.innomos.baas.common.model.Store',
            },
            'POST'
          );

          const {
            data: {
              data: [store],
            },
          } = JSON.parse(response);

          this.rootModels.store.storageStore.storeData = {
            availCount: store.availCount ?? 0,
            name: store.name,
            phone: store.phone,
            addresses: store.addresses,
            storeId: store.storeId,
            email: store.email,
            objectId: store.objectId,
          };
        } catch (error) {
          if (this.rootModels.store.debug) console.error(error);
          throw Error('Network Store: Request "LoadObjectsOfClass" failed! ');
        }
      }

      this.rootModels.CartModel.getStorage();

      await this.login();

      if (merchant === 'MASTERMATE') {
        this.rootModels.store.isMasterMate = true;
      } else {
        this.rootModels.store.isMasterMate = false;
      }
    } catch (error) {
      if (this.rootModels.store.debug) console.error(error);

      if(!this.isRegistrationRetried && error?.message?.includes('Device authorisation failed')) {
        await storageStore.updateRegistration();
        this.handleSubmitMerchantForm(values);
        this.isRegistrationRetried = true;
      } else {
        this.isServerError = true;
      }
    } finally {
      helperStore.toggleLoader(false);
    }
  });

  handleChangeStore = action(() => {
    const { networkStore } = this.rootModels.store;
    
    this.logout();
    this.isVerified = false;
    
    networkStore.put('Authorization');
    
    this.rootModels.store.isAuthorized = false;
    this.rootModels.store.isKioskMode = false;
    this.rootModels.store.isMasterMate = false;
    this.rootModels.store.storageStore.clear();
    this.rootModels.store.storageStore.deviceRegistration({
      os: 'WP',
      osVersion: this.rootModels.store.storageStore.version,
      deviceId: v4().toUpperCase(),
      deviceToken: (() => {
        const { userAgent } = navigator;

        let title = '',
          parsedAgents = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(parsedAgents[1])) {
          title = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
          return 'IE ' + (title[1] || '');
        }
        if (parsedAgents[1] === 'Chrome') {
          title = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
          if (title != null) return title.slice(1).join(' ').replace('OPR', 'Opera');
        }
        parsedAgents = parsedAgents[2]
          ? [parsedAgents[1], parsedAgents[2]]
          : [navigator.appName, navigator.appVersion, '-?'];
        if ((title = userAgent.match(/version\/(\d+)/i)) != null) parsedAgents.splice(1, 1, title[1]);
        return parsedAgents.join(' ');
      })(),
    });
  });

  closeModalOverlays = action(() => {
    const {
      store: { helperStore },
      AppointmentsFormModel,
      AppointmentsProcessingModel,
      ArticleModel,
      BarcodeScannerModel,
      CartProcessingModel,
      CustomerProductsModel,
      CustomerSignatureModel,
      CustomerTransactionModel,
      CustomerVouchersModel,
      HeaderModel,
      OrdersProcessingModel,
      RequestsProcessingModel,
    } = this.rootModels;

    if (AppointmentsFormModel.isToggled) AppointmentsFormModel.handleFormClose();
    if (AppointmentsProcessingModel.isToggled) AppointmentsProcessingModel.handleWindowClose();
    if (ArticleModel.isToggled) ArticleModel.handleCloseCall();
    if (BarcodeScannerModel.isToggled) BarcodeScannerModel.handleCloseCall();
    if (helperStore.notification) helperStore.toggleNotification(null);
    if (CustomerProductsModel.isToggled) CustomerProductsModel.handleCloseCall();
    if (CustomerSignatureModel.isToggled) CustomerSignatureModel.handleCloseCall();
    if (CustomerTransactionModel.isToggled) CustomerTransactionModel.handleCloseCall();
    if (CustomerVouchersModel.isToggled) CustomerVouchersModel.handleCloseCall();
    if (HeaderModel.isToggled) HeaderModel.handleMenuBtnCall();
    if (OrdersProcessingModel.isToggled) OrdersProcessingModel.handleCloseCall();
    if (RequestsProcessingModel.isToggled) RequestsProcessingModel.handleModalClose();

    CartProcessingModel.handleWindowsClose();
    helperStore.toggleLoader(false);
  });
}

export default Model('AuthModel')(AuthModel);
