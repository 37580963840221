import swal from 'sweetalert';

import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class CustomerVouchersModel {
  isToggled = false;

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
    });
  }

  handleUseVoucher = action(voucher => {
    const {
      store: { debug, localesStore },
      CustomerDetailsModel,
    } = this.rootModels;

    swal({
      title: localesStore.translate('modal.vouchers.controls.use'),
      text: localesStore.translate('modal.vouchers.label.description'),
      buttons: {
        cancel: localesStore.translate('modal.vouchers.controls.cancel'),
        apply: localesStore.translate('modal.vouchers.controls.apply'),
      },
    }).then(value => {
      if (value !== 'apply') return;
      CustomerDetailsModel.redeemVoucher(voucher).catch(error => debug && console.error(error));
    });
  });

  handleVoucherCall = action(() => {
    this.isToggled = true;
  });

  handleCloseCall = action(() => {
    const {
      store: { debug },
      CustomerDetailsModel,
    } = this.rootModels;

    CustomerDetailsModel.fetchVouchers().catch(error => debug && console.error(error));

    this.reset();
  });

  reset = action(() => {
    this.isToggled = false;
  });
}

export default Model('CustomerVouchersModel')(CustomerVouchersModel);
