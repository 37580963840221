import {v4} from "uuid";

import swal from 'sweetalert';

import {action, makeObservable, observable} from 'mobx';

import Model from '@framework/decorators/Model';

export class ComplaintsProcessing {
  Customer = {
    Guest: '@guest',
    Creation: '@creation',
  };
  
  Destination = {
    ComplaintOnline: 'Lagerware',
    ComplaintCustomer: 'Kundeneigentum',
    ComplaintDone: 'Reklamation bereits anerkannt'
  }
  
  isFormToggled = false;
  isDestinationToggled = false;
  isCustomerToggled = false;
  isSearchComponent = false;
  isPrefilled = false;

  articleGtin = '';
  articleNumber = '';
  articleColor = '';
  articleSize= '';
  
  customer = null;
  customerData = null;
  
  commentary = '';
  boughtDate = '';
  purchaseType = '';
  complaintType = '';
  complaintReason = '';

  objectId = '';
  complaintId = '';
  
  additionalInfo = '';
  
  returnBookedAtCheckout = false;
  
  signatures = {
    merchant: null,
    customer: null,
  }
  
  images = {
    article: null,
    damage: null,
    careLabel: null,
    receipt: null,
  }
  
  navigate = () => {}

  generateItem = action(async (status) => {
    const { storageStore } = this.rootModels.store;
    const images = await Promise.all([].concat(
      ['article', 'careLabel', 'damage', 'receipt'].map(async (key) => {
        if (!this.images[key]) return { image: null, type: key };
        if (this.images[key].includes('http')) return { image: this.images[key], type: key};
        
        const { url } = await this.createComplaintImage({
          objectId: v4(),
          image: this.images[key]
        });

        return  { image: url, type: key };
      }),
      ['customer', 'merchant'].map(async (key) => {
        if (!this.signatures[key]) return { image: null, type: key };
        if (this.signatures[key].includes('http')) return { image: this.images[key], type: key};
        
        const { url } = await this.createComplaintImage({
          objectId: v4(),
          image: this.signatures[key]
        });

        return  { image: url, type: key + 'Signature' };
      })
    ));

    return {
      status: status,
      merchantId: storageStore.merchant.id,
      complaintReason: this.complaintReason,
      complaintType: this.complaintType,
      purchaseType: this.purchaseType,
      ordNr: this.additionalInfo,
      comment: this.commentary,
      customerAddress: {
        addressType: "MainAddress",
        salutation: this.customerData?.salutation,
        customerId: this.customerData?.customerId,
        birthday: Date.parse(this.customerData?.birthday),
        addressLine1: this.customerData?.addressLine1,
        addressLine2: this.customerData?.addressLine2,
        street: this.customerData?.street,
        streetNumber: this.customerData?.streetNumber,
        city: this.customerData?.city,
        email: this.customerData?.email,
        postalCode: this.customerData?.postalCode,
        firstName: this.customerData?.firstName,
        lastName: this.customerData?.lastName,
        phone: this.customerData?.phone?.replace(/\s/g, ''),
        country: this.customerData?.country?.toUpperCase(),
      },
      boughtDate: Date.parse(this.boughtDate),
      articleNumber: this.articleNumber,
      articleSize: this.articleSize,
      articleGtin: this.articleGtin,
      articleColor: this.articleColor,
      returnBookedAtCheckout: this.returnBookedAtCheckout,
      images: images,
      sourceStoreId: storageStore.storeId
    };
  });

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isFormToggled: observable,
      isDestinationToggled: observable,
      isCustomerToggled: observable,
      isSearchComponent: observable,
      customer: observable,
      customerData: observable,
      navigate: observable,
      boughtDate: observable,
      complaintReason: observable,
      signatures: observable,
      images: observable,
      commentary: observable,
      purchaseType: observable,
      articleGtin: observable,
      articleColor: observable,
      articleNumber: observable,
      additionalInfo: observable,
      isPrefilled: observable,
      articleSize: observable,
      returnBookedAtCheckout: observable,
    });
  }
  
  register = (navigate) => {
    this.navigate = navigate;
  }
  
  toggleDestinationWindow = action((value) => {
    this.isDestinationToggled = value;
  })

  toggleCustomerWindow = action((value) => {
    this.isCustomerToggled = value;
  });

  toggleFormWindow = action(value => {
    this.isFormToggled = value;
  });

  chooseCustomerVariant = action(variant => {
    const { Editor, Guest } = this.Customer,
      {
        store: { helperStore },
        FormModel,
        SearchModel
      } = this.rootModels;
    
    this.customer = variant;

    if (variant === Editor) {
      SearchModel.isComplaints = true;
      this.isSearchComponent = true;
    } else if (variant === Guest) {
      FormModel.setFormSettings(variant, {
        complaints: true,
        include: ['firstName', 'lastName', 'email', 'addressLine2'],
        exclude: ['isEmailContactAllowed', 'signature', 'salutation', 'city', 'postalCode', 'street', 'streetNumber', 'userAgreementAllowed'],
        onResolve: (customerId, data) => {
          this.saveCustomerData(customerId, data).then(() => {
            this.handleWindowsClose();
            helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
            this.navigate('/complaints/create');
          });
        },
      });
    }

    this.toggleFormWindow(true);
  });

  handleParkedCall = (item) => {
    this.prefillItem(item);
    this.navigate('/complaints/edit');
  }

  prefillItem = (item) => {
    this.objectId = item.objectId;
    this.complaintId = item.complaintId;
    this.setComplaintReason(item.complaintReason);
    this.complaintType = item.complaintType;
    this.setPurchaseType(item.purchaseType); 
    this.ordNr = item.ordNr;
    this.setCommentary(item.comment);
    if (item.boughtDate) {
      this.setBoughtDate(new Date(parseInt(item.boughtDate)).toISOString().slice(0, 10));
    }
    
    this.setArticleNumber(item.articleNumber);
    this.setArticleSize(item.articleSize);
    this.setArticleGtin(item.articleGtin);
    this.setArticleColor(item.articleColor);
    this.setReturnBookedAtCheckout(item.returnBookedAtCheckout);
    this.customerData = {
      "salutation": item.salutation, 
      "customerId": item.customerAddress?.customerId ?? '-',
      "addressLine1": item.customerAddress?.addressLine1,
      "addressLine2": item.customerAddress?.addressLine2,
      "street": item.customerAddress?.street,
      "city": item.customerAddress?.city,
      "email": item.customerAddress?.email,
      "postalCode": item.customerAddress?.postalCode,
      "firstName": item.customerAddress?.firstName,
      "lastName": item.customerAddress?.lastName,
      "phone": item.customerAddress?.phone,
      "country": item.customerAddress?.country
    };

    this.isPrefilled = true;

    const sImages = item.images;
    const customerSignature = (sImages ?? []).find(({ type }) => type === 'customerSignature');
    const merchantSignature = (sImages ?? []).find(({ type }) => type === 'merchantSignature');

    this.setSignature('merchant', merchantSignature?.image);
    this.setSignature('customer', customerSignature?.image);

    ['article', 'careLabel', 'damage', 'receipt'].forEach(async (key) => {
      const aImage = (sImages ?? []).find(({ type }) => type === key);
      this.setImage(key, aImage?.image);
    });
  }
  
  setSignature = action((type, data) => {
    this.signatures[type] = data;
  })
  
  setImage = action((type, data) => {
    this.images[type] = data;
  })

  saveCustomerData = action((customerId, formData) => {
    const { Customer: { Editor, Guest } } = this;
    
    switch (this.customer) {
      case Editor: {
        return new Promise(resolve => {
          this.customerData = {
              ...formData,
              customerId: customerId,
              country: formData.country.toUpperCase(),
          };
          
          resolve();
        });
      }
      case Guest: {
        return new Promise(resolve => {
          this.customerData = formData;
          resolve();
        });
      }
    }
  });
  
  setComplaintType = action((complaintType) => {
    const { helperStore } = this.rootModels.store;
    
    this.complaintType = complaintType;
    
    if (complaintType === this.Destination.ComplaintOnline) {
      this.handleWindowsClose();
      helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
      this.navigate('/complaints/create');
    } else {
      this.toggleDestinationWindow(false);
      this.toggleCustomerWindow(true);
    }
  })
  
  setAdditionalInfo = action((info) => {
    this.additionalInfo = info;
  })
  
  setPurchaseType = action((purchaseType) => {
    this.purchaseType = purchaseType;
  })

  setReturnBookedAtCheckout = action(() => {
    this.returnBookedAtCheckout = !this.returnBookedAtCheckout;
  })
  
  setCommentary = action((value) => {
    this.commentary = value;
  })
  
  setArticleGtin = action((gtin) => {
    if (gtin.length === 0 || /^[0-9]+$/.test(gtin)) {
      this.articleGtin = gtin;
    }
  })
  
  setArticleColor = action((articleColor) => {
    if (articleColor.length === 0 || /^[0-9]+$/.test(articleColor)) {
      this.articleColor = articleColor;
    }
  })
  
  setArticleSize = action((size) => {
    this.articleSize = size;
  })
  
  setArticleNumber = action((articleNumber) => {
    if (articleNumber.length === 0 || /^[0-9]+$/.test(articleNumber)) {
      this.articleNumber = articleNumber;
    }
  })
  
  setBoughtDate = action((date) => {
    this.boughtDate = date;
  })
  
  setIsPrefilled = action((date) => {
    this.isPrefilled = date;
  })

  setComplaintReason = action((text) => {
    this.complaintReason = text;
  })
  
  verifyComplaint = action(() => {
    const { ComplaintOnline, ComplaintDone } = this.Destination;
    const isCustomerDataNeeded = ComplaintOnline !== this.complaintType;
    const isReceiptNeeded = ComplaintDone === this.complaintType;
    
    return (
        this.commentary &&
        this.signatures.merchant &&
        this.images.article &&
        this.images.careLabel &&
        this.images.damage &&
        this.articleNumber.length > 0 &&
        this.articleSize.length > 0 &&
        this.articleColor.length > 0 &&
        this.complaintReason &&
        (isCustomerDataNeeded ? this.purchaseType : true) &&
        (isCustomerDataNeeded ? this.boughtDate.length === 0 || this.boughtDate === '--' || /^\d{4}-\d{2}-\d{2}$/.test(this.boughtDate) : true) &&
        (isCustomerDataNeeded ? this.signatures.customer : true) &&
        (isCustomerDataNeeded ? this.customerData : true) &&
        (isReceiptNeeded ? (
          this.returnBookedAtCheckout &&
          this.images.receipt
        ) : true)
    );
  })
    
  createComplaintImage = action(async ({ image, objectId }) => {
    const {
      store: { networkStore, helperStore },
    } = this.rootModels;

    const blob = helperStore.convertBase64toBlob(image);
    
    if (!blob) throw new Error('Invalid Image Format!');
    
    const file = new File([blob], objectId + '.jpg', { type: 'image/jpeg' });
    
    const { $id } = await networkStore.post('CreateComplaintImage', { id: objectId, file });
    
    const url = await networkStore.get('GetComplaintImage', { id: objectId, file });

    return { id: $id, url: url };
  });

  verifyParking = action(() => {
    const { ComplaintOnline, ComplaintDone } = this.Destination;
    const isCustomerDataNeeded = ComplaintOnline !== this.complaintType;
    const isReceiptNeeded = ComplaintDone === this.complaintType;

    return (
      this.articleNumber.length > 0 &&
      this.articleSize.length > 0 &&
      this.articleColor.length > 0 &&
      (isCustomerDataNeeded ? this.signatures.customer : true) &&
      (isCustomerDataNeeded ? this.customerData : true)
    );
  })

  deleteComplaint = action(async () => {
    const { rootModels } = this,
      {
        store: { helperStore, localesStore, networkStore }
      } = rootModels, { ComplaintsModel } = rootModels;

    swal({
      title: localesStore.translate('modal.complaint.delete.label.title'),
      text: localesStore.translate('modal.complaint.delete.label.description'),
      buttons: {
        back: localesStore.translate('modal.confirm.delete.controls.decline'),
        apply: localesStore.translate('modal.confirm.delete.controls.apply'),
      },
    }).then(async (value) => {
      if (value !== 'apply') return;

      helperStore.toggleLoader(true);
      
      const { status, response } = await networkStore.post('deleteComplaint', this.objectId);
      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      ) throw new Error('Network Store: Request "DeleteComplaint" Failed!');

      ComplaintsModel.fetchComplaints().catch(error => debug && console.error(error));
      helperStore.toggleLoader(false);
      this.navigate('/complaints/parked');
    });
  });

  createComplaint = action(async (shouldPark) => {
    const { networkStore, helperStore } = this.rootModels.store;
    const { ComplaintsModel } = this.rootModels;
    
    try {
      helperStore.toggleLoader(true);
      const generatedItem = await this.generateItem(shouldPark ? "PARKED" : "PENDING")
      const { status, response } = await networkStore.post('CreateComplaint', generatedItem);
      
      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      ) throw new Error('Network Store: Request "CreateComplaint" Failed!');
      
      ComplaintsModel.fetchComplaints().catch(error => debug && console.error(error));

      swal({
          icon: 'success',
          content: {
            element: 'img',
            attributes: {
              src: 'images/ig-logotype-dark.png',
              style: 'height: 70px; margin-top: 20px;',
            },
          },
      }).then(() => {
          this.reset();
          this.navigate('/');
      })
    } catch (error) {
      console.error(error)
      networkStore.log('[ComplaintsProcessingModel]:[createComplaint]', error);
      swal('', 'Bei der Erstellung der Reklamation ist etwas schief gelaufen!', 'error');
    } finally {
      helperStore.toggleLoader(false);
    }
  })

  updateComplaint = action(async () => {
    const { networkStore, helperStore } = this.rootModels.store;
    const { ComplaintsModel } = this.rootModels;
    
    try {
      helperStore.toggleLoader(true);
      
      const updatedComplaint = await this.generateItem("PARKED");
      updatedComplaint.complaintId = this.complaintId;
      const request = {
        'objectId': this.objectId,
        'complaint': updatedComplaint
      };
      const { status, response } = await networkStore.post('UpdateComplaint', request);
      
      if (
        status !== 'completed' ||
        (response.includes('message') && response.includes('code') && +JSON.parse(response)?.data?.code === 0)
      ) throw new Error('Network Store: Request "UpdateComplaint" Failed!');
      
      ComplaintsModel.fetchComplaints().catch(error => debug && console.error(error));

      swal({
          icon: 'success',
          content: {
            element: 'img',
            attributes: {
              src: 'images/ig-logotype-dark.png',
              style: 'height: 70px; margin-top: 20px;',
            },
          },
      }).then(() => {
          this.reset();
          this.navigate('/');
      })
    } catch (error) {
      console.error(error)
      networkStore.log('[ComplaintsProcessingModel]:[createComplaint]', error);
      swal('', 'Bei der Erstellung der Reklamation ist etwas schief gelaufen!', 'error');
    } finally {
      helperStore.toggleLoader(false);
    }
  })

  handleCustomerClose = action(() => {
    this.reset();
  });
  
  handleDestinationClose = action(() => {
    this.reset();
  });

  handleFormClose = action(() => {
    this.reset();
  });

  handleWindowsClose = action(() => {
    this.isSearchComponent = false;
    this.isFormToggled = false;
    this.isCustomerToggled = false;
    this.isDestinationToggled = false;
  });
  
  clear = action(() => {
    this.articleGtin = '';
    this.articleNumber = '';
    this.articleColor = '';
    this.articleSize= '';
  
    this.boughtDate = '';
    this.purchaseType = '';
    this.commentary = '';
    this.additionalInfo = '';
    this.complaintType = '';
    this.complaintReason = '';
    
    this.returnBookedAtCheckout = false;
    
    this.signatures = {
      merchant: null,
      customer: null,
    }
    
    this.images = {
      article: null,
      damage: null,
      careLabel: null,
      receipt: null,
    }
  });

  reset = action(() => {
    const { FormModel } = this.rootModels;

    this.isFormToggled = false;
    this.isCustomerToggled = false;
    this.isSearchComponent = false;
    this.isDestinationToggled = false;
    
    this.customer = null;
    this.customerData = null;
    this.isPrefilled = false;
    
    this.clear();

    FormModel.reset();
  });
}


export default Model('ComplaintsProcessing')(ComplaintsProcessing);
