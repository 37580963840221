import { action, makeObservable, observable } from 'mobx';

import Model from '@framework/decorators/Model';

export class CustomerSignature {
  isToggled = false;
  isEmpty = true;

  canvasRef = {
    current: null,
  };

  data = null;

  resolve = () => {};
  reject = () => {};

  constructor(rootModels) {
    this.rootModels = rootModels;

    makeObservable(this, {
      isToggled: observable,
      isEmpty: observable,
      data: observable,
    });
  }

  signatureModalCall = action(data => {
    this.isToggled = true;
    this.data = data;

    return new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  });

  setIsEmpty = action(value => {
    this.isEmpty = value;
  });

  setCanvasNode = action(node => {
    if (node !== null && this.data) {
      node.fromDataURL(this.data);
      this.setIsEmpty(false);
    }

    this.canvasRef.current = node;
  });

  handleCloseCall = action(() => {
    this.isToggled = false;
    this.reject('closed');
    this.handleClearClick();
  });

  handleClearClick = action(() => {
    const { canvasRef } = this;
    if (canvasRef.current) canvasRef.current.clear();
    this.setIsEmpty(true);
  });

  handleConfirmClick = action(() => {
    const { canvasRef, handleCloseCall } = this;

    if (canvasRef.current) {
      const signature = {
        url: canvasRef.current.toDataURL(),
        blob: null,
      };

      canvasRef.current.getCanvas().toBlob(blob => {
        signature.blob = blob;
        this.resolve(signature);
        handleCloseCall();
      }, 'image/png');
    }
  });
}

export default Model('CustomerSignature')(CustomerSignature);
