import InfiniteScroll from 'react-infinite-scroll-component';
import getSymbolFromCurrency from 'currency-symbol-map';

import { Box, IconButton, useTheme, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  ArrowDownward,
  ArrowUpward,
  Close,
  FilterAltOutlined,
  GridViewSharp,
  MoreVert,
  Search,
  SettingsOutlined,
  SquareSharp,
  WarningAmber,
} from '@mui/icons-material';

import View from '@framework/decorators/View';

import Barcode from '@framework/icons/Barcode';
import Loader from '@framework/icons/Loader';

import SidebarComponent from '@framework/components/Sidebar';

import {
  PageContainerStyled,
  LayoutContainerStyled,
  ToolbarStyled,
  BackButtonStyled,
  RectangleWrapperStyled,
  InputStyled,
  ButtonStyled,
} from '@framework/styles/App';

import { IconsBlockStyled, ProductStyled, CounterStyled } from '@framework/styles/Catalog';

const CatalogView = View('Catalog')(props => {
  const {
    models: { CatalogModel, ArticleModel },
    store: { isKioskMode, helperStore, localesStore },
  } = props;

  const theme = useTheme(),
    navigate = useNavigate(),
    isMobile = helperStore.isMobile();

  const {
      isFiltersToggled,
      isIconsToggled,
      isSearchbarToggled,
      isToolbarToggled,
      isGridView,
      isWithDelay,
      Order,
      ordering,
      searchTerm,
      articles,
      totalArticles,
      categoryRootId,
      viewMounted,
      categoryHierarchyLoaded,
      fetchNextPage,
      selectedFiltersQuantity,
      handleSearchbarCall,
      handleFiltersCall,
      handleToolbarCall,
      handleOrderingChange,
      handleGridViewChange,
      handleSearchbarChange,
      handleSearchbarAction,
      handleSearchbarClear,
      handleIconsToggle,
      handleScannerCall,
    } = CatalogModel,
    { fetchArticleGroups, toggleWindow } = ArticleModel;

  useEffect(() => viewMounted(navigate), []);
  useEffect(() => categoryHierarchyLoaded(), [categoryRootId]);

  return (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.baseAnimationDirection)}>
      <LayoutContainerStyled $scrollable>
        <ToolbarStyled padding={isMobile && '20px 0'}>
          {!isMobile ? (
            <Fragment>
              {!isKioskMode ? (
                <BackButtonStyled
                  onClick={e => {
                    e.preventDefault();
                    helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
                    navigate('/');
                  }}
                >
                  {localesStore.translate('placeholder.label.back')}
                </BackButtonStyled>
              ) : (
                <div />
              )}
              {!isSearchbarToggled && (
                <Box
                  component="h2"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  {localesStore.translate('cockpit.label.catalog')}
                </Box>
              )}
              <IconsBlockStyled $isSearchbarToggled={isSearchbarToggled}>
                {isSearchbarToggled ? (
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr max-content',
                      columnGap: '20px',
                    }}
                  >
                    <InputStyled
                      inputRef={ref => ref?.focus()}
                      value={searchTerm}
                      onChange={handleSearchbarChange}
                      onKeyPress={handleSearchbarAction}
                      endAdornment={
                        searchTerm.length > 0 && (
                          <IconButton onClick={handleSearchbarClear}>
                            <Close />
                          </IconButton>
                        )
                      }
                    />
                    <ButtonStyled $isActive onClick={handleSearchbarCall}>
                      {localesStore.translate('placeholder.controls.cancel')}
                    </ButtonStyled>
                  </Box>
                ) : (
                  <RectangleWrapperStyled onClick={handleSearchbarCall}>
                    <Search />
                  </RectangleWrapperStyled>
                )}
                <RectangleWrapperStyled onClick={handleScannerCall}>
                  <Barcode />
                </RectangleWrapperStyled>
                <RectangleWrapperStyled $isActive={isToolbarToggled} onClick={handleToolbarCall}>
                  <SettingsOutlined />
                </RectangleWrapperStyled>
              </IconsBlockStyled>
            </Fragment>
          ) : (
            <Fragment>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateRows: '40px 50px',
                  width: '100%',
                  rowGap: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: isSearchbarToggled ? 'max-content 1fr' : '1fr 1fr 1fr',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  {!isKioskMode ? (
                    <BackButtonStyled
                      $relative
                      onClick={e => {
                        e.preventDefault();
                        helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
                        navigate('/');
                      }}
                    >
                      {localesStore.translate('placeholder.label.back')}
                    </BackButtonStyled>
                  ) : (
                    <div />
                  )}
                  <Box>
                    {!isIconsToggled && !isSearchbarToggled && (
                      <Fade in={!isIconsToggled}>
                        <Box component="h2" padding="0 !important">
                          {localesStore.translate('cockpit.label.catalog')}
                        </Box>
                      </Fade>
                    )}
                    {(isIconsToggled || isSearchbarToggled) && (
                      <Fade in={isIconsToggled || isSearchbarToggled}>
                        <IconsBlockStyled
                          sx={{
                            gridTemplateColumns: isSearchbarToggled && '1fr max-content',
                            paddingLeft: isSearchbarToggled && '10px',
                            justifyContent: 'center',
                          }}
                        >
                          {isSearchbarToggled ? (
                            <Fragment>
                              <InputStyled
                                inputRef={ref => ref?.focus()}
                                value={searchTerm}
                                onChange={handleSearchbarChange}
                                onKeyPress={handleSearchbarAction}
                                endAdornment={
                                  searchTerm.length > 0 && (
                                    <IconButton onClick={handleSearchbarClear}>
                                      <Close />
                                    </IconButton>
                                  )
                                }
                              />
                              <ButtonStyled $isActive onClick={handleSearchbarCall}>
                                {localesStore.translate('placeholder.controls.cancel')}
                              </ButtonStyled>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <RectangleWrapperStyled onClick={handleSearchbarCall}>
                                <Search />
                              </RectangleWrapperStyled>
                              <RectangleWrapperStyled onClick={handleScannerCall}>
                                <Barcode />
                              </RectangleWrapperStyled>
                              <RectangleWrapperStyled $isActive={isToolbarToggled} onClick={handleToolbarCall}>
                                <SettingsOutlined />
                              </RectangleWrapperStyled>
                            </Fragment>
                          )}
                        </IconsBlockStyled>
                      </Fade>
                    )}
                  </Box>
                  {!isSearchbarToggled && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        justifySelf: 'end',
                      }}
                      onClick={handleIconsToggle}
                    >
                      {isIconsToggled ? <Close /> : <MoreVert />}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'max-content',
                    columnGap: '15px',
                  }}
                >
                  <Box
                    onClick={handleFiltersCall}
                    sx={{
                      position: 'relative',
                      width: '50px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: selectedFiltersQuantity ? 'flex-end' : 'center',
                      border: `2px solid ${theme.application.palette.whitesmoke}`,
                    }}
                  >
                    <Box lineHeight={0} mb={Boolean(selectedFiltersQuantity) ? '7px' : '0px'}>
                      <FilterAltOutlined />
                    </Box>

                    {Boolean(selectedFiltersQuantity) && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                        }}
                      >
                        <CounterStyled>{selectedFiltersQuantity}</CounterStyled>
                      </Box>
                    )}
                    {isFiltersToggled && (
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: '-24px',
                          right: '-2px',
                          width: '50px',
                          height: '24px',
                          background: theme.application.palette.white,
                          borderLeft: `2px solid ${theme.application.palette.whitesmoke}`,
                          borderRight: `2px solid ${theme.application.palette.whitesmoke}`,
                          zIndex: '10',
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Fragment>
          )}
        </ToolbarStyled>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: !isMobile ? '266px 1fr' : '100%',
            columnGap: '20px',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {((isMobile && isFiltersToggled) || !isMobile) && <SidebarComponent />}
          {((isMobile && !isFiltersToggled) || !isMobile) && (
            <Box
              sx={{
                position: 'relative',
                display: isMobile && !articles.length ? 'block' : 'grid',
                gridTemplateRows: isToolbarToggled ? 'max-content 1fr' : '1fr',
                rowGap: isMobile ? '0px' : '20px',
                overflow: 'auto',
              }}
            >
              {isToolbarToggled && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    border: isMobile ? 'unset' : '2px solid',
                    padding: isMobile ? '10px' : '25px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '& > *': {
                        marginRight: isMobile ? '15px' : '30px',
                      },
                    }}
                  >
                    <Box fontSize={isMobile ? '14px' : '16px'}>
                      {`${localesStore.translate('catalog.label.sort')}:`}
                    </Box>
                    <Box
                      onClick={handleOrderingChange.bind(null, Order.New)}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '5px',
                        fontSize: isMobile ? '14px' : '16px',
                        fontWeight: ordering === Order.New ? 'bold' : 'normal',
                      }}
                    >
                      <span>{localesStore.translate('placeholder.controls.new')}</span>
                    </Box>
                    <Box
                      onClick={handleOrderingChange.bind(null, Order.Asc)}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '5px',
                        fontSize: isMobile ? '14px' : '16px',
                        fontWeight: ordering === Order.Asc ? 'bold' : 'normal',
                      }}
                    >
                      <span>{localesStore.translate('placeholder.controls.ascending')}</span>
                      <ArrowUpward fontSize={isMobile ? 'small' : 'medium'} />
                    </Box>
                    <Box
                      onClick={handleOrderingChange.bind(null, Order.Desc)}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: isMobile ? '14px' : '16px',
                        fontWeight: ordering === Order.Desc ? 'bold' : 'normal',
                        '& > *': { marginRight: '5px' },
                      }}
                    >
                      <span>{localesStore.translate('placeholder.controls.descending')}</span>
                      <ArrowDownward fontSize={isMobile ? 'small' : 'medium'} />
                    </Box>
                  </Box>

                  {!isMobile && (
                    <Box display="flex" alignItems="center" sx={{ '& > *': { marginRight: '10px' } }}>
                      <Box
                        onClick={handleGridViewChange}
                        sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          padding: '5px',
                          border: `1px solid ${theme.application.palette.darkgray}`,
                          background: !isGridView
                            ? theme.application.palette.darkgray
                            : theme.application.palette.white,
                        }}
                      >
                        <SquareSharp />
                      </Box>
                      <Box
                        onClick={handleGridViewChange}
                        sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          padding: '5px',
                          border: `1px solid ${theme.application.palette.darkgray}`,
                          background: isGridView ? theme.application.palette.darkgray : theme.application.palette.white,
                        }}
                      >
                        <GridViewSharp />
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {isMobile && (
                <Box
                  sx={{
                    height: '15px',
                    marginTop: isToolbarToggled ? '10px' : '0px',
                    background: theme.application.palette.whitesmoke,
                  }}
                />
              )}
              <Box
                id="scrollableDiv"
                sx={{
                  border: isMobile ? 'unset' : '2px solid',
                  padding: isMobile ? '0px 15px 15px' : '25px',
                  overflow: isMobile && !articles.length ? 'hidden' : 'auto',
                  height: isMobile && !articles.length ? 'calc(100% - 15px)' : 'auto',
                  background: theme.application.palette.whitesmoke,
                }}
              >
                {totalArticles > 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      bottom: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '30px',
                      borderRadius: '15px',
                      padding: '0 10px',
                      transform: 'translateX(-50%)',
                      fontSize: '14px',
                      background: theme.application.palette.black,
                      color: theme.application.palette.white,
                      opacity: '0.5',
                      zIndex: '10',
                    }}
                  >
                    {`${totalArticles} ${localesStore.translate(
                      `placeholder.label.${totalArticles !== 0 ? 'result' : 'results'}`
                    )}`}
                  </Box>
                )}
                {Boolean(articles.length) ? (
                  <InfiniteScroll
                    scrollableTarget="scrollableDiv"
                    dataLength={articles.length}
                    next={fetchNextPage}
                    hasMore={!(articles.length >= totalArticles)}
                    inverse={false}
                    loader={<Loader $relative />}
                    scrollThreshold="200px"
                  >
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: isGridView ? 'repeat(auto-fill, minmax(300px, 1fr))' : '100%',
                        gridTemplateRows: 'max-content',
                        columnGap: '25px',
                        rowGap: '25px',
                      }}
                    >
                      {articles.map(
                        ({
                          title,
                          shortDescr: description,
                          availableCurrentPrice,
                          availableGeneralPrice,
                          images,
                          objectId,
                          product,
                          additionalProperties,
                        }) => {
                          const [currentPrice] = availableCurrentPrice,
                            [generalPrice] = availableGeneralPrice;
                          const { price: minPrice, currency: minCurrency } = currentPrice || {
                              price: 0,
                              currency: '',
                            },
                            { price: maxPrice, currency: maxCurrency } = generalPrice || {
                              price: 0,
                              currency: '',
                            },
                            { thumbnailUrl } = images[0] || {
                              url: null,
                              thumbnailUrl: null,
                            };

                          const isGreenChoice = description.includes('MADE IN GREEN'),
                            currentCurrency =
                              minCurrency || maxCurrency ? getSymbolFromCurrency(minCurrency ?? maxCurrency) : '',
                            promotion = additionalProperties?.promotion;

                          return (
                            <ProductStyled
                              key={objectId}
                              isGridView={isGridView}
                              thumbnailUrl={thumbnailUrl}
                              onClick={() => {
                                fetchArticleGroups(product.objectId, objectId).then(() => {
                                  toggleWindow();
                                });
                              }}
                            >
                              {Boolean(promotion) && (
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '30px',
                                    fontSize: isMobile ? '14px' : '18px',
                                    paddingBottom: '3px',
                                    borderBottom: `1px solid ${
                                      promotion === 'sale'
                                        ? theme.application.palette.danger
                                        : theme.application.palette.black
                                    }`,
                                    color:
                                      promotion === 'sale'
                                        ? theme.application.palette.danger
                                        : theme.application.palette.black,
                                  }}
                                >
                                  {localesStore.translate('catalog.label.promotion.' + promotion)}
                                </Box>
                              )}

                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '100%',
                                  marginTop: '15px',
                                  rowGap: '5px',
                                  flexGrow: '1',
                                }}
                              >
                                {isGreenChoice ? (
                                  <Box
                                    component="img"
                                    src="images/ig-green-choice.png"
                                    sx={{
                                      margin: '5px 20px',
                                      alignSelf: 'flex-start',
                                      height: isMobile ? '18px' : '24px',
                                    }}
                                  />
                                ) : (
                                  <Box height="34px" />
                                )}
                                <Box
                                  component="span"
                                  sx={{
                                    margin: '5px 20px auto',
                                    fontSize: isMobile ? '14px' : '18px',
                                  }}
                                >
                                  {title}
                                </Box>
                                <Box
                                  component="span"
                                  sx={{
                                    display: 'flex',
                                    fontWeight: 'bold',
                                    margin: '5px 20px',
                                    fontSize: isMobile ? '14px' : '18px',
                                  }}
                                >
                                  {`${
                                    minPrice
                                      ? minPrice.toFixed(2).replace('.', ',')
                                      : localesStore.translate('placeholder.label.price')
                                  } ${currentCurrency}`}
                                  {maxPrice > 0 && (
                                    <Box
                                      sx={{
                                        marginLeft: '10px',
                                        textDecoration: 'line-through',
                                        color: theme.application.palette.darkgray,
                                      }}
                                    >
                                      {`${maxPrice.toFixed(2).replace('.', ',')} ${currentCurrency}`}
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </ProductStyled>
                          );
                        }
                      )}
                    </Box>
                  </InfiniteScroll>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      maxWidth: '300px',
                      margin: '0 auto',
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <WarningAmber sx={{ fontSize: '60px' }} />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '0 30px',
                          justifyContent: 'center',
                          textAlign: 'center',
                          fontSize: isMobile ? '14px' : '18px',
                        }}
                      >
                        {localesStore.translate('catalog.label.results')}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </LayoutContainerStyled>
    </PageContainerStyled>
  );
});

export default inject('store', 'models')(observer(CatalogView));
