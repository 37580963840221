import { css, styled } from '@mui/material';

import Styled from '@framework/decorators/Styled';

export const SelectLabelStyled = Styled(
  'Select',
  'SelectLabelStyled'
)(styled('div')`
  cursor: pointer;
  width: 100%;
  height: inherit;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 30px 0 10px;
  font-size: 16px;
  font-weight: 400;
  margin-right: 5px;
  border: 1px solid transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &.focused {
    outline: 2px solid rgba(0, 0, 0, 0.3);
  }

  ${({ theme: { application } }) => css`
    background-color: ${application.palette.white};
  `}
`);

export const SelectActiveOptionStyled = Styled(
  'Select',
  'SelectActiveOptionStyled'
)(styled('div')`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme: { application }, $isActive }) => css`
    color: ${$isActive ? application.palette.black : '#B0B0B0'};
  `}
`);

export const SelectWrapperStyled = Styled(
  'Select',
  'SelectWrapperStyled'
)(styled('div')`
  position: absolute;
  display: none;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;

  ${({ theme: { application }, $quantity }) => css`
    max-height: ${$quantity ? $quantity * 40 : 300}px;
    border: 1px solid ${application.palette.white};
  `}
`);

export const SelectOptionsStyled = Styled(
  'Select',
  'SelectOptionsStyled'
)(styled('ul')`
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
`);

export const SelectOptionStyled = Styled(
  'Select',
  'SelectOptionsStyled'
)(styled('li')`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 16px;
  padding: 0 30px 0 10px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  & span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${({ theme: { application }, $isInActive }) => css`
    background-color: ${application.palette.white};

    &:hover {
      background-color: #ebebeb;
    }

    ${$isInActive &&
    css`
      color: #b0b0b0 !important;
    `}
  `}
`);

export const SelectStyled = Styled(
  'Select',
  'SelectStyled'
)(styled('div')`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;

  & label {
    width: 100%;
    height: 100%;
  }

  & svg {
    position: absolute;
    right: 5px;
    width: 25px;
    height: 100%;
  }

  & select {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 0;
  }

  ${({ theme: { application }, error }) =>
    error &&
    css`
      & ${SelectLabelStyled} {
        border-color: ${application.palette.danger};
      }
    `}

  ${({ theme: { application }, $isToggled, error }) =>
    $isToggled &&
    css`
      & ${SelectLabelStyled} {
        border-color: ${error ? application.palette.danger : '#ebebeb'};
        border-bottom: none !important;
      }

      & ${SelectOptionStyled} {
        color: ${application.palette.black};

        &.focused {
          background-color: #ebebeb;
        }
      }

      & ${SelectWrapperStyled} {
        display: block;
        border-top: none;
        border-color: ${error ? application.palette.danger : '#ebebeb'};
      }
    `}

  ${({ theme: { application }, $isThickness }) =>
    $isThickness &&
    css`
      & ${SelectLabelStyled}, & ${SelectWrapperStyled} {
        border: 1px solid ${application.palette.black};
      }

      & ${SelectWrapperStyled} {
        border-top: 1px solid transparent;
      }
    `}

  ${({ theme: { application }, $isSolid }) =>
    $isSolid &&
    css`
      & ${SelectLabelStyled}, & ${SelectWrapperStyled} {
        border: 2px solid ${application.palette.black};
      }

      & ${SelectWrapperStyled} {
        border-top: 2px solid transparent;
      }
    `}
`);
