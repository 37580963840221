import { makeObservable, observable } from 'mobx';

import Store from '@framework/decorators/Store';

import LocalesStore from '@framework/stores/LocalesStore';
import StorageStore from '@framework/stores/StorageStore';
import HelperStore from '@framework/stores/HelperStore';
import NetworkStore from '@framework/stores/NetworkStore';

export class RootStore {
  isAuthorized = false;
  isKioskMode = false;
  isMasterMate = false;
  debug = process.env.DEVELOPMENT;
  applicationId = process.env.APPLICATION_ID;
  secretKey = process.env.SECRET_KEY;
  googleKey = process.env.GOOGLE_KEY;

  localesStore = new LocalesStore(this);
  networkStore = new NetworkStore(this);
  storageStore = new StorageStore(this);
  helperStore = new HelperStore(this);

  modules = [];
  stores = [];

  constructor(settings) {
    this.modules = settings.modules;

    makeObservable(this, {
      isAuthorized: observable,
      isMasterMate: observable,
      isKioskMode: observable,
      stores: observable
    });
  }
}

export default Store('RootStore')(RootStore);
