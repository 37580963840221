import { css } from '@mui/material';
import { normalize } from 'styled-normalize';

import Styled from '@framework/decorators/Styled';
import RootTheme from '@framework/styles/RootTheme';

const RootStyles = Styled('RootStyles')(css`
  ${normalize}
  *, *::before, *::after {
    overscroll-behavior: none;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    width: 100vw;
    height: 100vh;
    font-family: Roboto, sans-serif;
    overflow-x: hidden;
  }

  button {
    cursor: pointer;
    display: inline-block;
    box-shadow: none;
    border: none;
    font-size: 16px;
    background-color: transparent;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
  }

  img {
    user-select: none;
  }

  .swal {
    &-overlay {
      z-index: 100001000 !important;
    }

    &-modal {
      position: relative;
      border-radius: 0;
    }

    &-title {
      font-size: 24px;
      text-align: center;
      font-weight: 400;
      color ${RootTheme.application.palette.black};
    }

    &-text {
      text-align: center;
      padding: 0 30px;
      font-weight: 400;
      color ${RootTheme.application.palette.black};

      @media ${RootTheme.application.breakpoints.xs}, ${RootTheme.application.breakpoints.sm} {
        padding: 0 15px;
      }
    }

    &-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    &-button {
      font-family: 'Helvetica', 'Arial', sans-serif;
      border-color: transparent !important;
      outline-color: transparent !important;
      border-radius: 0;
      border: 2px solid transparent;
      color: ${RootTheme.application.palette.white};
      background-color: ${RootTheme.application.palette.black} !important;
      padding: 10px 25px;
      font-weight: 400;
      font-size: 15px;

      &-container {
        flex-grow: 1;
      }

      &--cancel, &--apply, &--back {
        width: 100%;
        padding: 15px 25px;

        @media ${RootTheme.application.breakpoints.xs}, ${RootTheme.application.breakpoints.sm} {
          padding: 10px;
        }
      }

      &--crossexit {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 5px;
        right: 5px;
        position: absolute;
        font-family: 'Helvetica', 'Arial', sans-serif;
        border-color: transparent !important;
        outline-color: transparent !important;
        border-radius: 0px;
        border: 2px solid transparent;
        background-color: transparent;
        width: 30px;
        height: 30px;

        &::after {
          position: absolute;
          content: " ";
          height: 2px;
          width: 16px;
          background-color: ${RootTheme.application.palette.black};
          transform: rotate(45deg);
        }

        &::before {
          position: absolute;
          content: " ";
          height: 2px;
          width: 16px;
          background-color: ${RootTheme.application.palette.black};
          transform: rotate(-45deg);
        }
      }

      &--cancel, &--back {
        border-color: ${RootTheme.application.palette.black} !important;
        background-color: transparent !important;
        color: ${RootTheme.application.palette.black};
      }

      & + button {
        margin-left: 20px;
      }

      &:active, &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }
`);

export default RootStyles;
