import { memo } from 'react';

import Icon from '@framework/decorators/Icon';

const Customer = memo(({ width, height }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    width={width ?? '148'}
    height={height ?? '148'}
    viewBox="0 0 258 294"
  >
    <g transform="translate(0.000000,294.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M12 2928 c-17 -17 -18 -2898 0 -2916 17 -17 2539 -17 2556 0 9 9 12
        270 12 1113 l0 1101 -351 357 -350 357 -928 0 c-712 0 -930 -3 -939 -12z
        m2166 -391 l342 -342 -2 -1065 -3 -1065 -1225 0 -1225 0 -3 1395 c-1 767 0
        1401 3 1408 3 9 190 12 887 12 l883 0 343 -343z"
      />
      <path
        d="M1090 2325 c-95 -27 -162 -85 -189 -164 -6 -19 -11 -82 -11 -140 -1
        -73 -5 -114 -15 -131 -9 -16 -14 -55 -15 -111 0 -71 3 -91 20 -111 10 -14 29
        -52 41 -84 12 -32 35 -80 51 -105 35 -58 39 -142 9 -181 -11 -13 -80 -57 -155
        -97 -223 -121 -241 -149 -241 -361 l0 -145 440 -5 440 -5 60 -30 c53 -26 71
        -30 145 -30 76 0 93 4 152 33 77 38 130 92 167 171 22 47 26 70 26 141 0 71
        -4 94 -26 141 -37 79 -90 133 -167 171 -59 30 -76 33 -152 33 -67 0 -95 -5
        -135 -23 l-50 -23 -22 26 c-35 40 -32 123 5 184 16 25 39 73 51 105 12 32 31
        70 41 84 17 20 20 40 20 111 -1 56 -6 95 -15 111 -10 17 -14 58 -15 129 0 57
        -6 122 -12 145 -17 56 -90 128 -153 150 -70 25 -224 30 -295 11z m285 -59 c50
        -21 93 -61 111 -103 9 -21 14 -73 14 -150 1 -88 4 -123 15 -137 22 -29 21
        -169 -2 -191 -9 -9 -26 -45 -38 -80 -13 -35 -37 -84 -54 -110 -27 -40 -31 -54
        -31 -114 0 -56 4 -75 25 -105 l26 -38 -32 -36 c-101 -114 -115 -296 -34 -416
        14 -20 25 -39 25 -41 0 -3 -173 -5 -385 -5 l-385 0 0 112 c0 178 11 192 240
        317 63 35 123 70 134 80 30 26 46 75 46 140 0 51 -5 67 -31 106 -17 26 -41 75
        -54 110 -12 35 -29 71 -38 80 -23 22 -24 162 -2 191 11 14 14 49 15 136 0 64
        5 129 11 145 21 57 81 104 156 123 58 14 221 6 268 -14z m325 -1191 l0 -85 85
        0 85 0 0 -30 0 -30 -85 0 -85 0 0 -85 0 -85 -30 0 -30 0 0 85 0 85 -85 0 -85
        0 0 30 0 30 85 0 85 0 0 85 0 85 30 0 30 0 0 -85z"
      />
    </g>
  </svg>
));

export default Icon('Customer')(Customer);
