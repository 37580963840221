import { observer, inject } from 'mobx-react';
import { Box, Collapse, useTheme } from '@mui/material';
import { useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import i18nIsoCountries from "i18n-iso-countries";

import View from '@framework/decorators/View';
import SelectComponent from '@framework/components/Select';

import { FormDigitalSignatureStyled, FormCheckboxStyled, FormTextareaStyled, FormBirthdayStyled } from "@framework/styles/Form";
import { PageContainerStyled, LayoutContainerStyled, ToolbarStyled, BackButtonStyled, OutlinedInputStyled, ButtonStyled } from '@framework/styles/App';
import { Cancel, DeleteOutline } from "@mui/icons-material";

let isInitial = true;

const CameraIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" id="svg8" version="1.1" viewBox="0 0 24 24" width="24">
    <g transform="translate(0,-290.65)">
      <path
        d="m 12,297.65039 c -2.74958,0 -5,2.25042 -5,5 0,2.74958 2.25042,5 5,5 2.74958,0 5,-2.25042 5,-5 0,-2.74958 -2.25042,-5 -5,-5 z m 0,2 c 1.6687,0 3,1.3313 3,3 0,1.6687 -1.3313,3 -3,3 -1.6687,0 -3,-1.3313 -3,-3 0,-1.6687 1.3313,-3 3,-3 z" />
      <path
        d="m 6,292.65 c -2.1987,0 -4,1.8013 -4,4 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 c 0,-1.1253 0.8747,-2 2,-2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z"
      />
      <path
        d="m 3,305.65 a 1,1 0 0 0 -1,1 v 2 c 0,2.1987 1.8013,4 4,4 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 H 6 c -1.1253,0 -2,-0.8747 -2,-2 v -2 a 1,1 0 0 0 -1,-1 z" />
      <path
        d="m 21,305.65 a 1,1 0 0 0 -1,1 v 2 c 0,1.1253 -0.8747,2 -2,2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 c 2.1987,0 4,-1.8013 4,-4 v -2 a 1,1 0 0 0 -1,-1 z" />
      <path
        d="m 16,292.65 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 c 1.1253,0 2,0.8747 2,2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 c 0,-2.1987 -1.8013,-4 -4,-4 z"
      />
    </g>
  </svg>
)

const ComplaintsProcessingView = View('ComplaintsProcessing')(props => {
  const {
    store: {localesStore, helperStore },
    models: {ComplaintsProcessingModel, CustomerSignatureModel, CameraModel},
  } = props;
  
  const theme = useTheme();
  const isMobile = helperStore.isMobile();
  const navigate = useNavigate();
  
  const {
    register,
    signatures,
    setSignature,
    commentary,
    boughtDate,
    images,
    setImage,
    purchaseType,
    additionalInfo,
    setAdditionalInfo,
    setPurchaseType,
    articleSize,
    articleColor,
    articleNumber,
    articleGtin,
    setArticleSize,
    setArticleNumber,
    setArticleGtin,
    setArticleColor,
    complaintReason,
    customerData,
    createComplaint,
    deleteComplaint,
    updateComplaint,
    complaintType,
    setBoughtDate,
    setCommentary,
    setComplaintReason,
    verifyComplaint,
    verifyParking,
    isPrefilled,
    Destination,
    reset,
    returnBookedAtCheckout,
    setReturnBookedAtCheckout
  } = ComplaintsProcessingModel;
  
  useEffect(() => {
    register(navigate);
    
    return () => {
      if (isInitial) {
        isInitial = false;
      } else {
        reset();
        isInitial = true;
      }
    }
  }, []);
  
  useEffect(() => {
    if (!complaintType) navigate('/');
  }, [complaintType]);
  
  const translations =
    i18nIsoCountries.getNames(localesStore.currentLocale, {select: 'official'});
  
  return (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.baseAnimationDirection)}>
      <LayoutContainerStyled $minHeight>
        <ToolbarStyled $centered>
          <BackButtonStyled
            onClick={e => {
              e.preventDefault();
              
              helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
              helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
              
              navigate(isPrefilled ? '/complaints/parked' : '/');

              setTimeout(() => {
                ComplaintsProcessingModel.reset();
             }, 500);
            }}
          >
            {localesStore.translate('placeholder.label.back')}
          </BackButtonStyled>
          <h2>{!isPrefilled ? "Reklamation erfassen" : "Reklamationen bearbeiten" }</h2>
          {isPrefilled ? 
                <Box
                  sx={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={deleteComplaint}
                  color={theme.application.palette.danger}
                >
                  <DeleteOutline />
                </Box> : ''}
        </ToolbarStyled>
        <Box
          sx={{
            gap: '20px',
            display: 'grid',
            padding: '20px 0',
            gridTemplateColumns: isMobile ? '100%' : '2fr minmax(320px, 1fr)',
          }}
        >
          <Box>
            {
              complaintType !== Destination.ComplaintOnline ? (
                <Box
                  sx={{
                    padding: '20px',
                    background: 'rgb(243, 243, 243)',
                  }}
                >
                  <Box
                    sx={{
                      gap: '15px',
                      display: 'flex',
                      fontSize: '18px',
                      flexDirection: 'column',
                      '& div': {
                        overflow: 'hidden',
                        wordWrap: 'nowrap',
                        textOverflow: 'ellipsis'
                      }
                    }}
                  >
                    <div><strong>Kunden-Nummer:</strong> {customerData?.customerId ?? '-'}</div>
                    <Box sx={{display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', gap: isMobile ? '15px' : '20px'}}>
                      <div><strong>Vorname:</strong> {customerData?.firstName ?? '-'}</div>
                      <div><strong>Nachname:</strong> {customerData?.lastName ?? '-'}</div>
                    </Box>
                    <Box sx={{display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', gap:  isMobile ? '15px' : '20px'}}>
                      <div><strong>Mobil:</strong> {customerData?.addressLine2 ?? '-'}</div>
                      <div><strong>E-mail:</strong> {customerData?.email ?? '-'}</div>
                    </Box>
                    <Box sx={{display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', gap:  isMobile ? '15px' : '20px'}}>
                      <div><strong>Strasse:</strong> {customerData?.street || customerData?.streetNumber ? [
                        customerData?.street || '',
                        customerData?.streetNumber || ''
                      ].join(' ') : '-'}</div>
                      <Box sx={{display: 'grid', gridTemplateColumns: '3fr 2fr', gap: '10px'}}>
                        <div><strong>Land:</strong> {customerData?.country ? translations[customerData?.country.toUpperCase()] : '-'}</div>
                        <div><strong>PLZ:</strong> {customerData?.postalCode ?? '-'}</div>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      gap: '20px',
                      display: isMobile ? 'flex' : 'grid',
                      marginTop: '20px',
                      alignItems: 'center',
                      flexDirection: 'column-reverse',
                      gridTemplateColumns: '1fr 1fr',
                    }}
                  >
                    <Box sx={{opacity: '0.5', fontSize: '14px', lineHeight: '1.5'}}>
                      Ich bin damit einverstanden, dass OLYMP Stores KG die von mir angegebenen Daten zur Abwicklung des
                      Reklamationsmanagements erhebt, speichert, verarbeitet und nutzt. Meine Daten werden darüberhinaus nicht
                      an Dritte weitergegeben.
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <FormDigitalSignatureStyled
                        value={signatures.customer}
                        placeholder="Unterschrift des Kunden *"
                        onClick={() => {
                            CustomerSignatureModel.signatureModalCall(null)
                              .then(({ url }) => {
                                setSignature('customer', url);
                              })
                              .catch(error => debug && console.error(error));
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ) : null
            }
            <Box
              sx={{
                padding: '20px',
                marginTop: complaintType !== Destination.ComplaintOnline ? '20px' : '0px',
                minHeight: complaintType !== Destination.ComplaintOnline ? 'unset' : '420px',
                background: theme.application.palette.whitesmoke
              }}
            >
              <Box sx={{ fontSize: '18px', marginBottom: '15px' }}><strong>Details zum Artikel:</strong></Box>
              
              <Box sx={{ display: 'grid', gridTemplateColumns: isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr', gridAutoRows: '50px', gap: '20px', marginBottom: '20px', '& *': { borderColor: 'rgba(0, 0, 0, .5) !important', fontSize: '16px !important' } }}>
                <Box sx={{ background: 'white', '& div': { height: '100%', width: '100%' }  }}>
                  <OutlinedInputStyled
                    placeholder={'Artikel *'}
                    value={articleNumber}
                    onChange={({ target }) => setArticleNumber(target.value)}
                  />
                </Box>
                <Box sx={{ background: 'white', '& div': { height: '100%', width: '100%' }  }}>
                  <OutlinedInputStyled
                    placeholder={'Farbe *'}
                    value={articleColor}
                    onChange={({ target }) => setArticleColor(target.value)}
                  />
                </Box>
                <Box sx={{ background: 'white', '& div': { height: '100%', width: '100%' }  }}>
                  <OutlinedInputStyled
                    placeholder={'Größe *'}
                    value={articleSize}
                    onChange={({ target }) => setArticleSize(target.value)}
                  />
                </Box>
                 <Box sx={{ background: 'white', '& div': { height: '100%', width: '100%' }  }}>
                  <OutlinedInputStyled
                    placeholder={'EAN'}
                    value={articleGtin}
                    onChange={({ target }) => setArticleGtin(target.value)}
                  />
                </Box>
              </Box>
              
              <Box sx={{ display: 'grid', gridTemplateColumns: isMobile ? '1fr' : (complaintType !== Destination.ComplaintOnline ? '1fr 300px' : '1fr'), gap: '20px', '& *': { borderColor: 'rgba(0, 0, 0, .5) !important' } }}>
                <Box sx={{ height: '50px', marginTop: '3px' }}>
                  <SelectComponent
                    $isThickness
                    quantity={4}
                    useLowerCaseValue={false}
                    defaultValue={complaintReason}
                    onChange={setComplaintReason}
                    data={[
                      { value: '', label: 'Reklamationsgrund *', inactive: true },
                      { value: 'Beschädigung Material', label: 'Beschädigung Material' },
                      { value: 'Scheuerung', label: 'Scheuerung' },
                      { value: 'Zutat defekt', label: 'Zutat defekt' },
                      { value: 'Verarbeitung', label: 'Verarbeitung' },
                      { value: 'Farbveränderung', label: 'Farbveränderung' },
                      { value: 'Fleck', label: 'Fleck' },
                      { value: 'Loch', label: 'Loch' },
                      { value: 'Maße', label: 'Maße' },
                    ]}
                  />
                </Box>
                {
                  complaintType !== Destination.ComplaintOnline ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', '& p': { fontSize: '16px !important' } }}>
                      <FormBirthdayStyled
                        withBorders
                        limitYears={3}
                        label='Kaufdatum'
                        placeholder='JJJJ-MM-TT'
                        value={boughtDate}
                        onChange={({ target }) => setBoughtDate(target.value)}
                      />
                    </Box>
                  ) : null
                }
              </Box>
              <Box sx={{ marginTop: '20px', height: '150px', '& > div': { height: '100%', margin: '0' }, border: '1px solid rgba(0, 0, 0, .5)' }}>
                <FormTextareaStyled
                  value={commentary}
                  placeholder={'Kommentar *'}
                  onChange={({ target }) => setCommentary(target.value)}
                />
              </Box>
              
              <Box
                sx={{
                  gap: '20px',
                  display: 'grid',
                  marginTop: '20px',
                  gridTemplateColumns: isMobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)',
                }}
              >
                {
                  ['article', 'damage', 'careLabel', 'receipt'].map((id) => (
                    <Box onClick={() => {
                      CameraModel.cameraPromise().then((value) => {
                        setImage(id, value)
                      })
                    }} sx={{ cursor: 'pointer', position: 'relative', width: '100%', paddingTop: '100%', border: '1px solid rgba(0, 0, 0, .5)' }}>
                      {
                        images[id] ? (
                          <>
                            <Cancel onClick={(e) => {
                              e.stopPropagation();
                              setImage(id, null);
                            }} sx={{ position: 'absolute', zIndex: '10', top: '0', right: '0', transform: 'translate(50%, -50%)', background: 'white', borderRadius: '50%' }} />
                            <Box component="img" src={images[id]} alt='' sx={{ position: 'absolute', top: '0', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}/>
                          </>
                        ) : (
                          <Box sx={{ position: 'absolute', left: '0', top: '0', right: '0', bottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '20px', background: 'white' }}>
                            <Box sx={{ width: '30px', height: '30px', opacity: '0.4', '& svg':{ width: '100%', height: '100%' } }}>
                              <CameraIcon />
                            </Box>
                            <Box sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, .5)', whiteSpace: 'pre', textAlign: 'center', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              {localesStore.translate(`placeholder.image.${id}`) + ` ${['careLabel', 'article', 'damage'].includes(id) || complaintType === Destination.ComplaintDone && id === 'receipt' ? ' *' : '' ? '*' : ''}`}
                            </Box>
                          </Box>
                        )
                      }
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
          
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ padding: '20px', background: theme.application.palette.whitesmoke }}>
                <Box sx={{ fontSize: '18px', marginBottom: '15px' }}><strong>Details zur Reklamation:</strong></Box>
                <Box sx={{ marginBottom: '20px' }}>
                  Kaufebene: {complaintType}
                </Box>
                {
                  complaintType !== Destination.ComplaintOnline ? (
                    <Box sx={{ height: '50px', marginBottom: '20px' }}>
                    <SelectComponent
                      quantity={4}
                      useLowerCaseValue={false}
                      defaultValue={purchaseType}
                      onChange={setPurchaseType}
                      data={[
                        { value: '', label: 'Warenebene *', inactive: true },
                        { value: 'OLYMP Store', label: 'OLYMP Store' },
                        { value: 'OLYMP online', label: 'OLYMP online' },
                        { value: 'Fachhandel', label: 'Fachhandel' },
                      ]}
                    />
                  </Box>
                  ) : null
                }
                <Collapse in={purchaseType === 'OLYMP online' || purchaseType === 'Fachhandel'}>
                  <Box sx={{ background: 'white', height: '50px', '& div': { height: '100%', width: '100%' }, '& input': { padding: '16.5px 10px' }, '& fieldset': { border: 'none !important' } }}>
                    <OutlinedInputStyled
                      placeholder={'Store Name / ORD-Nummer'}
                      value={additionalInfo}
                      onChange={({ target }) => setAdditionalInfo(target.value)}
                    />
                  </Box>
                </Collapse>
                {
                  complaintType !== Destination.ComplaintOnline ? (
                    <Box sx={{ marginTop: '20px', '& *' :{ whiteSpace: 'pre-line !important', fontSize: '15px !important' } }}>
                      <FormCheckboxStyled
                        name='returnBookedAtCheckout'
                        value={returnBookedAtCheckout}
                        disabled={complaintType === Destination.ComplaintCustomer}
                        placeholder={'Retoure an Kasse gebucht' + (complaintType === Destination.ComplaintDone ? ' *' : '')}
                        onChange={setReturnBookedAtCheckout}
                      />
                    </Box>
                  ) : null
                }
                <Box sx={{ marginTop: '20px' }}>
                  <FormDigitalSignatureStyled
                    value={signatures.merchant}
                    placeholder="Unterschrift des Mitarbeiters *"
                    onClick={() => {
                      CustomerSignatureModel.signatureModalCall(null)
                        .then(({ url }) => {
                          setSignature('merchant', url);
                        })
                        .catch(error => debug && console.error(error));
                    }}
                  />
                </Box>
              </Box>

            <Box sx={{ display: 'grid', gridTemplateRows: '80px', marginTop: '20px' }}>
              <Box sx={{ marginTop: '20px', height: "50px" }}>
                <ButtonStyled disabled={!verifyParking()} $fullWidth onClick={() => {isPrefilled ? updateComplaint() : createComplaint(true)}}>
                  Entwurf speichern
                </ButtonStyled>
              </Box>
              <Box sx={{ height: "50px" }}>
                <ButtonStyled disabled={!verifyComplaint()} $fullWidth onClick={() => {verifyComplaint() ? createComplaint(false) : () => {}}}>
                  Reklamation senden
                </ButtonStyled>
              </Box>
            </Box>
          </Box>
        </Box>
      </LayoutContainerStyled>
    </PageContainerStyled>
  );
});

export default inject('store', 'models')(observer(ComplaintsProcessingView));
