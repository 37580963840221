import swal from 'sweetalert';

import { Box } from '@mui/material';
import { Fragment, useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { Add, ExitToAppOutlined, Search } from '@mui/icons-material';

import View from '@framework/decorators/View';

import Barcode from '@framework/icons/Barcode';

import { PageContainerStyled, RectangleWrapperStyled } from '@framework/styles/App';

import {
  CockpitToolbarStyled,
  CockpitToolbarBoxStyled,
  CockpitGridStyled,
  CockpitModuleStyled,
  CockpitEmployerStyled,
  CockpitModuleTitleStyled,
  CockpitModuleTileGroupStyled,
  CockpitModuleTileStyled,
  CockpitModuleTileLoaderStyled,
  CockpitProcessesListStyled,
  CockpitProcessesListItemStyled,
  CockpitRequestsStyled,
  CockpitModuleButtonStyled,
  CockpitSearchStyled,
} from '@framework/styles/Cockpit';
import { useNavigate } from 'react-router-dom';

const CockpitCustomerSection = View(
  'Cockpit',
  'CockpitCustomerSection'
)(
  observer(props => {
    const {
      store: { localesStore, AppointmentsModel = {}, AppointmentsFormModel = {}, modules },
    } = props;

    const activeModules = useMemo(
      () => modules.filter(module => ['appointments', 'customer'].includes(module)),
      [modules]
    );

    const { Tabs, handleTabCall, appointmentsByOpened, appointmentsByConfirmed } = AppointmentsModel,
      { handleFormCall } = AppointmentsFormModel;

    const appointmentsByToday = useMemo(() => {
      const todayDate = new Date().setHours(23, 59, 59, 0);
      return appointmentsByConfirmed?.filter(appointment => appointment.appointmentDate < todayDate);
    }, [appointmentsByConfirmed]);

    return (
      <Box
        data-section="customer"
        sx={{
          display: 'grid',
          gridTemplateRows: `repeat(${activeModules.length}, 1fr)`,
          rowGap: '30px',
        }}
      >
        {activeModules.includes('appointments') && (
          <CockpitModuleStyled $url="images/bg-appointments.png">
            <CockpitModuleTitleStyled>{localesStore.translate('cockpit.label.services')}</CockpitModuleTitleStyled>
            <CockpitProcessesListStyled>
              <CockpitProcessesListItemStyled to="/appointments" onClick={handleTabCall.bind(this, Tabs.Opened)}>
                <p>{localesStore.translate('cockpit.appointments.status.Requested')}</p>
                <span>{appointmentsByOpened.length ?? 0}</span>
              </CockpitProcessesListItemStyled>
              <CockpitProcessesListItemStyled to="/appointments" onClick={handleTabCall.bind(this, Tabs.Confirmed)}>
                <p>{localesStore.translate('cockpit.appointments.status.Planned')}</p>
                <span>
                  {appointmentsByConfirmed.length ?? 0} ({appointmentsByToday.length ?? 0})
                </span>
              </CockpitProcessesListItemStyled>
            </CockpitProcessesListStyled>
            <CockpitModuleButtonStyled onClick={handleFormCall}>
              <Add />
              <span>{localesStore.translate('cockpit.appointments.controls.new')}</span>
            </CockpitModuleButtonStyled>
          </CockpitModuleStyled>
        )}
        {activeModules.includes('customer') && (
          <CockpitModuleStyled $url="images/bg-customer.png">
            <CockpitModuleTitleStyled>{localesStore.translate('cockpit.label.customer')}</CockpitModuleTitleStyled>
            <CockpitModuleTileGroupStyled>
              <CockpitModuleTileStyled to="/customer/new" $isDirection>
                <Add />
                <span>{localesStore.translate('cockpit.tile.customer.create')}</span>
              </CockpitModuleTileStyled>
              <CockpitModuleTileStyled to="/customer/search" $isDirection>
                <Search />
                <span>{localesStore.translate('cockpit.tile.customer.search')}</span>
              </CockpitModuleTileStyled>
            </CockpitModuleTileGroupStyled>
          </CockpitModuleStyled>
        )}
      </Box>
    );
  })
);

const CockpitCatalogSection = View(
  'Cockpit',
  'CockpitCatalogSection'
)(
  observer(props => {
    const {
      store: { localesStore, modules, CatalogModel, ComplaintsModel, storageStore, ComplaintsProcessingModel },
    } = props;

    const navigate = useNavigate();

    const activeModules = useMemo(() => modules.filter(module => ['catalog', 'complaints'].includes(module)), [modules]);
    
    const { archivedComplaints, parkedComplaints} = ComplaintsModel

    useEffect(() => {
      ComplaintsProcessingModel.register(navigate);
    }, []);

    return (
      <Box
        data-section="catalog"
        sx={{
          display: 'grid',
          gridTemplateRows: `repeat(${activeModules.length}, 1fr)`,
          rowGap: '30px',
        }}
      >
         {activeModules.includes('complaints') && (
            <CockpitModuleStyled $url="images/bg-complaints.png">
              <CockpitModuleTitleStyled>Kundenreklamationen</CockpitModuleTitleStyled>
  
              <CockpitProcessesListStyled>
                <CockpitProcessesListItemStyled to="/complaints/parked">
                  <p>In Erstellung</p>
                  <span>{parkedComplaints.length}</span>
                </CockpitProcessesListItemStyled>
                <CockpitProcessesListItemStyled to="/complaints/archive">
                  <p>Archiv</p>
                  <span>{archivedComplaints.length}</span>
                </CockpitProcessesListItemStyled>
              </CockpitProcessesListStyled>

              
              <CockpitModuleButtonStyled onClick={() => {
                  ComplaintsProcessingModel.reset();
                  ComplaintsProcessingModel.toggleDestinationWindow(true)
                }}>
                Reklamation erfassen
              </CockpitModuleButtonStyled>
            </CockpitModuleStyled>
        )}
        {activeModules.includes('catalog') && (
          <Box
            sx={{
              pointerEvents: storageStore.isOutlet() ? 'none' : 'all',
              opacity: storageStore.isOutlet() ? '0.3' : '1',
            }}
          >
            <CockpitModuleStyled $url="images/bg-catalog.png">
              <CockpitModuleTitleStyled>{localesStore.translate('cockpit.label.catalog')}</CockpitModuleTitleStyled>
  
              <Box>
                <CockpitSearchStyled
                  placeholder={localesStore.translate('cockpit.controls.search')}
                  onKeyPress={e => {
                    if (e.key === 'Enter' && e.target.value.trim() !== '') {
                      CatalogModel.handleCockpitSearch(e);
                      navigate('/catalog');
                    }
                  }}
                />
                <CockpitModuleTileStyled to="/catalog">
                  {localesStore.translate('cockpit.tile.catalog')}
                </CockpitModuleTileStyled>
              </Box>
            </CockpitModuleStyled>
          </Box>
        )}
      </Box>
    );
  })
);

const CockpitProcessesSection = View(
  'Cockpit',
  'CockpitProcessesSection'
)(
  observer(props => {
    const {
      store: { localesStore, storageStore, CockpitModel, OrdersModel, RequestsModel, modules },
    } = props;

    const activeModules = useMemo(() => modules.filter(module => ['catalog'].includes(module)), [modules]);

    const { isLoading } = CockpitModel,
      {
        Tabs: OrderTabs,
        isFinishedLoaded,
        handleTabCall: handleOrdersTabCall,
        ordersByInCreation,
        ordersByWaitingForDelivery,
        ordersByWaitingForCustomers,
        ordersByFinished,
      } = OrdersModel,
      { Tabs: RequestTabs, requestsByOpened, handleTabCall: handleRequestsTabCall } = RequestsModel;

    return (
      <Box
        data-section="processes"
        sx={{
          display: 'grid',
          gridTemplateRows: `repeat(${activeModules.length}, 1fr)`,
          rowGap: '30px',
          pointerEvents: storageStore.isOutlet() ? 'none' : 'all',
          opacity: storageStore.isOutlet() ? '0.5' : '1',
        }}
      >
        {activeModules.includes('catalog') && (
          <CockpitModuleStyled $url="images/bg-processes.png">
            <CockpitModuleTitleStyled>{localesStore.translate('cockpit.label.orders')}</CockpitModuleTitleStyled>

            <CockpitProcessesListStyled $isLoading={isLoading && !storageStore.isOutlet()}>
              <CockpitProcessesListItemStyled onClick={handleOrdersTabCall.bind(this, OrderTabs.InCreation)}>
                <p>{localesStore.translate('cockpit.orders.status.Creation')}</p>
                <span>{ordersByInCreation.length}</span>
              </CockpitProcessesListItemStyled>
              <CockpitProcessesListItemStyled onClick={handleOrdersTabCall.bind(this, OrderTabs.WaitingForDelivery)}>
                <p>{localesStore.translate('cockpit.orders.status.Delivery')}</p>
                <span>{ordersByWaitingForDelivery.length}</span>
              </CockpitProcessesListItemStyled>
              <CockpitProcessesListItemStyled onClick={handleOrdersTabCall.bind(this, OrderTabs.WaitingForCustomer)}>
                <p>{localesStore.translate('cockpit.orders.status.Customer')}</p>
                <span>{ordersByWaitingForCustomers.length}</span>
              </CockpitProcessesListItemStyled>
              <CockpitProcessesListItemStyled onClick={handleOrdersTabCall.bind(this, OrderTabs.Finished)}>
                <p>{localesStore.translate('cockpit.orders.status.Finished')}</p>
                <span>{isFinishedLoaded ? ordersByFinished.length : '\u21CB'}</span>
              </CockpitProcessesListItemStyled>
            </CockpitProcessesListStyled>

            <CockpitRequestsStyled
              to="/requests"
              $isLoading={isLoading && !storageStore.isOutlet()}
              onClick={handleRequestsTabCall.bind(this, RequestTabs.Opened)}
            >
              <p>{localesStore.translate('cockpit.orders.status.Requested')}</p>
              <span>{requestsByOpened.length}</span>
            </CockpitRequestsStyled>

            <CockpitModuleTileStyled to="/catalog" $isLoading={isLoading && !storageStore.isOutlet()}>
              {isLoading && !storageStore.isOutlet() ? (
                <CockpitModuleTileLoaderStyled>
                  <span>{localesStore.translate('placeholder.modal.loader')}</span>
                </CockpitModuleTileLoaderStyled>
              ) : (
                <Fragment>
                  <span>{localesStore.translate('cockpit.tile.orders')}</span>
                  <Add />
                </Fragment>
              )}
            </CockpitModuleTileStyled>
          </CockpitModuleStyled>
        )}
      </Box>
    );
  })
);

const CockpitView = View('Cockpit')(props => {
  const navigate = useNavigate();

  const {
    models: {
      CockpitModel,
      OrdersModel,
      RequestsModel,
      BarcodeScannerModel,
      ArticleModel,
      AuthModel,
      AppointmentsModel,
      AppointmentsFormModel,
      CatalogModel,
      CartModel,
      ComplaintsModel,
      ComplaintsProcessingModel,
    },
    store: { helperStore, localesStore, storageStore, networkStore, modules },
  } = props;

  const { viewMounted } = CockpitModel,
    { fetchArticleGroupsByBarcode } = ArticleModel,
    { isDataLoaded: isOrdersDataLoaded } = OrdersModel,
    { isDataLoaded: isRequestsDataLoaded } = RequestsModel;

  useEffect(() => {
    viewMounted();
  }, [isOrdersDataLoaded, isRequestsDataLoaded]);

  return (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.cockpitAnimationDirection)}>
      <Box display="flex" height="100%" flexDirection="column">
        <CockpitToolbarStyled>
          <CockpitToolbarBoxStyled data-section="employer">
            <CockpitEmployerStyled>
              <span>{localesStore.translate('cockpit.label.merchant')}:</span>
              {storageStore.merchant.fullName}
            </CockpitEmployerStyled>

            <ExitToAppOutlined onClick={() => AuthModel.logout()} />
          </CockpitToolbarBoxStyled>
          <CockpitToolbarBoxStyled data-section="heading">
            <h2>{localesStore.translate('page.title.cockpit')}</h2>
          </CockpitToolbarBoxStyled>
          <CockpitToolbarBoxStyled data-section="scanner">
            <span>{localesStore.translate('cockpit.label.scanner')}</span>
            <RectangleWrapperStyled
              onClick={() => {
                BarcodeScannerModel.callBarcodeScannerPromise(null, true).then(value => {
                  if (value?.type === 'ShoppingCart') {
                    CartModel.setCart(value?.data);
                    navigate('/cart');
                  } else {
                    fetchArticleGroupsByBarcode(value).then(error => {
                      if (error) {
                        networkStore.trackAnalytics('barcode_scan_not_successful', 'Article', value);
                        
                        return swal('', localesStore.translate('modal.article.label.error'), 'error')
                      }
                      
                      networkStore.trackAnalytics('barcode_scan_successful', 'Article', value);
                    });
                  }
                });
              }}
            >
              <Barcode />
            </RectangleWrapperStyled>
          </CockpitToolbarBoxStyled>
        </CockpitToolbarStyled>
        <CockpitGridStyled>
          <CockpitCustomerSection
            store={{
              localesStore,
              AppointmentsModel,
              AppointmentsFormModel,
              modules,
            }}
          />
          <CockpitCatalogSection store={{ localesStore, modules, CatalogModel, storageStore, ComplaintsProcessingModel, ComplaintsModel }} />
          <CockpitProcessesSection
            store={{
              storageStore,
              localesStore,
              CockpitModel,
              OrdersModel,
              RequestsModel,
              modules,
            }}
          />
        </CockpitGridStyled>
      </Box>
    </PageContainerStyled>
  );
});

export default inject('models', 'store')(observer(CockpitView));
