import { format } from 'date-fns';
import { useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { MoreHoriz } from '@mui/icons-material';
import { Table, TableHead, TableBody, Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import View from '@framework/decorators/View';

import Phone from '@framework/icons/Phone';
import Store from '@framework/icons/Store';

import {
  BackButtonStyled,
  ToolbarStyled,
  LayoutContainerStyled,
  PageContainerStyled,
  CounterStyled,
  CircleWrapperStyled,
  TabsStyled,
  TabStyled,
} from '@framework/styles/App';

import {
  TableRowHeadStyled,
  TableCellHeadStyled,
  TableHeadGapStyled,
  TableBodyGapStyled,
  TableContainerStyled,
  TableRowBodyStyled,
  TableCellBodyStyled,
  TableCardStyled,
} from '@framework/styles/Table';

const AppointmentsView = View('Appointments')(props => {
  const {
    models: { AppointmentsModel, AppointmentsProcessingModel },
    store: { helperStore, localesStore },
  } = props;

  const theme = useTheme(),
    navigate = useNavigate(),
    isMobile = helperStore.isMobile();

  const {
      Tabs,
      Type,
      currentTab,
      viewMounted,
      appointmentsByOpened,
      appointmentsByConfirmed,
      appointmentsByCanceled,
      appointmentsByStatus,
      handleTabCall,
    } = AppointmentsModel,
    { handleAppointmentCall } = AppointmentsProcessingModel;

  useEffect(() => viewMounted(), []);

  return (
    <PageContainerStyled backward={helperStore.isLTR(helperStore.baseAnimationDirection)}>
      <LayoutContainerStyled $scrollable>
        <ToolbarStyled $centered>
          <BackButtonStyled
            onClick={e => {
              e.preventDefault();
              helperStore.baseAnimationDirection = helperStore.Direction.Rtl;
              helperStore.screensAnimationDirection = helperStore.Direction.Rtl;
              navigate('/');
            }}
          >
            {localesStore.translate('placeholder.label.back')}
          </BackButtonStyled>
          <h2>{localesStore.translate('page.title.appointments')}</h2>
        </ToolbarStyled>
        <TableHeadGapStyled />
        <TableContainerStyled>
          {isMobile ? (
            appointmentsByStatus.map((item, index) => (
              <TableCardStyled $grid="1fr max-content" key={item.objectId} onClick={() => handleAppointmentCall(item)}>
                <div>
                  <Box p="15px 0 0">
                    <Box fontSize={18} fontWeight={600}>
                      {localesStore.translate(`appointments.status.${item.status}`)}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'grid',
                      gridAutoRows: 'max-content',
                      rowGap: '10px',
                      padding: '15px 0',
                      borderBottom: `1px solid ${theme.application.palette.whitesmoke}`,
                    }}
                  >
                    <Box sx={{ fontSize: '12px', fontWeight: '600' }}>
                      {localesStore.translate('placeholder.table.createdDate') + ': '}
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={{ fontSize: '12px' }}>
                        {localesStore.translate('placeholder.time.at') + ': ' + format(+item.created, 'dd MMM, yyyy')}
                      </Box>
                      <Box sx={{ marginLeft: '10px', fontSize: '12px' }}>
                        {localesStore.translate('placeholder.time.around') +
                          ': ' +
                          format(+item.created, 'HH:mm') +
                          ' ' +
                          localesStore.translate('placeholder.label.time')}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      padding: '15px 0',
                      borderBottom: `1px solid ${theme.application.palette.whitesmoke}`,
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: '5px',
                        fontSize: '12px',
                        fontWeight: '600',
                      }}
                    >
                      {`${item.firstName} ${item.lastName}`}
                    </Box>
                    <Box
                      sx={{
                        marginBottom: '5px',
                        fontSize: '12px',
                        fontWeight: '600',
                      }}
                    >
                      {item.email}
                    </Box>
                    <Box sx={{ fontSize: '12px', fontWeight: '600' }}>{item.phone}</Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'grid',
                      gridAutoRows: 'max-content',
                      rowGap: '10px',
                      padding: '15px 0',
                      borderBottom: `1px solid ${theme.application.palette.whitesmoke}`,
                    }}
                  >
                    <Box sx={{ fontSize: '12px', fontWeight: '600' }}>
                      {localesStore.translate('placeholder.table.requestedDate') + ': '}
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={{ fontSize: '12px' }}>
                        {localesStore.translate('placeholder.time.at') +
                          ': ' +
                          format(+item.appointmentDate, 'dd MMM, yyyy')}
                      </Box>
                      <Box sx={{ fontSize: '12px', marginLeft: '10px' }}>
                        {localesStore.translate('placeholder.time.around') +
                          ': ' +
                          format(+item.appointmentDate, 'HH:mm') +
                          ' ' +
                          localesStore.translate('placeholder.label.time')}
                      </Box>
                    </Box>
                  </Box>
                </div>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: '5px',
                  }}
                >
                  <Box>{item.type === Type.Store ? <Store /> : <Phone />}</Box>
                  <CircleWrapperStyled>
                    <MoreHoriz />
                  </CircleWrapperStyled>
                </Box>
              </TableCardStyled>
            ))
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRowHeadStyled>
                  <TableCellHeadStyled>
                    <span>{localesStore.translate('placeholder.table.type')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled>
                    <span>{localesStore.translate('placeholder.table.createdDate')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled width="25%">
                    <span>{localesStore.translate('placeholder.table.customer')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled>
                    <span>{localesStore.translate('placeholder.table.requestedDate')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled align="center">
                    <span>{localesStore.translate('placeholder.table.status')}</span>
                  </TableCellHeadStyled>
                  <TableCellHeadStyled />
                </TableRowHeadStyled>
              </TableHead>
              <TableBody>
                {appointmentsByStatus.map(item => (
                  <Fragment key={item.objectId}>
                    <TableBodyGapStyled />
                    <TableRowBodyStyled onClick={() => handleAppointmentCall(item)}>
                      <TableCellBodyStyled>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {item.type === Type.Store ? <Store /> : <Phone />}
                        </Box>
                      </TableCellBodyStyled>
                      <TableCellBodyStyled sx={{ whiteSpace: 'nowrap' }}>
                        <Box>
                          {localesStore.translate('placeholder.time.at') + ': ' + format(+item.created, 'dd MMM, yyyy')}
                        </Box>
                        <Box>
                          {localesStore.translate('placeholder.time.around') +
                            ': ' +
                            format(+item.created, 'HH:mm') +
                            ' ' +
                            localesStore.translate('placeholder.label.time')}
                        </Box>
                      </TableCellBodyStyled>
                      <TableCellBodyStyled $isEllipsed sx={{ wordBreak: 'break-word' }}>
                        <div>{`${item.firstName} ${item.lastName}`}</div>
                        <div>{item.email}</div>
                        <div>{item.phone}</div>
                      </TableCellBodyStyled>
                      <TableCellBodyStyled sx={{ whiteSpace: 'nowrap' }}>
                        <Box>
                          {localesStore.translate('placeholder.time.at') +
                            ': ' +
                            format(+item.appointmentDate, 'dd MMM, yyyy')}
                        </Box>
                        <Box>
                          {localesStore.translate('placeholder.time.around') +
                            ': ' +
                            format(+item.appointmentDate, 'HH:mm') +
                            ' ' +
                            localesStore.translate('placeholder.label.time')}
                        </Box>
                      </TableCellBodyStyled>
                      <TableCellBodyStyled sx={{ textAlign: 'center' }}>
                        {localesStore.translate(`appointments.status.${item.status}`)}
                      </TableCellBodyStyled>
                      <TableCellBodyStyled>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CircleWrapperStyled>
                            <MoreHoriz />
                          </CircleWrapperStyled>
                        </Box>
                      </TableCellBodyStyled>
                    </TableRowBodyStyled>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainerStyled>
        <TabsStyled $quantity={3} $isMobile={isMobile}>
          <TabStyled $isActive={currentTab === Tabs.Opened} onClick={handleTabCall.bind(this, Tabs.Opened)}>
            <CounterStyled $isActive={currentTab === Tabs.Opened}>{appointmentsByOpened.length}</CounterStyled>
            <span>{localesStore.translate('appointments.controls.Opened')}</span>
          </TabStyled>
          <TabStyled $isActive={currentTab === Tabs.Confirmed} onClick={handleTabCall.bind(this, Tabs.Confirmed)}>
            <CounterStyled $isActive={currentTab === Tabs.Confirmed}>{appointmentsByConfirmed.length}</CounterStyled>
            <span>{localesStore.translate('appointments.controls.Confirmed')}</span>
          </TabStyled>
          <TabStyled $isActive={currentTab === Tabs.Canceled} onClick={handleTabCall.bind(this, Tabs.Canceled)}>
            <CounterStyled $isActive={currentTab === Tabs.Canceled}>{appointmentsByCanceled.length}</CounterStyled>
            <span>{localesStore.translate('appointments.controls.Closed')}</span>
          </TabStyled>
        </TabsStyled>
      </LayoutContainerStyled>
    </PageContainerStyled>
  );
});

export default inject('store', 'models')(observer(AppointmentsView));
