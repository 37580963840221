import AuthModel from '@framework/models/Auth';
import CockpitModel from '@framework/models/Cockpit';
import HeaderModel from '@framework/models/Header';
import OrdersModel from '@framework/models/Orders';
import RequestsModel from '@framework/models/Requests';
import SearchModel from '@framework/models/Search';
import CatalogModel from '@framework/models/Catalog';
import ArticleModel from '@framework/models/Article';
import OrdersProcessingModel from '@framework/models/OrdersProcessing';
import RequestsProcessingModel from '@framework/models/RequestsProcessing';
import CartModel from '@framework/models/Cart';
import CustomerDetailsModel from '@framework/models/CustomerDetails';
import BarcodeScannerModel from '@framework/models/Barcode';
import CustomerProductsModel from '@framework/models/CustomerProducts';
import CustomerTransactionModel from '@framework/models/CustomerTransaction';
import CustomerVouchersModel from '@framework/models/CustomerVouchers';
import CustomerSignatureModel from '@framework/models/CustomerSignature';
import FormModel from '@framework/models/Form';
import OrderModel from '@framework/models/Order';
import CartProcessingModel from '@framework/models/CartProcessing';
import AppointmentsModel from '@framework/models/Appointments';
import AppointmentsFormModel from '@framework/models/AppointmentsForm';
import AppointmentsProcessingModel from '@framework/models/AppointmentsProcessing';
import ComplaintsProcessingModel from '@framework/models/ComplaintsProcessing';
import CameraModel from '@framework/models/Camera';
import ComplaintsModel from '@framework/models/Complaints';

import Model from '@framework/decorators/Model';

export class RootModels {
  constructor(store) {
    this.store = store;

    this.AppointmentsModel = new AppointmentsModel(this);
    this.AppointmentsFormModel = new AppointmentsFormModel(this);
    this.AppointmentsProcessingModel = new AppointmentsProcessingModel(this);
  }

  HeaderModel = new HeaderModel(this);
  AuthModel = new AuthModel(this);
  CockpitModel = new CockpitModel(this);
  OrdersModel = new OrdersModel(this);
  OrdersProcessingModel = new OrdersProcessingModel(this);
  RequestsModel = new RequestsModel(this);
  RequestsProcessingModel = new RequestsProcessingModel(this);
  CustomerDetailsModel = new CustomerDetailsModel(this);
  SearchModel = new SearchModel(this);
  CustomerProductsModel = new CustomerProductsModel(this);
  CustomerVouchersModel = new CustomerVouchersModel(this);
  CustomerSignatureModel = new CustomerSignatureModel(this);
  CustomerTransactionModel = new CustomerTransactionModel(this);
  CatalogModel = new CatalogModel(this);
  ArticleModel = new ArticleModel(this);
  CartModel = new CartModel(this);
  FormModel = new FormModel(this);
  BarcodeScannerModel = new BarcodeScannerModel(this);
  OrderModel = new OrderModel(this);
  CartProcessingModel = new CartProcessingModel(this);
  ComplaintsProcessingModel = new ComplaintsProcessingModel(this);
  CameraModel = new CameraModel(this);
  ComplaintsModel = new ComplaintsModel(this);
}

export default Model('RootModels')(RootModels);
