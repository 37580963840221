import { inject, observer } from 'mobx-react';
import { Fragment, useCallback } from 'react';
import { Box, InputAdornment, useTheme } from '@mui/material';
import { Close, PhotoCamera } from '@mui/icons-material';

import Modal from '@framework/decorators/Modal';

import {
  BarcodeScannerDialogStyled,
  BarcodeScannerTitleStyled,
  BarcodeScannerContentStyled,
  BarcodeScannerInputStyled,
  BarcodeScannerStreamStyled,
} from '@framework/styles/Barcode';

import { ModalAnimation } from '@framework/styles/App';

const BarcodeScannerModal = Modal('BarcodeScannerModal')(props => {
  const {
    models: { BarcodeScannerModel },
    store: { debug, helperStore, localesStore },
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile();

  const {
    isToggled,
    isCameraReady,
    inputValue,
    currentCamera,
    cameraList,
    subtitle,
    barcodeScannerMounted,
    handleCloseCall,
    handleInputSubmit,
    handleInputClear,
    handleInputChange,
    switchCamera,
  } = BarcodeScannerModel;

  const videoRef = useCallback(
    node => {
      if (node !== null) {
        barcodeScannerMounted(node);
      }
    },
    [currentCamera]
  );

  return (
    <BarcodeScannerDialogStyled
      fullScreen
      open={isToggled}
      onClose={handleCloseCall}
      TransitionComponent={ModalAnimation}
      sx={{ zIndex: '10000001' }}
    >
      {isToggled ? (
        <Fragment>
          <BarcodeScannerTitleStyled>
            {cameraList.length > 1 && (
              <Box
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  left: '20px',
                  lineHeight: 0,
                }}
                onClick={switchCamera}
              >
                <PhotoCamera />
              </Box>
            )}
            <Box display="flex" justifyContent="center" width="100%">
              <Box
                sx={
                  debug
                    ? {
                      position: 'relative',
                      display: 'flex',
                      height: '40px',
                      '& > img': {
                        opacity: '0.3',
                      },
                      '&::after': {
                        content: '"[prelive]"',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        letterSpacing: '5px',
                        lineHeight: '2',
                        fontSize: '20px',
                        fontWeight: '400',
                        color: theme.application.palette.danger,
                      },
                    }
                    : {
                      display: 'flex',
                      height: '40px',
                    }
                }
              >
                <img
                  height="100%"
                  src="images/ig-logotype-dark.png"
                  alt=""
                />
              </Box>
            </Box>

            <Box
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                right: '20px',
                lineHeight: 0,
              }}
              onClick={handleCloseCall}
            >
              <Close />
            </Box>
          </BarcodeScannerTitleStyled>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={isMobile ? '10px' : '20px'}
            fontSize={isMobile ? 16 : 24}
            fontWeight="bold"
            bgcolor={theme.application.palette.darkgray}
          >
            <span>{localesStore.translate('modal.scanner.label.title')}</span>
            <span>{subtitle}</span>
          </Box>
          <BarcodeScannerContentStyled>
            <Box bgcolor={theme.application.palette.black} flexGrow={1} height="100%" zIndex={0}>
              <BarcodeScannerStreamStyled ref={videoRef} />
              <Box
                position="absolute"
                width="40%"
                top="calc(50% - 20px)"
                left="30%"
                borderBottom={`3px solid ${theme.application.palette.white}`}
                zIndex={10}
              />
              <Box
                position="absolute"
                bottom="20px"
                left="50%"
                p={isMobile ? '10px' : '20px'}
                sx={{ transform: 'translateX(-50%)' }}
                bgcolor={theme.application.palette.darkgray}
                zIndex={10}
              >
                <BarcodeScannerInputStyled
                  placeholder={localesStore.translate('modal.scanner.label.placeholder')}
                  value={inputValue}
                  endAdornment={
                    inputValue.length ? (
                      <InputAdornment position="end" onClick={handleInputClear}>
                        <Close />
                      </InputAdornment>
                    ) : null
                  }
                  onChange={handleInputChange}
                  onKeyPress={e => {
                    if (e.key === 'Enter') handleInputSubmit();
                  }}
                />
              </Box>
            </Box>
          </BarcodeScannerContentStyled>
          <Box
            display="flex"
            justifyContent="center"
            padding={isMobile ? '20px' : '40px'}
            textAlign="center"
            bgcolor={theme.application.palette.aliceblue}
          >
            {localesStore.translate(`modal.scanner.label.${isCameraReady ? 'description' : 'camera'}`)}
          </Box>
        </Fragment>
      ) : null}
    </BarcodeScannerDialogStyled>
  );
});

export default inject('store', 'models')(observer(BarcodeScannerModal));
