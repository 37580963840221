import SignatureCanvas from 'react-signature-canvas/src';

import { observer, inject } from 'mobx-react';
import { Box, Dialog } from '@mui/material';
import { Close } from '@mui/icons-material';

import { DialogWrapperStyled } from '@framework/styles/Loader';
import { ButtonStyled, SignatureContainerStyled } from '@framework/styles/App';

import Modal from '@framework/decorators/Modal';

const CustomerSignatureModal = Modal('CustomerSignatureModal')(props => {
  const {
    store: { helperStore, localesStore },
    models: { CustomerSignatureModel },
  } = props;

  const isMobile = helperStore.isMobile(),
    {
      isToggled,
      isEmpty,
      canvasRef,
      setCanvasNode,
      setIsEmpty,
      handleCloseCall,
      handleConfirmClick,
      handleClearClick,
    } = CustomerSignatureModel;

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
      open={isToggled}
      onClose={handleCloseCall}
      sx={{ zIndex: 10000010, '& .MuiDialog-paper': { height: '100%' } }}
    >
      <DialogWrapperStyled
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        p="20px"
        height="100%"
      >
        <Box display="flex" justifyContent="center" alignItems="center" position="relative" width="100%">
          <Box fontSize={28}>{localesStore.translate('modal.signature.label.title')}</Box>
          <Box position="absolute" right="0px" lineHeight={0} sx={{ cursor: 'pointer' }} onClick={handleCloseCall}>
            <Close />
          </Box>
        </Box>
        <SignatureContainerStyled>
          <SignatureCanvas
            ref={setCanvasNode}
            onBegin={() => {
              if (isEmpty) setIsEmpty(false);
            }}
            canvasProps={{
              width: canvasRef.current?.getCanvas()?.offsetWidth,
              height: canvasRef.current?.getCanvas()?.offsetHeight,
            }}
            backgroundColor="rgba(243,243,243, 0)"
          />
        </SignatureContainerStyled>
        <Box
          display="grid"
          width="100%"
          gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
          columnGap="100px"
          rowGap="10px"
          gridTemplateRows={isMobile ? '60px 60px' : '60px'}
        >
          <ButtonStyled onClick={handleClearClick} disabled={isEmpty}>
            {localesStore.translate('modal.signature.controls.clear')}
          </ButtonStyled>
          <ButtonStyled onClick={handleConfirmClick} disabled={isEmpty}>
            {localesStore.translate('modal.signature.controls.confirm')}
          </ButtonStyled>
        </Box>
      </DialogWrapperStyled>
    </Dialog>
  );
});

export default inject('models', 'store')(observer(CustomerSignatureModal));
