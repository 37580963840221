import { Box, Container, Dialog, Fade, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Modal from '@framework/decorators/Modal';

import Barcode from '@framework/icons/Barcode';

import FormComponent from '@framework/components/Form';
import SearchComponent from '@framework/components/Search';

import { ModalAnimation, RectangleWrapperStyled } from '@framework/styles/App';
import { TrackerDialogTitleStyled, TrackerDialogContentStyled } from '@framework/styles/Tracker';

const CartFormModal = Modal('CartFormModal')(props => {
  const {
    store: { debug, helperStore, localesStore },
    models: { CartProcessingModel, SearchModel, OrdersModel },
  } = props;

  const
    theme = useTheme(),
    location = useLocation(),
    navigate = useNavigate(),
    isMobile = helperStore.isMobile();

  const {
      Customer: { Editor, Creation, Guest },
      customer,
      destination,
      isSearchComponent,
      isFormToggled,
      handleFormClose,
    } = CartProcessingModel,
    { handleBarcodeScanner, setup } = SearchModel,
    {
      Type: { InStore },
    } = OrdersModel;

  const getTitle = useCallback(() => {
    switch (customer) {
      case Editor: {
        return isSearchComponent && !isMobile
          ? localesStore.translate('page.title.prefix') + ' ' + localesStore.translate('page.title.customer.search')
          : localesStore.translate('page.title.customer.details');
      }

      case Creation: {
        return isMobile
          ? localesStore.translate('page.title.customer.creation')
          : localesStore.translate('page.title.prefix') + ' ' + localesStore.translate('page.title.customer.creation');
      }

      case Guest: {
        return localesStore.translate('page.title.order.guest');
      }
    }
  }, [customer, isSearchComponent]);

  useEffect(() => {
    if (isFormToggled) SearchModel.register(navigate, location);
  }, [isFormToggled]);

  useEffect(() => {
    if (isSearchComponent) setup(true, CartProcessingModel.options.type === 'reservation');
  }, [isSearchComponent]);

  return (
    <Dialog
      fullScreen
      open={isFormToggled}
      onClose={handleFormClose}
      TransitionComponent={ModalAnimation}
      sx={{ zIndex: 10000000 }}
    >
      <TrackerDialogTitleStyled>
        <Box display="flex" justifyContent="center" width="100%">
          <Box
            sx={
              debug
                ? {
                  position: 'relative',
                  display: 'flex',
                  height: '40px',
                  '& > img': {
                    opacity: '0.3',
                  },
                  '&::after': {
                    content: '"[prelive]"',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    letterSpacing: '5px',
                    lineHeight: '2',
                    fontSize: '20px',
                    fontWeight: '400',
                    color: theme.application.palette.danger,
                  },
                }
                : {
                  display: 'flex',
                  height: '40px',
                }
            }
          >
            <img height="100%" src="images/ig-logotype-dark.png" alt="" />
          </Box>
          <Box
            onClick={handleFormClose}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '20px',
            }}
          >
            <Close />
          </Box>
        </Box>
      </TrackerDialogTitleStyled>
      <TrackerDialogContentStyled>
        <Fade in={true} key={isSearchComponent}>
          <Container
            maxWidth="lg"
            sx={{
              height: '100%',
              overflow: 'hidden auto',
              display: isSearchComponent ? 'grid' : 'block',
              gridTemplateRows: isSearchComponent ? '100%' : 'unset',
              padding: isMobile && '0px',
              flexGrow: '1',
            }}
          >
            <Box
              mt={isMobile && '15px'}
              p={isMobile ? '10px' : '20px'}
              display="flex"
              flexDirection="column"
              flexGrow={isSearchComponent ? '1' : ''}
            >
              <Box
                position="relative"
                display="grid"
                alignItems="center"
                justifyContent="center"
                component="h2"
                fontSize={24}
                textAlign="center"
                fontWeight={400}
                mb="20px"
              >
                {getTitle()}
                {isSearchComponent && (
                  <Box
                    sx={{
                      display: 'flex',
                      position: 'absolute',
                      top: '50%',
                      right: '0',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    <RectangleWrapperStyled onClick={handleBarcodeScanner}>
                      <Barcode />
                    </RectangleWrapperStyled>
                  </Box>
                )}
              </Box>
              {isSearchComponent ? (
                <SearchComponent />
              ) : (
                <FormComponent
                  isAdditionalAddressEnabled={(customer === Editor || customer === Creation) && destination === InStore}
                />
              )}
            </Box>
          </Container>
        </Fade>
      </TrackerDialogContentStyled>
    </Dialog>
  );
});

export default inject('store', 'models')(observer(CartFormModal));
